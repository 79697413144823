import { IUserCompanyProps } from '@/components/Interfaces/UserCompanyProps';
import Button from '@/components/Ui/Button';
import DefaultHeader from '@/components/Ui/DefaultHeader';
import { DefaultContainer } from '@/components/Ui/DefaultHeader/style';
import { FullScreenLoader } from '@/components/Ui/FullScreenLoader';
import Input from '@/components/Ui/Input';
import ModalConfirm from '@/components/Ui/Modal/ModalConfirm';
import ModalError from '@/components/Ui/Modal/ModalError';
import Select from '@/components/Ui/Select';
import Toggle from '@/components/Ui/Toggle';
import { ISignInProps } from '@/modules/_layouts/components/HeaderCompany/components/UserSetting';
import { splitName } from '@/utils/Utilities';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import jwtDecode from 'jwt-decode';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { ContainerButton } from '../components/EditContainer/styles';
import { getOptionsEntidadesCtrl } from '../controllers/getOptionsEntidadesCtrl';
import { UsuarioPayload, UsuarioPayloadV1 } from './interfaces/user';
import { criarUsuarioRequest } from './providers/criarUsuario';

interface SchemaValidator {
  username: Yup.StringSchema<string>;
  name: Yup.StringSchema<string>;
  email: Yup.StringSchema<string>;
  password: Yup.StringSchema<string>;
  corporation?: Yup.StringSchema<string>;
}

const CriarUsuario: React.FC = () => {
  const history = useHistory();
  const accessToken = useSelector((state: ISignInProps) => state.SignIn.access_token);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [isSuperUser, setIsSuperUser] = useState(false);
  const [corporationId, setCorporationId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [entidadeOptions, setEntidadeOptions] = useState<any[]>([]);

  const [is_admin, setIsCheckedAdmin] = useState(false);
  const handleChangeAdmin = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckedAdmin(e.target.checked);
  };
  const [is_superuser_protestado, setIsCheckedSuperUser] = useState(false);
  const handleChangeSuperUser = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckedSuperUser(e.target.checked);
  };

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (fields: UsuarioPayload) => {
      try {
        setIsLoading(true);
        setLoadingMessage('Criando Usuário...');

        formRef.current?.setErrors({});

        const schemaObject = {
          username: Yup.string().required('Por favor preencha seu username completo'),
          name: Yup.string().required('Por favor preencha seu nome completo'),
          email: Yup.string()
            .required('Por favor informe um email')
            .matches(/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/, 'Insira um email válido'),
          password: Yup.string().required('Por favor preencha sua senha')
        } as SchemaValidator;

        if (isSuperUser) schemaObject.corporation = Yup.string().required('Por favor preencha a Empresa');

        const schema = Yup.object().shape(schemaObject);

        await schema.validate(fields, {
          abortEarly: false
        });

        if (!fields.corporation) fields.corporation = corporationId;

        fields.is_admin = is_admin;
        fields.is_superuser_protestado = is_superuser_protestado;

        const { firstName, lastName } = splitName(fields.username);

        const payload = {
          first_name: firstName,
          last_name: lastName,
          username: fields.email,
          password: fields.password,
          email: fields.email,
          is_staff: false,
          is_active: true,
          is_superuser: is_superuser_protestado,
          privilege: is_admin ? 'ADMIN' : 'BASIC',
          company_id: fields.corporation
        } as UsuarioPayloadV1;

        await criarUsuarioRequest(payload);
        setShowModal(true);
      } catch (err) {
        setShowModalError(true);
        const validationErrors: { [key: string]: string } = {};

        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: Yup.ValidationError) => {
            validationErrors[error.path] = error.message;
          });

          formRef.current?.setErrors(validationErrors);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [corporationId, is_admin, is_superuser_protestado, isSuperUser]
  );

  const loadSelectOptions = useCallback(async () => {
    try {
      setIsLoading(true);
      setLoadingMessage('Preparando Criação de Usuário...');
      const entidadesOpt = await getOptionsEntidadesCtrl();
      setEntidadeOptions(entidadesOpt);
    } catch (e) {
      toast.error('Erro ao carregar informações de Entidades!');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    let continua = false;
    if (accessToken) {
      const userDecode = jwtDecode<IUserCompanyProps>(accessToken);
      if (userDecode && userDecode.is_superuser_protestado) {
        continua = true;
        setIsSuperUser(true);
      } else {
        setCorporationId(userDecode.corporation_id);
      }
    }

    if (continua) {
      loadSelectOptions();
    }
  }, [loadSelectOptions, accessToken]);

  return (
    <>
      {isLoading && <FullScreenLoader loadingMessage={loadingMessage} />}
      <DefaultContainer paddingTop paddingBottom>
        <DefaultHeader label="Criar usuário" pageMessage="Informe abaixo os detalhes do novo Usuário"></DefaultHeader>
        <Form className="editForm" ref={formRef} onSubmit={handleSubmit}>
          <Input name="username" type="text" placeholder="Ex.: Fernando.Silva" label="Nome de Usuário" full data-cy="username" />
          <Input name="name" type="text" placeholder="Ex.: Fernando Aparecido da Silva" label="Nome Completo*" full data-cy="name" />
          <Input name="email" type="string" placeholder="Ex.: fernando@protestado.com.br" label="E-mail*" full data-cy="email" />
          <Input name="password" type="text" placeholder="Crie uma senha para esse usuário" label="Senha*" full data-cy="password" />
          {isSuperUser && <Select name="corporation" label="Corporation*" color="gray400" isBold options={entidadeOptions} />}
          <Toggle onChange={handleChangeAdmin} label="Admin" value={is_admin} name="is_admin" paddingRight margin="20px 0px 0px 0px" />
          <Toggle
            onChange={handleChangeSuperUser}
            label="Super usuário"
            isView={isSuperUser}
            value={is_superuser_protestado}
            name="is_superuser_protestado"
            paddingRight
            margin="20px 0px 0px 0px"
          />

          <ContainerButton>
            <Button type="submit" size="big" full color="primary" loading={isLoading} disabled={!!isLoading} data-cy="criarUsuario">
              Criar Usuário
            </Button>
          </ContainerButton>
        </Form>
      </DefaultContainer>

      <ModalConfirm
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        handleButtonClick={() => history.push('/usuarios')}
        label="Usuário Criado com Sucesso"
        buttonLabel="Ok"
      />
      <ModalError
        isOpen={showModalError}
        onClose={() => setShowModalError(false)}
        handleButtonClick={() => setShowModalError(false)}
        label="Erro na Solicitação, verifique os dados informados"
        buttonLabel="Ok"
      />
    </>
  );
};

export default CriarUsuario;
