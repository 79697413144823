import React from 'react';
import GoBack from '../GoBack';
import Text from '../Text';
import Title from '../Title';
import { MarginTopTitle, PageMessage } from './style';

interface Props {
  url?: string;
  label?: string;
  pageMessage?: string;
  marginTop?: string;
}

const DefaultHeader: React.FC<Props> = ({ url, label = '', pageMessage = '', marginTop }) => {
  return (
    <>
      <GoBack url={url} />
      <MarginTopTitle marginTop={marginTop} />
      <Title label={label} isVisiblePixel />
      {pageMessage !== '' && (
        <PageMessage>
          <Text size="medium2">{pageMessage}</Text>
        </PageMessage>
      )}
    </>
  );
};

export default DefaultHeader;
