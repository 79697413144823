import api from '@/services/api'

interface ISendEmailConfirmation {
  cpf: string | number
}

export const sendEmailConfirmation = (cpf: ISendEmailConfirmation) => {
  return api.post('/auth/send-email-confirmation', cpf).then(({ data }) => {
    return data
  })
}
