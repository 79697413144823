import React, { useEffect, useRef } from 'react';
import InputMask from 'react-input-mask';
import { useField } from '@unform/core';
import { ClearableInputContainer, Container, IconContainer } from './styles';
import { MdClear } from 'react-icons/md';
import Tooltip from '../Tooltip';

interface Props<Multiline = false> {
  label?: string;
  note?: string;
  name: string;
  multiline?: Multiline;
  full?: boolean;
  mask?: string | (string | RegExp)[];
  maskChar?: string;
  isBold?: boolean;
  height?: string;
  width?: string;
  pattern?: string;
  disabled?: boolean;
  readOnly?: boolean;
  onBlur?(event: React.SyntheticEvent): Promise<void>;
  onChange?(event: React.SyntheticEvent): void;
  value?: string | boolean | number;
  isView?: boolean;
  isClearable?: boolean;
  iconSize?: string;
  padding?: string;
  tooltip?: boolean;
  tooltipMessage?: string;
  tooltipPlace?: 'top' | 'right' | 'bottom' | 'left';
  tooltipType?: 'dark' | 'success' | 'warning' | 'error' | 'info' | 'light';
  tooltipEffect?: 'float' | 'solid';
  iconFontSize?: string;
  tooltipBackgroundColor?: string;
  clearFunction?: () => void;
  customMargin?: string;
}

type InputProps = JSX.IntrinsicElements['input'] & Props<false>;
type TextAreaProps = JSX.IntrinsicElements['textarea'] & Props<true>;

const Input: React.FC<InputProps | TextAreaProps> = ({
  label,
  note,
  name,
  multiline,
  mask,
  maskChar = '_',
  full,
  isBold,
  height,
  disabled = false,
  readOnly = false,
  onChange,
  onBlur,
  value,
  pattern,
  width,
  isView = true,
  isClearable = false,
  iconSize,
  padding,
  tooltip = false,
  tooltipMessage,
  tooltipPlace,
  tooltipType,
  tooltipEffect,
  iconFontSize,
  tooltipBackgroundColor,
  clearFunction,
  customMargin,
  defaultValue,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  const { fieldName, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      path: 'value',
      ref: inputRef.current
    });
  }, [fieldName, registerField]);

  const props = {
    ...rest,
    ref: inputRef,
    id: fieldName,
    'aria-label': fieldName,
    defaultValue: defaultValue,
    disabled: disabled,
    readOnly: readOnly
  };

  return (
    <Container height={height} full={full} isBold={isBold} width={width} isView={isView}>
      {label && (
        <label htmlFor={fieldName}>
          {label}
          <Tooltip
            isView={tooltip}
            tooltipMessage={tooltipMessage}
            place={tooltipPlace}
            type={tooltipType}
            effect={tooltipEffect}
            iconFontSize={iconFontSize}
            backgroundColor={tooltipBackgroundColor}
          />
        </label>
      )}
      {note && <small>{note}</small>}

      {multiline && <textarea {...(props as TextAreaProps)} onChange={onChange} />}

      <ClearableInputContainer customMargin={customMargin} height={height} isErrored={!!error && error !== 'undefined'}>
        {mask ? (
          <InputMask
            readOnly={readOnly}
            disabled={disabled}
            mask={mask || ''}
            maskChar={maskChar}
            onBlur={onBlur}
            value={value}
            onChange={onChange}
            pattern={pattern}
          >
            {() => <input pattern={pattern} {...(props as InputProps)} />}
          </InputMask>
        ) : (
          <input {...(props as InputProps)} onChange={onChange} onBlur={onBlur} value={value} pattern={pattern} />
        )}
        <IconContainer isClearable={isClearable} iconSize={iconSize} padding={padding} onClick={clearFunction}>
          <MdClear />
        </IconContainer>
      </ClearableInputContainer>

      {error && error !== 'undefined' && <span>{error}</span>}
    </Container>
  );
};

export default Input;
