import styled, { css } from 'styled-components';
import flex from '@/assets/styles/flex';
import theme from '@/assets/styles/theme/';

interface EditarTituloProps {
  marginTopBoleto?: boolean;
  marginTopCartao?: boolean;
}

interface CompanyDescriptionProps {
  margin?: string;
}

interface OfertasProps {
  margin?: string;
}

interface Options {
  backgroundColor?: string;
}

const heights = {
  descriptionItem: css`
    height: 78px;

    @media only screen and (max-width: 768px) {
      height: 56px;
    }
  `
};

export const CompanyDescription = styled.div<CompanyDescriptionProps>`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;
  margin: ${({ margin }) => (margin ? margin : '50px 0px 0px 0px')};

  // zoom 200%
  @media (min-width: 768.99px) and (max-width: 1024px) {
    ${flex.justify.spaceBetween}
  }

  @media only screen and (max-width: 768px) {
    ${flex.justify.spaceBetween}
    display: block;
  }
`;

export const CompanyDetails = styled.div`
  ${flex.direction.column}
  ${flex.justifySpaceBetween.alignStart}
  margin-left: 10px;
  margin-top: 0px;
  width: 100%;

  @media only screen and (max-width: 768px) {
    margin: 0;
    height: 60px;
    margin-top: 30px;
  }
`;

export const CompanyDetailsToggle = styled.div`
  ${flex.direction.column}
  ${flex.justify.end}
  ${flex.align.center}
  margin-left: 10px;
  margin-top: 0px;
  width: 30%;

  @media only screen and (max-width: 768px) {
    margin: 0;
    height: 60px;
    margin-top: 30px;
  }
`;

export const OfertasContainer = styled.div<OfertasProps>`
  margin: ${(props) => props.margin || '60px 0px 0px 0px'};
  width: 100%;
`;

export const MensageriaStatusContainer = styled.div`
  margin-top: 60px;
  width: 100%;
`;

export const MensageriaArea = styled.div<Options>`
  display: flex;
  border-radius: 20px;
  margin: 0px;
  padding: 60px;
  width: 100%;
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : '')};
`;

export const TextArea = styled.div`
  display: grid;
  width: 65%;
`;

export const ContainerCriarOferta = styled.div`
  display: grid;
  align-self: end;
  margin: -8px;
`;

export const ContainerNovaOferta = styled.div`
  ${flex.direction.column};
  ${flex.justify.spaceBetween}
  width: 100%;
  padding: 10px 40px;
  margin-bottom: 50px;
  border-radius: 10px;
  background-color: ${theme.colors.white};
  @media only screen and (max-width: 1366px) {
    margin-bottom: 30px;
  }
  @media only screen and (max-width: 768px) {
    padding: 20px;
    margin-bottom: 20px;
  }
`;

export const InputsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  padding: 0px 8px 0px 8px;
`;

export const ReactToggleRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  padding: 0px 8px 0px 8px;
  margin-top: 30px;
`;

export const ReactToggleContainerBoleto = styled.div<EditarTituloProps>`
  display: flex;
  align-items: center;
  margin-top: ${(EditarTituloProps) => (EditarTituloProps.marginTopBoleto ? '40px' : '')};
`;

export const ReactToggleContainerCartao = styled.div<EditarTituloProps>`
  display: flex;
  align-items: center;
  margin-top: ${(EditarTituloProps) => (EditarTituloProps.marginTopCartao ? '40px' : '')};
`;

export const ReactToggleContainerStyle = styled.div`
  display: flex;
  align-items: center;
`;

export const FormInformationContainer = styled.div<CompanyDescriptionProps>`
  margin: ${({ margin }) => (margin ? margin : '50px 0px 0px 0px')};

  .label {
    margin: 10px 10px;
    font-size: 20px;
    color: ${theme.colors.primary};
    font-weight: bold;
    -webkit-text-decoration-color: ${theme.colors.primary};
    text-decoration-color: ${theme.colors.primary};
  }

  .red-label {
    font-size: 20px;
    color: #ff0000;
    font-weight: bold;
    -webkit-text-decoration-color: #ff0000;
    text-decoration-color: #ff0000;
  }

  .green-label {
    font-size: 20px;
    color: #008000;
    font-weight: bold;
    -webkit-text-decoration-color: #008000;
    text-decoration-color: #008000;
  }

  .statusTitulo {
    margin: 10px 10px 10px 0px;
    font-size: 20px;
    color: ${theme.colors.primary};
    font-weight: bold;
    -webkit-text-decoration-color: ${theme.colors.primary};
    text-decoration-color: ${theme.colors.primary};
  }
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 20px 10px 0px 10px;
  font-size: 20px;
  font-weight: 500;
  color: ${theme.colors.primary};
`;

export const ContainerStatusTitulo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TituloStatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
