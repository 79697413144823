import theme from '@/assets/styles/theme';
import Text from '@/components/Ui/Text';
import DescriptionContainer from '@/modules/Deal/components/DescriptionContainer';
import ChipStatusContainer from '@/modules/Entidades/ListarOfertasTitulo/components/chipStatusContainer';
import { formatarDinheiro } from '@/utils/Utilities';
import moment from 'moment';
import React from 'react';
import { IconContext } from 'react-icons';
import { AiOutlineBarcode, AiOutlineWallet } from 'react-icons/ai';
import { BsFillCheckCircleFill, BsInfoCircleFill } from 'react-icons/bs';
import { MdOutlineCreditCard } from 'react-icons/md';
import { AgreementV1 } from '../../interfaces/interfaceRelatorio';
import { AreaIcon, BtnItemTipoPagamentoAviso, BtnItemTipoPagamentoSucesso, Container, Info, ItemDetails } from './styles';

interface IProps {
  posts: AgreementV1[];
}

const DataList: React.FC<IProps> = ({ posts }) => {
  return (
    <div className="list__container">
      <div className="list_data">
        {posts.map((item: AgreementV1) => {
          return (
            <Container className="Container" key={item.id}>
              <DescriptionContainer>
                <ItemDetails width="10%">
                  <Text margin="10px 10px" size="small" bold>
                    {item.titles_numbers}
                  </Text>
                </ItemDetails>
                <ItemDetails width="15%">
                  <Text margin="10px 10px" size="small">
                    {item.status === 'CONFIRMED' ? (
                      <BtnItemTipoPagamentoSucesso>
                        <AreaIcon>
                          <IconContext.Provider
                            value={{
                              size: '30px',
                              color: theme.colors.secondary
                            }}
                          >
                            <BsFillCheckCircleFill />
                          </IconContext.Provider>
                        </AreaIcon>
                        <Info>
                          <Text size="small" margin="0px 5px" color="secondary">
                            Pagamento
                          </Text>
                          <Text size="small" margin="0px 5px" color="secondary" bold>
                            Confirmado
                          </Text>
                        </Info>
                      </BtnItemTipoPagamentoSucesso>
                    ) : (
                      <BtnItemTipoPagamentoAviso>
                        <AreaIcon>
                          <IconContext.Provider
                            value={{
                              size: '30px',
                              color: 'gray'
                            }}
                          >
                            <BsInfoCircleFill />
                          </IconContext.Provider>
                        </AreaIcon>
                        {item.status === 'PENDING' ? (
                          <Info>
                            <Text size="small" margin="0px 5px" color="gray500">
                              Pagamento
                            </Text>
                            <Text size="small" margin="0px 5px" color="gray500" bold>
                              Pendente
                            </Text>
                          </Info>
                        ) : item.status === 'PAYMENT_DEFERRED' ? (
                          <Info>
                            <Text size="small" margin="0px 5px" color="gray500">
                              Pagamento
                            </Text>
                            <Text size="small" margin="0px 5px" color="gray500" bold>
                              Deferido
                            </Text>
                          </Info>
                        ) : item.status === 'PAYMENT_DECLINED' ? (
                          <Info>
                            <Text size="small" margin="0px 5px" color="gray500">
                              Pagamento
                            </Text>
                            <Text size="small" margin="0px 5px" color="gray500" bold>
                              Recusado
                            </Text>
                          </Info>
                        ) : item.status === 'AUTHORIZED' ? (
                          <Info>
                            <Text size="small" margin="0px 5px" color="gray500">
                              Acordo
                            </Text>
                            <Text size="small" margin="0px 5px" color="gray500" bold>
                              Autorizado
                            </Text>
                          </Info>
                        ) : item.status === 'APPROVED' ? (
                          <Info>
                            <Text size="small" margin="0px 5px" color="gray500">
                              Acordo
                            </Text>
                            <Text size="small" margin="0px 5px" color="gray500" bold>
                              Aprovado
                            </Text>
                          </Info>
                        ) : item.status === 'PAID' ? (
                          <Info>
                            <Text size="small" margin="0px 5px" color="gray500">
                              Acordo
                            </Text>
                            <Text size="small" margin="0px 5px" color="gray500" bold>
                              Pago
                            </Text>
                          </Info>
                        ) : item.status === 'PARTIALLY_PAID' ? (
                          <Info>
                            <Text size="small" margin="0px 5px" color="gray500">
                              Parcialmente
                            </Text>
                            <Text size="small" margin="0px 5px" color="gray500" bold>
                              Pago
                            </Text>
                          </Info>
                        ) : item.status === 'CANCELED' ? (
                          <Info>
                            <Text size="small" margin="0px 5px" color="gray500">
                              Acordo
                            </Text>
                            <Text size="small" margin="0px 5px" color="gray500" bold>
                              Cancelado
                            </Text>
                          </Info>
                        ) : (
                          <Info>
                            <Text size="small" margin="0px 5px" color="gray500">
                              Status
                            </Text>
                            <Text size="small" margin="0px 5px" color="gray500" bold>
                              Desconhecido
                            </Text>
                          </Info>
                        )}
                      </BtnItemTipoPagamentoAviso>
                    )}
                  </Text>
                </ItemDetails>
                <ItemDetails width="20%">
                  <Text margin="10px 10px" size="small">
                    {item.payment_type === 'CREDIT_CARD' ? (
                      <BtnItemTipoPagamentoSucesso>
                        <AreaIcon>
                          <IconContext.Provider
                            value={{
                              size: '30px',
                              color: '#666666'
                            }}
                          >
                            <MdOutlineCreditCard />
                          </IconContext.Provider>
                        </AreaIcon>
                        <Info>
                          <Text size="small" margin="0px 5px" color="gray500" bold>
                            Cartão de Crédito
                          </Text>
                        </Info>
                      </BtnItemTipoPagamentoSucesso>
                    ) : (
                      <Text></Text>
                    )}
                    {item.payment_type === 'BOLETO' ? (
                      <BtnItemTipoPagamentoSucesso>
                        <AreaIcon>
                          <IconContext.Provider
                            value={{
                              size: '30px',
                              color: '#666666'
                            }}
                          >
                            <AiOutlineBarcode />
                          </IconContext.Provider>
                        </AreaIcon>
                        <Info>
                          <Text size="small" margin="0px 5px" color="gray500" bold>
                            Boleto Bancário
                          </Text>
                        </Info>
                      </BtnItemTipoPagamentoSucesso>
                    ) : (
                      <Text></Text>
                    )}
                    {item.payment_type === 'PIX' ? (
                      <BtnItemTipoPagamentoSucesso>
                        <AreaIcon>
                          <IconContext.Provider
                            value={{
                              size: '30px',
                              color: '#666666'
                            }}
                          >
                            <AiOutlineWallet />
                          </IconContext.Provider>
                        </AreaIcon>
                        <Info>
                          <Text size="small" margin="0px 5px" color="gray500" bold>
                            PIX
                          </Text>
                        </Info>
                      </BtnItemTipoPagamentoSucesso>
                    ) : (
                      <Text></Text>
                    )}
                  </Text>
                </ItemDetails>
                <ItemDetails width="10%">
                  <Text margin="10px 10px" size="small">
                    {formatarDinheiro(Number(item.agreed_value))}
                  </Text>
                </ItemDetails>
                <ItemDetails width="7%">
                  <Text margin="10px 10px" size="small">
                    {moment(item.updated_at).format('DD/MM/YYYY')}
                  </Text>
                </ItemDetails>
                <ItemDetails width="15%">
                  <Text margin="10px 10px" size="small">
                    <ChipStatusContainer size="small">
                      {item.offer_type === 'TITLE' ? (
                        <Text margin="10px 10px">
                          <BtnItemTipoPagamentoSucesso>
                            <AreaIcon>
                              <IconContext.Provider
                                value={{
                                  size: '30px',
                                  color: '#666666'
                                }}
                              >
                                <BsInfoCircleFill />
                              </IconContext.Provider>
                            </AreaIcon>
                            <Info>
                              <Text size="small" margin="0px 10px" color="gray500">
                                Oferta
                              </Text>
                              <Text size="small" margin="0px 10px" color="gray500" bold>
                                Título
                              </Text>
                            </Info>
                          </BtnItemTipoPagamentoSucesso>
                        </Text>
                      ) : (
                        ''
                      )}
                      {item.offer_type === 'GLOBAL' ? (
                        <BtnItemTipoPagamentoSucesso>
                          <AreaIcon>
                            <IconContext.Provider
                              value={{
                                size: '30px',
                                color: '#666666'
                              }}
                            >
                              <BsInfoCircleFill />
                            </IconContext.Provider>
                          </AreaIcon>
                          <Info>
                            <Text size="small" margin="0px 10px" color="gray500">
                              Oferta
                            </Text>
                            <Text size="small" margin="0px 10px" color="gray500" bold>
                              Global
                            </Text>
                          </Info>
                        </BtnItemTipoPagamentoSucesso>
                      ) : (
                        ''
                      )}
                    </ChipStatusContainer>
                  </Text>
                </ItemDetails>
              </DescriptionContainer>
            </Container>
          );
        })}
      </div>
    </div>
  );
};

export default DataList;
