import theme from '@/assets/styles/theme';
import styled from 'styled-components';

interface OfertaTituloProps {
  padding?: string;
  isCenter?: boolean;
  textPrimary?: boolean;
}

export const InformationContainer = styled.div`
  background-color: white;
  border-radius: 5px;
  margin-top: 20px;
  border: 0.07em solid ${theme.colors.gray[300]};
`;

export const InformationRow = styled.div<OfertaTituloProps>`
  display: flex;
  justify-content: ${(props) => (props.isCenter ? 'center' : 'space-between')};
  padding: ${(props) => (props.padding ? props.padding : '16px 24px 16px 24px')};
`;

export const InformationRowContainer = styled.div<OfertaTituloProps>`
  display: flex;
  justify-content: space-between;
  width: 50%;
  padding: ${(props) => (props.padding ? props.padding : '16px 24px 16px 24px')};
`;

export const InformationTitle = styled.div<OfertaTituloProps>`
  color: ${(props) => (props.textPrimary ? theme.colors.primary : theme.colors.gray[500])};
  font-weight: 500;
  font-size: 1.25rem;
`;

export const InformationData = styled.div<OfertaTituloProps>`
  color: ${(props) => (props.textPrimary ? theme.colors.primary : theme.colors.gray[500])};
  font-weight: 500;
  font-size: 1.1rem;
`;
