import { IUserCompanyProps } from '@/components/Interfaces/UserCompanyProps';
import Button from '@/components/Ui/Button';
import DefaultHeader from '@/components/Ui/DefaultHeader';
import { DefaultContainer } from '@/components/Ui/DefaultHeader/style';
import { FullScreenLoader } from '@/components/Ui/FullScreenLoader';
import Input from '@/components/Ui/Input';
import ModalConfirm from '@/components/Ui/Modal/ModalConfirm';
import ModalError from '@/components/Ui/Modal/ModalError';
import Toggle from '@/components/Ui/Toggle';
import { ISignInProps } from '@/modules/_layouts/components/HeaderCompany/components/UserSetting';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import jwtDecode from 'jwt-decode';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Entidades } from '../ListarEntidades/interfaces/interface';
import { ContainerButton } from '../components/EditContainer/styles';
import { getOptionsEntidadesCtrl } from '../controllers/getOptionsEntidadesCtrl';
import { UserPayload } from './interfaces/user';
import { getUserById } from './providers/getUser';
import { updateUsuario } from './providers/updateUser';

interface Props {}

interface ISelectEntidades {
  value: string;
  label: string;
}

const EditarUsuario: React.FC<Props> = () => {
  const history = useHistory();
  const url = window.location.href.split('/');
  const userId = url[4];
  const accessToken = useSelector((state: ISignInProps) => state.SignIn.access_token);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [is_superuser_logado, setSuperUserLogado] = useState(false);
  const [showModalSucess, setShowModalSucess] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [is_active, setIsCheckedA] = useState(false);
  const handleChangeActive = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckedA(e.target.checked);
  };

  const [is_admin, setIsCheckedAdmin] = useState(false);
  const handleChangeAdmin = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckedAdmin(e.target.checked);
  };

  const [is_superuser_protestado, setIsCheckedSuperUser] = useState(false);
  const handleChangeSuperUser = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckedSuperUser(e.target.checked);

    if (e.target.checked) {
      setIsCheckedAdmin(e.target.checked);
    }
  };

  const [entidade, setEntidade] = useState<Entidades[]>([]);
  const [entidadeOptions, setEntidadeOptions] = useState<ISelectEntidades[]>([]);
  const handleSelectBankSlipParcel = (parcel: any) => {
    setEntidade(parcel);
  };

  const formRef = useRef<FormHandles>(null);
  const getUserData = async () => {
    try {
      setIsLoading(true);
      setLoadingMessage('Carregando detalhes de Usuário...');
      const user = await getUserById(userId);

      formRef.current?.setData({
        id: user?.id,
        email: user?.email,
        is_active: user?.is_active,
        is_admin: user?.is_admin,
        is_superuser_protestado: user?.is_superuser_protestado
      });

      if (user.is_active === true) {
        setIsCheckedA(true);
      }
      if (user.is_admin === true) {
        setIsCheckedAdmin(true);
      }
      if (user.is_superuser_protestado === true) {
        setIsCheckedSuperUser(true);
      }
    } catch (error) {
      toast.error('Não foi possível carregar seu dados, tente mais tarde!');
    } finally {
      setIsLoading(false);
    }
  };

  const getEntidadesList = useCallback(async () => {
    try {
      setIsLoading(true);
      setLoadingMessage('Carregando detalhes de Usuário...');
      const entidadeOptions = await getOptionsEntidadesCtrl();
      setEntidadeOptions(entidadeOptions);
    } catch (e) {
      toast.error('Erro ao carregar informações de Entidades!');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getUserData();
    if (accessToken) {
      const userDecode = jwtDecode<IUserCompanyProps>(accessToken);
      if (userDecode && userDecode.is_superuser_protestado) {
        getEntidadesList();
        setSuperUserLogado(true);
      }
    }
  }, []);

  const handleSubmit = useCallback(
    async (fields: UserPayload) => {
      try {
        setIsLoading(true);
        setLoadingMessage('Atualizando Usuário...');

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          id: Yup.string().required('Por favor preencha o Id'),
          email: Yup.string()
            .required('Por favor informe um email')
            .matches(/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/, 'Insira um email válido')
        });

        await schema.validate(fields, {
          abortEarly: false
        });

        const payload = {
          id: fields.id,
          email: fields.email,
          is_active: is_active,
          privilege: is_superuser_protestado ? 'SUPERUSER' : is_admin ? 'ADMIN' : 'BASIC',
          is_superuser: is_superuser_protestado
        };

        await updateUsuario(payload);
        setShowModalSucess(true);
      } catch (err) {
        setShowModalError(true);

        const validationErrors: { [key: string]: string } = {};

        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: Yup.ValidationError) => {
            validationErrors[error.path] = error.message;
          });
          formRef.current?.setErrors(validationErrors);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [is_active, is_admin, is_superuser_protestado, entidade]
  );

  return (
    <>
      {isLoading && <FullScreenLoader loadingMessage={loadingMessage} />}
      <DefaultContainer paddingTop paddingBottom>
        <DefaultHeader label="Editar usuário" pageMessage="Altere abaixo os detalhes do Usuário"></DefaultHeader>
        <Form className="editForm" ref={formRef} onSubmit={handleSubmit}>
          <Input readOnly name="id" type="string" placeholder="Ex.: " label="Id do Usuário" full />

          <Input name="email" type="string" placeholder="Ex.: protestado@protestado.com.br" label="E-mail*" full />
          {/* <Select
            isView={is_superuser_logado}
            name="entidades"
            label="Pertence a empresa"
            color="gray400"
            isBold
            onChange={handleSelectBankSlipParcel}
            options={entidadeOptions}
          /> */}
          <Toggle onChange={handleChangeActive} label="Ativo" value={is_active} name="is_active" paddingRight margin="20px 0px 0px 0px" />
          <Toggle
            onChange={handleChangeAdmin}
            label="Admin"
            value={is_admin}
            name="is_admin"
            disabled={is_superuser_protestado}
            paddingRight
            margin="20px 0px 0px 0px"
          />
          <Toggle
            onChange={handleChangeSuperUser}
            label="Super usuário"
            isView={is_superuser_logado}
            value={is_superuser_protestado}
            name="is_superuser_protestado"
            paddingRight
            margin="20px 0px 0px 0px"
          />
          <ContainerButton>
            <Button type="submit" size="big" full color="primary" loading={isLoading} disabled={!!isLoading} data-cy="salvarAlteracoes">
              Salvar Alterações
            </Button>
          </ContainerButton>
        </Form>
      </DefaultContainer>

      <ModalConfirm
        isOpen={showModalSucess}
        onClose={() => setShowModalSucess(false)}
        handleButtonClick={() => history.push('/usuarios')}
        label="Usuário Editado com Sucesso"
        buttonLabel="Ok"
      />
      <ModalError
        isOpen={showModalError}
        onClose={() => setShowModalError(false)}
        handleButtonClick={() => setShowModalError(false)}
        label="Erro na Solicitação, verifique os dados informados"
        buttonLabel="Ok"
      />
    </>
  );
};

export default EditarUsuario;
