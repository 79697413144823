import { put, call } from 'redux-saga/effects'

import api from '@/services/api'
import history from '@/services/history'

import { ForgotRequestPayload, Creators } from '../ducks/ForgotPassword'

export function* forgotRequest(payload: ForgotRequestPayload) {
  const { cpf } = payload
  try {
    const response = yield call(api.post, '/auth/forgot-password', {
      cpf,
    })
    history.push('/email-sent')
    const { email } = response.data
    yield put(Creators.ForgotSuccess(email))
  } catch (error) {
    const { code, message } = error.response.data
    yield put(Creators.ForgotFailure({ code, message }))
  }
}
