import { put, call } from 'redux-saga/effects'

import api from '@/services/api'
import history from '@/services/history'

import { ForgotCompanyRequestPayload, Creators } from '../ducks/ForgotPasswordCompany'

export function* forgotCompanyRequest(payload: ForgotCompanyRequestPayload) {
  const { email } = payload
  try {
    const response = yield call(api.post, 'auth/corporation/forgot-password', {
      email,
    })
    history.push('/email-sent')
    const { emailresponse } = response.data
    yield put(Creators.ForgotSuccess(emailresponse))
  } catch (error) {
    const { code, message } = error.response.data
    yield put(Creators.ForgotFailure({ code, message }))
  }
}
