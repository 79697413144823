import Button from '@/components/Ui/Button';
import Text from '@/components/Ui/Text';
import DescriptionContainer from '@/modules/Deal/components/DescriptionContainer';
import { TextContainer } from '@/modules/Entidades/ListarOfertasGlobais/components/paginate/styles';
import { formatarDinheiro } from '@/utils/Utilities';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { OfertaTitulo } from '../../interfaces/interface';
import ChipStatus from '../chipStatus/ChipStatus';
import ChipStatusContainer from '../chipStatusContainer';
import { Container, ContainerButton, ItemDetails, ListContainer, PaymentDiscount, SectionDiscount } from './styles';

interface IProps {
  offers: OfertaTitulo[];
}

const DataList: React.FC<IProps> = ({ offers }) => {
  const history = useHistory();

  const handleOferta = useCallback((oferta: OfertaTitulo, tipoDescricao: string) => {
    if (!oferta.desconto && !oferta.desconto_porcentagem) {
      if (tipoDescricao === 'label') {
        return 'Emolumentos (R$)';
      }
    } else {
      return getTipoDesconto(oferta, tipoDescricao);
    }
  }, []);

  const getTipoDesconto = (oferta: OfertaTitulo, tipoDescricao: string): string => {
    let ofertaDescontoNumber = oferta.desconto;
    if (typeof oferta.desconto === 'string') {
      ofertaDescontoNumber = Number(oferta.desconto);
    }
    if (ofertaDescontoNumber) {
      if (tipoDescricao === 'label') {
        return 'Desconto: ';
      } else {
        return Number(oferta.desconto).toFixed(2);
      }
    } else {
      if (tipoDescricao === 'label') {
        return 'Desconto (%): ';
      } else {
        return oferta.desconto_porcentagem;
      }
    }
  };

  return (
    <ListContainer>
      {offers.map((item: OfertaTitulo, index: number) => {
        return (
          <Container key={item.key}>
            <DescriptionContainer>
              <ItemDetails>
                <Text size="medium" bold>
                  Id da Oferta: {item.id}
                </Text>
                <Text margin="12px 0px 0px 0px" size="small">
                  Id do Título: {item.titulo}
                </Text>
              </ItemDetails>
              <ItemDetails>
                <ChipStatusContainer size="small" bold>
                  <ChipStatus statusArquivo={item.status} />
                </ChipStatusContainer>
              </ItemDetails>

              <SectionDiscount>
                <TextContainer>
                  <Text margin="0px 0px 0px 0px" size="medium" bold>
                    {handleOferta(item, 'label')}
                  </Text>
                </TextContainer>
                <PaymentDiscount>
                  {Number(item.desconto) > 0 || Number(item.desconto_porcentagem) > 0 ? (
                    <Text align="center" color="primary" size="medium" bold>
                      {item.desconto
                        ? `${formatarDinheiro(Number(item.desconto))}`
                        : `${formatarDinheiro(Number(item.desconto_porcentagem), false)}`}
                    </Text>
                  ) : (
                    <Text align="center" size="small" color="primary" bold>
                      Oferta sem desconto
                    </Text>
                  )}
                </PaymentDiscount>
              </SectionDiscount>

              <ContainerButton>
                <Button
                  full
                  onClick={() => history.push(`/ofertas/listar-ofertas-titulo/${item.id}`)}
                  data-cy={`visualizarOferta${index}`}
                  id={`visualizarOferta${index}`}
                >
                  Visualizar Oferta
                </Button>
              </ContainerButton>
            </DescriptionContainer>
          </Container>
        );
      })}
    </ListContainer>
  );
};

export default DataList;
