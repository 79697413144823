import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react';
import React from 'react';
import { DisableTitlesBatchResponse, TitlesBatchResponse } from '../../interfaces/DisableTitlesBatchResponse';

interface Props {
  response: DisableTitlesBatchResponse | null;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const BatchTitlesResponseModal = ({ response, isOpen, onClose, onConfirm }: Props) => {
  const confirmModal = () => {
    onConfirm();
    onClose();
  };

  const renderTitles = (titles: TitlesBatchResponse[]) =>
    titles.map((title) => (
      <Text fontSize="xl" my="12px" key={`${title.id}`}>
        Nº Título: {title.title_number}
      </Text>
    ));

  return (
    <Modal
      closeOnOverlayClick={false}
      blockScrollOnMount={true}
      scrollBehavior="inside"
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader bg="blue.900" color="white">
          Títulos Desabilitados
        </ModalHeader>
        <ModalCloseButton color="white" tabIndex={2} />
        <ModalBody>
          <Text fontSize="xl" mb="24px" mt="12px" fontWeight="500">
            Confira abaixo o resultado da sua solicitação:
          </Text>
          {response?.updated_titles && response.updated_titles.length > 0 && (
            <>
              <Text fontSize="xl" mt="24px" mb="12px" fontWeight="500">
                Títulos atualizados:
              </Text>
              {response.updated_titles && renderTitles(response.updated_titles)}
            </>
          )}
          {response?.not_updated_titles && response.not_updated_titles.length > 0 && (
            <>
              <Text fontSize="xl" mt="24px" mb="12px" fontWeight="500">
                Títulos não atualizados:
              </Text>
              {response.not_updated_titles && renderTitles(response.not_updated_titles)}
            </>
          )}
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="green" onClick={confirmModal} tabIndex={1}>
            Confirmar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BatchTitlesResponseModal;
