import { put, call } from 'redux-saga/effects'
import api from '@/services/api'
import { toast } from 'react-toastify'

import { EmailConfirmationRequestPayload, Creators } from '../ducks/ConfirmEmail'

export function* emailConfirmationRequest(payload: EmailConfirmationRequestPayload) {
  const { user_id } = payload
  try {
    const response = yield call(api.post, '/auth/confirm-email', {
      user_id,
    })
    const { affected } = response.data
    
    if (affected) {
      yield put(Creators.emailConfirmationSuccess())
      toast.success('Conta Confirmada!')
    } else {
      yield put(Creators.emailConfirmationFailure())
      toast.error('Falha na Solicitação!')
    }
  } catch (error) {
    
    yield put(Creators.emailConfirmationFailure())
    toast.error('Erro na Solicitação!')
  }
}
