import styled from 'styled-components';

export const PaginationSpacer = styled.div`
  margin: 30px 0px 0px 0px;
`;

export const PaginationContainer = styled.div`
  margin: 0px 0px 0px 0px;
  width: 100%;
  display: flex;
  flex-direction: inherit;
  align-items: center;
  justify-content: center;
`;

export const PaginationTextContainer = styled.div`
  align-self: center;
  margin: 0px 30px 0px 30px;
`;
