import React from 'react'

import styled from 'styled-components'

import backgroundImage from '@/assets/images/pantone-background.svg'
import GlobalStyle from '@/assets/styles/global'

import PanelLeft from '../Auth/components/PanelLeft'
import PanelRight from '../Auth/components/PanelRight'
// Styles
export const ContainerAuth = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-wrap: wrap;
  height: 100%;
  align-content: flex-start;
  background: url(${backgroundImage});
  background-color: #f6f7f6;
  background-size: cover;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

const AuthLayout: React.FC = ({ children }) => {
  return (
    <ContainerAuth>
      <GlobalStyle />
      <PanelLeft />
      <PanelRight>{children}</PanelRight>
    </ContainerAuth>
  )
}

export default AuthLayout
