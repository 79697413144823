import React from 'react'

import { ErrorMessage } from './styles'

interface ErrorMessageInterface {
  label: string
}

const ErrorMessageComponent: React.FC<ErrorMessageInterface> = ({ label }) => (
  <ErrorMessage>{label}</ErrorMessage>
)

export default ErrorMessageComponent
