import styled from 'styled-components';

interface Props {
  status: boolean;
}

export const ChipStatus = styled.div<Props>`
  background-color: ${(props) => (props.status === true ? '#26d076' : 'red')};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 10px 5px 10px;
  padding: 5px 0px 5px 10px;
  border-radius: 10px;
  color: white;
  min-width: 200px;
`;
