import { connectRouter } from 'connected-react-router'
import { History } from 'history'
import { combineReducers } from 'redux'

import { reducer as EmailConfirmation } from './ConfirmEmail'
import { reducer as ForgotPassword } from './ForgotPassword'
import { reducer as RecoverPassword } from './RecoverPassword'
import { reducer as SignIn } from './SignIn'
import { reducer as SignUp } from './SignUp'

const reducers = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    SignIn,
    ForgotPassword,
    RecoverPassword,
    SignUp,
    EmailConfirmation,
  })

export default reducers
