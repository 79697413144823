import { css } from 'styled-components';

export default {
  start: css`
    justify-content: flex-start;
  `,
  center: css`
    justify-content: center;
  `,
  end: css`
    justify-content: flex-end;
  `,
  spaceBetween: css`
    justify-content: space-between;
  `
};
