/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from '@/components/Ui/Button';
import DefaultHeader from '@/components/Ui/DefaultHeader';
import { DefaultContainer } from '@/components/Ui/DefaultHeader/style';
import { FullScreenLoader } from '@/components/Ui/FullScreenLoader';
import Input from '@/components/Ui/Input';
import ModalConfirm from '@/components/Ui/Modal/ModalConfirm';
import ModalError from '@/components/Ui/Modal/ModalError';
import { getOnlyNumbers, getStringBetweenParentheses } from '@/utils/Utilities';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { cnpj as cnpjValidator, cpf as cpfValidator } from 'cpf-cnpj-validator';
import React, { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { ContainerButton } from '../components/EditContainer/styles';
import { EntidadePayload } from './interfaces/entidade';
import { criarEntidadeRequest } from './providers/createEntidade';

const CriarEntidade: React.FC = () => {
  const [loadingMessage, setLoadingMessage] = useState('');
  const [mask, setMask] = useState('99.999.999/9999-99');
  const [errorMessage, setErrorMessage] = useState('Erro na Solicitação, verifique os dados informados');
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const history = useHistory();

  const formRef = useRef<FormHandles>(null);

  const handleSetMask = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const valorDigitado = e.target.value;
    if (!valorDigitado.length || valorDigitado.length > 14) {
      setMask('99.999.999/9999-99');
    } else {
      setMask('999.999.999-999');
    }
  }, []);

  const handleSubmit = useCallback(async (fields: EntidadePayload) => {
    try {
      setIsLoading(true);
      setLoadingMessage('Criando Entidade...');

      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().required('Por favor preencha o nome da entidade'),
        mdr: Yup.string().required('Por favor preencha a tarifa MDR da entidade'),
        pix_fee: Yup.string().required('Por favor preencha a tarifa PIX da entidade'),
        email: Yup.string()
          .required('Por favor informe um email')
          .matches(/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/, 'Insira um email válido'),
        document: Yup.string()
          .required('Por favor informe um CNPJ')
          .test('validateCpf', 'Você precisa informar um CPF/CNPJ válido', (value) => {
            if (value && value.length > 0) {
              return value.length < 15 ? cpfValidator.isValid(value) : cnpjValidator.isValid(value);
            }
            return true;
          })
      });

      await schema.validate(fields, {
        abortEarly: false
      });

      fields.document = getOnlyNumbers(fields.document);
      fields.mdr = Number(fields.mdr);
      fields.pix_fee = Number(fields.pix_fee);

      await criarEntidadeRequest(fields);
      setShowModal(true);
    } catch (err) {
      const validationErrors: { [key: string]: string } = {};
      setErrorMessage('Erro na Solicitação, verifique os dados informados');

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error: Yup.ValidationError) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current?.setErrors(validationErrors);
      }

      if (err instanceof Error) {
        const error = err as any;
        if (error?.response?.data.message) {
          const messages = getStringBetweenParentheses(error.response.data.message);
          if (messages && messages.length > 0) {
            const errorMessage =
              messages[0] === '(email)'
                ? 'Já existe um cadastro utilizando esse e-mail. Favor, utilizar um e-mail diferente.'
                : 'Já existe um cadastro utilizando esse nome. Favor, utilizar um nome diferente.';
            setErrorMessage(errorMessage);
          }
        }
      }

      setShowModalError(true);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return (
    <>
      {isLoading && <FullScreenLoader loadingMessage={loadingMessage} />}
      <DefaultContainer paddingTop paddingBottom>
        <DefaultHeader label="Criar Entidade" pageMessage="Informe abaixo os detalhes da nova Entidade"></DefaultHeader>
        <Form className="editForm" ref={formRef} onSubmit={handleSubmit}>
          <Input name="name" type="text" placeholder="Ex.: Protestado" label="Nome da Entidade" full data-cy="nomeEntidade" />
          <Input name="email" type="string" placeholder="Ex.: protestado@protestado.com.br" label="E-mail*" full data-cy="emailEntidade" />
          <Input
            name="document"
            type="string"
            placeholder="Ex.: 52.077.714/0001-00"
            label="Documento CPF/CNPJ"
            full
            data-cy="documentoEntidade"
            mask={mask}
            onChange={handleSetMask}
            maskChar=""
          />
          <Input
            name="mdr"
            type="number"
            placeholder="2.50"
            label="Tarifa para Cartão de Crédito (MDR)"
            step="any"
            min="0"
            max="100"
            full
            data-cy="mdrEntidade"
          />
          <Input
            name="pix_fee"
            type="number"
            placeholder="2.50"
            label="Tarifa para PIX"
            step="any"
            min="0"
            max="100"
            full
            data-cy="pixEntidade"
          />
          <ContainerButton>
            <Button type="submit" size="big" full color="primary" loading={isLoading} disabled={!!isLoading} data-cy="criarEntidade">
              Criar Entidade
            </Button>
          </ContainerButton>
        </Form>
      </DefaultContainer>

      <ModalConfirm
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        handleButtonClick={() => history.push(`/entidades?`)}
        label="Entidade Criada com Sucesso"
        buttonLabel="Ok"
      />
      <ModalError
        isOpen={showModalError}
        onClose={() => setShowModalError(false)}
        handleButtonClick={() => setShowModalError(false)}
        label={errorMessage}
        buttonLabel="Ok"
      />
    </>
  );
};

export default CriarEntidade;
