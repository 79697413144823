import styled from 'styled-components';

interface Props {
  margin?: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100vh;
  margin-top: 5vh;
  width: 480px;

  @media only screen and (max-width: 1366px) {
    margin-top: 15vh;
    margin-bottom: 15vh;
  }

  @media only screen and (max-width: 1024px) {
    margin-top: 21vh;
    margin-bottom: 10vh;
    height: 100%;
  }

  @media only screen and (max-width: 768px) {
    width: 93%;
    margin-top: 18vh;
    margin-bottom: 10vh;
  }

  .editForm {
    width: 100%;
  }
`;

export const PageTitle = styled.div`
  width: 100%;
  margin-top: 35px;

  @media only screen and (max-width: 1366px) {
    margin-top: 30px;
  }

  @media only screen and (max-width: 768px) {
    margin-top: 20px;
  }
`;

export const PageMessage = styled.div`
  margin-top: 20px;

  @media only screen and (max-width: 1366px) {
    margin-top: 10px;
  }
`;

export const ContainerButton = styled.div`
  margin: 30px 0 0 0;
`;

export const ContainerSearch = styled.div`
  margin: 0px 10px 0px 0px;
  text-align: -webkit-right;
  width: 100%;
`;

export const ContainerFiltros = styled.div`
  background-color: white;
  border-radius: 10px;
  margin: 0px 0px 50px 0px;
  padding: 0px 40px 40px 40px;
`;

export const ContainerTop = styled.div`
  margin: 0px 0 0 0;
  display: flex;
  justify-content: flex-end;
  text-align: -webkit-right;
`;

export const Input = styled.div`
  margin: 30px 0 0 0;
`;
export const CheckboxArea = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin: 10px 10px 10px 10px;
`;

export const FeedbackArea = styled.div`
  margin: -50px 0 50px 0;
`;
export const ResultsArea = styled.div`
  margin: 0 0 0 0;
`;

export const ContainerStats = styled.div<Props>`
  display: grid;
  width: 100%;
  justify-content: center;
  justify-items: center;
  align-items: center;
  padding: 30px;
  margin: ${(props) => (props.margin ? props.margin : '12px 10px')};
  background-color: white;
  border-radius: 10px;
`;

export const AreaStats = styled.div`
  display: flex;
  width: 100%;
  margin: 0px 0px;
  height: 25vh;
`;

export const ButtonRow = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 8px;
  margin: 20px;
`;
