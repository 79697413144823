import React from 'react';
import { TituloDetail } from '../../interfaces/interfaceRelatorio';
import {
  Container,
  BtnItemTipoPagamentoSucesso,
  BtnItemTipoPagamentoAviso,
  Info,
  ItemDetails,
  AreaIcon
} from './styles';
import DescriptionContainer from '@/modules/Deal/components/DescriptionContainer';
import Text from '@/components/Ui/Text';
import Button from '@/components/Ui/Button';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import ChipStatusContainer from '@/modules/Entidades/ListarOfertasTitulo/components/chipStatusContainer';
import { IconContext } from 'react-icons';
import { MdOutlineCreditCard, MdPaid } from 'react-icons/md';
import { BsFillCheckCircleFill, BsInfoCircleFill } from 'react-icons/bs';
import { AiOutlineBarcode } from 'react-icons/ai';

interface IProps {
  posts: TituloDetail[];
}

const DataList: React.FC<IProps> = ({ posts }) => {
  const history = useHistory();
  return (
    <div className="list__container">
      <div className="list_data">
        {posts.map((item: TituloDetail) => {
          return (
            <Container className="Container" key={item.id}>
              <DescriptionContainer>
                <ItemDetails width="10%">
                  <Text margin="10px 10px" size="small" bold>
                    {item.id}
                  </Text>
                </ItemDetails>
                <ItemDetails width="10%">
                  <Text margin="10px 10px" size="small">
                    {item.titulo}
                  </Text>
                </ItemDetails>
                <ItemDetails width="25%">
                  <Text margin="10px 10px" size="small">
                    {item.destinatario}
                  </Text>
                </ItemDetails>
                <ItemDetails width="15%">
                  <Text margin="10px 10px" size="small">
                    {item.data_envio}
                  </Text>
                </ItemDetails>
                <ItemDetails width="15%">
                  <Text margin="10px 10px" size="small">
                    {item.status_titulo}
                  </Text>
                </ItemDetails>
                <ItemDetails width="15%">
                  <Text margin="10px 10px" size="small">
                    {item.assunto_email}
                  </Text>
                </ItemDetails>
                <ItemDetails width="15%">
                  <Button full size='small' onClick={() => history.push(`/titulos/listar-titulos/${item.id_titulo}`)}>
                    Detalhes
                  </Button>
                </ItemDetails>
              </DescriptionContainer>
            </Container>
          );
        })}
      </div>
    </div >
  );
};

export default DataList;
