import styled from 'styled-components';

import flex from '@/assets/styles/flex';

interface IContainerProps {
  height?: boolean;
}

export const Container = styled.div<IContainerProps>`
  ${flex.direction.row}
  ${flex.justify.spaceBetween}
  align-items: center;

  // zoom 200%
  @media (min-width: 768.99px) and (max-width: 1024px) {
    /* ${flex.direction.column} */
    /* height: 260px; */
    margin: 0 0 0 0;
  }

  @media only screen and (max-width: 768px) {
    /* ${flex.direction.column} */
    /* height: ${(props) => (props.height ? '300px' : '460px')}; */
    margin: 0 0 0 0;
  }
`;
