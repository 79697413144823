import FeedbackImage from '@/assets/images/empty.png';
import theme from '@/assets/styles/theme';
import EmptyFeedback from '@/components/Helpers/EmptyFeedback';
import Button from '@/components/Ui/Button';
import DefaultHeader from '@/components/Ui/DefaultHeader';
import { DefaultContainer } from '@/components/Ui/DefaultHeader/style';
import { FullScreenLoader } from '@/components/Ui/FullScreenLoader';
import IconController from '@/components/Ui/IconController';
import Input from '@/components/Ui/Input';
import InputMask from '@/components/Ui/InputMask';
import ModalConfirm from '@/components/Ui/Modal/ModalConfirm';
import ModalError from '@/components/Ui/Modal/ModalError';
import Text from '@/components/Ui/Text';
import Title from '@/components/Ui/Title';
import Tooltip from '@/components/Ui/Tooltip';
import { tituloStatus } from '@/utils/Consts';
import { formatarDinheiro, getErrorMessage, getSuccessMessage, getValorCustas } from '@/utils/Utilities';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { OfertaTituloPayload } from '../CriarOfertaTitulo/interfaces/ofertaTitulo';
import { criarOfertaTituloRequest } from '../CriarOfertaTitulo/providers/criarOfertaTitulo';
import {
  InformationContainer,
  InformationData,
  InformationRow,
  InformationRowContainer,
  InformationTitle
} from '../CriarOfertaTitulo/style';
import { OfertaTitulo } from '../ListarOfertasTitulo/interfaces/interface';
import { FeedbackArea } from '../components/EditContainer/styles';
import ListaOfertaGlobal from './components/listaOfertaGlobal';
import ListaOfertaTitulo from './components/listaOfertaTitulo';
import { ContainerButton } from './components/listaOfertaTitulo/styles';
import { Titulo } from './interfaces/Titulo';
import { atualizarMensageria } from './providers/atualizarMensageria';
import { desabilitaTitulo } from './providers/desabilitaTitulo';
import { getTituloById } from './providers/getTituloRequest';
import {
  CompanyDescription,
  CompanyDetails,
  ContainerCriarOferta,
  ContainerNovaOferta,
  ContainerStatusTitulo,
  FormInformationContainer,
  InputsGrid,
  MensageriaArea,
  MensageriaStatusContainer,
  OfertasContainer,
  StatusContainer,
  TextArea,
  TituloStatusContainer
} from './styles';

interface Props {}

const EditarTitulo: React.FC<Props> = () => {
  const url = window.location.href.split('/');
  const tituloId = url[5];
  const msgCampos = {
    juros_cartao: 'Juros Cartão',
    juros_boleto: 'Juros Boleto',
    desconto: 'Desconto Valor',
    desconto_porcentagem: 'Desconto Porcentagem'
  } as any;
  const [tituloEditado, setTituloEditado] = useState<Titulo | null>(null);
  const [ofertaId, setOfertaId] = useState<null | string>(null);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [isMounted, setIsMounted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [maskTelefone, setMaskTelefone] = useState('');
  const [maskDevedor, setMaskDevedor] = useState('');
  const [maskSacador, setMaskSacador] = useState('');
  const [statusTitulo, setStatusTitulo] = useState('');
  const [telefone, setTelefone] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [numero_identificacao_devedor, setDocumentoDevedor] = useState('');
  const [dataEmissao, setDataEmissao] = useState('');
  const [dataVencimento, setDataVencimento] = useState('');
  const [listaOfertaTitulo, setListaOfertaTitulo] = useState<any[]>([]); // TODO TIPAR PARA OFERTA
  const [listaOfertaGlobal, setListaOfertaApresentante] = useState<any[]>([]); // TODO TIPAR PARA OFERTA

  const [valorOriginalTitulo, setValorOriginalTitulo] = useState(0);
  const [valorSaldoProtesto, setValorSaldoProtesto] = useState(0);
  const [valorCustas, setValorCustas] = useState(0);
  const [valorTotal, setValorTotal] = useState(0);

  const [emailCallback, setEmailCallback] = useState(false);
  const handleChangeCallback = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailCallback(e.target.checked);
  };
  const [isDeleted, setIsDeleted] = useState(false);
  const handleChangeIsDeleted = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsDeleted(e.target.checked);
  };
  const [novaOferta, setNovaOferta] = useState<OfertaTitulo | null>(null);
  const [status, setStatus] = useState(true);
  const handleChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStatus(e.target.checked);
  };
  const [boleto, setBoleto] = useState(false);
  const handleChangeBoleto = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBoleto(e.target.checked);
    setJurosBoleto('');
  };
  const [juros_boleto, setJurosBoleto] = useState('');
  const handleChangeJurosBoleto = (e: React.ChangeEvent<HTMLInputElement>) => {
    setJurosBoleto(e.target.value);
  };
  const [juros_cartao, setJurosCartao] = useState('');
  const handleChangeJurosCartao = (e: React.ChangeEvent<HTMLInputElement>) => {
    setJurosCartao(e.target.value);
    setJurosCartao('');
  };
  const [cartao, setCartao] = useState(true);
  const handleChangeCartao = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCartao(e.target.checked);
  };
  const [disableEmolumentos, setDisableEmolumentos] = useState(true);
  const [disableDescontoPorcentagem, setDisableDescontoPorcentagem] = useState(false);
  const [disableDesconto, setDisableDesconto] = useState(false);
  const [emolumentos, setEmolumentos] = useState('');
  const handleChangeEmolumentos = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmolumentos(e.target.value);
  };
  const [desconto, setDesconto] = useState('');
  const handleChangeDesconto = (e: React.ChangeEvent<HTMLInputElement>) => {
    discountCtrl(e);
    setDesconto(e.target.value);
  };
  const [desconto_porcentagem, setDescontoPorcentagem] = useState('');
  const handleChangeDescontoPorcentagem = (e: React.ChangeEvent<HTMLInputElement>) => {
    discountCtrl(e);
    setDescontoPorcentagem(e.target.value);
  };
  const ofertaFormRef = useRef<FormHandles>(null);
  const formRef = useRef<FormHandles>(null);

  const discountCtrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'desconto') {
      if (e.target.value) {
        setDisableDescontoPorcentagem(true);
      } else {
        setDisableDescontoPorcentagem(false);
      }
    } else if (e.target.id === 'desconto_porcentagem') {
      if (e.target.value && e.target.value !== '__%') {
        setDisableDesconto(true);
      } else {
        setDisableDesconto(false);
      }
    }
  };

  const resetarPreenchimento = useCallback(() => {
    setNovaOferta(null);
    setDesconto('');
    setDescontoPorcentagem('');
    setDisableDescontoPorcentagem(false);
    setBoleto(false);
    setJurosBoleto('');
    setCartao(false);
    setJurosCartao('');
    setOfertaId(null);
  }, []);

  const recarregarDados = useCallback(async (loadingMessage: string) => {
    setShowModal(false);
    setShowModalConfirm(false);
    resetarPreenchimento();
    await getTituloData(loadingMessage);
  }, []);

  const cancelarEdicao = useCallback(() => {
    resetarPreenchimento();
  }, []);

  const criarNovaOferta = useCallback(() => {
    const novaOferta = {} as OfertaTitulo;
    setNovaOferta(novaOferta);
  }, []);

  const handleSubmit = useCallback(async (fields: any) => {
    console.log(fields);
  }, []);

  const setDisableDiscountFields = useCallback((val: boolean) => {
    setDisableDescontoPorcentagem(val);
    setDisableDesconto(val);
  }, []);

  const getTituloData = useCallback(
    async (loadingMessage = 'Carregando Detalhes do Título...') => {
      try {
        setIsLoading(true);
        setLoadingMessage(loadingMessage);
        const titulo = await getTituloById(tituloId);
        setTituloEditado(titulo);

        const valorCustas = getValorCustas(titulo);

        formRef.current?.setData({
          id: titulo?.id,
          nome_devedor: titulo?.nome_devedor,
          email_devedor: titulo?.email_devedor,
          numero_identificacao_devedor: titulo?.numero_identificacao_devedor,
          telefone_devedor: titulo?.telefone_devedor,
          uf: titulo?.uf,
          apresentante: titulo?.apresentante == '[1]' ? 'Cartório de Protesto' : titulo?.apresentante,
          cnpj: titulo?.cnpj,
          data_emissao: titulo?.data_emissao,
          data_vencimento: titulo?.data_vencimento,
          email_callback: titulo?.email_callback,
          is_deleted: titulo?.is_deleted,
          razao: titulo?.razao,
          nosso_numero: titulo?.nosso_numero,
          numero_parcela: titulo?.numero_parcela,
          numero_titulo: titulo?.numero_titulo,
          valor_saldo_protesto: titulo?.valor_saldo_protesto ? formatarDinheiro(Number(titulo?.valor_saldo_protesto)) : 'R$ 0,00',
          valor_titulo: titulo?.valor_titulo ? formatarDinheiro(Number(titulo?.valor_titulo)) : 'R$ 0,00',
          valor_custas: formatarDinheiro(valorCustas),
          valor_total_titulo:
            titulo.status_codigo == '320'
              ? formatarDinheiro(Number(titulo.valor_titulo))
              : formatarDinheiro(Number(titulo.valor_saldo_protesto)) || valorCustas
              ? formatarDinheiro(valorCustas + Number(titulo?.valor_saldo_protesto))
              : 'R$ 0,00'
        });

        const valorTitulo = Number(titulo.valor_titulo);
        const valorSaldoProtesto = Number(titulo.valor_saldo_protesto);
        const valorTotal = valorSaldoProtesto + valorCustas;

        setValorOriginalTitulo(valorTitulo);
        setValorSaldoProtesto(valorSaldoProtesto);
        setValorCustas(valorCustas);
        setValorTotal(valorTotal);

        if (valorCustas) {
          setEmolumentos(valorCustas.toFixed(2).replace('.', ','));
        }
        setDisableEmolumentos(true);

        if ((!valorSaldoProtesto && !valorTitulo) || titulo.status_codigo === '144') {
          setDisableDiscountFields(true);
        }

        if (titulo?.ofertas && titulo?.ofertas.length > 0) {
          for (let index = 0; index < titulo.ofertas.length; index++) {
            const oferta = titulo.ofertas[index];
            oferta.key = `${oferta.titulo}/${oferta.id}`;
            oferta.icon_emolumentos = false;
            if (!oferta.desconto && !oferta.desconto_porcentagem) {
              oferta.icon_emolumentos = true;
            }
          }
          setListaOfertaTitulo(titulo?.ofertas);
        } else {
          setListaOfertaTitulo([]);
        }

        if (titulo?.apresentante_ofertas && titulo?.apresentante_ofertas.length > 0) {
          for (let index = 0; index < titulo.apresentante_ofertas.length; index++) {
            const apresentante_oferta = titulo.apresentante_ofertas[index];
            apresentante_oferta.key = `${apresentante_oferta.titulo}/${apresentante_oferta.id}`;
            apresentante_oferta.icon_emolumentos = false;
            if (!apresentante_oferta.desconto && !apresentante_oferta.desconto_porcentagem) {
              apresentante_oferta.icon_emolumentos = true;
            }
          }
          setListaOfertaApresentante(titulo?.apresentante_ofertas);
        } else {
          setListaOfertaApresentante([]);
        }

        if (titulo?.telefone_devedor) {
          const telefoneDevedor = String(titulo.telefone_devedor);
          setTelefone(telefoneDevedor);
          if (telefoneDevedor.length < 11) {
            setMaskTelefone('(99) 9999-9999');
          } else {
            setMaskTelefone('(99) 99999-9999');
          }
        }

        setDocumentoDevedor(titulo?.numero_identificacao_devedor);
        if (titulo?.numero_identificacao_devedor.length > 11) {
          setMaskDevedor('99.999.999/9999-99');
        } else {
          setMaskDevedor('999.999.999-99');
        }

        setCnpj(titulo?.cnpj);
        if (titulo?.cnpj?.length > 11) {
          setMaskSacador('99.999.999/9999-99');
        } else {
          setMaskSacador('999.999.999-99');
        }

        setDataEmissao(moment(titulo?.data_emissao).format('DD/MM/YYYY'));
        setDataVencimento(moment(titulo?.data_vencimento).format('DD/MM/YYYY'));
        setStatusTitulo(tituloStatus[titulo.status_codigo]);

        if (titulo.email_callback === true) {
          setEmailCallback(true);
        }
        if (titulo.is_deleted === true) {
          setIsDeleted(true);
        }

        setIsMounted(true);
      } catch (error) {
        console.log(error, 'ERROR');
        toast.error('Não foi possível carregar seu dados, tente mais tarde!');
      } finally {
        setIsLoading(false);
      }
    },
    [setDisableDiscountFields, tituloId]
  );

  const verificarValorNegativo = (campo: string): boolean => {
    if (Number(campo) < 0) {
      return true;
    }
    return false;
  };

  const possuiValoresNegativos = (payload: OfertaTituloPayload): string | null => {
    const camposNumericos = ['desconto', 'desconto_porcentagem', 'juros_cartao', 'juros_boleto'];
    let possuiValorNegativo: string | null = null;

    for (const [key, value] of Object.entries(payload)) {
      if (camposNumericos.includes(key) && value != null) {
        const valorNegativo = verificarValorNegativo(value);
        if (valorNegativo) {
          possuiValorNegativo = key;
          break;
        }
      }
    }

    return possuiValorNegativo;
  };

  const handleSubmitOferta = useCallback(
    async (fields: any) => {
      try {
        setIsLoading(true);
        setLoadingMessage('Cadastrando Oferta...');
        ofertaFormRef.current?.setErrors({});

        const schema = Yup.object().shape({
          data_inicio: Yup.date()
            .nullable()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .required('Por favor informe a Data Inicial'),
          data_fim: Yup.date()
            .nullable()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .min(Yup.ref('data_inicio'), 'Data Final não pode ser menor do que a Inicial')
            .required('Por favor informe a Data Final')
        });

        await schema.validate(fields, {
          abortEarly: false
        });

        const desconto_formatado = desconto.replace('R$', '').replace('_', '').replace('.', '').replace(',', '.');
        const desconto_porcentagem_formatado = desconto_porcentagem.replace('%', '').replace('_', '');

        const descontoValorNumber = Number(desconto_formatado);
        const valorSaldoProtestoNumber = Number(valorSaldoProtesto);

        if (descontoValorNumber <= valorSaldoProtestoNumber) {
          const payload = {
            titles: [tituloEditado.id],
            start_date: fields.data_inicio,
            end_date: fields.data_fim,
            company: tituloEditado?.protestado_entidade_uuid,
            name: `${tituloEditado?.nome_devedor} - ${tituloEditado.id} - Oferta criada via titulo`,
            discount_type: desconto_porcentagem ? 'PERCENTAGE' : 'VALUE',
            discount_value: desconto_porcentagem ? Number(desconto_porcentagem_formatado) : Number(desconto_formatado)
          } as OfertaTituloPayload;

          if (!desconto_porcentagem && !desconto) {
            payload.discount_type = 'PERCENTAGE';
            payload.discount_value = 0;
          }

          const campoValorNegativo = possuiValoresNegativos(payload);
          if (!campoValorNegativo) {
            const novaOferta = await criarOfertaTituloRequest(payload);
            if (novaOferta) {
              setOfertaId(novaOferta.id);
            }
            setShowModal(true);
          } else {
            toast.warning(`Não são permitidos valores negativos. Favor verificar campo ${msgCampos[campoValorNegativo]}`);
          }
        } else {
          toast.warning(`O Valor do Desconto não poder ser maior do que o valor protestado disponível: R$ ${valorSaldoProtestoNumber}`);
        }
      } catch (e) {
        setShowModalError(true);
        const validationErrors: { [key: string]: string } = {};

        if (e instanceof Yup.ValidationError) {
          e.inner.forEach((error: Yup.ValidationError) => {
            validationErrors[error.path] = error.message;
          });

          ofertaFormRef.current?.setErrors(validationErrors);
        }
      } finally {
        // resetarPreenchimento();
        setIsLoading(false);
      }
    },
    [boleto, cartao, desconto, desconto_porcentagem, juros_cartao, juros_boleto, valorSaldoProtesto, status, msgCampos, tituloEditado]
  );

  useEffect(() => {
    if (tituloEditado) {
      ofertaFormRef.current?.setData({
        apresentante: tituloEditado.apresentante,
        titulo: tituloEditado.id,
        protestado_entidade_uuid: tituloEditado.protestado_entidade_uuid
      });
    }
  }, [novaOferta, tituloEditado]);

  const desabilitarTitulo = async (tituloId: string) => {
    try {
      setIsLoading(true);
      setLoadingMessage('Desabilitando Título');
      const payload = {
        id: tituloId
      };
      const tituloDesabilitado = await desabilitaTitulo(payload);
      if (tituloDesabilitado) {
        setEmailCallback(false);
        setIsDeleted(false);
        setShowModalConfirm(true);
        toast.success('Sucesso! Esse Título foi desabilitado');
      }
    } catch (error) {
      const errorMsg = getErrorMessage(error, 'Não foi possível Desabilitar o Título');
      toast.error(errorMsg);
      // setShowModalError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAtualizarMensageria = useCallback(
    async (tituloId: number) => {
      try {
        setIsLoading(true);
        setLoadingMessage('Atualizando status de Mensageria');
        const updatedEmailCallback = !emailCallback;
        const payload = {
          id: tituloId,
          allow_messaging: updatedEmailCallback
        };
        const mensageriaAtualizada = await atualizarMensageria(payload);
        if (mensageriaAtualizada) {
          setEmailCallback(updatedEmailCallback);
          toast.success(getSuccessMessage(mensageriaAtualizada, 'Sucesso! O status da Mensageria foi atualizado!'));
        }
      } catch (error) {
        const errorMsg = getErrorMessage(error, 'Não foi possível atualizar a Mensageria');
        toast.error(errorMsg);
        // setShowModalError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [emailCallback]
  );

  useEffect(() => {
    if (!tituloEditado) {
      getTituloData();
    }
  }, [emailCallback, getTituloData, tituloEditado]);

  return (
    <>
      {isLoading && <FullScreenLoader loadingMessage={loadingMessage} />}
      <DefaultContainer paddingTop paddingBottom ignoreWidth>
        <DefaultHeader label={`Detalhes do Título ${tituloId}`}></DefaultHeader>
        <Form className="editForm" ref={formRef} onSubmit={handleSubmit}>
          <FormInformationContainer margin="0px">
            <label className="label">Informações do Devedor</label>
            <CompanyDescription margin="0px">
              <CompanyDetails>
                <Input readOnly width="100%" name="id" type="string" placeholder="Ex.: exemplo" label="ID do Título" />
              </CompanyDetails>
              <CompanyDetails>
                <Input readOnly width="100%" name="nome_devedor" type="string" placeholder="Ex.: Fernando Alves" label="Nome do Devedor" />
              </CompanyDetails>
              <CompanyDetails>
                <Input
                  readOnly
                  width="100%"
                  name="email_devedor"
                  type="string"
                  // placeholder="Ex.: exemplo@email.com"
                  label="E-mail do Devedor"
                />
              </CompanyDetails>
              <CompanyDetails>
                <Input
                  width="100%"
                  name="numero_identificacao_devedor"
                  type="string"
                  mask={maskDevedor}
                  value={numero_identificacao_devedor}
                  placeholder="Ex.: "
                  label="Documento do Devedor"
                />
              </CompanyDetails>
            </CompanyDescription>
            <CompanyDescription margin="20px 0px 0px">
              <CompanyDetails>
                <Input readOnly width="100%" name="telefone_devedor" type="string" label="Telefone do Devedor" />
                {/* <Input
                  width="100%"
                  name="telefone_devedor"
                  value={telefone && telefone !== '' ? telefone : 'N/D'}
                  type="string"
                  mask={maskTelefone}
                  placeholder="Ex.:"
                  label="Telefone do Devedor"
                /> */}
              </CompanyDetails>
              <CompanyDetails>
                <Input readOnly width="100%" name="uf" type="string" placeholder="Ex.: PR" label="UF do devedor" />
              </CompanyDetails>
              <CompanyDetails>
                <Input
                  width="100%"
                  name="data_vencimento"
                  value={dataVencimento}
                  type="string"
                  placeholder="Ex.:"
                  label="Data de Vencimento do Título"
                />
              </CompanyDetails>
            </CompanyDescription>
          </FormInformationContainer>
          <FormInformationContainer margin="20px 0px 0px">
            <label className="label">Informações do Apresentante</label>
            <CompanyDescription margin="0px">
              <CompanyDetails>
                <Input readOnly width="100%" name="apresentante" type="string" placeholder="Ex.:" label="Apresentante" />
              </CompanyDetails>
              <CompanyDetails>
                <Input width="100%" name="cnpj" type="string" value={cnpj} mask={maskSacador} placeholder="Ex.: PR" label="CNPJ" />
              </CompanyDetails>
              <CompanyDetails>
                <Input readOnly width="100%" name="razao" type="string" placeholder="Ex.:" label="Razão Social" />
              </CompanyDetails>
              {/* <CompanyDetailsToggle>
              <Toggle
                fontWeight="500"
                onChange={handleChangeCallback}
                label="Status Mensageria"
                value={emailCallback}
                name="email_callback"
                paddingRight
                disabled={true}
              />
            </CompanyDetailsToggle>
            <CompanyDetailsToggle>
            <Toggle
                fontWeight="500"
                onChange={handleChangeIsDeleted}
                label="Título Removido"
                value={isDeleted}
                name="is_deleted"
                paddingRight
                disabled={true}
              />
            </CompanyDetailsToggle> */}
            </CompanyDescription>
          </FormInformationContainer>
          <FormInformationContainer margin="20px 0px 0px">
            <TituloStatusContainer>
              <label className="label">Informações do Título</label>
              {statusTitulo !== '' && <label className="statusTitulo">Status: {statusTitulo}</label>}
            </TituloStatusContainer>
            {isMounted && (
              <ContainerStatusTitulo>
                <StatusContainer>
                  {isDeleted ? (
                    <>
                      <IconController margin="5px 0px 0px 0px" icon="closeCircle" fontSize="40px" iconColor="red" />
                      <div>
                        Título <span className="red-label">desabilitado</span> para cobrança
                      </div>
                      <Tooltip
                        isView
                        margin="4px 0px 0px 0px"
                        iconFontSize="1.1rem"
                        type="dark"
                        backgroundColor={theme.colors.secondary}
                        tooltipMessage="Títulos Desabilitados não estão disponíveis para cobrança através das Ofertas!"
                      />
                    </>
                  ) : (
                    <>
                      <IconController margin="5px 0px 0px 0px" icon="checkCircle" fontSize="40px" iconColor="green" />
                      <div>
                        Título <span className="green-label">habilitado</span> para cobrança
                      </div>
                      <Tooltip
                        isView
                        margin="4px 0px 0px 0px"
                        iconFontSize="1.1rem"
                        type="dark"
                        backgroundColor={theme.colors.secondary}
                        tooltipMessage="Títulos com status Habilitado estão disponíveis para cobrança através das Ofertas!"
                      />
                    </>
                  )}
                </StatusContainer>
                {!isDeleted && (
                  <Button type="button" color="error" onClick={() => desabilitarTitulo(tituloId)} data-cy="desabilitarTitulo">
                    Desabilitar Titulo
                  </Button>
                )}
              </ContainerStatusTitulo>
            )}
            <CompanyDescription margin="0px">
              <CompanyDetails>
                <Input readOnly width="100%" name="numero_titulo" type="string" placeholder="Ex.:" label="Número do Título" />
              </CompanyDetails>
              <CompanyDetails>
                <Input readOnly width="100%" name="nosso_numero" type="string" placeholder="Ex.:" label="Nosso Número" />
              </CompanyDetails>
              <CompanyDetails>
                <Input readOnly width="100%" name="numero_parcela" type="string" placeholder="Ex.:" label="Número da parcela" />
              </CompanyDetails>
              <CompanyDetails>
                <Input readOnly width="100%" name="valor_titulo" type="string" placeholder="Ex.:" label="Valor Original do Título/Dívida" />
              </CompanyDetails>
              <CompanyDetails>
                <Input readOnly width="100%" name="valor_saldo_protesto" type="string" placeholder="Ex.:" label="Valor Protestado" />
              </CompanyDetails>
              <CompanyDetails>
                <Input
                  readOnly
                  width="100%"
                  name="valor_custas"
                  type="string"
                  placeholder="Custas/Emolumentos"
                  label="Custas Emolumentos"
                />
              </CompanyDetails>
              <CompanyDetails>
                <Input
                  readOnly
                  width="100%"
                  name="valor_total_titulo"
                  type="string"
                  placeholder="Valor Total do Título"
                  label="Valor Total do Título"
                />
              </CompanyDetails>
            </CompanyDescription>
          </FormInformationContainer>
        </Form>
        <MensageriaStatusContainer>
          <Title marginBottom="50px" label="Mensageria" isVisiblePixel />
          {emailCallback === false ? (
            <MensageriaArea backgroundColor="#d0d0d0">
              <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="#666666" viewBox="0 0 16 16">
                <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z" />
                <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-4.854-1.354a.5.5 0 0 0 0 .708l.647.646-.647.646a.5.5 0 0 0 .708.708l.646-.647.646.647a.5.5 0 0 0 .708-.708l-.647-.646.647-.646a.5.5 0 0 0-.708-.708l-.646.647-.646-.647a.5.5 0 0 0-.708 0Z" />
              </svg>
              <TextArea>
                <Text margin="0px 0px 0px 80px" color="primary" size="big">
                  O envio de Mensagens de email
                </Text>
                <Text margin="0px 0px 0px 80px" color="primary" size="big" bold>
                  Está Desabilitado
                </Text>
                <Text margin="0px 0px 0px 80px" color="primary" size="big">
                  para esse título
                </Text>
              </TextArea>
              <ContainerButton>
                <Button
                  full
                  color="secondary"
                  disabled={isDeleted}
                  onClick={() => handleAtualizarMensageria(tituloId)}
                  data-cy="desabilitarEnvios"
                >
                  Habilitar Envios
                </Button>
              </ContainerButton>
            </MensageriaArea>
          ) : (
            <MensageriaArea backgroundColor={theme.colors.secondary}>
              <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="#ffffff" viewBox="0 0 16 16">
                <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z" />
                <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z" />
              </svg>
              <TextArea>
                <Text margin="0px 0px 0px 80px" color="white" size="big">
                  O envio de Mensagens de email
                </Text>
                <Text margin="0px 0px 0px 80px" color="primary" size="big" bold>
                  Está ativo
                </Text>
                <Text margin="0px 0px 0px 80px" color="white" size="big">
                  para esse título
                </Text>
              </TextArea>
              <ContainerButton>
                <Button full color="error" onClick={() => handleAtualizarMensageria(tituloId)} data-cy="desabilitarEnvios">
                  Desabilitar Envios
                </Button>
              </ContainerButton>
            </MensageriaArea>
          )}
        </MensageriaStatusContainer>
        <OfertasContainer>
          <Title label="Ofertas Gerais ativas para esse título" isVisiblePixel />
          {listaOfertaGlobal?.length === 0 && !novaOferta ? (
            <FeedbackArea>
              <EmptyFeedback imagePath={FeedbackImage} title="" message="Não há Ofertas Gerais cadastradas para este Título." />
            </FeedbackArea>
          ) : (
            <ListaOfertaGlobal offers={listaOfertaGlobal} reloadData={recarregarDados} />
          )}
        </OfertasContainer>
        <OfertasContainer margin="0px 0px 0px 0px">
          <Title label="Ofertas do Título" isVisiblePixel />
          {listaOfertaTitulo?.length === 0 && !novaOferta ? (
            <FeedbackArea>
              <EmptyFeedback imagePath={FeedbackImage} title="" message="Não há Ofertas de Título cadastradas para este Título." />
            </FeedbackArea>
          ) : (
            <ListaOfertaTitulo offers={listaOfertaTitulo} reloadData={recarregarDados} />
          )}
        </OfertasContainer>
        {novaOferta && (
          <ContainerNovaOferta>
            <InformationContainer>
              <InformationRow isCenter padding="16px 24px 8px 24px">
                <InformationTitle>Resumo do Título</InformationTitle>
              </InformationRow>
              <InformationRow padding="8px 24px 8px 24px">
                <InformationRowContainer padding="0px 48px 0px 12px">
                  <InformationData>Valor Original Título</InformationData>
                  <InformationData>{formatarDinheiro(valorOriginalTitulo)}</InformationData>
                </InformationRowContainer>
                <InformationRowContainer padding="0px 12px 0px 48px">
                  <InformationData>Valor Protestado</InformationData>
                  <InformationData>{formatarDinheiro(valorSaldoProtesto)}</InformationData>
                </InformationRowContainer>
              </InformationRow>
              <InformationRow padding="8px 24px 16px 24px">
                <InformationRowContainer padding="0px 48px 0px 12px">
                  <InformationData>Valor Custas/Emolumentos</InformationData>
                  <InformationData>{formatarDinheiro(valorCustas)}</InformationData>
                </InformationRowContainer>
                <InformationRowContainer padding="0px 12px 0px 48px">
                  <InformationData>Valor Total</InformationData>
                  <InformationData>{formatarDinheiro(valorTotal)}</InformationData>
                </InformationRowContainer>
              </InformationRow>
            </InformationContainer>
            <Form className="editForm" ref={ofertaFormRef} onSubmit={handleSubmitOferta}>
              <InputsGrid>
                <Input disabled name="titulo" value={tituloEditado?.id} label="ID do Título" />
                <Input isView={false} disabled name="protestado_entidade_uuid" type="string" label="Entidade ID" />
                <InputMask
                  disabled={disableEmolumentos}
                  name="emolumentos"
                  label="Emolumentos Valor"
                  placeholder="Ex.: R$ 50"
                  prefix="R$"
                  value={emolumentos}
                  onChange={handleChangeEmolumentos}
                />
                <InputMask
                  disabled={disableDesconto}
                  name="desconto"
                  label="Desconto Valor"
                  placeholder="Ex.: R$ 50"
                  prefix="R$"
                  value={desconto}
                  customMargin={'20px 0px 10px 0px'}
                  onChange={handleChangeDesconto}
                />
                <Input
                  disabled={disableDescontoPorcentagem}
                  name="desconto_porcentagem"
                  mask={'99%'}
                  type="string"
                  placeholder="Ex.: 20%"
                  label="Desconto Porcentagem (%)"
                  value={desconto_porcentagem}
                  onChange={handleChangeDescontoPorcentagem}
                  full
                />
                <Input name="data_inicio" type="date" label="Data Inicial" full />
                <Input name="data_fim" type="date" label="Data Final" full />
              </InputsGrid>
              <ContainerButton>
                <Button type="submit">Salvar</Button>
                <Button type="button" onClick={cancelarEdicao}>
                  Cancelar
                </Button>
              </ContainerButton>
            </Form>
          </ContainerNovaOferta>
        )}
        <ContainerCriarOferta className="containerCriarOferta">
          <Button disabled={isDeleted || novaOferta != null} onClick={criarNovaOferta}>
            Criar Nova Oferta
          </Button>
        </ContainerCriarOferta>
      </DefaultContainer>

      <ModalConfirm
        isOpen={showModal}
        onClose={() => recarregarDados('Carregando Informações Atualizadas...')}
        handleButtonClick={() => recarregarDados('Carregando Informações Atualizadas...')}
        label={`Oferta criada com sucesso! ID: ${ofertaId}`}
        buttonLabel="Ok"
      />
      <ModalConfirm
        isOpen={showModalConfirm}
        onClose={() => recarregarDados('Carregando Informações Atualizadas...')}
        handleButtonClick={() => recarregarDados('Carregando Informações Atualizadas...')}
        label={`Título ${tituloId} Desabilitado com Sucesso!`}
        buttonLabel="Ok"
      />
      <ModalError
        isOpen={showModalError}
        onClose={() => setShowModalError(false)}
        handleButtonClick={() => setShowModalError(false)}
        label="Erro na Solicitação, verifique os dados informados"
        buttonLabel="Ok"
      />
    </>
  );
};

export default EditarTitulo;
