import React, { useEffect, useState, useRef, useCallback } from 'react';
import { toast } from 'react-toastify';
import { FullScreenLoader } from '@/components/Ui/FullScreenLoader';
import Button from '@/components/Ui/Button';
import Input from '@/components/Ui/Input';
import EmptyFeedback from '@/components/Helpers/EmptyFeedback';
import FeedbackImage from '@/assets/images/magnifier.png';
import { FeedbackArea, ResultsArea, ContainerStats, AreaStats, ButtonRow } from '../components/EditContainer/styles';
import { Form } from '@unform/web';
import { getTituloRelatorioRequestFiltrar, getIntervaloRequestFiltrar } from './providers/getTituloRelatorio';
import { handleListTitulo } from './utils/handleListTitulo';
import { handleListInterval7 } from './utils/handleListInterval7';
import { TituloDetail } from './interfaces/interfaceRelatorio';
import { FormHandles } from '@unform/core';
//pagination
import DefaultPagination from '@/components/Ui/DefaultPagination';
import DataList from './components/paginate/DataList';
import Header from './components/Header';
import Text from '@/components/Ui/Text';
import {
  DefaultFilterButton,
  DefaultFilterButtonContainer,
  DefaultFilterContainer,
  DefaultFilterField,
  DefaultFilterInputs,
  DefaultFilterRow,
  ButtonEndArea,
  DefaultFilterCheckboxArea
} from '@/components/Ui/DefaultFilterContainer/styles';
import { DefaultContainerPages, DefaultListPageContainer } from '@/components/Ui/DefaultHeader/style';
import ModalWarning from '@/components/Ui/Modal/ModalWarning';
import { enviaRelatorioEmail } from './providers/getTituloRelatorio';
import ModalConfirm from '@/components/Ui/Modal/ModalConfirm';
import Toggle from '@/components/Ui/Toggle';
import Select from '@/components/Ui/Select';
import { TbReportMoney } from 'react-icons/tb';
import { IconContext } from 'react-icons';
import GoBack from '@/components/Ui/GoBack';
import DefaultHeader from '@/components/Ui/DefaultHeader';

const RelatoriosMensageriaEmails: React.FC = () => {
  const [data, setData] = useState<TituloDetail[]>([]);
  const [ofertas7Dias, setOfertas7Dias] = useState([0]);
  const [ofertas7DiasValor, setOfertas7DiasValor] = useState([0]);
  const [ofertas30Dias, setOfertas30Dias] = useState([0]);
  const [ofertas30DiasValor, setOfertas30DiasValor] = useState([0]);
  const [ofertas60Dias, setOfertas60Dias] = useState([0]);
  const [ofertas60DiasValor, setOfertas60DiasValor] = useState([0]);
  const [isLoading, setIsLoading] = useState(false);
  const [displayedData, setDisplayedData] = useState<TituloDetail[]>([]);
  const itemsPerPage = 10;
  const allPages = Math.ceil(data.length / itemsPerPage);
  const [showModalWarning, setShowModalWarning] = useState(false);
  const [showModalSucess, setShowModalSucess] = useState(false);
  const [showModalError, setShowModalError] = useState(false);

  const [statusPagos, setStatusPagos] = useState(false);
  const handleChangeStatusPagos = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStatusPagos(e.target.checked);
  };

  const [tempoPlataforma, setTempoPlataforma] = useState('');
  const [tempoVencimento, setTempoVencimento] = useState('Todos');
  const handleTempoPlataforma = useCallback((tempoPlataforma: string) => {
    setTempoPlataforma(tempoPlataforma);
  }, []);

  const handleButtonColor = useCallback(
    (buttonType: string, value: string): 'primary' | 'inverse' => {
      if (buttonType === 'tempoVencimento') {
        if (value === tempoVencimento) {
          return 'primary';
        } else {
          return 'inverse';
        }
      } else {
        if (value === tempoPlataforma) {
          return 'primary';
        } else {
          return 'inverse';
        }
      }
    },
    [tempoVencimento, tempoPlataforma]
  );

  const statusPagamentoOptions = [
    { value: '', label: 'Todos' },
    { value: 'DEBT_PAID_OFF', label: 'Pago' },
    { value: 'WAITING_PAYMENT', label: 'Aguardando Pagamento' }
  ];

  const formaPagamentoOptions = [
    { value: '', label: 'Todos' },
    { value: 'C', label: 'Cartão' },
    { value: 'B', label: 'Boleto Bancário' }
  ];

  const tipoOfertaOptions = [
    { value: '', label: 'Todos' },
    { value: 'T', label: 'Oferta Título' },
    { value: 'A', label: 'Oferta Geral' }
  ];

  const onPageChange = (page: number = 1) => {
    const startItem = (page - 1) * itemsPerPage;
    const endItem = page * itemsPerPage;
    setDisplayedData(data.slice(startItem, endItem));
  };

  const formRef = useRef<FormHandles>(null);

  const clearFilter = useCallback((fieldValue: string) => {
    formRef.current?.clearField(fieldValue);
  }, []);

  const clearAllFilters = useCallback(() => {
    formRef.current?.setFieldValue('id', '');
    formRef.current?.setFieldValue('nome_devedor', '');
    formRef.current?.setFieldValue('dias_de_negociacao', '');
    formRef.current?.setFieldValue('nosso_numero', '');
  }, []);

  useEffect(() => {
    onPageChange();
    //getStats30DiasData();
  }, [data]);

  const handleSendEmail = async (id: any) => {
    try {
      setIsLoading(true);
      setShowModalWarning(false);
      const payload = {
        email: 'email@email.com'
      };

      await enviaRelatorioEmail(payload);
      setShowModalSucess(true);
    } catch (error) {
      toast.error('Não foi possível Desabilitar a Oferta');
      setShowModalError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (fields: TituloDetail) => {
    try {
      setIsLoading(true);

      const params = {
        id: fields.id
      };
      //const TituloList = await getTituloRelatorioRequestFiltrar(params);
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      //const titulo: any[] = handleListTitulo(TituloList);
      //setData(titulo);

      setData([
        // {
        //   id: 1,
        //   titulo: '0896',
        //   id_titulo: '75486',
        //   destinatario: 'destinatario@devedor.com.br',
        //   data_envio: '01/01/2022',
        //   status_titulo: 'Cartório Santo André | Você permanece com débitos de custas junto ao cartório',
        //   assunto_email: 'Olá Augusto, Você foi protestado, confira aqui os detalhes para fazer o pagamento'
        // },
        // {
        //   id: 2,
        //   titulo: '12345',
        //   id_titulo: '75486',
        //   destinatario: 'felipe@devedor.com.br',
        //   data_envio: '03/03/2022',
        //   status_titulo: 'Cartório Santo André | Você permanece com débitos de custas junto ao cartório',
        //   assunto_email: 'Olá Felipe, Você foi protestado, confira aqui os detalhes para fazer o pagamento'
        // },
        // {
        //   id: 3,
        //   titulo: '12345',
        //   id_titulo: '75486',
        //   destinatario: 'augusto@devedor.com.br',
        //   data_envio: '04/03/2022',
        //   status_titulo: 'Cartório Santo André | Você permanece com débitos de custas junto ao cartório',
        //   assunto_email: 'Olá Augusto, existem custas de cartório pendente para regularizar seu nome'
        // },
        // {
        //   id: 4,
        //   titulo: '12345',
        //   id_titulo: '75486',
        //   destinatario: 'devedor@gmail.com',
        //   data_envio: '02/07/2022',
        //   status_titulo: 'Cartório Santo André | Você permanece com débitos de custas junto ao cartório',
        //   assunto_email: 'Você foi protestado'
        // },
        // {
        //   id: 5,
        //   titulo: '12345',
        //   id_titulo: '75486',
        //   destinatario: 'augusto@devedor.com.br',
        //   data_envio: '04/03/2022',
        //   status_titulo: 'Cartório Santo André | Você permanece com débitos de custas junto ao cartório',
        //   assunto_email: 'Olá Augusto, existem custas de cartório pendente para regularizar seu nome'
        // },
        // {
        //   id: 6,
        //   titulo: '12345',
        //   id_titulo: '75486',
        //   destinatario: 'felipe@devedor.com.br',
        //   data_envio: '03/03/2022',
        //   status_titulo: 'Cartório Santo André | Você permanece com débitos de custas junto ao cartório',
        //   assunto_email: 'Olá Felipe, Você foi protestado, confira aqui os detalhes para fazer o pagamento'
        // }
      ]);
    } catch (error) {
      toast.error('Não foi possível listar os Títulos!');
    } finally {
      setIsLoading(false);
    }
  };

  const getStats30DiasData = () => {
    try {
      setIsLoading(true);

      const params = {
        tempo_intervalo: 90
      };

      const TituloList = getIntervaloRequestFiltrar(params);
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      //const titulo: any[] = handleListInterval7(TituloList);
      setOfertas7Dias([0]);
    } catch (error) {
      toast.error('Não foi possível listar os Títulos!');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <FullScreenLoader loadingMessage="Carregando Envios..." />}
      <DefaultContainerPages paddingTop paddingBottom>
        <DefaultHeader label="Relatório de Envio de Emails" marginTop="12px" />
        <DefaultListPageContainer>
          <DefaultFilterContainer>
            <Form className="editForm" ref={formRef} onSubmit={handleSubmit}>
              <DefaultFilterInputs>
                <DefaultFilterField>
                  <Input name="titulo" type="string" placeholder="Ex.: 12345/8" label="Título:" full />
                </DefaultFilterField>
                <DefaultFilterField>
                  <Input name="destinatario" type="string" placeholder="Ex.: devedor@exemplo.com.br" label="Destinatário:" full />
                </DefaultFilterField>
                <DefaultFilterField>
                  <Input name="campanha" type="string" placeholder="Ex.: Campanha 1" label="Campanha:" full />
                </DefaultFilterField>
                <DefaultFilterField>
                  <Input name="data_inicio" type="date" label="Envio De:" full />
                </DefaultFilterField>
                <DefaultFilterField>
                  <Input name="data_fim" type="date" label="Envio Até" full />
                </DefaultFilterField>
              </DefaultFilterInputs>
              <DefaultFilterInputs margin="30px 0 0 0">
                <Text size="medium" margin="20px 20px 20px 20px" color="gray500" weight="medium">
                  Tempo de Envio de email:
                </Text>
                <Text size="default" margin="20px 20px 20px 20px" color="gray500">
                  Ao selecionar essa informação, serão selecionados apenas E-mails que estão alocados na Plataforma com Status enviado
                  dentro desse prazo (em dias)
                </Text>
                <ButtonRow>
                  <Button
                    onClick={() => handleTempoPlataforma('1')}
                    type="button"
                    size="small"
                    color={handleButtonColor('tempoPlataforma', '1')}
                  >
                    {'Até 1D'}
                  </Button>
                  <Button
                    onClick={() => handleTempoPlataforma('7')}
                    type="button"
                    size="small"
                    color={handleButtonColor('tempoPlataforma', '7')}
                  >
                    {'Até 7D'}
                  </Button>
                  <Button
                    onClick={() => handleTempoPlataforma('30')}
                    type="button"
                    size="small"
                    color={handleButtonColor('tempoPlataforma', '30')}
                  >
                    {'Até 30D'}
                  </Button>
                  <Button
                    onClick={() => handleTempoPlataforma('90')}
                    type="button"
                    size="small"
                    color={handleButtonColor('tempoPlataforma', '90')}
                  >
                    {'Até 90D'}
                  </Button>
                  <Button
                    onClick={() => handleTempoPlataforma('')}
                    type="button"
                    size="small"
                    color={handleButtonColor('tempoPlataforma', '')}
                  >
                    Todos
                  </Button>
                </ButtonRow>
              </DefaultFilterInputs>
              <DefaultFilterRow padding="0px 10px 0px 10px">
                <DefaultFilterButtonContainer>
                  <DefaultFilterButton>
                    <Button type="button" onClick={clearAllFilters} size="big" color="warning" width="100%" disabled={!!isLoading}>
                      Limpar Filtros
                    </Button>
                  </DefaultFilterButton>
                </DefaultFilterButtonContainer>
                <DefaultFilterButtonContainer>
                  {data && data.length > 0 && (
                    <DefaultFilterButton padding="0px 12px 0px 0px">
                      <Button
                        onClick={() => setShowModalWarning(true)}
                        type="button"
                        minWidth="180px"
                        size="big"
                        color="primary"
                        width="100%"
                        loading={isLoading}
                        disabled={!!isLoading}
                      >
                        Exportar
                      </Button>
                    </DefaultFilterButton>
                  )}
                  <DefaultFilterButton>
                    <Button
                      type="submit"
                      minWidth="180px"
                      size="big"
                      color="primary"
                      width="100%"
                      loading={isLoading}
                      disabled={!!isLoading}
                    >
                      Filtrar
                    </Button>
                  </DefaultFilterButton>
                </DefaultFilterButtonContainer>
              </DefaultFilterRow>
            </Form>
          </DefaultFilterContainer>
          {data?.length === 0 ? (
            <FeedbackArea>
              <EmptyFeedback
                imagePath={FeedbackImage}
                title="Clique em Filtrar para buscar Resultados"
                message="
            Utilize os filtros acima para encontrar os Títulos cadastrados"
              />
            </FeedbackArea>
          ) : (
            <ResultsArea>
              <Header posts={[]} />
              <DataList posts={displayedData} />
              <ButtonEndArea>
                <Button
                  onClick={() => setShowModalWarning(true)}
                  size="big"
                  color="primary"
                  width="16%"
                  loading={isLoading}
                  disabled={!!isLoading}
                  margin="0 15px 0 0"
                >
                  Exportar
                </Button>
              </ButtonEndArea>
              <GoBack />
              <DefaultPagination allPagesNumber={allPages} itemsPerPage={10} itemsNumber={data.length} pageChange={onPageChange} />
            </ResultsArea>
          )}
        </DefaultListPageContainer>
        <ModalWarning
          isOpen={showModalWarning}
          buttonLabelConfirm="Gerar Relatório"
          label="Clique em confirmar para receber seu relatório por e-mail"
          handleButtonClick={() => {
            handleSendEmail(statusPagos);
          }}
          handleClickCancelButton={() => setShowModalWarning(false)}
          onClose={() => setShowModalWarning(false)}
        />
        <ModalConfirm
          isOpen={showModalSucess}
          onClose={() => setShowModalSucess(false)}
          handleButtonClick={() => setShowModalSucess(false)}
          label={`E-mail enviado com Sucesso`}
          buttonLabel="Ok"
        />
      </DefaultContainerPages>
    </>
  );
};

export default RelatoriosMensageriaEmails;
