import React from 'react';
import { useSelector } from 'react-redux';

import GoBack from '@/components/Ui/GoBack';
import Text from '@/components/Ui/Text';
import Title from '@/components/Ui/Title';
import history from '@/services/history';

import AuthMessage from '../components/AuthMessage';
import AuthTitle from '../components/AuthTitle';
import { Container } from '../components/Container/styles';
import { EmailFiltered } from './styles';

interface EmailConfirmation {
  ForgotPassword: {
    email: string;
  };
}

const EmailSent: React.FC = () => {
  const email = useSelector((state: EmailConfirmation) => state.ForgotPassword.email);
  return (
    <Container>
      <GoBack label="Voltar para login" url="/login" />
      <AuthTitle>
        <Title label="E-mail enviado!" isVisiblePixel />
      </AuthTitle>
      <AuthMessage>
        <Text size="medium2">Você receberá um email com instruções para cadastrar uma nova senha neste endereço de email:</Text>
        <EmailFiltered>{email}</EmailFiltered>
      </AuthMessage>
    </Container>
  );
};
export default EmailSent;
