import styled, { css } from 'styled-components'

import { AgreementStatusEnum } from '@/@types/enum/agreementStatus'
import flex from '@/assets/styles/flex'
import theme from '@/assets/styles/theme/'

interface Props {
  status: string
}

const heights = {
  descriptionItem: css`
    height: 78px;

    @media only screen and (max-width: 768px) {
      height: 56px;
    }
  `,
}

interface ItemDetailsProps {
  width?: string;
}

export const ItemDetails = styled.div<ItemDetailsProps>`
  ${flex.direction.column}
  ${flex.justifySpaceBetween.alignStart}
  margin: 10px 10px;
  justify-content: center;
  width: ${(props) => (props.width ? props.width : '')};
  height: 30px;

  @media only screen and (max-width: 768px) {
    margin: 0;
    height: 60px;
    margin-top: 30px;
  }
`;

export const AreaIcon = styled.div`
margin: 0px 10px;
`

export const Container = styled.div`
  ${flex.direction.column};
  ${flex.justify.spaceBetween}
  width: 100%;
  padding: 20px 40px;
  margin-bottom: 5px;
  border-radius: 0px;
  background-color: ${theme.colors.white};
  @media only screen and (max-width: 1366px) {
    margin-bottom: 30px;
  }
  @media only screen and (max-width: 768px) {
    padding: 20px;
    margin-bottom: 20px;
  }
`

export const CompanyDescription = styled.div`
  ${flex.direction.row}
  ${heights.descriptionItem}
  max-width: 450px;

    // zoom 200%
    @media (min-width: 768.99px) and (max-width: 1024px) {
    ${flex.justify.spaceBetween}
  }

  @media only screen and (max-width: 768px) {
    ${flex.justify.spaceBetween}
    display: block;
  }
`

export const CompanyDatails = styled.div`
  ${flex.direction.column}
  ${flex.justifySpaceBetween.alignStart}
  margin: 0 10px;

  @media only screen and (max-width: 768px) {
    margin: 0;
    height: 60px;
    margin-top: 30px;
  }
`

export const PaymentsDescription = styled.div`
  ${flex.direction.row}
  ${flex.justify.end}
  ${heights.descriptionItem}

   // zoom 200%
   @media (min-width: 768.99px) and (max-width: 1024px) {
    ${flex.direction.column}
    ${flex.justify.end}
    flex-basis: 0%
  }

  @media only screen and (max-width: 768px) {
    ${flex.direction.column}
    ${flex.justify.end}
    flex-basis: 0%
  }
`

export const PaymentDetails = styled.div`
  ${flex.direction.column}
  ${flex.justifySpaceBetween.alignEnd};

  margin: 0 50px 0 0;

  // zoom 200%
  @media (min-width: 768.99px) and (max-width: 1024px) {
    ${flex.justifySpaceBetween.alignCenter};
    margin: 20px 0 14px 0;
  }

  @media only screen and (max-width: 768px) {
    ${flex.justifySpaceBetween.alignStart};
    margin: 20px 0 30px 0;
  }
`

export const StatusContainer = styled.div`
  ${flex.direction.row}
  ${flex.justifyEnd.alignCenter}

  @media (min-width: 768.99px) and (max-width: 1024px) {
    ${flex.direction.column}
  }

  @media only screen and (max-width: 768px) {
    ${flex.direction.column};
    ${flex.justifyEnd.alignCenter}
  }
`

export const PaymentStatus = styled.div<Props>`
  padding: 6px 20px;
  border: 1px solid;
  border-radius: 16px;
  margin: 0 0 0 10px;

  @media (min-width: 768.99px) and (max-width: 1024px) {
    margin: 6px 0;
  }


  @media only screen and (max-width: 768px) {
    margin: 6px 0;
  }

  ${props =>
    props.status === AgreementStatusEnum.DEBT_PAID_OFF &&
    css`
      border-color: ${theme.colors.secondary};
    `}

  ${props =>
    props.status === AgreementStatusEnum.EXPIRED_AGREEMENT &&
    css`
      border-color: ${theme.colors.red[300]};
    `}

  ${props =>
    props.status === AgreementStatusEnum.WAITING_PAYMENT &&
    css`
      border-color: ${theme.colors.orange[200]};
    `}
`

export const ContainerIcon = styled.div`
  ${flex.direction.column}
  ${flex.justifyCenter.alignCenter}
  color: ${theme.colors.primary};
  cursor: pointer;

  span{
    @media only screen and (min-width: 1024.99px) {
      display: none;
    }
  }
`

export const InformationItem = styled.div`
  ${flex.direction.column}
`

export const ContainerButton = styled.div`
display: flex;
flex-direction: row;
align-items: flex-start;
width: 50%;
`

export const BtnItemTipoPagamentoSucesso = styled.div`
background: transparent;
border: 1px solid #666666;
color: #666666;
display: flex;
padding: 10px 10px 10px 10px;
border-radius: 10px;
color: white
`

export const BtnItemTipoPagamentoAviso = styled.div`
background: transparent;
border: 1px solid #666666;
color: ${theme.colors.secondary};
display: flex;
padding: 10px 10px 10px 10px;
border-radius: 10px;
color: white
`

export const Info = styled.div`
display: grid;
`

export const SectionList = styled.div`
width: 50%;
`

export const SectionName = styled.div`
width: 80%;
`

export const SectionButton = styled.div`
width: 30%;
`
