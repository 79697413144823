import api from '@/services/api'
import {Titulos} from '../interfaces/interface'
import {Intervalos} from '../interfaces/interface'

export const getTituloRelatorioRequestFiltrar = (params: Titulos) => {
  return api.get(`/users/protested-titles/corporation/reports/renegociacoes`, {
    params:
      { 
        ...(params.id ? { id: params.id } : {}),
        ...(params.data_inicio ? { data_inicio: params.data_inicio } : {}),
        ...(params.data_fim ? { data_fim: params.data_fim } : {}),
        ...(params.tempo_plataforma ? { tempo_plataforma: params.tempo_plataforma } : {}),
        ...(params.status_pagamento ? { status_pagamento: params.status_pagamento } : {}),
        ...(params.forma_pagamento ? { forma_pagamento: params.forma_pagamento } : {}),
        ...(params.tipo_oferta ? { tipo_oferta: params.tipo_oferta } : {})
        }
  }).then(({ data }) => data)
}

export const getIntervaloRequestFiltrar = (params: Intervalos) => {
  return api.get(`/users/protested-titles/corporation/reports/renegociacoes-intervalo`, {
    params:
      { 
        ...(params.tempo_intervalo ? { tempo_intervalo: params.tempo_intervalo } : {}),
        }
  }).then(({ data }) => data)
}


export const enviaRelatorioEmail = (payload: any) => {
    console.log(payload)
}