import React from 'react';
import { useDispatch } from 'react-redux';

import { Creators } from '@/store/ducks/SignIn';

import { IconLogout, Container, NavItem } from './styles';
import { LOCAL_STORAGE_KEYS, setItemLocalStorage } from '@/utils/LocalStorage';

const DropdownNav: React.FC = () => {
  const dispatch = useDispatch();

  return (
    <Container>
      {/* <NavItem color={theme.colors.secondary} onClick={() => history.push('/edit-profile')}>
        <IconPencil />
        Editar dados
      </NavItem>
      <NavItem onClick={() => history.push('/change-password')}>
        <IconLock />
        Alterar senha
      </NavItem> */}
      <NavItem
        color="#FF4040"
        onClick={() => {
          setItemLocalStorage(LOCAL_STORAGE_KEYS.SHOW_CNPJ_MODAL, JSON.stringify(true));
          dispatch(Creators.logoutRequest());
        }}
      >
        <IconLogout />
        Sair
      </NavItem>
    </Container>
  );
};

export default DropdownNav;
