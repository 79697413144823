import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import * as Interfaces from './types'

/* ------------- Types and Action Creators ------------- */

export enum RecoverPassword {
  RECOVER_PASSWORD_REQUEST = 'RECOVER_PASSWORD_REQUEST',
  RECOVER_PASSWORD_SUCCESS = 'RECOVER_PASSWORD_SUCCESS',
  RECOVER_PASSWORD_FAILURE = 'RECOVER_PASSWORD_FAILURE',
}

type RecoverType = typeof RecoverPassword

// CreateActions
export const { Types, Creators } = createActions<RecoverType, Interfaces.ActionTypes>({
  RecoverPasswordRequest: { token: '', password: '', confirm_password: '' },
  RecoverPasswordFailure: { code: 0, message: '' },
  RecoverPasswordSuccess: { redefined: 0 },
})

export interface RecoverStateType {
  data: {
    name: string
  }
  token: string | null
  password: string | null
  confirm_password: string | null
  loading: boolean
  redefined: boolean
  error: {
    code: number
    message: string
  }
}

/* ------------- Initial State ------------- */

export const INITIAL_STATE: RecoverStateType = Immutable({
  data: {
    name: '',
  },
  token: null,
  password: null,
  confirm_password: null,
  loading: false,
  redefined: false,
  error: {
    code: 0,
    message: '',
  },
})

/* ------------- Reducers ------------- */
export const RecoverPasswordRequest = (
  state: RecoverStateType = INITIAL_STATE,
  { token, password, confirm_password }: Interfaces.ResetPassword,
): RecoverStateType => {
  return {
    ...state,
    loading: true,
  }
}

export const RecoverPasswordFailure = (
  state: RecoverStateType = INITIAL_STATE,
  { code, message }: Interfaces.ErrorPayload,
): RecoverStateType => ({
  ...state,
  error: {
    code,
    message,
  },
  loading: false,
})

export const RecoverPasswordSuccess = (
  state: RecoverStateType = INITIAL_STATE,
  { redefined }: Interfaces.RecoverPasswordSuccessPayload,
): RecoverStateType => ({
  ...state,
  redefined,
  loading: false,
})

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer<RecoverStateType, Interfaces.ActionTypes>(INITIAL_STATE, {
  [Types.RECOVER_PASSWORD_REQUEST]: RecoverPasswordRequest,
  [Types.RECOVER_PASSWORD_FAILURE]: RecoverPasswordFailure,
  [Types.RECOVER_PASSWORD_SUCCESS]: RecoverPasswordSuccess,
})

// /* ------------- Selectors ------------- */
