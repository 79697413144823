import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import * as Interfaces from './types'

/* ------------- Types and Action Creators ------------- */

export enum AuthEnum {
  SIGNUP_REQUEST = 'SIGNUP_REQUEST',
  SIGNUP_SUCCESS = 'SIGNUP_SUCCESS',
  SIGNUP_FAILURE = 'SIGNUP_FAILURE',
}

type AuthType = typeof AuthEnum

// CreateActions
export const { Types, Creators } = createActions<AuthType, Interfaces.ActionTypes>({
  SignupRequest: {
    cpf: '',
    name: '',
    birth_date: '',
    email: '',
    password: null,
  },
  SignupSuccess: {
    id: null,
  },
  SignupFailure: { code: 0, message: '' },
})

export interface AuthStateType {
  data: {
    name: string
  }
  cpf: string | null
  name: string | null
  birth_date: string | null
  email: string | null
  password: string | null
  loading: boolean | null
  created: boolean | null
  error: {
    code: number
    message: string
  }
}

/* ------------- Initial State ------------- */

export const INITIAL_STATE: AuthStateType = Immutable({
  data: {
    name: '',
  },
  cpf: null,
  name: null,
  birth_date: null,
  email: null,
  password: null,
  loading: false,
  created: false,
  error: {
    code: 0,
    message: '',
  },
})

/* ------------- Reducers ------------- */
export const SignupRequest = (
  state: AuthStateType = INITIAL_STATE,
  { cpf, name, birth_date, email, password }: Interfaces.SignUpRequest,
): AuthStateType => {
  return {
    ...state,
    cpf,
    name,
    birth_date,
    email,
    password,
    loading: true,
  }
}

export const SignupSuccess = (
  state: AuthStateType = INITIAL_STATE,
  { email }: Interfaces.SignUpSuccess,
): AuthStateType => {
  return {
    ...state,
    email,
    loading: false,
  }
}

export const SignupFailure = (
  state: AuthStateType = INITIAL_STATE,
  { code, message }: Interfaces.Error,
): AuthStateType => {
  return {
    ...state,
    error: {
      code,
      message,
    },
    loading: false,
  }
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer<AuthStateType, Interfaces.ActionTypes>(INITIAL_STATE, {
  [Types.SIGNUP_REQUEST]: SignupRequest,
  [Types.SIGNUP_SUCCESS]: SignupSuccess,
  [Types.SIGNUP_FAILURE]: SignupFailure,
})

// /* ------------- Selectors ------------- */
