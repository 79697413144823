import { call, put } from 'redux-saga/effects';

import api from '@/services/api';

import { Creators, ResetPassword } from '../ducks/RecoverPassword';

export function* recoverPasswordrequest(payload: ResetPassword) {
  const { token, password, confirm_password } = payload;
  try {
    const response = yield call(api.post, 'auth/corporation/reset-password', {
      token,
      password,
      confirm_password
    });
    const { status } = response;
    if (status === 201) {
      yield put(Creators.RecoverPasswordSuccess({ redefined: true }));
    }
  } catch (error) {
    const { code, message } = error.response.data;
    yield put(Creators.RecoverPasswordFailure({ code, message }));
  }
}
