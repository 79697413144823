import FeedbackImage from '@/assets/images/magnifier.png';
import EmptyFeedback from '@/components/Helpers/EmptyFeedback';
import Button from '@/components/Ui/Button';
import {
  DefaultFilterButton,
  DefaultFilterButtonContainer,
  DefaultFilterCheckboxArea,
  DefaultFilterContainer,
  DefaultFilterField,
  DefaultFilterInputs,
  DefaultFilterRow,
  DefaultTopContainer
} from '@/components/Ui/DefaultFilterContainer/styles';
import { DefaultContainerPages, DefaultListPageContainer } from '@/components/Ui/DefaultHeader/style';
import DefaultPagination from '@/components/Ui/DefaultPagination';
import { FullScreenLoader } from '@/components/Ui/FullScreenLoader';
import GoBack from '@/components/Ui/GoBack';
import Input from '@/components/Ui/Input';
import Title from '@/components/Ui/Title';
import Toggle from '@/components/Ui/Toggle';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ContainerTop, FeedbackArea, ResultsArea } from '../components/EditContainer/styles';
import DataList from './components/paginate/DataList';
import { listaOfertasTituloCtrl } from './controllers/listaOfertasTituloCtrl';
import { OfertaTitulo } from './interfaces/interface';

const ITEMS_PER_PAGE = 10;

const ListarOfertasTitulo: React.FC = () => {
  const history = useHistory();
  const [data, setData] = useState<OfertaTitulo[]>([]);
  const [offersList, setOffersList] = useState<OfertaTitulo[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const formRef = useRef<FormHandles>(null);
  const allPages = Math.ceil(data.length / ITEMS_PER_PAGE);

  const handleChangeIsActive = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsActive(e.target.checked);
  };

  const clearFilter = useCallback((fieldValue: string) => {
    formRef.current?.clearField(fieldValue);
  }, []);

  const clearAllFilters = useCallback(() => {
    formRef.current?.setFieldValue('id', '');
    formRef.current?.setFieldValue('numero_titulo', '');
    formRef.current?.setFieldValue('apresentante', '');
    setIsActive(true);
  }, []);

  const onPageChange = (page = 1) => {
    const startItem = (page - 1) * ITEMS_PER_PAGE;
    const endItem = page * ITEMS_PER_PAGE;
    setOffersList(data.slice(startItem, endItem));
  };

  const getOffers = async (filtros: any) => {
    const listaOfertasTitulo = await listaOfertasTituloCtrl(filtros);

    if (listaOfertasTitulo && listaOfertasTitulo.length > 0) {
      setData(listaOfertasTitulo);
    } else {
      toast.warning('Não foram encontradas Ofertas Título para este Filtro!');
      setData([]);
    }
  };

  const handleSubmit = async (filtros: any) => {
    try {
      setIsLoading(true);
      const params = {
        numero_titulo: filtros.numero_titulo,
        apresentante: filtros.apresentante,
        id: filtros.id,
        status: isActive
      };
      await getOffers(params);
    } catch (e) {
      toast.error('Não foi possível listar as Ofertas Título!');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onPageChange();
  }, [data]);

  return (
    <>
      {isLoading && <FullScreenLoader loadingMessage="Carregando Ofertas Título..." />}
      <DefaultContainerPages paddingTop paddingBottom>
        <DefaultTopContainer>
          <GoBack />
          <ContainerTop>
            <Button color="secondary" onClick={() => history.push('/ofertas/listar-ofertas-titulo/criar-oferta-titulo')}>
              Criar Oferta Título
            </Button>
          </ContainerTop>
        </DefaultTopContainer>
        <Title label="Listar Ofertas por Título" isVisiblePixel />
        <DefaultListPageContainer>
          <DefaultFilterContainer>
            <Form className="editForm" ref={formRef} onSubmit={handleSubmit}>
              <DefaultFilterInputs>
                <DefaultFilterField>
                  <Input
                    width="100%"
                    name="id"
                    type="string"
                    placeholder="Ex.: 14"
                    label="ID da Oferta"
                    padding="8px 8px 8px 6px"
                    iconSize="1.2rem"
                    isClearable={true}
                    clearFunction={() => clearFilter('id')}
                    data-cy="ofertaTituloId"
                  />
                </DefaultFilterField>
                <DefaultFilterField>
                  <Input
                    width="100%"
                    name="numero_titulo"
                    type="string"
                    placeholder="Ex.: 3333334"
                    label="Id do Título"
                    padding="8px 8px 8px 6px"
                    iconSize="1.2rem"
                    isClearable={true}
                    clearFunction={() => clearFilter('numero_titulo')}
                  />
                </DefaultFilterField>
                <DefaultFilterField></DefaultFilterField>
                <DefaultFilterCheckboxArea>
                  <Toggle onChange={handleChangeIsActive} label="Somente Ofertas Habilitadas" value={isActive} name="status" paddingRight />
                </DefaultFilterCheckboxArea>
              </DefaultFilterInputs>
              <DefaultFilterRow padding="0px 10px 0px 10px">
                <DefaultFilterButtonContainer>
                  <DefaultFilterButton>
                    <Button type="button" onClick={clearAllFilters} size="big" color="warning" width="100%" disabled={!!isLoading}>
                      Limpar Filtros
                    </Button>
                  </DefaultFilterButton>
                </DefaultFilterButtonContainer>
                <DefaultFilterButtonContainer>
                  <DefaultFilterButton>
                    <Button
                      type="submit"
                      minWidth="180px"
                      size="big"
                      color="primary"
                      width="100%"
                      loading={isLoading}
                      disabled={!!isLoading}
                      data-cy="filtrarOfertasTitulo"
                    >
                      Filtrar
                    </Button>
                  </DefaultFilterButton>
                </DefaultFilterButtonContainer>
              </DefaultFilterRow>
            </Form>
          </DefaultFilterContainer>
          {data?.length === 0 ? (
            <FeedbackArea>
              <EmptyFeedback
                imagePath={FeedbackImage}
                title="Clique em Filtrar para buscar Resultados"
                message="Utilize os filtros acima para encontrar as Ofertas Título cadastradas"
              />
            </FeedbackArea>
          ) : (
            <ResultsArea>
              <DataList offers={offersList} />
              <GoBack />
              <DefaultPagination
                allPagesNumber={allPages}
                itemsPerPage={ITEMS_PER_PAGE}
                itemsNumber={data.length}
                pageChange={onPageChange}
              />
            </ResultsArea>
          )}
        </DefaultListPageContainer>
      </DefaultContainerPages>
    </>
  );
};

export default ListarOfertasTitulo;
