import { IUserCompanyProps } from '@/components/Interfaces/UserCompanyProps';
import ScreenNavCard from '@/components/Ui/ScreenNavCard';
import Text from '@/components/Ui/Text';
import Title from '@/components/Ui/Title';
import { ISignInProps } from '@/modules/_layouts/components/HeaderCompany/components/UserSetting';
import jwtDecode from 'jwt-decode';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, ContainerTitle } from '../../Entidades/GerenciarOfertas/components/EditContainer/styles';
import { ChartRowContainer, ScreenNavCardContainer } from './styles';

const Dashboard: React.FC = () => {
  const accessToken = useSelector((state: ISignInProps) => state.SignIn.access_token);
  // const [allowedMenus, setAllowedMenus] = useState(['mensageria', 'titulos', 'ofertas', 'relatorios']);
  const [allowedMenus, setAllowedMenus] = useState(['titulos', 'ofertas', 'relatorios']);
  const [user, setUserDecode] = useState({} as IUserCompanyProps);
  const [isSuperUser, setIsSuperUser] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (accessToken && Object.keys(user).length === 0) {
      const userDecode = jwtDecode<IUserCompanyProps>(accessToken);
      const allowedMenusCopy = cloneDeep(allowedMenus);
      if (userDecode) {
        setUserDecode(userDecode);
        const isAdmin = userDecode.is_superuser_protestado || userDecode.is_admin ? true : false;
        setIsSuperUser(userDecode.is_superuser_protestado);
        setIsAdmin(isAdmin);

        if (userDecode.is_superuser_protestado) {
          allowedMenusCopy.push('entidades');
          allowedMenusCopy.push('usuarios');
        } else if (isAdmin) {
          allowedMenusCopy.push('usuarios');
        }

        setAllowedMenus(allowedMenusCopy);
      }
    }
  }, [accessToken, allowedMenus, user]);

  return (
    <>
      <Container className="Container" paddingTop paddingBottom>
        <ContainerTitle className="containerTitle">
          <Title label="Bem vindo ao painel Protestado Empresas" isVisiblePixel marginBottom="10px" />
        </ContainerTitle>
        <Text margin="12px 0px" color="primary" size="medium" align="center" textJustify={false} bold>
          Confira abaixo detalhes de alguns resultados
        </Text>
        {/* <label className="labelDescricaoCenter">Confira abaixo detalhes de alguns resultados:</label> */}

        <ChartRowContainer>
          {/* <ChartContainer minHeight="280px" padding="0px 20px 12px 60px"> */}
          {/* <LineChart options={defaultOptionsX} data={defaultDataX} /> */}
          {/* <BarChart options={defaultOptionsX} data={defaultDataX} /> */}
          {/* <PieChart options={defaultOptionsX} data={defaultDataPieChart} /> */}
          {/* <DoughnutChart options={defaultOptionsX} data={defaultDataPieChart} /> */}
          {/* </ChartContainer> */}
          {/* <ChartContainer minHeight="280px" padding="0px 60px 12px 20px"> */}
          {/* <LineChart options={defaultOptionsY} data={defaultDataY} /> */}
          {/* <BarChart options={defaultOptionsY} data={defaultDataY} /> */}
          {/* </ChartContainer> */}
        </ChartRowContainer>

        <Text margin="0px 0px 12px 0px" color="primary" size="small" align="center" textJustify={false} bold>
          O que deseja fazer? Selecione uma opção abaixo:
        </Text>
        {/* <label className="labelDescricaoCenter">O que deseja fazer? Selecione uma opção abaixo:</label> */}

        <ScreenNavCardContainer repeats={allowedMenus.length}>
          <ScreenNavCard
            titleLabel="Títulos"
            description="Gerencie todos os títulos disponíveis"
            icon="titulos"
            btnLabel="Acessar"
            path="/titulos"
            btnColor="secondary"
          />
          <ScreenNavCard
            titleLabel="Ofertas"
            description="Crie e gerencie Ofertas para seus Títulos"
            icon="ofertas"
            btnLabel="Acessar"
            path="/ofertas"
          />
          {/* <ScreenNavCard
            titleLabel="Mensageria"
            description="Controle/Gerenciamento de Mensageria"
            icon="mensageria"
            btnLabel="Acessar"
            path="/mensageria"
          /> */}
          <ScreenNavCard
            titleLabel="Relatórios"
            description="Consulte os Relatórios disponíveis"
            icon="relatorios"
            btnLabel="Acessar"
            path="/relatorios"
          />
          {isSuperUser && (
            <ScreenNavCard
              titleLabel="Empresas"
              description="Crie e gerencie Empresas/Entidades"
              icon="entidades"
              btnLabel="Acessar"
              path="/entidades"
            />
          )}
          {isAdmin && (
            <ScreenNavCard
              titleLabel="Usuários"
              description="Crie e gerencie Usuários do sistema"
              icon="usuarios"
              btnLabel="Acessar"
              path="/usuarios"
            />
          )}
        </ScreenNavCardContainer>
      </Container>
    </>
  );
};

export default Dashboard;
