import ScreenNavCard from '@/components/Ui/ScreenNavCard';
import { ScreenNavCardContainerDefault } from '@/components/Ui/ScreenNavCard/styles';
import Text from '@/components/Ui/Text';
import React from 'react';
import { Container, ContainerTitle } from '../GerenciarOfertas/components/EditContainer/styles';

const Titulos: React.FC = () => {
  return (
    <>
      <Container className="Container" paddingTop paddingBottom>
        <ContainerTitle className="containerTitle">
          <Text color="primary" size="big" align="center" textJustify={false} bold>
            Títulos
          </Text>
          <Text margin="20px" color="primary" size="medium" align="center" textJustify={false}>
            O que deseja fazer? Selecione uma opção abaixo
          </Text>
        </ContainerTitle>
        <ScreenNavCardContainerDefault>
          <ScreenNavCard
            titleLabel="Upload de Títulos"
            description="Realize o Upload de Títulos através do template"
            icon="upload"
            btnLabel="Fazer Upload"
            path="/titulos/upload-titulos"
            isDashboard={false}
            isManagement={true}
            btnSize="custom2"
            btnColor="secondary"
          />
          <ScreenNavCard
            titleLabel="Consultar Títulos"
            description="Visualize a listagem de títulos e gerencie suas Ofertas"
            icon="list"
            btnLabel="Acessar"
            path="/titulos/listar-titulos"
            isDashboard={false}
            isManagement={true}
            btnSize="custom2"
            btnColor="secondary"
          />
          <ScreenNavCard
            titleLabel="Relatórios"
            description="Acesso ao Relatório Geral com informações detalhadas de Títulos"
            icon="relatorios"
            btnLabel="Acessar"
            path="/relatorios/titulos"
            isDashboard={false}
            isManagement={true}
            btnSize="custom2"
            btnColor="secondary"
          />
        </ScreenNavCardContainerDefault>
      </Container>
    </>
  );
};

export default Titulos;
