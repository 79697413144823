import api from '@/services/api';
import { convertTitlesV1 } from '@/utils/utils_v1/Title';
import { FiltroRelatorioTitulo } from '../interfaces/FiltroRelatorioTitulo';

export const getTituloRelatorioRequestFiltrar = async (params: FiltroRelatorioTitulo) => {
  const { data } = await api.get(`/users/protested-titles/corporation/reports`, {
    params: {
      ...(params.id ? { id: params.id } : {}),
      ...(params.nosso_numero ? { partner_number: params.nosso_numero } : {}),
      ...(params.numero_titulo ? { title_number: params.numero_titulo } : {}),
      ...(params.id_arquivo ? { title_upload_id: params.id_arquivo } : {}),
      ...(params.is_deleted ? { is_deleted: true } : {}),
      ...(params.oferta_renegociacoes ? { has_offers: true } : {})
      // ...(params.nome_devedor ? { nome_devedor: params.nome_devedor } : {}),
      // ...(params.dias_de_negociacao ? { dias_de_negociacao: params.dias_de_negociacao } : {}),
      // ...(params.dias_pagamento_confirmado ? { dias_pagamento_confirmado: params.dias_pagamento_confirmado } : {}),
      // ...(params.renegociacoes_ativas ? { renegociacoes_ativas: 1 } : {}),
      // ...(params.titulo_pago ? { titulo_pago: 1 } : {}),
    }
  });
  const convertedTitles = convertTitlesV1(data.results);
  return convertedTitles;
};

export const enviaRelatorioEmail = (payload: any) => {
  console.log(payload);
};
