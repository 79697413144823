import React, { useState } from 'react';
import Button from '@/components/Ui/Button';
import Text from '@/components/Ui/Text';
import {
  DefaultCardContainer,
  DefaultCardColumns,
  DefaultContainerButton,
  DefaultCardDescription
} from '@/components/Ui/DefaultListContainer/styles';
import DefaultTemplate from '@/components/Ui/Modal/ModalTemplates/DefaultTemplate';
import { TemplateEmail } from '../../types/TemplateEmail';
import IconController from '@/components/Ui/IconController';

interface TemplateListProps {
  templates: TemplateEmail[];
}

const TemplateList: React.FC<TemplateListProps> = ({ templates }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState('');

  return (
    <>
      {templates.map((item: TemplateEmail) => {
        return (
          <div key={item.id}>
            <DefaultCardContainer key={item.id} padding="10px 30px">
              <DefaultCardDescription>
                <DefaultCardColumns>
                  <Text size="medium" bold>
                    {item.name}
                  </Text>
                </DefaultCardColumns>

                <DefaultContainerButton>
                  <Button
                    color="secondary"
                    height="45px"
                    disabled={true}
                    onClick={() => {
                      setSelectedTemplate(item.template);
                      setShowModal(true);
                    }}
                  >
                    <IconController icon="config" margin="3px 0px 0px 0px" />
                  </Button>
                  <Button
                    width="180px"
                    onClick={() => {
                      setSelectedTemplate(item.template);
                      setShowModal(true);
                    }}
                  >
                    Visualizar
                  </Button>
                </DefaultContainerButton>
              </DefaultCardDescription>
            </DefaultCardContainer>
          </div>
        );
      })}

      {/* TODO IMPLEMENTAR UTILIZAR TEMPLATE */}
      <DefaultTemplate
        extraBtnVisible
        extraBtnLabel="Utilizar template"
        extraBtnFunction={() => setShowModal(false)}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        template={selectedTemplate}
        handleButtonClick={() => setShowModal(false)}
        buttonLabel="Fechar"
      />
    </>
  );
};

export default TemplateList;
