import api from '@/services/api';

export const uploadTitulo = (file: any, entidadeId: any) => {
  const formData = new FormData();
  formData.append('file_uploaded', file);
  formData.append('company_id', entidadeId);

  return api.post(`users/protested-titles/corporation/upload/`, formData).then(({ data }) => data);
};

export const getUploadTitulosRequest = () => {
  return api
    .get(`/users/protested-titles/corporation/uploads-realizados`, {
      params: {
        return_titles: false
      }
    })
    .then(({ data }) => data);
};

export const sentApprovedTitlesRequest = (payload: any) => {
  return api.post(`/users/protested-titles/corporation/confirmar-upload`, payload).then(({ data }) => data);
};

export const cancelaRemessaService = (payload: any) => {
  return api.patch(`/users/protested-titles/corporation/cancelar-upload/`, payload).then(({ data }) => data);
};
