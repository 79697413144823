import flex from '@/assets/styles/flex';
import styled from 'styled-components';
import theme from '@/assets/styles/theme';

interface DefaultFilterProps {
  margin?: string;
  padding?: string;
  hide?: boolean;
  isView?: boolean;
}

interface DefaultGridProps {
  repeats?: number;
}

export const DefaultFilterContainer = styled.div<DefaultFilterProps>`
  background-color: white;
  border-radius: 10px;
  margin: ${(props) => (props.margin ? props.margin : '0px 0px 40px 0px')};
  padding: 20px 30px 20px 30px;
`;

export const DefaultFilterInputs = styled.div<DefaultFilterProps>`
  display: ${(props) => (!props.isView ? 'flex' : 'none !important')};
  ${flex.direction.row}
  justify-content: space-between;
  align-items: end;
  justify-content: flex-start;
  margin: ${(props) => (props.margin ? props.margin : '')};
`;

export const DefaultFilterField = styled.div`
  ${flex.direction.column}
  ${flex.justifySpaceBetween.alignStart}
  padding-left: 10px;
  padding-top: 0px;
  width: 25%;

  @media only screen and (max-width: 768px) {
    margin: 0;
    height: 60px;
    margin-top: 30px;
  }
`;

export const DefaultFilterInputGrid = styled.div<DefaultGridProps>`
  display: grid;
  grid-template-columns: ${(props) => (props.repeats ? `repeat(${props.repeats}, 1fr)` : 'repeat(4, 1fr)')};
  gap: 12px;
`;

export const DefaultFilterFieldGrid = styled.div`
  ${flex.direction.column}
  ${flex.justifySpaceBetween.alignStart}
      margin-left: 10px;
  margin-top: 0px;
  width: 100%;

  @media only screen and (max-width: 768px) {
    margin: 0;
    height: 60px;
    margin-top: 30px;
  }
`;

export const DefaultFilterCheckboxArea = styled.div<DefaultFilterProps>`
  display: ${(props) => (props.hide ? 'none !important' : 'flex')};
  flex-direction: column-reverse;
  margin: 10px 10px 10px 10px;
`;

export const DefaultFilterRow = styled.div<DefaultFilterProps>`
  display: flex;
  justify-content: space-between;
  margin: 30px 0px 20px 0px;
  padding: ${(props) => (props.padding ? props.padding : '0px 10px 0px 10px')};
`;

export const DefaultFilterButtonContainer = styled.div<DefaultFilterProps>`
  display: flex;
`;

export const DefaultFilterButton = styled.div<DefaultFilterProps>`
  padding: ${(props) => (props.padding ? props.padding : '')};
`;

export const DefaultTopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0;
`;

export const ButtonEndArea = styled.div`
  display: flex;
  margin: 25px 0px;
  width: 98%;
  justify-content: right;
`;

export const DefaultHeaderFiltro = styled.div<DefaultFilterProps>`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: ${(props) => (props.margin ? props.margin : '0')};
  padding: ${(props) => (props.padding ? props.padding : '0px 10px 0px 10px')};

  color: ${theme.colors.primary};
  font-size: 20px;
  font-weight: 700;
`;

export const DefaultContainerIconText = styled.div<DefaultFilterProps>`
  display: flex;
  font-size: 14px;
  cursor: pointer;
`;

export const DefaultContainerIcon = styled.div<DefaultFilterProps>`
  font-size: 18px;
  padding-left: 8px;
`;
