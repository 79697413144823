import React from 'react'

import styled from 'styled-components'

const Container = styled.div`
  margin-top: 50px;
  margin-bottom: 20px;
`

const AuthTitle: React.FC = ({ children }) => {
  return <Container>{children}</Container>
}

export default AuthTitle
