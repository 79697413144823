import React from 'react';

import styled from 'styled-components';

import backgroundImage from '@/assets/images/pantone-background.svg';
import GlobalStyle from '@/assets/styles/global';
import theme from '@/assets/styles/theme';

import Footer from './components/Footer';
import Header from './components/Header';
import HeaderEmpresa from './components/HeaderCompany';
import jwtDecode from 'jwt-decode';
import { useSelector } from 'react-redux';

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${theme.colors.gray[100]};
  min-height: 93.9vh;
  background: url(${backgroundImage});
  background-size: cover;
`;
const ColorContainer = styled.div`
  background-color: #f6f7f6;
`;

const DefaultLayout: React.FC = ({ children }) => {
  const accessToken = useSelector((state: any) => state.SignIn.access_token); // pegamos o token
  const user: any = jwtDecode(accessToken); // do token pegamos as informacoes de sessao :)
  return (
    <>
      {user.cpf !== undefined ? <Header /> : <HeaderEmpresa />}
      <ColorContainer>
        <Content>{children}</Content>
      </ColorContainer>
      <Footer />
      <GlobalStyle />
    </>
  );
};

export default DefaultLayout;
