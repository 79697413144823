import LogoProtestado from '@/assets/images/logo-white.svg';
import theme from '@/assets/styles/theme';
import { IUserCompanyProps } from '@/components/Interfaces/UserCompanyProps';
import Nav from '@/components/Ui/Nav';
import { Box } from '@chakra-ui/react';
import jwtDecode from 'jwt-decode';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import UserSetting, { ISignInProps } from './components/UserSetting';
import { MenuItem } from './interfaces/MenuItem';
import { Container, ImageLogo, MenuIcon, NavBar, NavigationContainer } from './styles';

const Header: React.FC = () => {
  const history = useHistory();
  const accessToken = useSelector((state: ISignInProps) => state.SignIn.access_token);
  const [user, setUserDecode] = useState({} as IUserCompanyProps);
  const [NavOptions, setNavOptions] = useState([] as MenuItem[]);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLHeadingElement>(null);
  const [logo, setLogo] = useState('');
  const [dataFramaLogo, sethasDataFramaLogo] = useState(false);

  const buildHeaderMenu = useCallback(
    (user: IUserCompanyProps) => {
      const menuItems: MenuItem[] = [];
      menuItems.push({
        id: '/titulos',
        label: 'Títulos',
        dropdown: [
          { id: 1, title: 'Títulos', path: '/titulos', icon: 'dashboard', dataCy: '' },
          { id: 2, title: 'Upload Títulos', path: '/titulos/upload-titulos', icon: 'upload', dataCy: 'menuUploadTitulos' },
          { id: 3, title: 'Comandos em Lote', path: '/titulos/comandos-em-lote', icon: 'lote', dataCy: 'menuComandosLote' },
          { id: 4, title: 'Consulta Títulos', path: '/titulos/listar-titulos', icon: 'list', dataCy: 'menuListarTitulos' },
          { id: 5, title: 'Relatórios', path: '/relatorios/titulos', icon: 'reports', dataCy: '' }
        ],
        icon: 'menuDropdown',
        dataCy: 'menuTitulos',
        onClickHandler: () => history.push('/titulos')
      });
      menuItems.push({
        id: '/ofertas',
        label: 'Ofertas',
        dropdown: [
          { id: 1, title: 'Ofertas', path: '/ofertas', icon: 'dashboard', isHovered: false, dataCy: '' },
          {
            id: 2,
            title: 'Oferta Global',
            path: '/ofertas/listar-ofertas-globais',
            icon: 'offer',
            isHovered: false,
            dataCy: 'menuOfertasGlobais',
            subitems: [
              {
                id: 21,
                title: 'Listar Ofertas',
                path: '/ofertas/listar-ofertas-globais',
                icon: 'subitem',
                isHovered: false,
                isSubitem: true,
                dataCy: 'menuListarOfertasGlobais'
              },
              {
                id: 22,
                title: 'Criar Oferta',
                path: '/ofertas/listar-ofertas-globais/criar-oferta-global',
                icon: 'subitem',
                isHovered: false,
                isSubitem: true,
                dataCy: 'menuCriarOfertaGlobal'
              },
              {
                id: 23,
                title: 'Relatórios',
                path: '/relatorios/ofertas-globais',
                icon: 'subitem',
                isHovered: false,
                isSubitem: true,
                dataCy: ''
              }
            ]
          },
          {
            id: 3,
            title: 'Oferta Título',
            path: '/ofertas/listar-ofertas-titulo',
            icon: 'offer',
            isHovered: false,
            dataCy: 'menuOfertasTitulo',
            subitems: [
              {
                id: 31,
                title: 'Listar Ofertas',
                path: '/ofertas/listar-ofertas-titulo',
                icon: 'subitem',
                isHovered: false,
                isSubitem: true,
                dataCy: 'menuListarOfertasTitulo'
              },
              {
                id: 32,
                title: 'Criar Oferta',
                path: '/ofertas/listar-ofertas-titulo/criar-oferta-titulo',
                icon: 'subitem',
                isHovered: false,
                isSubitem: true,
                dataCy: 'menuCriarOfertaTitulo'
              },
              {
                id: 33,
                title: 'Relatórios',
                path: '/relatorios/ofertas-titulo',
                icon: 'subitem',
                isHovered: false,
                isSubitem: true,
                dataCy: ''
              }
            ]
          }
        ],
        icon: 'menuDropdown',
        dataCy: 'menuOfertas',
        onClickHandler: () => history.push('/ofertas')
      });
      // menuItems.push({
      //   id: '/mensageria',
      //   label: 'Mensageria',
      //   dropdown: [
      //     // { id: 1, title: 'Mensageria', path: '/mensageria', icon: 'dashboard', isHovered: false, dataCy: '' },
      //     // { id: 2, title: 'Ajustes', path: '/mensageria/ajustes', icon: 'config', disabled: true },
      //     // { id: 3, title: 'Templates de Email', path: '/mensageria/galeria-templates', icon: 'email', isHovered: false, dataCy: '' },
      //     {
      //       id: 4,
      //       title: 'Campanhas',
      //       path: '',
      //       icon: 'campanhas',
      //       isHovered: false,
      //       dataCy: 'menuCampanhas',
      //       subitems: [
      //         // {
      //         //   id: 41,
      //         //   title: 'Listar Campanhas',
      //         //   path: '/mensageria/listar-campanhas',
      //         //   icon: 'subitem',
      //         //   isHovered: false,
      //         //   isSubitem: true,
      //         //   dataCy: 'menuListarCampanhas'
      //         // },
      //         {
      //           id: 42,
      //           title: 'Criar Campanha',
      //           path: '/mensageria/listar-campanhas/criar-template-email',
      //           icon: 'subitem',
      //           isHovered: false,
      //           isSubitem: true,
      //           dataCy: 'menuCriarCampanha'
      //         }
      //         // {
      //         //   id: 43,
      //         //   title: 'Relatórios',
      //         //   path: '/relatorios/mensageria-emails',
      //         //   icon: 'subitem',
      //         //   isHovered: false,
      //         //   isSubitem: true,
      //         //   dataCy: ''
      //         // }
      //       ]
      //     }
      //     // { id: 5, title: 'SMS', path: '/mensageria/enviar-sms', icon: 'sms', disabled: true },
      //   ],
      //   icon: 'menuDropdown',
      //   dataCy: 'menuMensageria',
      //   onClickHandler: () => history.push('/mensageria')
      // });
      menuItems.push({
        id: '/relatorios',
        label: 'Relatórios',
        dropdown: [
          { id: 1, title: 'Relatórios', path: '/relatorios', icon: 'dashboard', isHovered: false, dataCy: '' },
          { id: 2, title: 'Títulos', path: '/relatorios/titulos', icon: 'reports', isHovered: false, dataCy: '' },
          { id: 3, title: 'Renegociações', path: '/relatorios/renegociacoes', icon: 'reports', isHovered: false, dataCy: '' },
          { id: 4, title: 'Ofertas Globais', path: '/relatorios/ofertas-globais', icon: 'reports', isHovered: false, dataCy: '' },
          { id: 5, title: 'Ofertas Título', path: '/relatorios/ofertas-titulo', icon: 'reports', isHovered: false, dataCy: '' }
          // { id: 6, title: 'Mensageria/Emails', path: '/relatorios/mensageria-emails', icon: 'reports', isHovered: false, dataCy: '' }
        ],
        icon: 'menuDropdown',
        dataCy: '',
        onClickHandler: () => history.push('/relatorios')
      });
      if (user.is_superuser_protestado) {
        menuItems.push(
          {
            id: '/entidades',
            label: 'Empresas',
            icon: 'menuDropdown',
            dropdown: [
              {
                id: 1,
                title: 'Entidades',
                path: '/entidades',
                icon: 'company',
                isHovered: false,
                dataCy: 'menuDashEntidades',
                subitems: [
                  {
                    id: 11,
                    title: 'Listar Entidades',
                    path: '/entidades',
                    icon: 'subitem',
                    isHovered: false,
                    isSubitem: true,
                    dataCy: 'menuListarEntidades'
                  },
                  {
                    id: 12,
                    title: 'Criar Entidade',
                    path: '/entidades/criar-entidade',
                    icon: 'subitem',
                    isHovered: false,
                    isSubitem: true,
                    dataCy: 'menuCriarEntidade'
                  }
                ]
              }
            ],
            dataCy: 'menuEntidades',
            onClickHandler: () => history.push('/entidades')
          },
          {
            id: '/usuarios',
            label: 'Usuários',
            icon: 'menuDropdown',
            dropdown: [
              {
                id: 1,
                title: 'Usuários',
                path: '/usuarios',
                icon: 'user',
                isHovered: false,
                dataCy: 'menuDashUsuarios',
                subitems: [
                  {
                    id: 11,
                    title: 'Listar Usuários',
                    path: '/usuarios',
                    icon: 'subitem',
                    isHovered: false,
                    isSubitem: true,
                    dataCy: 'menuListarUsuarios'
                  },
                  {
                    id: 12,
                    title: 'Criar Usuário',
                    path: '/usuarios/criar-usuario',
                    icon: 'subitem',
                    isHovered: false,
                    isSubitem: true,
                    dataCy: 'menuCriarUsuario'
                  }
                ]
              }
            ],
            dataCy: 'menuUsuarios',
            onClickHandler: () => history.push('/usuarios')
          }
        );
      } else if (user.is_admin) {
        menuItems.push({
          id: '/usuarios',
          label: 'Usuários',
          dropdown: [
            {
              id: 1,
              title: 'Usuários',
              path: '/usuarios',
              icon: 'list',
              isHovered: false,
              dataCy: 'menuDashUsuarios',
              subitems: [
                {
                  id: 11,
                  title: 'Listar Usuários',
                  path: '/usuarios',
                  icon: 'subitem',
                  isHovered: false,
                  isSubitem: true,
                  dataCy: 'menuListarUsuarios'
                },
                {
                  id: 12,
                  title: 'Criar Usuário',
                  path: '/usuarios/criar-usuario',
                  icon: 'subitem',
                  isHovered: false,
                  isSubitem: true,
                  dataCy: 'menuCriarUsuario'
                }
              ]
            }
          ],
          icon: 'menuDropdown',
          dataCy: 'menuUsuarios',
          onClickHandler: () => history.push('/usuarios')
        });
      }
      return menuItems;
    },
    [history]
  );

  const handleClickOutSide = useCallback(
    (e) => {
      if (!ref.current?.contains(e.target) && isOpen) {
        setIsOpen(!isOpen);
      }
    },
    [isOpen]
  );

  useEffect(() => {
    if (accessToken && Object.keys(user).length === 0) {
      const userDecode = jwtDecode<IUserCompanyProps>(accessToken);
      if (userDecode) {
        setUserDecode(userDecode);
        const headerMenu = buildHeaderMenu(userDecode);
        setNavOptions(headerMenu);
        setLogo(userDecode.corporation_white_label_data?.logo_url || '');
        sethasDataFramaLogo(userDecode.corporation_white_label_data?.logo_frame || false);
      }
    }
  }, [accessToken, user, buildHeaderMenu]);

  useEffect(() => {
    window.addEventListener('click', handleClickOutSide);

    return () => {
      window.removeEventListener('click', handleClickOutSide);
    };
  }, [handleClickOutSide]);

  return (
    <Container primaryCompany={theme.colors.headerFooterColor} headerFooterFontColor={theme.colors.headerFooterFontColor}>
      <MenuIcon onClick={() => setIsOpen(!isOpen)} />
      <Box display="grid" alignItems="center">
        <ImageLogo
          dataFrameLogo={dataFramaLogo}
          onClick={() => history.push('/minha-conta-empresarial')}
          src={logo ? logo : LogoProtestado}
          alt="Protestado"
        />
      </Box>
      <NavigationContainer>
        <NavBar onClick={() => setIsOpen(!isOpen)} ref={ref}>
          <Nav items={NavOptions} isVisible mobileIsVisible={isOpen} navType="navBar" mobileNavType="dropNav" />
        </NavBar>
        <UserSetting />
      </NavigationContainer>
    </Container>
  );
};

export default Header;
