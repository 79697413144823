import theme from '@/assets/styles/theme';
import { shade, tint } from 'polished';
import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import Loading from '../Loading';

interface Props {
  size?: 'small' | 'custom' | 'custom2' | 'default' | 'big';
  color?: 'primary' | 'inverse' | 'cancelar' | 'error' | 'warning' | 'secondary' | 'light';
  full?: boolean;
  loading?: boolean;
  inline?: boolean;
  disabled?: boolean;
  width?: string;
  height?: string;
  margin?: string;
  minWidth?: string;
  isDashboard?: boolean;
  isManagement?: boolean;
  onClick?: () => ReactNode | void;
}

const sizes = {
  small: css`
    border-radius: 4px;
    font-size: 14px;
    padding: 12px 20px;
  `,

  custom: css`
    border-radius: 4px;
    font-size: 20px;
    padding: 12px 20px;

    @media only screen and (max-width: 1366px) {
      font-size: 16px;
      padding: 8px 16px;
    }

    @media only screen and (max-width: 1024px) {
      font-size: 12px;
      padding: 8px 16px;
    }
  `,

  custom2: css`
    border-radius: 4px;
    font-size: 20px;
    padding: 12px 20px;

    @media only screen and (max-width: 1024px) {
      font-size: 16px;
      padding: 8px 16px;
    }
  `,

  default: css`
    border-radius: 4px;
    font-size: 18px;
    padding: 12px 20px;
    margin: 8px;
  `,

  big: css`
    border-radius: 5px;
    font-size: 20px;
    padding: 12px 20px;
  `
};

const colorsButton = (type = 'primary') => {
  switch (type) {
    case 'primary':
      return css`
        background: ${theme.colors.secondary};
        color: ${theme.colors.white};
        &:hover {
          background: ${shade(0.1, theme.colors.secondary)};
        }
      `;
    case 'secondary':
      return css`
        background: ${theme.colors.primary};
        color: ${theme.colors.white};
        &:hover {
          background: ${shade(0.1, theme.colors.primary)};
        }
      `;
    case 'inverse':
      return css`
        background: transparent;
        border: 1px solid ${theme.colors.secondary};
        color: ${theme.colors.secondary};
        &:hover {
          background: ${tint(0.8, theme.colors.gray[400])};
        }
      `;
    case 'cancelar':
      return css`
        background: ${theme.colors.gray[250]};
        border: 1px solid ${theme.colors.gray[250]};
        color: ${theme.colors.gray[350]};
        &:hover {
          background: ${tint(0.8, theme.colors.gray[400])};
        }
      `;
    case 'error':
      return css`
        background: ${theme.colors.error};
        border: 1px solid ${theme.colors.error};
        color: ${theme.colors.white};
        &:hover {
          background: ${tint(0.8, theme.colors.error)};
        }
      `;
    case 'warning':
      return css`
        background: ${theme.colors.gray[400]};
        border: 1px solid ${theme.colors.gray[400]};
        color: ${theme.colors.white};
        &:hover {
          background: ${shade(0.1, theme.colors.gray[400])};
        }
      `;
    case 'light':
      return css`
        background: transparent;
        border: 0.6px solid ${theme.colors.primary};
        color: ${theme.colors.primary};
        &:hover {
          color: ${theme.colors.secondary};
        }
      `;
    default:
      return css`
        background: ${theme.colors.secondary};
        color: ${theme.colors.white};
        &:hover {
          background: ${shade(0.1, theme.colors.secondary)};
        }
      `;
  }
};

const Button: React.FC<Props & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({ loading, onClick, children, ...rest }) => (
  <Sbutton {...rest} onClick={onClick}>
    {loading ? <Loading /> : children}
  </Sbutton>
);

const Sbutton = styled.button<Props>`
  align-items: center;
  background: ${theme.colors.primary};
  border: 0;
  color: ${theme.colors.white};
  cursor: pointer;
  font-weight: bold;
  letter-spacing: 0.4px;
  display: ${(props) => (props.inline ? 'inline-block' : 'flex')};
  justify-content: center;
  transition: background-color 0.2s, color 0.2s;
  min-width: ${(props) => (props.minWidth ? props.minWidth : '')};
  width: ${(props) => (props.full ? '100%' : props.width)};
  height: ${({ height }) => (height ? height : '')};
  margin: ${(props) => (props.margin ? props.margin : '')};

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  ${({ isDashboard }) =>
    isDashboard &&
    `
      min-width: 100% !important;
      max-width: 100% !important;
      min-height: 45px !important;
      max-height: 45px !important;
  `}

  ${({ isManagement }) =>
    isManagement &&
    `
      min-width: 240px !important;
      max-width: 240px !important;
      min-height: 45px !important;
      max-height: 45px !important;

      @media only screen and (max-width: 1024px) {
        min-width: 190px !important;
        max-width: 190px !important;
        min-height: 45px !important;
        max-height: 45px !important;
      }
  `}

  ${(props) => (props.size ? sizes[props.size] : sizes.default)}
  ${(props) => colorsButton(props.color)}
`;

export default Button;
