import React, { useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import Button from '@/components/Ui/Button';
import GoBack from '@/components/Ui/GoBack';
import Input from '@/components/Ui/Input';
import Text from '@/components/Ui/Text';
import Title from '@/components/Ui/Title';
import { ForgotCompanyRequestPayload, Creators } from '@/store/ducks/ForgotPasswordCompany';
import { FormHandles } from '@unform/core';

import AuthMessage from '../components/AuthMessage';
import AuthTitle from '../components/AuthTitle';
import { ContainerButton, Form } from '../components/Container/styles';
import ErrorMessage from '../components/ErrorMessage';
import { Container } from './styles';
import { Link } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import { HiExternalLink } from 'react-icons/hi';
import theme from '@/assets/styles/theme';

interface DataValidation {
  email: string;
}

interface Error {
  ForgotPassword: {
    error: { code: number; message: string };
  };
}

interface Loading {
  ForgotPassword: {
    loading: boolean;
  };
}

const ForgotPasswordCompany: React.FC = () => {
  const dispatch = useDispatch();
  const formRef = useRef<FormHandles>(null);

  const errorMessage = useSelector((state: Error) => state.ForgotPassword.error);
  const loading = useSelector((state: Loading) => state.ForgotPassword.loading);

  const handleSubmit = useCallback(
    async (data: DataValidation) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string().required('Por favor preencha seu e-mail')
        });

        await schema.validate(data, {
          abortEarly: false
        });
        const cpfWithoutMask = data.email;
        const payload: ForgotCompanyRequestPayload = {
          email: cpfWithoutMask
        };
        dispatch(Creators.ForgotCompanyRequest(payload));
      } catch (err) {
        const validationErrors: { [key: string]: string } = {};

        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: Yup.ValidationError) => {
            validationErrors[error.path] = error.message;
          });

          formRef.current?.setErrors(validationErrors);
        }
      }
    },
    [dispatch]
  );

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <GoBack url="/login-para-empresas" />
        <AuthTitle>
          <Title label="Esqueceu a senha?" isVisiblePixel />
        </AuthTitle>
        <AuthMessage>
          <Text size="medium2">Insira seu E-mail para poder redefinir a senha de seu acesso Empresarial</Text>
        </AuthMessage>

        {errorMessage && errorMessage.message && <ErrorMessage label={errorMessage.message} />}
        <Input name="email" type="text" placeholder="Digite seu E-mail" label="Digite seu E-mail" full />
        <ContainerButton>
          <Button full color="primary" type="submit" loading={loading} disabled={!!loading} size="big">
            Continuar
          </Button>
        </ContainerButton>
        <Box mt={5} alignItems='center'>
          <Link color={theme.colors.primary} display={'flex'} href='https://protestado.com.br/contato' isExternal>
            Problemas ao acessar sua conta? Fale Conosco <HiExternalLink />
          </Link>
        </Box>
      </Form>
    </Container>
  );
};

export default ForgotPasswordCompany;
