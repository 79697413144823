
interface OfertasResponse {
    oferta_id: number
    renegociacoes_ativas: boolean
    renegociacoes_pagas: boolean
    apresentante: number
    boleto: boolean
    cartao: boolean
    data_fim: string
    data_inicio: string
    desconto: string
    desconto_porcentagem: string
    filtro_dias_upload_ate: string
    filtro_dias_upload_de: string
    filtro_dias_vcto_ate: string
    filtro_dias_vcto_de: string
    id: number
    iof: string
    juros_boleto: string
    juros_cartao: string
    mensagem: string
    parcela_boleto: number
    parcela_cartao: number
    protestado_entidade_uuid: string
    status: boolean
    status_envio_sms: boolean
    taxa_servicos: string
    valor_ate: string
    valor_de: string
}

export const handleListTitulo = (ofertas: OfertasResponse[]) => {
  return ofertas.map(oferta => {
    const idOferta = oferta.id
    console.log(idOferta)
    return {
      oferta_id: oferta.oferta_id,
      renegociacoes_ativas: oferta.renegociacoes_ativas,
      renegociacoes_pagas: oferta.renegociacoes_pagas,
      apresentante: oferta.apresentante,
      boleto: oferta.boleto,
      cartao: oferta.cartao,
      data_fim: oferta.data_fim,
      data_inicio: oferta.data_inicio,
      desconto: oferta.desconto,
      desconto_porcentagem: oferta.desconto_porcentagem,
      filtro_dias_upload_ate: oferta.filtro_dias_upload_ate,
      filtro_dias_upload_de: oferta.filtro_dias_upload_de,
      filtro_dias_vcto_ate: oferta.filtro_dias_vcto_ate,
      filtro_dias_vcto_de: oferta.filtro_dias_vcto_de,
      id: oferta.id,
      iof: oferta.iof,
      juros_boleto: oferta.juros_boleto,
      juros_cartao: oferta.juros_cartao,
      mensagem: oferta.mensagem,
      parcela_boleto: oferta.parcela_boleto,
      parcela_cartao: oferta.parcela_cartao,
      protestado_entidade_uuid: oferta.protestado_entidade_uuid,
      status: oferta.status,
      status_envio_sms: oferta.status_envio_sms,
      taxa_servicos: oferta.taxa_servicos,
      valor_ate: oferta.valor_ate,
      valor_de: oferta.valor_de
    }
  })
}
