import React from 'react'

import Logo from '@/assets/images/logo-blue.png'
import LogoAlt from '@/assets/images/logo-white.svg'

import {
  BackgroundImage,
  HoverColor,
  ImageLogo,
  ImageLogoOutside,
  ContainerLogoOutside,
} from './styles'

const PanelLeft: React.FC = () => (
  <>
    <ContainerLogoOutside>
      <ImageLogoOutside src={LogoAlt} alt="Protestado" />
    </ContainerLogoOutside>
    <BackgroundImage>
      <ImageLogo src={Logo} alt="Protestado" />
      <HoverColor />
    </BackgroundImage>
  </>
)

export default PanelLeft
