import theme from '@/assets/styles/theme';
import styled, { css } from 'styled-components';

interface StyledProps {
  size?: 'small' | 'default' | 'medium' | 'medium2' | 'medium3' | 'big' | 'big2' | 'biggest';
  weight?: 'bold' | 'bolder' | 'lighter' | 'normal' | 'medium';
  color?: 'secondary' | 'primary' | 'orange200' | 'white' | 'gray350' | 'gray400' | 'yellow' | 'red' | 'gray500';
  bold?: boolean;
  lineThrough?: boolean;
  textDecorationColor?: 'primary' | 'red';
  align?: 'left' | 'center' | 'right';
  margin?: string;
  isView?: boolean;
  textJustify?: boolean;
}

const sizes = (type = 'default') => {
  switch (type) {
    case 'small':
      return css`
        font-size: 14px;

        @media only screen and (max-width: 1366px) {
          font-size: 12px;
        }

        @media only screen and (max-width: 768px) {
          font-size: 10px;
        }
      `;

    case 'default':
      return css`
        font-size: 16px;
        @media only screen and (max-width: 1366px) {
          font-size: 14px;
        }
        @media only screen and (max-width: 768px) {
          font-size: 16px;
        }
      `;

    case 'medium':
      return css`
        font-size: 18px;
        @media only screen and (max-width: 1366px) {
          font-size: 16px;
        }
        @media only screen and (max-width: 768px) {
          font-size: 16px;
        }
      `;

    case 'medium2':
      return css`
        font-size: 20px;
        line-height: 25px;

        @media (max-width: 768px) {
          font-size: 16px;
        }
      `;

    case 'medium3':
      return css`
        font-size: 20px;
        line-height: 25px;

        // zoom 150% até 175%
        @media (min-width: 1024.99px) and (max-width: 1366px) {
          font-size: 16px;
        }

        // zoom 200%
        @media (min-width: 360px) and (max-width: 1024px) {
          font-size: 16px;
        }
      `;

    case 'big':
      return css`
        font-size: 26px;
        @media only screen and (max-width: 1366px) {
          font-size: 22px;
        }
        @media only screen and (max-width: 768px) {
          font-size: 20px;
        }
      `;

    case 'big2':
      return css`
        font-size: 32px;
        @media only screen and (max-width: 1366px) {
          font-size: 26px;
        }
        @media only screen and (max-width: 768px) {
          font-size: 22px;
        }
      `;
    case 'biggest':
      return css`
        font-size: 42px;
        @media only screen and (max-width: 1366px) {
          font-size: 34px;
        }
        @media only screen and (max-width: 768px) {
          font-size: 30px;
        }
      `;
  }
};

const colors = (type = 'primary') => {
  switch (type) {
    case 'primary':
      return css`
        color: ${theme.colors.primary};
      `;
    case 'secondary':
      return css`
        color: ${theme.colors.secondary};
      `;
    case 'orange200':
      return css`
        color: ${theme.colors.orange[200]};
      `;
    case 'white':
      return css`
        color: ${theme.colors.white};
      `;
    case 'gray350':
      return css`
        color: ${theme.colors.gray[350]};
      `;
    case 'gray400':
      return css`
        color: ${theme.colors.gray[400]};
      `;
    case 'gray500':
      return css`
        color: ${theme.colors.gray[500]};
      `;
    case 'red':
      return css`
        color: ${theme.colors.red[300]};
      `;
    case 'yellow':
      return css`
        color: ${theme.colors.yellow};
      `;
  }
};

const weights = (type = 'normal') => {
  switch (type) {
    case 'normal':
      return css`
        font-weight: normal;
      `;
    case 'medium':
      return css`
        font-weight: 500;
      `;
    case 'bold':
      return css`
        font-weight: bold;
      `;
    case 'bolder':
      return css`
        font-weight: bolder;
      `;
    case 'lighter':
      return css`
        font-weight: lighter;
      `;
  }
};

const textDecorations = (type = 'default') => {
  switch (type) {
    case 'lineThrough':
      return css`
        text-decoration: line-through;
      `;
    case 'default':
      return css`
        text-decoration: none;
      `;
  }
};

const textDecorationColors = (type = 'primary') => {
  switch (type) {
    case 'primary':
      return css`
        text-decoration-color: ${theme.colors.primary};
      `;
    case 'red':
      return css`
        text-decoration-color: ${theme.colors.red[300]};
      `;
  };
};

const aligns = (type = 'left') => {
  switch (type) {
    case 'left':
      return css`
        text-align: left;
      `;
    case 'center':
      return css`
        text-align: center;
      `;
    case 'right':
      return css`
        text-align: right;
      `;
  }
};

export const Container = styled.p<StyledProps>`
  ${({ size }) => (size ? sizes(size) : sizes('default'))}
  ${({ color }) => (color ? colors(color) : colors('primary'))}
  ${({ weight }) => (weight ? weights(weight) : weights('normal'))}
  ${({ bold }) => bold && weights('bold')};
  display: ${({ isView }) => (!isView ? 'none !important' : '')};
  margin: ${({ margin }) => margin && margin};
  ${({ lineThrough }) => (lineThrough ? textDecorations('lineThrough') : textDecorations('default'))};
  ${({ textDecorationColor }) => (textDecorationColor ? textDecorationColors(textDecorationColor) : textDecorationColors('primary'))};
  ${({ textJustify, align }) => (textJustify ? 'text-align: justify' : align ? aligns(align) : aligns('left'))};
`;

export const DefaultTextTooltip = styled.div`
  display: flex;
  gap: 12px;
`;
