import theme from '@/assets/styles/theme';
import styled from 'styled-components';

interface toggleProps {
  paddingRight?: boolean;
  paddingLeft?: boolean;
  margin?: string;
  isView?: boolean;
  fontWeight?: string;
}

export const ToggleArea = styled.div<toggleProps>`
  display: ${(props) => (props.isView ? 'flex' : 'none !important')};
  align-items: center;
  margin: ${(toggleProps) => (toggleProps.margin ? toggleProps.margin : '')};
`;

export const ToggleTextContainer = styled.div<toggleProps>`
  span {
    margin-top: 20px;
    color: ${theme.colors.gray[500]};
    font-size: 16px;
    font-weight: ${(toggleProps) => (toggleProps.fontWeight ? toggleProps.fontWeight : '400')};
  }
  padding-left: ${(toggleProps) => (toggleProps.paddingLeft ? '12px' : '')};
  padding-right: ${(toggleProps) => (toggleProps.paddingRight ? '12px' : '')};
`;
