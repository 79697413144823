import FeedbackImage from '@/assets/images/magnifier.png';
import EmptyFeedback from '@/components/Helpers/EmptyFeedback';
import Button from '@/components/Ui/Button';
import { FullScreenLoader } from '@/components/Ui/FullScreenLoader';
import Input from '@/components/Ui/Input';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { ButtonRow, FeedbackArea, ResultsArea } from '../components/EditContainer/styles';
import { AgreementV1 } from './interfaces/interfaceRelatorio';
import { getAgreementReport } from './providers/getTituloRelatorio';
//pagination
import {
  ButtonEndArea,
  DefaultFilterButton,
  DefaultFilterButtonContainer,
  DefaultFilterContainer,
  DefaultFilterField,
  DefaultFilterInputs,
  DefaultFilterRow
} from '@/components/Ui/DefaultFilterContainer/styles';
import DefaultHeader from '@/components/Ui/DefaultHeader';
import { DefaultContainerPages, DefaultListPageContainer } from '@/components/Ui/DefaultHeader/style';
import DefaultPagination from '@/components/Ui/DefaultPagination';
import GoBack from '@/components/Ui/GoBack';
import ModalConfirm from '@/components/Ui/Modal/ModalConfirm';
import ModalWarning from '@/components/Ui/Modal/ModalWarning';
import Select from '@/components/Ui/Select';
import Text from '@/components/Ui/Text';
import Header from './components/Header';
import DataList from './components/paginate/DataList';
import { enviaRelatorioEmail } from './providers/getTituloRelatorio';

export interface AgreementReportFilter {
  id: string;
  status_pagamento: string;
  forma_pagamento: string;
  tipo_oferta: string;
  tempo_plataforma: string;
  data_inicio: string;
  data_fim: string;
}

const RelatoriosRenegociacoes: React.FC = () => {
  const [data, setData] = useState<AgreementV1[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [displayedData, setDisplayedData] = useState<AgreementV1[]>([]);
  const itemsPerPage = 10;
  const allPages = Math.ceil(data.length / itemsPerPage);
  const [showModalWarning, setShowModalWarning] = useState(false);
  const [showModalSucess, setShowModalSucess] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [clearSelect, setClearSelect] = useState(false);

  const [statusPagos, setStatusPagos] = useState(false);
  const handleChangeStatusPagos = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStatusPagos(e.target.checked);
  };

  const [tempoPlataforma, setTempoPlataforma] = useState('');
  const [tempoVencimento, setTempoVencimento] = useState('Todos');
  const handleTempoPlataforma = useCallback((tempoPlataforma: string) => {
    setTempoPlataforma(tempoPlataforma);
  }, []);

  const handleButtonColor = useCallback(
    (buttonType: string, value: string): 'primary' | 'inverse' => {
      if (buttonType === 'tempoVencimento') {
        if (value === tempoVencimento) {
          return 'primary';
        } else {
          return 'inverse';
        }
      } else {
        if (value === tempoPlataforma) {
          return 'primary';
        } else {
          return 'inverse';
        }
      }
    },
    [tempoVencimento, tempoPlataforma]
  );

  const statusPagamentoOptions = [
    { value: '', label: 'Todos' },
    { value: 'PENDING', label: 'Pendente' },
    { value: 'PAYMENT_DEFERRED', label: 'Deferido' },
    { value: 'PAYMENT_DECLINED', label: 'Recusado' },
    { value: 'AUTHORIZED', label: 'Autorizado' },
    { value: 'APPROVED', label: 'Aprovado' },
    { value: 'PAID', label: 'Pago' },
    { value: 'PARTIALLY_PAID', label: 'Pago Parcialmente' },
    { value: 'CANCELED', label: 'Cancelado' },
    { value: 'CONFIRMED', label: 'Confirmadoo' }
  ];

  const formaPagamentoOptions = [
    { value: '', label: 'Todos' },
    { value: 'BANK_SLIP', label: 'Boleto Bancário' },
    { value: 'CREDIT_CARD', label: 'Cartão' },
    { value: 'PIX', label: 'Pix' }
  ];

  const tipoOfertaOptions = [
    { value: '', label: 'Todos' },
    { value: 'GLOBAL', label: 'Oferta Geral' },
    { value: 'TITLE', label: 'Oferta Título' }
  ];

  const onPageChange = (page = 1) => {
    const startItem = (page - 1) * itemsPerPage;
    const endItem = page * itemsPerPage;
    setDisplayedData(data.slice(startItem, endItem));
  };

  const formRef = useRef<FormHandles>(null);

  const clearFilter = useCallback((fieldValue: string) => {
    formRef.current?.clearField(fieldValue);
  }, []);

  const clearAllFilters = useCallback(() => {
    formRef.current?.reset();
    setClearSelect(true);
  }, []);

  useEffect(() => {
    onPageChange();
    //getStats30DiasData();
  }, [data]);

  const handleSendEmail = async (id: any) => {
    try {
      setIsLoading(true);
      setShowModalWarning(false);
      const payload = {
        email: 'email@email.com'
      };

      await enviaRelatorioEmail(payload);
      setShowModalSucess(true);
    } catch (error) {
      toast.error('Não foi possível Desabilitar a Oferta');
      setShowModalError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (fields: AgreementReportFilter) => {
    try {
      setIsLoading(true);

      const params = {
        id: fields.id,
        data_inicio: fields.data_inicio,
        data_fim: fields.data_fim,
        tempo_plataforma: tempoPlataforma,
        status_pagamento: fields.status_pagamento,
        forma_pagamento: fields.forma_pagamento,
        tipo_oferta: fields.tipo_oferta
      } as AgreementReportFilter;

      const agreementReports = await getAgreementReport(params);
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      // const titulo: any[] = handleListTitulo(TituloList);

      if (!agreementReports.length) {
        toast.warning('Não foram encontradas Renegociações para este Filtro!');
      }

      setData(agreementReports);
    } catch (error) {
      toast.error('Não foi possível listar as Renegociações!');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <FullScreenLoader loadingMessage="Carregando Acordos..." />}
      <DefaultContainerPages paddingTop paddingBottom>
        <DefaultHeader label="Relatório de Renegociações" marginTop="12px" />
        {/* <AreaStats className="areaStats">
          <ContainerStats className="containerStats" margin="0px 12px 24px 0px">
            <Text color="secondary" size="big" bold>
              <IconContext.Provider
                value={{
                  color: 'primary'
                }}
              >
                <TbReportMoney />
              </IconContext.Provider>
              Pagos Últimos 7 dias
            </Text>
            <Text color="primary" size="big">
              Ofertas: {ofertas7Dias}
            </Text>
            <Text color="primary" size="big" bold>
              R$ {ofertas7DiasValor}
            </Text>
          </ContainerStats>
          <ContainerStats className="containerStats" margin="0px 12px 24px 12px">
            <Text color="secondary" size="big" bold>
              <IconContext.Provider
                value={{
                  color: 'primary'
                }}
              >
                <TbReportMoney />
              </IconContext.Provider>
              Pagos Últimos 30 dias
            </Text>
            <Text color="primary" size="big">
            Ofertas: {ofertas30Dias}
            </Text>
            <Text color="primary" size="big" bold>
            R$ {ofertas30DiasValor}
            </Text>
          </ContainerStats>
          <ContainerStats className="containerStats" margin="0px 0px 24px 12px">
            <Text color="secondary" size="big" bold>
              <IconContext.Provider
                value={{
                  color: 'primary'
                }}
              >
                <TbReportMoney />
              </IconContext.Provider>
              Pagos Últimos 60 dias
            </Text>
            <Text color="primary" size="big">
            Ofertas: {ofertas60Dias}
            </Text>
            <Text color="primary" size="big" bold>
            R$ {ofertas60DiasValor}
            </Text>
          </ContainerStats>
        </AreaStats> */}
        <DefaultListPageContainer>
          <DefaultFilterContainer>
            <Form className="editForm" ref={formRef} onSubmit={handleSubmit}>
              <DefaultFilterInputs>
                <DefaultFilterField>
                  <Select
                    clearSelect={clearSelect}
                    setClearSelect={setClearSelect}
                    isFull
                    name="forma_pagamento"
                    label="Forma de pagamento"
                    color="gray400"
                    options={formaPagamentoOptions}
                  />
                </DefaultFilterField>
                <DefaultFilterField>
                  <Select
                    clearSelect={clearSelect}
                    setClearSelect={setClearSelect}
                    isFull
                    name="status_pagamento"
                    label="Status do pagamento"
                    color="gray400"
                    options={statusPagamentoOptions}
                  />
                </DefaultFilterField>
                <DefaultFilterField>
                  <Select
                    clearSelect={clearSelect}
                    setClearSelect={setClearSelect}
                    isFull
                    name="tipo_oferta"
                    label="Tipo de Oferta"
                    color="gray400"
                    options={tipoOfertaOptions}
                  />
                </DefaultFilterField>
                <DefaultFilterField>
                  <Input name="data_inicio" type="date" label="Última atualização De:" full />
                </DefaultFilterField>
                <DefaultFilterField>
                  <Input name="data_fim" type="date" label="Última Atualização Até" full />
                </DefaultFilterField>
              </DefaultFilterInputs>
              <DefaultFilterInputs margin="30px 0 0 0">
                <Text size="medium" margin="20px 20px 20px 20px" color="gray500" weight="medium">
                  Tempo do Pagamento na plataforma:
                </Text>
                <Text size="default" margin="20px 20px 20px 20px" color="gray500">
                  Ao selecionar essa informação, serão selecionados apenas títulos que estão alocados na Plataforma com Status Pago dentro
                  desse prazo (em dias)
                </Text>
                <ButtonRow>
                  <Button
                    onClick={() => handleTempoPlataforma('1')}
                    type="button"
                    size="small"
                    color={handleButtonColor('tempoPlataforma', '1')}
                  >
                    {'Até 1D'}
                  </Button>
                  <Button
                    onClick={() => handleTempoPlataforma('7')}
                    type="button"
                    size="small"
                    color={handleButtonColor('tempoPlataforma', '7')}
                  >
                    {'Até 7D'}
                  </Button>
                  <Button
                    onClick={() => handleTempoPlataforma('30')}
                    type="button"
                    size="small"
                    color={handleButtonColor('tempoPlataforma', '30')}
                  >
                    {'Até 30D'}
                  </Button>
                  <Button
                    onClick={() => handleTempoPlataforma('90')}
                    type="button"
                    size="small"
                    color={handleButtonColor('tempoPlataforma', '90')}
                  >
                    {'Até 90D'}
                  </Button>
                  <Button
                    onClick={() => handleTempoPlataforma('')}
                    type="button"
                    size="small"
                    color={handleButtonColor('tempoPlataforma', '')}
                  >
                    Todos
                  </Button>
                </ButtonRow>
              </DefaultFilterInputs>
              <DefaultFilterRow padding="0px 10px 0px 10px">
                <DefaultFilterButtonContainer>
                  <DefaultFilterButton>
                    <Button type="button" onClick={clearAllFilters} size="big" color="warning" width="100%" disabled={!!isLoading}>
                      Limpar Filtros
                    </Button>
                  </DefaultFilterButton>
                </DefaultFilterButtonContainer>
                <DefaultFilterButtonContainer>
                  {data && data.length > 0 && (
                    <DefaultFilterButton padding="0px 12px 0px 0px">
                      <Button
                        onClick={() => setShowModalWarning(true)}
                        type="button"
                        minWidth="180px"
                        size="big"
                        color="primary"
                        width="100%"
                        loading={isLoading}
                        disabled={!!isLoading}
                      >
                        Exportar
                      </Button>
                    </DefaultFilterButton>
                  )}
                  <DefaultFilterButton>
                    <Button
                      type="submit"
                      minWidth="180px"
                      size="big"
                      color="primary"
                      width="100%"
                      loading={isLoading}
                      disabled={!!isLoading}
                    >
                      Filtrar
                    </Button>
                  </DefaultFilterButton>
                </DefaultFilterButtonContainer>
              </DefaultFilterRow>
            </Form>
          </DefaultFilterContainer>
          {data?.length === 0 ? (
            <FeedbackArea>
              <EmptyFeedback
                imagePath={FeedbackImage}
                title="Clique em Filtrar para buscar Resultados"
                message="Utilize os filtros acima para encontrar as Renegociações cadastradas"
              />
            </FeedbackArea>
          ) : (
            <ResultsArea>
              <Header posts={[]} />
              <DataList posts={displayedData} />
              <ButtonEndArea>
                <Button
                  onClick={() => setShowModalWarning(true)}
                  size="big"
                  color="primary"
                  width="16%"
                  loading={isLoading}
                  disabled={!!isLoading}
                  margin="0 15px 0 0"
                >
                  Exportar
                </Button>
              </ButtonEndArea>
              <GoBack />
              <DefaultPagination allPagesNumber={allPages} itemsPerPage={10} itemsNumber={data.length} pageChange={onPageChange} />
            </ResultsArea>
          )}
        </DefaultListPageContainer>
        <ModalWarning
          isOpen={showModalWarning}
          buttonLabelConfirm="Gerar Relatório"
          label="Clique em confirmar para receber seu relatório por e-mail"
          handleButtonClick={() => {
            handleSendEmail(statusPagos);
          }}
          handleClickCancelButton={() => setShowModalWarning(false)}
          onClose={() => setShowModalWarning(false)}
        />
        <ModalConfirm
          isOpen={showModalSucess}
          onClose={() => setShowModalSucess(false)}
          handleButtonClick={() => setShowModalSucess(false)}
          label={`E-mail enviado com Sucesso`}
          buttonLabel="Ok"
        />
      </DefaultContainerPages>
    </>
  );
};

export default RelatoriosRenegociacoes;
