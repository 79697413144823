import styled from 'styled-components'

import backgroundImage from '@/assets/images/auth/background-login.png'
import theme from '@/assets/styles/theme'

export const BackgroundImage = styled.div`
  position: relative;
  background-image: url(${backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 56%;
  min-height: 100vh;

  // zoom 150% até 175%
  @media (min-width: 1024.99px) and (max-width: 1366px) {
    display: none;
  }

  // zoom 200%
  @media (min-width: 768.99px) and (max-width: 1024px) {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`
export const ContainerLogoOutside = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  background-color: ${theme.colors.primary};

  // zoom 90% até 25%
  @media (min-width: 1920.99px) {
    display: none;
  }

  // zoom 100% até 110%
  @media (min-width: 1600.99px) and (max-width: 1920px) {
    display: none;
  }

  // zoom 125%
  @media (min-width: 1366.99px) and (max-width: 1600px) {
    display: none;
  }

  // zoom 150% até 175%
  @media (min-width: 1024.99px) and (max-width: 1366px) {
    display: flex;
  }

  // zoom 200%
  @media (min-width: 768.99px) and (max-width: 1024px) {
    display: flex;
  }

  @media only screen and (max-width: 769px) {
    display: flex;
  }
`

export const HoverColor = styled.div`
  mix-blend-mode: multiply;
  width: 100%;
  height: 100%;
  opacity: 0.42;
  position: absolute;
  top: 0;
  left: 0;
`
export const ImageLogo = styled.img`
  padding: 40px;
  height: auto;
  min-width: 195px;
  z-index: 9999;
  position: absolute;
  left: 0;
  top: 0;
`
export const ImageLogoOutside = styled.img`
  padding: 30px;
  height: auto;
  width: 100%;
  min-width: 150px;
  max-width: 197px;
`
