import theme from '@/assets/styles/theme';
import styled from 'styled-components';

interface DefaultTemplateProps {
  padding?: string;
  isCentered?: boolean;
  margin?: string;
}

export const BodyContainer = styled.div<DefaultTemplateProps>`
  padding: ${(props) => (props.padding ? props.padding : '16px 32px 16px 32px')};
  margin: ${(props) => (props.margin ? props.margin : '0px')};
  background: ${theme.colors.white};
  border-radius: 5px;
`;

export const TextContainer = styled.div<DefaultTemplateProps>`
  padding: ${(props) => (props.padding ? props.padding : '10px 0px 10px 0px')};

  ${({ isCentered }) =>
    isCentered &&
    `
    display: flex;
    justify-content: center;
  `}

  p {
    text-align: justify;
    line-height: 1.25;
  }
`;
