import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

//import { ConnectedRouter } from 'connected-react-router'
import GlobalStyle from '@/assets/styles/global';
import EmailSent from '@/modules/Auth/EmailSent';
import ForgotPasswordCompany from '@/modules/Auth/ForgotPasswordCompany';
import SignInCompany from '@/modules/Auth/SignInCompany';
import UnconfirmedEmail from '@/modules/Auth/UnconfirmedEmail';
import DetalheRenegociacao from '@/modules/Entidades/DetalhesRenegociacao';
import EditarTitulo from '@/modules/Entidades/EditarTitulo';
import GerenciarTitulos from '@/modules/Entidades/ListarTitulos';
import RelatoriosMensageriaEmails from '@/modules/Entidades/RelatoriosMensageriaEmails';
import RelatoriosOfertasGlobais from '@/modules/Entidades/RelatoriosOfertasGlobais';
import RelatoriosRenegociacoes from '@/modules/Entidades/RelatoriosRenegociacoes';
import RelatoriosTitulos from '@/modules/Entidades/RelatoriosTitulos';
import { History } from 'history';

import DetalheUpload from '@/modules/Entidades/EditarUpload';
import UploadTitulos from '@/modules/Entidades/UploadTitulos';

import CriarEntidades from '@/modules/Entidades/CriarEntidades';
import EditarEntidade from '@/modules/Entidades/EditarEntidade';
import GerenciarEntidades from '@/modules/Entidades/ListarEntidades';

import CriarUsuarios from '@/modules/Entidades/CriarUsuarios';
import EditarUsuario from '@/modules/Entidades/EditarUsuario';
import GerenciarUsuarios from '@/modules/Entidades/ListarUsuarios';

import CriarOfertaGlobal from '@/modules/Entidades/CriarOfertaGlobal';
import CriarOfertaTitulo from '@/modules/Entidades/CriarOfertaTitulo';
import EditarOfertaGlobal from '@/modules/Entidades/EditarOfertaGlobal';
import EditarOfertaTitulo from '@/modules/Entidades/EditarOfertaTitulo';
import GerenciarOfertas from '@/modules/Entidades/GerenciarOfertas';
import ListarOfertasGlobais from '@/modules/Entidades/ListarOfertasGlobais';
import ListarOfertasTitulo from '@/modules/Entidades/ListarOfertasTitulo';

import Dashboard from '@/modules/Entidades/Dashboard';

import CriarTemplateEmail from '@/modules/Entidades/CriarTemplateEmail';
import EnviarEmail from '@/modules/Entidades/EnviarEmail';

import RecoverPassword from '@/modules/Auth/RecoverPassword';
import ComandosLote from '@/modules/Entidades/ComandosLote';
import EditarTemplateEmail from '@/modules/Entidades/EditarTemplateEmail';
import GaleriaTemplates from '@/modules/Entidades/GaleriaTemplates';
import Mensageria from '@/modules/Entidades/Mensageria';
import PainelFinanceiro from '@/modules/Entidades/PainelFinanceiro';
import Relatorios from '@/modules/Entidades/Relatorios';
import RelatoriosOfertasTitulo from '@/modules/Entidades/RelatoriosOfertasTitulo';
import Titulos from '@/modules/Entidades/Titulos';
import PrivateRoute from './PrivateRoute';
import Route from './Route';
interface RouterProps {
  history: History;
}
const Routes = (props: RouterProps) => {
  return (
    <BrowserRouter>
      <Switch>
        {/* Telas de login */}
        <Route path="/" exact component={SignInCompany} />
        <Route path="/login" exact component={SignInCompany} />
        <Route path="/login-para-empresas" exact component={SignInCompany} />

        {/* Telas de Senhas e Contas */}
        <Route path="/esqueci-minha-senha" exact component={ForgotPasswordCompany} />
        <Route path="/esqueci-minha-senha-empresas" exact component={ForgotPasswordCompany} />
        <Route path="/email-nao-confirmado" exact component={UnconfirmedEmail} />
        <Route path="/email-sent" exact component={EmailSent} />
        <Route path="/recuperar-senha" exact component={RecoverPassword} />

        {/* Seção Entidades */}
        <Route path="/minha-conta-empresarial" exact component={Dashboard} isPrivate />
        <PrivateRoute
          path="/entidades/criar-entidade"
          component={CriarEntidades}
          unauthorizedPath="/minha-conta-empresarial"
          superUserOnly={false}
        />
        <PrivateRoute
          path="/painel-financeiro"
          component={PainelFinanceiro}
          unauthorizedPath="/minha-conta-empresarial"
          superUserOnly={false}
        />
        <PrivateRoute path="/entidades/:id" component={EditarEntidade} unauthorizedPath="/minha-conta-empresarial" superUserOnly={false} />
        <PrivateRoute path="/entidades" component={GerenciarEntidades} unauthorizedPath="/minha-conta-empresarial" superUserOnly={false} />

        {/* Seção Usuários de Entidades */}
        <PrivateRoute
          path="/usuarios/criar-usuario"
          component={CriarUsuarios}
          unauthorizedPath="/minha-conta-empresarial"
          superUserOnly={false}
        />
        <PrivateRoute path="/usuarios/:id" component={EditarUsuario} unauthorizedPath="/minha-conta-empresarial" superUserOnly={false} />
        <PrivateRoute path="/usuarios" component={GerenciarUsuarios} unauthorizedPath="/minha-conta-empresarial" superUserOnly={false} />

        {/* Seção de Mensageria */}
        <Route path="/mensageria/listar-campanhas/criar-template-email" exact component={CriarTemplateEmail} isPrivate />
        <Route path="/mensageria/listar-campanhas/:id" exact component={EditarTemplateEmail} isPrivate />
        <Route path="/mensageria/listar-campanhas" exact component={EnviarEmail} isPrivate />
        <Route path="/mensageria/galeria-templates" exact component={GaleriaTemplates} isPrivate />
        <Route path="/mensageria" exact component={Mensageria} isPrivate />

        {/* Seção de Relatórios */}
        <Route path="/relatorios/titulos" exact component={RelatoriosTitulos} isPrivate />
        <Route path="/relatorios/ofertas-globais" exact component={RelatoriosOfertasGlobais} isPrivate />
        <Route path="/relatorios/ofertas-titulo" exact component={RelatoriosOfertasTitulo} isPrivate />
        <Route path="/relatorios/renegociacoes" exact component={RelatoriosRenegociacoes} isPrivate />
        <Route path="/relatorios/mensageria-emails" exact component={RelatoriosMensageriaEmails} isPrivate />
        <Route path="/relatorios" exact component={Relatorios} isPrivate />

        {/* Seção Ofertas Entidades/Renegociação */}
        <Route path="/ofertas/listar-ofertas-globais/criar-oferta-global" exact component={CriarOfertaGlobal} isPrivate />
        <Route path="/ofertas/listar-ofertas-globais/:id" exact component={EditarOfertaGlobal} isPrivate />
        <Route path="/ofertas/listar-ofertas-globais" exact component={ListarOfertasGlobais} isPrivate />
        <Route path="/ofertas/listar-ofertas-titulo/criar-oferta-titulo/:id" exact component={CriarOfertaTitulo} isPrivate />
        <Route path="/ofertas/listar-ofertas-titulo/criar-oferta-titulo" exact component={CriarOfertaTitulo} isPrivate />
        <Route path="/ofertas/listar-ofertas-titulo/:id" exact component={EditarOfertaTitulo} isPrivate />
        <Route path="/ofertas/listar-ofertas-titulo" exact component={ListarOfertasTitulo} isPrivate />
        <Route path="/ofertas/renegociacoes/:id" exact component={DetalheRenegociacao} isPrivate />
        <Route path="/ofertas" exact component={GerenciarOfertas} isPrivate />

        {/* Seção Títulos */}
        <Route path="/titulos/listar-titulos/:id" exact component={EditarTitulo} isPrivate />
        <Route path="/titulos/listar-titulos" exact component={GerenciarTitulos} isPrivate />
        <Route path="/titulos/upload-titulos/:id" exact component={DetalheUpload} isPrivate />
        <Route path="/titulos/upload-titulos" exact component={UploadTitulos} isPrivate />
        <Route path="/titulos/comandos-em-lote" exact component={ComandosLote} isPrivate />
        {/* <Route path="/inserir-titulos" exact component={InserirTitulos} isPrivate /> */}
        <Route path="/titulos" exact component={Titulos} isPrivate />
        <GlobalStyle />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
