import FeedbackImage from '@/assets/images/magnifier.png';
import EmptyFeedback from '@/components/Helpers/EmptyFeedback';
import Button from '@/components/Ui/Button';
import { FullScreenLoader } from '@/components/Ui/FullScreenLoader';
import Input from '@/components/Ui/Input';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { FeedbackArea, ResultsArea } from '../components/EditContainer/styles';
//pagination
// import DefaultPagination from '@/components/Ui/DefaultPagination';
// import DataList from './components/paginate/DataList';
import {
  ButtonEndArea,
  DefaultContainerIcon,
  DefaultContainerIconText,
  DefaultFilterButton,
  DefaultFilterButtonContainer,
  DefaultFilterContainer,
  DefaultFilterField,
  DefaultFilterInputs,
  DefaultFilterRow,
  DefaultHeaderFiltro
} from '@/components/Ui/DefaultFilterContainer/styles';
import DefaultHeader from '@/components/Ui/DefaultHeader';
import { DefaultContainerPages, DefaultListPageContainer } from '@/components/Ui/DefaultHeader/style';
import DefaultPaginationV2 from '@/components/Ui/DefaultPagination v2';
import GoBack from '@/components/Ui/GoBack';
import InputMask from '@/components/Ui/InputMask';
import ModalConfirm from '@/components/Ui/Modal/ModalConfirm';
import Select from '@/components/Ui/Select';
import Text from '@/components/Ui/Text';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { OfertaTitulo } from '../ListarOfertasTitulo/interfaces/interface';
import { FiltroRelatorioOfertaTitulo } from './interfaces/FiltroRelatorioOfertaTitulo';
import DataList from './paginate/DataList';
import { getRelatorioOfertasTitulo } from './providers/getRelatorioOfertasTitulo';

interface RelatorioOfertaTitulosFields {
  oferta_negociacao: 'ALL_OFFERS' | 'ACCEPTED_OFFERS' | 'NOT_ACCEPTED_OFFERS';
  oferta_status: 'ALL_OFFERS' | 'ACTIVE_OFFERS' | 'INACTIVE_OFFERS';
  ofertas_expiradas: 'ALL_OFFERS' | 'EXPIRED_OFFERS' | 'NOT_EXPIRED_OFFERS';
  formas_pagamento: 'ALL_OFFERS' | 'BANK_SLIP' | 'CREDIT_CARD';
  desconto_tipo: 'ALL_TYPES' | 'PERCENTAGE' | 'VALUE';
  titulo_status: string;
  dt_cadastro_de: string;
  dt_cadastro_ate: string;
  valor_de: string;
  valor_ate: string;
  desconto_porcentagem_de: string;
  desconto_porcentagem_ate: string;
  numero_titulo: string;
}

const ofertasRenegociacoesOptions = [
  { value: 'ALL_OFFERS', label: 'Todas' },
  { value: 'ACCEPTED_OFFERS', label: 'Ofertas com Negociações' },
  { value: 'NOT_ACCEPTED_OFFERS', label: 'Ofertas sem Negociações' }
];

const ofertasStatusOptions = [
  { value: 'ALL_OFFERS', label: 'Todas' },
  { value: 'ACTIVE_OFFERS', label: 'Apenas ativas' },
  { value: 'INACTIVE_OFFERS', label: 'Apenas inativas' }
];

const descontoTipoOptions = [
  { value: 'ALL_TYPES', label: 'Todos' },
  { value: 'PERCENTAGE', label: 'Porcentagem' },
  { value: 'VALUE', label: 'Valor' }
];

const tituloStatusOptions = [
  { value: 'ALL_OFFERS', label: 'Todos' },
  { value: '102', label: 'Apenas Protestados' },
  { value: '144', label: 'Apenas Anuídos' },
  { value: '320', label: 'Apenas Negociação' }
];

const ofertasExpiradasOptions = [
  { value: 'ALL_OFFERS', label: 'Todos' },
  { value: 'EXPIRED_OFFERS', label: 'Apenas Expiradas' },
  { value: 'NOT_EXPIRED_OFFERS', label: 'Apenas Não Expiradas' }
];

const formasPagamentoOptions = [
  { value: 'ALL_OFFERS', label: 'Todas' },
  { value: 'BANK_SLIP', label: 'Boleto' },
  { value: 'CREDIT_CARD', label: 'Cartão de Crédito' }
];

const RelatoriosOfertasTitulo: React.FC = () => {
  const itemsPerPage = 10;
  const [data, setData] = useState<OfertaTitulo[]>([]);
  const [resetPagination, setResetPagination] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [previousPage, setPreviousPage] = useState('');
  const [nextPage, setNextPage] = useState('');
  const [firstPage, setFirstPage] = useState('');
  const [lastPage, setLastPage] = useState('');
  const [dataCounter, setDataCounter] = useState(0);
  const allPages = Math.ceil(dataCounter / itemsPerPage);
  const [isLoading, setIsLoading] = useState(false);
  const [showModalWarning, setShowModalWarning] = useState(false);
  const [showModalSucess, setShowModalSucess] = useState(false);
  const [clearSelect, setClearSelect] = useState(false);

  const [valor_de, setDescontoValoradoDe] = useState('');
  const handleChangeDescontoValoradoDe = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescontoValoradoDe(e.target.value);
  };

  const [valor_ate, setDescontoValoradoAte] = useState('');
  const handleChangeDescontoValoradoAte = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescontoValoradoAte(e.target.value);
  };

  const [desconto_porcentagem_de, setDescontoPorcentagemDe] = useState('');
  const handleChangeDescontoPorcentagemDe = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescontoPorcentagemDe(e.target.value);
  };

  const [desconto_porcentagem_ate, setDescontoPorcentagemAte] = useState('');
  const handleChangeDescontoPorcentagemAte = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescontoPorcentagemAte(e.target.value);
  };

  const formRef = useRef<FormHandles>(null);

  const clearFilter = useCallback((fieldValue: string) => {
    formRef.current?.clearField(fieldValue);
  }, []);

  const clearAllFilters = useCallback(() => {
    formRef.current?.clearField('dt_cadastro_de');
    formRef.current?.clearField('dt_cadastro_ate');
    formRef.current?.clearField('numero_titulo');
    formRef.current?.clearField('oferta_negociacao');
    formRef.current?.clearField('oferta_status');
    formRef.current?.clearField('titulo_status');
    formRef.current?.clearField('ofertas_expiradas');
    formRef.current?.clearField('formas_pagamento');
    setClearSelect(true);
    setDescontoValoradoDe('');
    setDescontoValoradoAte('');
    setDescontoPorcentagemDe('');
    setDescontoPorcentagemAte('');
  }, []);

  const handleSubmit = useCallback(
    async (fields: RelatorioOfertaTitulosFields) => {
      try {
        setIsLoading(true);
        setResetPagination(true);
        const valor_de_formatado = valor_de.replace('R$', '').replace(/,/g, '').replace('_', '');
        const valor_ate_formatado = valor_ate.replace('R$', '').replace(/,/g, '').replace('_', '');

        const params = {
          dt_cadastro_de: fields.dt_cadastro_de,
          dt_cadastro_ate: fields.dt_cadastro_ate,
          valor_de: valor_de_formatado,
          valor_ate: valor_ate_formatado,
          numero_titulo: fields.numero_titulo,
          oferta_negociacao: fields.oferta_negociacao,
          oferta_status: fields.oferta_status,
          titulo_status: fields.titulo_status,
          formas_pagamento: fields.formas_pagamento,
          ofertas_expiradas: fields.ofertas_expiradas,
          desconto_tipo: fields.desconto_tipo
        } as FiltroRelatorioOfertaTitulo;

        const ofertasTitulo = await getRelatorioOfertasTitulo(params);
        if (ofertasTitulo.data.length > 0) {
          setData(ofertasTitulo.data);
          setDataCounter(ofertasTitulo.count);
          setPreviousPage(ofertasTitulo.previous);
          setNextPage(ofertasTitulo.next);
        } else {
          setData([]);
          setDataCounter(0);
          setPreviousPage('');
          setNextPage('');
          setFirstPage('');
          setLastPage('');
          toast.warning('Não foram encontradas Ofertas para este Filtro!');
        }
      } catch (error) {
        toast.error('Não foi possível listar as Ofertas!');
      } finally {
        setIsLoading(false);
        setResetPagination(false);
      }
    },
    [valor_de, valor_ate]
  );

  const onPageChange = useCallback(
    async (navigationType?: string) => {
      try {
        setIsLoading(true);
        if (navigationType && navigationType !== '') {
          let ofertasTitulo = {} as any;
          if (navigationType === 'NEXT') {
            ofertasTitulo = await getRelatorioOfertasTitulo({ url: nextPage } as FiltroRelatorioOfertaTitulo);
          }
          if (navigationType === 'PREVIOUS') {
            ofertasTitulo = await getRelatorioOfertasTitulo({ url: previousPage } as FiltroRelatorioOfertaTitulo);
          }
          if (navigationType === 'FIRST') {
            ofertasTitulo = await getRelatorioOfertasTitulo({ url: firstPage } as FiltroRelatorioOfertaTitulo);
          }
          if (navigationType === 'LAST') {
            ofertasTitulo = await getRelatorioOfertasTitulo({ url: lastPage } as FiltroRelatorioOfertaTitulo);
          }

          if (ofertasTitulo?.length > 0) {
            setData(ofertasTitulo);
            setDataCounter(ofertasTitulo.count);
            setPreviousPage(ofertasTitulo.previous);
            setNextPage(ofertasTitulo.next);
            setFirstPage(ofertasTitulo.first);
            setLastPage(ofertasTitulo.last);
          } else {
            toast.error('Não foi possível listar as Ofertas!');
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    },
    [nextPage, previousPage, firstPage, lastPage]
  );

  return (
    <>
      {isLoading && <FullScreenLoader loadingMessage="Carregando Ofertas..." />}
      <DefaultContainerPages paddingTop paddingBottom>
        <DefaultHeader label="Relatório de Ofertas Título" marginTop="12px" />
        <DefaultListPageContainer>
          <DefaultFilterContainer>
            <DefaultHeaderFiltro>
              <Text bold color="secondary" size="medium2">
                Filtro
              </Text>
              <DefaultContainerIconText onClick={() => setIsOpen(!isOpen)}>
                {!isOpen ? (
                  <Text bold color="secondary">
                    Mostrar mais filtros
                  </Text>
                ) : (
                  <Text bold color="secondary">
                    Mostrar menos filtros
                  </Text>
                )}
                <DefaultContainerIcon>{!isOpen ? <IoIosArrowDown /> : <IoIosArrowUp />}</DefaultContainerIcon>
              </DefaultContainerIconText>
            </DefaultHeaderFiltro>

            <Form className="editForm" ref={formRef} onSubmit={handleSubmit}>
              <DefaultFilterInputs>
                <DefaultFilterField>
                  <Select
                    clearSelect={clearSelect}
                    setClearSelect={setClearSelect}
                    name="oferta_negociacao"
                    label="Ofertas e Renegociações:"
                    color="gray500"
                    isDisabled
                    isFull
                    customMargin="20px 0px 0px 0px"
                    options={ofertasRenegociacoesOptions}
                  />
                </DefaultFilterField>
                <DefaultFilterField>
                  <Select
                    clearSelect={clearSelect}
                    setClearSelect={setClearSelect}
                    name="oferta_status"
                    label="Status da Oferta:"
                    color="gray500"
                    isFull
                    customMargin="20px 0px 0px 0px"
                    options={ofertasStatusOptions}
                  />
                </DefaultFilterField>
                <DefaultFilterField>
                  <Select
                    clearSelect={clearSelect}
                    setClearSelect={setClearSelect}
                    name="titulo_status"
                    label="Status do Título:"
                    color="gray500"
                    isFull
                    isDisabled
                    customMargin="20px 0px 0px 0px"
                    options={tituloStatusOptions}
                  />
                </DefaultFilterField>
                <DefaultFilterField>
                  <Input
                    width="100%"
                    name="numero_titulo"
                    type="string"
                    placeholder="Ex.: ABC123"
                    label="Número do Título:"
                    padding="8px 8px 8px 6px"
                    iconSize="1.2rem"
                    isClearable={true}
                    clearFunction={() => clearFilter('numero_titulo')}
                  />
                </DefaultFilterField>
              </DefaultFilterInputs>
              {isOpen && (
                <>
                  <DefaultFilterInputs margin="20px 0 0 0">
                    <DefaultFilterField>
                      <Select
                        clearSelect={clearSelect}
                        setClearSelect={setClearSelect}
                        name="desconto_tipo"
                        label="Tipo do desconto:"
                        color="gray500"
                        isFull
                        customMargin="20px 0px 0px 0px"
                        options={descontoTipoOptions}
                      />
                    </DefaultFilterField>
                    <DefaultFilterField>
                      <InputMask
                        name="valor_de"
                        label="Valor de:"
                        placeholder="1"
                        prefix=""
                        value={valor_de}
                        onChange={handleChangeDescontoValoradoDe}
                      />
                    </DefaultFilterField>
                    <DefaultFilterField>
                      <InputMask
                        name="valor_ate"
                        label="Valor ate:"
                        placeholder="100"
                        prefix=""
                        value={valor_ate}
                        onChange={handleChangeDescontoValoradoAte}
                      />
                    </DefaultFilterField>
                    <DefaultFilterField>
                      <Select
                        clearSelect={clearSelect}
                        setClearSelect={setClearSelect}
                        name="ofertas_expiradas"
                        label="Ofertas Expiradas:"
                        color="gray500"
                        isDisabled
                        isFull
                        options={ofertasExpiradasOptions}
                      />
                    </DefaultFilterField>
                  </DefaultFilterInputs>
                  <DefaultFilterInputs margin="20px 0 0 0">
                    <DefaultFilterField>
                      <Input
                        width="100%"
                        name="dt_cadastro_de"
                        type="date"
                        label="Data Cadastro de:"
                        padding="8px 8px 8px 6px"
                        iconSize="1.2rem"
                        isClearable={true}
                        clearFunction={() => clearFilter('dt_cadastro_de')}
                      />
                    </DefaultFilterField>
                    <DefaultFilterField>
                      <Input
                        width="100%"
                        name="dt_cadastro_ate"
                        type="date"
                        placeholder="Ex.: 3200"
                        label="Data Cadastro até:"
                        padding="8px 8px 8px 6px"
                        iconSize="1.2rem"
                        isClearable={true}
                        clearFunction={() => clearFilter('dt_cadastro_ate')}
                      />
                    </DefaultFilterField>
                    <DefaultFilterField>
                      <Select
                        clearSelect={clearSelect}
                        setClearSelect={setClearSelect}
                        name="formas_pagamento"
                        label="Formas de Pagamento:"
                        color="gray500"
                        isDisabled
                        isFull
                        options={formasPagamentoOptions}
                      />
                    </DefaultFilterField>
                  </DefaultFilterInputs>
                </>
              )}
              <DefaultFilterRow padding="0px 10px 0px 10px">
                <DefaultFilterButtonContainer>
                  <DefaultFilterButton>
                    <Button type="button" onClick={clearAllFilters} size="big" color="warning" width="100%" disabled={!!isLoading}>
                      Limpar Filtros
                    </Button>
                  </DefaultFilterButton>
                </DefaultFilterButtonContainer>
                <DefaultFilterButtonContainer>
                  {data && data.length > 0 && <DefaultFilterButton padding="0px 12px 0px 0px"></DefaultFilterButton>}
                  <DefaultFilterButton>
                    <Button
                      type="submit"
                      minWidth="180px"
                      size="big"
                      color="primary"
                      width="100%"
                      loading={isLoading}
                      disabled={!!isLoading}
                    >
                      Filtrar
                    </Button>
                  </DefaultFilterButton>
                </DefaultFilterButtonContainer>
              </DefaultFilterRow>
            </Form>
          </DefaultFilterContainer>
          {data?.length === 0 ? (
            <FeedbackArea>
              <EmptyFeedback
                imagePath={FeedbackImage}
                title="Clique em Filtrar para buscar resultados"
                message="Aqui serão exibidos resultados de Ofertas Título"
              />
            </FeedbackArea>
          ) : (
            <ResultsArea>
              <DataList posts={data} />
              <ButtonEndArea>
                <Button
                  onClick={() => setShowModalWarning(true)}
                  size="big"
                  color="primary"
                  width="16%"
                  loading={isLoading}
                  disabled={!!isLoading}
                  margin="0 22px 0 0"
                >
                  Exportar
                </Button>
              </ButtonEndArea>
              <GoBack />
              <DefaultPaginationV2
                allPagesNumber={allPages}
                itemsPerPage={10}
                itemsNumber={data.length}
                pageChange={onPageChange}
                resetPagination={resetPagination}
              />
            </ResultsArea>
          )}
        </DefaultListPageContainer>
        {/* <ModalWarning
          isOpen={showModalWarning}
          buttonLabelConfirm="Gerar Relatório"
          label="Clique em confirmar para receber seu relatório por e-mail"
          handleButtonClick={() => {
            handleSendEmail(email);
          }}
          handleClickCancelButton={() => setShowModalWarning(false)}
          onClose={() => setShowModalWarning(false)}
        /> */}
        <ModalConfirm
          isOpen={showModalSucess}
          onClose={() => setShowModalSucess(false)}
          handleButtonClick={() => setShowModalSucess(false)}
          label={`E-mail enviado com Sucesso`}
          buttonLabel="Ok"
        />
      </DefaultContainerPages>
    </>
  );
};

export default RelatoriosOfertasTitulo;
