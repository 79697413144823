import styled from 'styled-components';

interface TooltipProps {
  isView?: boolean;
  padding?: string;
  margin?: string;
}

export const TooltipContainer = styled.div<TooltipProps>`
  display: ${(props) => (!props.isView ? 'none !important' : '')};
  padding: ${(props) => (props.padding ? props.padding : '0px')};
  margin: ${(props) => (props.margin ? props.margin : '0px')};
  cursor: help;

  .customClass {
    font-weight: bold;
  }
`;
