import api from '@/services/api';
import { EmailCampaignNotificaDTO } from '../interfaces/EmailCampaignNotificaDTO';

export const CreateEmailCampaign = async (payload: EmailCampaignNotificaDTO[]) => {
  try {
    const response = await api.post('users/protested-titles/corporation/create-email-campaign', payload);
    return response.data;
  } catch (error) {
    console.error(`Erro ao criar campanha de e-mail: ${error}`);
    throw error;
  }
};
