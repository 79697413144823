import { put, call } from 'redux-saga/effects';

import api from '@/services/api';
import history from '@/services/history';

import { SignUpRequest, Creators } from '../ducks/SignUp';

export function* signUpRequest(payload: SignUpRequest) {
  const { cpf, name, birth_date, email, password } = payload;
  try {
    const response = yield call(api.post, '/auth/register', {
      cpf,
      name,
      birth_date,
      email,
      password
    });
    window.location.replace('/email-confirmation');
    const { email: emailResponse } = response.data;
    if (emailResponse) {
      yield put(
        Creators.SignupSuccess({
          email: emailResponse
        })
      );
    }
  } catch (error) {
    const { code, message } = error.response.data;
    yield put(Creators.SignupFailure({ code, message }));
  }
}
