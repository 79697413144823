import theme from '@/assets/styles/theme';
import { getRandomNumber } from './Utilities';

export const parcelaBoletoOptions = [
  { value: '1', label: '1 Parcela (à vista)' }
  // { value: '2', label: '2 Parcelas' },
  // { value: '3', label: '3 Parcelas' },
  // { value: '4', label: '4 Parcelas' },
  // { value: '5', label: '5 Parcelas' },
  // { value: '6', label: '6 Parcelas' },
  // { value: '7', label: '7 Parcelas' },
  // { value: '8', label: '8 Parcelas' },
  // { value: '9', label: '9 Parcelas' },
  // { value: '10', label: '10 Parcelas' }
];

export const parcelaCartaoOptions = [
  { value: '1', label: '1 Parcela (à vista)' }
  // { value: '2', label: '2 Parcelas' },
  // { value: '3', label: '3 Parcelas' },
  // { value: '4', label: '4 Parcelas' },
  // { value: '5', label: '5 Parcelas' },
  // { value: '6', label: '6 Parcelas' },
  // { value: '7', label: '7 Parcelas' },
  // { value: '8', label: '8 Parcelas' },
  // { value: '9', label: '9 Parcelas' },
  // { value: '10', label: '10 Parcelas' }
];

export const defaultOptionsX = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top' as const
    },
    title: {
      display: true,
      text: 'Ofertas/Renegociações pagas'
    }
  }
};

export const defaultOptionsY = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top' as const
    },
    title: {
      display: true,
      text: 'Ofertas Criadas'
    }
  }
};

export const defaultLabels = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

export const defaultDataX = {
  labels: defaultLabels,
  datasets: [
    {
      label: 'Renegociações',
      data: defaultLabels.map(() => getRandomNumber(1, 500)),
      borderColor: theme.colors.secondary,
      backgroundColor: 'rgba(38, 208, 124, 1.0)'
    }
    // {
    //   label: 'Apenas Custas',
    //   data: defaultLabels.map(() => getRandomNumber(1, 1000)),
    //   borderColor: '#012169',
    //   backgroundColor: 'rgba(1, 33, 105, 1.0)'
    // }
  ]
};

export const defaultDataY = {
  labels: defaultLabels,
  datasets: [
    {
      label: 'Renegociações',
      data: defaultLabels.map(() => getRandomNumber(1, 300)),
      borderColor: theme.colors.secondary,
      backgroundColor: 'rgba(38, 208, 124, 1.0)'
    }
    // {
    //   label: 'Apenas Custas',
    //   data: defaultLabels.map(() => getRandomNumber(1, 1000)),
    //   borderColor: '#012169',
    //   backgroundColor: 'rgba(1, 33, 105, 1.0)'
    // }
  ]
};

export const defaultDataPieChart = {
  labels: defaultLabels,
  datasets: [
    {
      label: 'Protestados',
      data: defaultLabels.map(() => getRandomNumber(1, 1000)),
      backgroundColor: [
        'rgba(38, 208, 124, 0.6)',
        'rgba(54, 162, 235, 0.6)',
        'rgba(255, 206, 86, 0.6)',
        'rgba(75, 192, 192, 0.6)',
        'rgba(153, 102, 255, 0.6)',
        'rgba(255, 159, 64, 0.6)',
        'rgba(1, 33, 105, 0.6)'
      ],
      borderColor: [
        'rgba(38, 208, 124, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(1, 33, 105, 1)'
      ],
      borderWidth: 2
    }
  ]
};

export const tituloStatus = {
  '101': 'Pago',
  '102': 'Protestado',
  '103': 'Retirado',
  '105': 'Irregular - sem custas',
  '107': 'Liquidação em condicional',
  '121': 'Cancelado',
  '144': 'Anuência confirmada no Cartório',
  '220': 'Solicitado Protesto',
  '221': 'Enviado a Protesto',
  '225': 'Apontado',
  '230': 'Solicitado Desistência',
  '231': 'Enviado a Desistencia',
  '240': 'Solicitado Cancelamento',
  '241': 'Enviado a cancelamento',
  '250': 'Solicitado Anuência',
  '251': 'Enviado à Anuência',
  '261': 'Upload Cancelado',
  '320': 'Solicitado Negociação',
  '998': 'Título Desabilitado via Painel',
  '999': 'Débitos Pagos'
};
