import direction from './_direction';
import align from './align/_align';
import justify from './justify/_justify';
import justifyCenter from './justify/_justifyCenter';
import justifyEnd from './justify/_justifyEnd';
import justifySpaceBetween from './justify/_justifySpaceBetween';
import justifySpaceAround from './justify/_justifySpaceAround';
import justifyStart from './justify/_justifyStart';

const flex = {
  direction,
  align,
  justify,
  justifyCenter,
  justifyEnd,
  justifyStart,
  justifySpaceBetween,
  justifySpaceAround
};

export default flex;
