import theme from '@/assets/styles/theme';
import api from '@/services/api';
import history from '@/services/history';
import { call, put } from 'redux-saga/effects';
import { Creators, LoginRequestPayload } from '../ducks/SignIn';

interface ResponseType {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

/**
 * Login
 *
 * @param param0
 */
export function* loginRequest({ login }: { login: LoginRequestPayload }) {
  try {
    const response: ResponseType = yield call(api.post, '/auth/login', login);
    const { access_token } = response.data;
    yield put(Creators.loginSuccess(access_token));
    history.push('/deal-offers');
  } catch (e) {
    const error = e as any;
    const { code, message } = error.response.data;

    if (code === 2002) {
      history.push(`email-nao-confirmado?document=${login.cpf}`);
    }

    yield put(Creators.loginFailure({ code, message }));
  }
}

/**
 * Logout
 *
 */
export function* logout() {
  try {
    yield call(api.post, '/auth/logout');
    yield put(Creators.logoutSuccess());
    theme.colors.primary = '#012169';
    theme.colors.primaryCompany = '#119955';
    theme.colors.secondary = '#26D07C';
    theme.colors.headerFooterColor = '#012169';
    theme.colors.headerFooterFontColor = '#ffffff';
  } catch (error) {
    yield put(Creators.logoutFailure());
  }
}
