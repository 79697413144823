import Button from '@/components/Ui/Button';
import { Titulos } from '@/modules/Entidades/ListarTitulos/interfaces/interface';
import { formatarCpfCnpj, formatarDinheiro } from '@/utils/Utilities';
import { Box, Checkbox, Table, TableContainer, Tbody, Td, Tfoot, Th, Thead, Tr } from '@chakra-ui/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

interface Props {
  allTitles: Titulos[];
  displayedTitles: Titulos[];
  sendCommands: (checkedItems: CheckedItemsBackTitles[]) => void;
  isTitleOffers?: boolean;
}

export interface CheckedItemsBackTitles {
  titleId: string;
  title: Titulos;
  isChecked: boolean;
}

const BatchTitlesTable = ({ allTitles, displayedTitles, sendCommands, isTitleOffers }: Props) => {
  const [checkedItems, setCheckedItems] = useState<CheckedItemsBackTitles[]>(
    allTitles.map((title) => {
      return { titleId: title.id, title: title, isChecked: false };
    })
  );

  const allChecked = checkedItems.every((item) => item.isChecked);
  const isIndeterminate = checkedItems.some((item) => item.isChecked) && !allChecked;

  useEffect(() => {
    const updatedCheckedItems = allTitles.map((title) => {
      return { titleId: title.id, title: title, isChecked: false };
    });
    setCheckedItems(updatedCheckedItems);
  }, [allTitles]);

  return (
    <>
      <Box mb="12px">
        <Button
          type="button"
          onClick={() => sendCommands(checkedItems)}
          minWidth="180px"
          size="big"
          color="primary"
          data-cy="selecionarTitulosLoteTop"
        >
          {isTitleOffers ? 'Selecionar títulos para oferta' : 'Baixa em Lote'}
        </Button>
      </Box>
      <TableContainer p="8px" border="1px" borderColor="gray.300" borderRadius="10px">
        <Table variant="striped" colorScheme="blackAlpha">
          <Thead>
            <Tr>
              <Th>
                <Checkbox
                  isChecked={allChecked}
                  isIndeterminate={isIndeterminate}
                  onChange={(e) =>
                    setCheckedItems(
                      allTitles.map((title) => {
                        return { titleId: title.id, title: title, isChecked: e.target.checked };
                      })
                    )
                  }
                  borderColor="blue.500"
                ></Checkbox>
              </Th>
              <Th>Número Título</Th>
              <Th>Data Vencimento</Th>
              {isTitleOffers && <Th>Custas/Protesto/Negociação</Th>}
              <Th>Documento Devedor</Th>
              <Th isNumeric>Valor Título</Th>
              <Th isNumeric>Valor Protestado</Th>
              <Th isNumeric>Valor Custas</Th>
            </Tr>
          </Thead>
          <Tbody>
            {displayedTitles.map((title: Titulos, index: number) => (
              <Tr key={`${title.id}`}>
                <Td>
                  <Checkbox
                    data-cy={`selecionarTitulo${index}`}
                    isChecked={checkedItems[title.index_titulo!].isChecked}
                    onChange={(e) =>
                      setCheckedItems([
                        ...checkedItems.slice(0, title.index_titulo!),
                        { titleId: title.id, title: title, isChecked: e.target.checked },
                        ...checkedItems.slice(title.index_titulo! + 1)
                      ])
                    }
                    borderColor="blue.500"
                  ></Checkbox>
                </Td>
                <Td>{title.numero_titulo}</Td>
                <Td>{moment(title.data_vencimento).format('DD/MM/YYYY')}</Td>
                {isTitleOffers && <Td>{title.status_codigo == '102' ? 'Protesto' : title.status_codigo == '320' ? 'Negociação' : 'Custas'}</Td>}
                <Td>{formatarCpfCnpj(title.numero_identificacao_devedor)}</Td>
                <Td isNumeric>{formatarDinheiro(Number(title.valor_titulo))}</Td>
                <Td isNumeric>{formatarDinheiro(Number(title.valor_saldo_protesto))}</Td>
                <Td isNumeric>{formatarDinheiro(Number(title.valor_custas))}</Td>
              </Tr>
            ))}
          </Tbody>
          <Tfoot>
            <Tr>
              <Th>
                <Checkbox
                  isChecked={allChecked}
                  isIndeterminate={isIndeterminate}
                  onChange={(e) =>
                    setCheckedItems(
                      allTitles.map((title) => {
                        return { titleId: title.id, title: title, isChecked: e.target.checked };
                      })
                    )
                  }
                  borderColor="blue.500"
                ></Checkbox>
              </Th>
              <Th>Número Título</Th>
              <Th>Data Vencimento</Th>
              {isTitleOffers && <Th>Custas/Protesto/Negociação</Th>}
              <Th>Documento Devedor</Th>
              <Th isNumeric>Valor Título</Th>
              <Th isNumeric>Valor Protestado</Th>
              <Th isNumeric>Valor Custas</Th>
            </Tr>
          </Tfoot>
        </Table>
      </TableContainer>
      <Box mt="12px">
        <Button
          type="button"
          onClick={() => sendCommands(checkedItems)}
          minWidth="180px"
          size="big"
          color="primary"
          data-cy="selecionarTitulosLoteBottom"
        >
          {isTitleOffers ? 'Selecionar títulos para oferta' : 'Baixa em Lote'}
        </Button>
      </Box>
    </>
  );
};

export default BatchTitlesTable;
