import React from 'react';

import { Container, FeedbackContainer, Image, MessageTitle, MessageContent } from './styles';
import Text from '@/components/Ui/Text';

interface Props {
  imagePath: string;
  title: string;
  message: string;
  paddingTop?: string;
}

const EmptyFeedback: React.FC<Props> = ({ imagePath, title, message, paddingTop }) => {
  return (
    <Container>
      <FeedbackContainer paddingTop={paddingTop}>
        <Image src={imagePath} />
        <Text>
          <MessageTitle>{title}</MessageTitle>
        </Text>
        <Text>
          <MessageContent>{message}</MessageContent>
        </Text>
      </FeedbackContainer>
    </Container>
  );
};

export default EmptyFeedback;
