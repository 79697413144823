import styled from 'styled-components';

import theme from '@/assets/styles/theme';

interface Props {
  color?: string;
  fontColor?: string;
}

export const Container = styled.div<Props>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 6.111696522655427vh;
  padding-left: 14vw;
  padding-right: 14vw;

  position: relative;
  bottom: 0;

  font-size: 1rem;
  background-color: ${({ color }) => (color ? color : theme.colors.primary)};
  color: ${({ fontColor }) => (fontColor ? fontColor : 'white')};

  @media only screen and (max-width: 1440px) {
    padding-left: 14vw;
    padding-right: 14vw;
    font-size: 0.75rem;
  }

  @media only screen and (max-width: 1200px) {
    padding-left: 2.6343519494204424vh;
    padding-right: 2.6343519494204424vh;
    font-size: 0.75rem;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column-reverse;
    height: 100%;
    width: 100%;
    padding: 15px;
    font-size: 0.75rem;
    text-align: center;
  }
`;

export const Copyright = styled.div`
  // zoom 250% até 500%
  @media (min-width: 360px) and (max-width: 768px) {
    margin-top: 10px;
    width: 100%;
  }
`;

export const Adress = styled.div`
  @media (min-width: 360px) and (max-width: 768px) {
    width: 90%;
    line-height: 20px;
  }
`;

export default Container;
