import { getDateFormatter } from '@/utils/formatters/date/dateFormatter';
import { OfertaGlobal } from '../interfaces/interface';
import { getListaOfertasGlobais } from '../providers/getListaOfertasGlobais';

export const listaOfertasGlobaisCtrl = async (filtros: any): Promise<OfertaGlobal[]> => {
  const listaOfertasGlobais: OfertaGlobal[] = [];
  const ofertasGlobais = await getListaOfertasGlobais(filtros);
  if (ofertasGlobais && ofertasGlobais.length > 0) {
    for (let j = 0; j < ofertasGlobais.length; j++) {
      const ofertaGlobal = ofertasGlobais[j] as OfertaGlobal;
      const ofertaGlobalEncontrada = listaOfertasGlobais.find((og) => og.id === ofertaGlobal.id);
      if (!ofertaGlobalEncontrada) {
        ofertaGlobal.key = ofertaGlobal.id;
        if (ofertaGlobal.data_inicio) ofertaGlobal.data_inicio = getDateFormatter(ofertaGlobal.data_inicio);
        if (ofertaGlobal.data_fim) ofertaGlobal.data_fim = getDateFormatter(ofertaGlobal.data_fim);
        listaOfertasGlobais.push(ofertaGlobal);
      }
    }
  }
  return listaOfertasGlobais;
};
