import FeedbackImage from '@/assets/images/magnifier.png';
import EmptyFeedback from '@/components/Helpers/EmptyFeedback';
import { IUserCompanyProps } from '@/components/Interfaces/UserCompanyProps';
import Button from '@/components/Ui/Button';
import DefaultHeader from '@/components/Ui/DefaultHeader';
import { DefaultContainerPages, DefaultListPageContainer } from '@/components/Ui/DefaultHeader/style';
import DefaultPagination from '@/components/Ui/DefaultPagination';
import { FullScreenLoader } from '@/components/Ui/FullScreenLoader';
import GoBack from '@/components/Ui/GoBack';
import ModalConfirm from '@/components/Ui/Modal/ModalConfirm';
import Select from '@/components/Ui/Select';
import Text from '@/components/Ui/Text';
import Title from '@/components/Ui/Title';
import { ISignInProps } from '@/modules/_layouts/components/HeaderCompany/components/UserSetting';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import jwtDecode from 'jwt-decode';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IconContext } from 'react-icons';
import { AiOutlineRight } from 'react-icons/ai';
import { RiFileDownloadFill, RiUploadCloudLine } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FeedbackArea } from '../components/EditContainer/styles';
import { getOptionsEntidadesCtrl } from '../controllers/getOptionsEntidadesCtrl';
import Upload, { UploadFile } from './UploadData/Upload';
import DataList from './components/paginate/DataList';
import { UploadHistorico } from './interfaces/UploadHistorico';
import { UploadConfirmationPayload, UploadTitle } from './interfaces/validTitle';
import { cancelaRemessaService, getUploadTitulosRequest, sentApprovedTitlesRequest, uploadTitulo } from './providers/uploadTitulo';
import {
  ContainerUpload,
  DisplayButton,
  DisplayText,
  FormContainer,
  FormContainerLeft,
  FormContainerRight,
  IconArea,
  TextDescUpload
} from './styles';

interface Props {}

const UploadTitulos: React.FC<Props> = () => {
  const ITEMS_PER_PAGE = 200;
  const history = useHistory();
  const accessToken = useSelector((state: ISignInProps) => state.SignIn.access_token);
  const [uploadId, setUploadId] = useState<string | null>('');
  const [uploadReponse, setUploadReponse] = useState('');
  const [loadingMessage, setLoadingMessage] = useState('');
  const [entidadeId, setEntidadeId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<UploadHistorico[]>([]);
  const [uploadsList, setUploadsList] = useState<UploadHistorico[]>([]);
  const [uploadFile, setUploadFile] = useState<UploadFile | null>(null);
  const formRef = useRef<FormHandles>(null);
  const allPages = useMemo(() => {
    return Math.ceil(data.length / ITEMS_PER_PAGE);
  }, [data]);

  const onPageChange = (page = 1) => {
    const startItem = (page - 1) * ITEMS_PER_PAGE;
    const endItem = page * ITEMS_PER_PAGE;
    setUploadsList(data.slice(startItem, endItem));
  };

  const getUploadTitulos = async (loadingMessage?: string) => {
    try {
      setIsLoading(true);
      onClose();
      setLoadingMessage(loadingMessage ? loadingMessage : 'Carregando Uploads de Títulos...');
      const uploadedTitulos = await getUploadTitulosRequest();
      setData(uploadedTitulos.results);
    } catch (error) {
      toast.error('Não foi possível listar os Uploads de Títulos!');
    } finally {
      setIsLoading(false);
    }
  };

  const baixarTemplate = () => {
    console.log('texto');
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tipoOperacao, setTipoOperacao] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [upload, setUpload] = useState('');
  const [created_at, setCreated_at] = useState('Data não informada');
  const [id, setId] = useState('Id não informado');
  const [nome, setNome] = useState('Nome não informado');
  const [processamento, setProcessamento] = useState('Retorno de Processamento');
  const [status, setStatus] = useState('Status não infomado');
  const [isSuperUser, setIsSuperUser] = useState(false);
  const [entidadeOptions, setEntidadeOptions] = useState<any[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [totalSuccess, setTotalSuccess] = useState(0);
  const [validTitles, setValidTitles] = useState<UploadTitle[]>([]);
  const [errors, setErrors] = useState([]);

  const confirmarUploadModal = async () => {
    try {
      setIsLoading(true);
      onClose();
      setLoadingMessage('Confirmando Upload...');

      const payload: UploadConfirmationPayload = {
        title_upload_id: uploadId,
        titles: validTitles
      };
      const response = await sentApprovedTitlesRequest(payload);

      toast.success('Upload confirmado com sucesso!');
      setUploadReponse('Upload confirmado com sucesso, ID do upload: ' + uploadId);
      getUploadTitulos('Atualizando Listagem...');
      setShowModal(true);
    } catch (error) {
      console.error('Erro ao confirmar upload:', error);
      toast.error('Erro ao confirmar upload. Por favor, tente novamente.');
    } finally {
      setIsLoading(false);
    }
  };

  const cancelaRemessaHandler = async (id: string) => {
    console.log(`Remessa com ID ${id} cancelada.`);
    try {
      setIsLoading(true);
      setLoadingMessage('Cancelando Upload...');
      const payload = {
        id: id,
        status: 'R'
      };

      await cancelaRemessaService(payload);
      toast.success('Upload cancelado com sucesso!');
      const uploadedTitulos = await getUploadTitulosRequest();
      setData(uploadedTitulos.results);
    } catch (error) {
      toast.error('Não foi possível Cancelar o Upload');
    } finally {
      setIsLoading(false);
    }
  };

  const confirmaRemessaHandler = async (title_upload_id: string, titles: any) => {
    try {
      setIsLoading(true);
      setLoadingMessage('Confirmando Upload...');
      const payload = {
        title_upload_id: title_upload_id,
        titles: titles
      };
      await sentApprovedTitlesRequest(payload);
      getUploadTitulos('Atualizando Listagem...');
      toast.success('Upload confirmado com sucesso!');
    } catch (error) {
      console.error('Erro ao confirmar upload:', error);
      toast.error('Erro ao confirmar upload. Por favor, tente novamente.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = useCallback(
    async (fields: any) => {
      if (!uploadFile) {
        toast.error('Selecione um arquivo para upload de títulos! (Nenhum arquivo selecionado)');
        return;
      }

      if (isSuperUser && !fields.corporation) {
        toast.error('Selecione uma empresa para upload de títulos!');
        return;
      }

      try {
        setIsLoading(true);
        setLoadingMessage('Efetuando Upload de Títulos...');
        formRef.current?.setErrors({});
        const companyId = isSuperUser ? fields.corporation : null;
        const uploadResponse = await uploadTitulo(uploadFile, companyId);
        if (uploadResponse) {
          setUploadId(uploadResponse.title_upload_id);
          setTotalRows(uploadResponse.total_rows || 0);
          setTotalSuccess(uploadResponse.valid_titles?.length || 0);
          setValidTitles(uploadResponse.valid_titles || []);
          setErrors(uploadResponse.errors || []);
          onOpen();
        }
      } catch (error) {
        if (error.response) {
          onOpen();
          setTipoOperacao('Falha no upload!');
          setMensagem(error.response.data);
        }
      } finally {
        setUploadFile(null);
        setIsLoading(false);
      }
    },
    [uploadFile, entidadeId]
  );

  const loadSelectOptions = useCallback(async () => {
    try {
      setIsLoading(true);
      setLoadingMessage('Preparando Criação de Usuário...');
      const entidadesOpt = await getOptionsEntidadesCtrl();
      setEntidadeOptions(entidadesOpt);
    } catch (e) {
      toast.error('Erro ao carregar informações de Entidades!');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const userDecode = jwtDecode<IUserCompanyProps>(accessToken);
    const entidadeId = userDecode.corporation_id;
    const isSuperUser = userDecode.is_superuser_protestado;
    setIsSuperUser(isSuperUser);
    setEntidadeId(entidadeId);

    async function getUploads() {
      if (!data.length) {
        await getUploadTitulos();

        if (isSuperUser) {
          await loadSelectOptions();
        }
      }
    }
    getUploads();
  }, [accessToken, data.length]);

  return (
    <>
      {isLoading && <FullScreenLoader loadingMessage={loadingMessage} />}
      <DefaultContainerPages paddingTop paddingBottom>
        <DefaultHeader label="Upload de Títulos"></DefaultHeader>
        <DefaultListPageContainer>
          <ContainerUpload>
            <Form className="editForm" ref={formRef} onSubmit={handleSubmit}>
              <FormContainer>
                <FormContainerLeft>
                  <IconArea>
                    <IconContext.Provider
                      value={{
                        size: '80px',
                        color: '#5fd17d',
                        className: 'IconsUpload'
                      }}
                    >
                      <RiUploadCloudLine />
                    </IconContext.Provider>
                  </IconArea>
                  <TextDescUpload className="TextDescUpload">
                    <Text size="medium" bold>
                      Faça o Upload do arquivo de dados através do campo ao lado <AiOutlineRight />
                    </Text>
                    <Text size="small" margin="10px 0px">
                      Formatos de arquivo suportados: XLS/XLSX
                    </Text>
                  </TextDescUpload>
                </FormContainerLeft>
                <FormContainerRight>
                  <label
                    htmlFor="uploadInput"
                    style={{
                      background: '#f6f7f6',
                      margin: '10px 30px 10px 10px',
                      padding: '25px 14px',
                      color: '#002169',
                      width: '50%',
                      height: '140px',
                      borderRadius: '10px'
                    }}
                  >
                    <Text size="small" margin="10px 0px">
                      Selecione um arquivo:
                    </Text>
                    <Upload uploadFile={uploadFile} onSelectFile={setUploadFile} />
                  </label>
                  <DisplayButton>
                    {isSuperUser && (
                      <Select
                        customMargin={'0px 12px 12px 0px'}
                        name="corporation"
                        label="Empresa"
                        color="gray400"
                        isBold
                        options={entidadeOptions}
                      />
                    )}
                    <Button type="submit" size="big" color="primary" width="352px" loading={isLoading} disabled={!!isLoading}>
                      Enviar Títulos
                    </Button>
                    <DisplayText>
                      <Text align="center" margin="10px 10px 10px 10px" size="small">
                        Ainda não tem o arquivo modelo?
                      </Text>
                      <a href="/planilha_modelo_protesto_102.xlsx">
                        <Button type="button" size="small" color="light" width="100%" loading={isLoading} disabled={!!isLoading}>
                          <IconContext.Provider
                            value={{
                              size: '20px',
                              color: '#5fd17d',
                              className: 'IconsUpload'
                            }}
                          >
                            <RiFileDownloadFill />
                          </IconContext.Provider>
                          <Text align="center" margin="0px 10px 0px 10px" size="small">
                            Download do Template <b>Protesto</b> (Excel)
                          </Text>
                        </Button>
                      </a>
                      <a href="/planilha_modelo_protesto_144.xlsx">
                        <Button type="button" size="small" color="light" width="100%" loading={isLoading} disabled={!!isLoading}>
                          <IconContext.Provider
                            value={{
                              size: '20px',
                              color: '#5fd17d',
                              className: 'IconsUpload'
                            }}
                          >
                            <RiFileDownloadFill />
                          </IconContext.Provider>
                          <Text align="center" margin="0px 10px 0px 10px" size="small">
                            Download do Template <b>Custas</b> (Excel)
                          </Text>
                        </Button>
                      </a>
                    </DisplayText>
                  </DisplayButton>
                </FormContainerRight>
              </FormContainer>
            </Form>
          </ContainerUpload>
          {data?.length === 0 ? (
            <>
              <FeedbackArea>
                <EmptyFeedback
                  imagePath={FeedbackImage}
                  title="Não foram encontrados uploads de título"
                  message="Utilize as ferramentas acima para realizar seu upload"
                />
              </FeedbackArea>
            </>
          ) : (
            <>
              <Title label="Uploads Realizados" isVisiblePixel />
              <DataList posts={uploadsList} cancelaRemessa={cancelaRemessaHandler} confirmaRemessa={confirmaRemessaHandler} />
              <GoBack />
              <DefaultPagination
                allPagesNumber={allPages}
                itemsPerPage={ITEMS_PER_PAGE}
                itemsNumber={data.length}
                pageChange={onPageChange}
              />
            </>
          )}
        </DefaultListPageContainer>
      </DefaultContainerPages>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text bold size="medium" margin="10px 10px">
              {tipoOperacao}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text bold size="medium" margin="10px 10px">
              ID do upload: {uploadId}
            </Text>
            <Text margin="10px 10px" size="small">
              Total de títulos processados: {totalRows}
            </Text>
            <Text margin="10px 10px" size="small">
              Total de títulos com sucesso: {totalSuccess}
            </Text>
            {errors.length > 0 && (
              <div>
                <Text bold size="medium" margin="10px 10px">
                  Titulos com Erro:
                </Text>
                {errors.map((error, index) => {
                  const key = Object.keys(error)[0];
                  const errorDetails = error[key];

                  return (
                    <div key={index} style={{ marginBottom: '10px' }}>
                      <Text bold size="small">
                        Linha {key}:
                      </Text>
                      <div>
                        {Object.entries(errorDetails).map(([fieldName, fieldValue]) => (
                          <div key={fieldName} style={{ marginBottom: '5px' }}>
                            <Text size="small">
                              {fieldName}: {fieldValue}
                            </Text>
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </ModalBody>

          <ModalFooter>
            <Button
              color="error"
              onClick={() => {
                onClose();
                setUploadId(null);
                setUploadFile(null);
                getUploadTitulos('Atualizando Listagem...');
              }}
            >
              Voltar
            </Button>
            <Button disabled={validTitles.length === 0} color="primary" onClick={confirmarUploadModal}>
              Confirmar Upload
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <ModalConfirm
        extraBtnVisible={uploadId}
        extraBtnLabel="Ver Resultados"
        extraBtnFunction={() => history.push(`/titulos/upload-titulos/${uploadId}`)} // Rota para visualizar detalhes dos títulos
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
          setUploadId(null);
          getUploadTitulos('Atualizando Listagem...');
        }}
        handleButtonClick={() => {
          setShowModal(false);
          setUploadId(null);
          getUploadTitulos('Atualizando Listagem...');
        }}
        label={uploadReponse}
        buttonLabel="Fechar"
      />
    </>
  );
};

export default UploadTitulos;
