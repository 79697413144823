import { IUserCompanyProps } from '@/components/Interfaces/UserCompanyProps';
import Button from '@/components/Ui/Button';
import { DefaultFilterButton, DefaultFilterButtonContainer, DefaultFilterRow } from '@/components/Ui/DefaultFilterContainer/styles';
import DefaultHeader from '@/components/Ui/DefaultHeader';
import { DefaultContainerPages } from '@/components/Ui/DefaultHeader/style';
import { FullScreenLoader } from '@/components/Ui/FullScreenLoader';
import Input from '@/components/Ui/Input';
import InputMask from '@/components/Ui/InputMask';
import ModalConfirm from '@/components/Ui/Modal/ModalConfirm';
import ModalError from '@/components/Ui/Modal/ModalError';
import { ISignInProps } from '@/modules/_layouts/components/HeaderCompany/components/UserSetting';
import { Titulos } from '@/modules/Entidades/ListarTitulos/interfaces/interface';
import { formatarDinheiro, getValorCustas } from '@/utils/Utilities';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import jwtDecode from 'jwt-decode';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { ContainerButton } from '../../../components/EditContainer/styles';
import { criarOfertaTituloRequest } from '../../providers/criarOfertaTitulo';
import { InformationContainer, InformationData, InformationRow, InformationTitle } from '../../style';

interface Props {
  selectedTitles: Titulos[];
  clearSelectedTitles: () => void;
}

const CriarOfertaForm: React.FC<Props> = ({ selectedTitles, clearSelectedTitles }) => {
  const history = useHistory();
  const accessToken = useSelector((state: ISignInProps) => state.SignIn.access_token);
  const formRef = useRef<FormHandles>(null);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [ofertaTituloId, setOfertaTituloId] = useState('');
  const [entidadeId, setEntidadeId] = useState('');
  const [valorOriginalTitulo, setValorOriginalTitulo] = useState(0);
  const [valorSaldoProtesto, setValorSaldoProtesto] = useState(0);
  const [valorCustas, setValorCustas] = useState(0);
  const [valorTotal, setValorTotal] = useState(0);
  const [valorTotalParcial, setValorTotalParcial] = useState(0);
  const [disableEmolumentos, setDisableEmolumentos] = useState(false);
  const [disableDescontoPorcentagem, setDisableDescontoPorcentagem] = useState(false);
  const [disableDesconto, setDisableDesconto] = useState(false);
  const [emolumentos, setEmolumentos] = useState('');
  const [desconto, setDesconto] = useState('');
  const [descontoPorcentagem, setDescontoPorcentagem] = useState('');

  const handleChangeEmolumentos = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmolumentos(e.target.value);
  };

  const handleChangeDesconto = (e: React.ChangeEvent<HTMLInputElement>) => {
    discountCtrl(e);
    setDesconto(e.target.value);
    recalculoValorComDesconto(e.target.value, 'VALUE');
  };

  const handleChangeDescontoPorcentagem = (e: React.ChangeEvent<HTMLInputElement>) => {
    discountCtrl(e);
    setDescontoPorcentagem(e.target.value);
    recalculoValorComDesconto(e.target.value, 'PERCENTAGE');
  };

  const recalculoValorComDesconto = (desconto: any, tipo_desconto: string) => {
    if (tipo_desconto === 'PERCENTAGE') {
      desconto = desconto.replace('%', '').replace('_', '');
      desconto = Number(desconto);
      const descontoPorcentagem = (valorTotal * desconto) / 100;
      setValorTotalParcial(valorTotal - descontoPorcentagem);
    }

    if (tipo_desconto === 'VALUE') {
      desconto = desconto.replace('R$', '').replace(/,/g, '').replace('_', '');
      desconto = Number(desconto);

      if (verificarValorNegativo(valorTotal - desconto)) {
        toast.warning('Desconto não pode ser maior do que o saldo devedor!');
        setValorTotalParcial(0);
        setDesconto('');
        return;
      }

      setValorTotalParcial(valorTotal - desconto);
    }
  };

  const discountCtrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'desconto') {
      if (e.target.value) {
        setDisableDescontoPorcentagem(true);
      } else {
        setDisableDescontoPorcentagem(false);
      }
    } else if (e.target.id === 'desconto_porcentagem') {
      if (e.target.value && e.target.value !== '__%') {
        setDisableDesconto(true);
      } else {
        setDisableDesconto(false);
      }
    }
  };

  const verificarValorNegativo = (campo: any): boolean => {
    if (Number(campo) < 0) {
      return true;
    }
    return false;
  };

  const possuiValoresNegativos = (payload: any): string | null => {
    const camposNumericos = ['discount_value'];
    let possuiValorNegativo: string | null = null;

    for (const [key, value] of Object.entries(payload)) {
      if (camposNumericos.includes(key) && value != null) {
        const valorNegativo = verificarValorNegativo(value);
        if (valorNegativo) {
          possuiValorNegativo = key;
          break;
        }
      }
    }

    return possuiValorNegativo;
  };

  const handleSubmitPayload = useCallback(
    async (fields) => {
      try {
        setIsLoading(true);
        setLoadingMessage('Criando Oferta Título...');

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          data_inicio: Yup.date()
            .nullable()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .required('Por favor informe a Data Inicial'),
          data_fim: Yup.date()
            .nullable()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .min(Yup.ref('data_inicio'), 'Data Final não pode ser menor do que a Inicial')
            .required('Por favor informe a Data Final')
        });

        await schema.validate(fields, {
          abortEarly: false
        });

        const desconto_formatado = desconto.replace('R$', '').replace(/,/g, '').replace('_', '');
        const desconto_porcentagem_formatado = descontoPorcentagem.replace('%', '').replace('_', '');

        const descontoValorNumber = Number(desconto_formatado);
        const valorSaldoProtestoNumber = Number(valorSaldoProtesto);

        if (descontoValorNumber <= valorSaldoProtestoNumber) {
          const discount_type = descontoPorcentagem ? 'PERCENTAGE' : 'VALUE';
          const discount_value = descontoPorcentagem ? desconto_porcentagem_formatado : desconto_formatado;
          const titles = selectedTitles.map((title) => title.id);

          const payload = {
            company: entidadeId,
            name: '',
            discount_type: discount_type,
            discount_value: Number(discount_value),
            start_date: fields.data_inicio,
            end_date: fields.data_fim,
            titles: titles
          };

          const campoValorNegativo = possuiValoresNegativos(payload);

          if (!campoValorNegativo) {
            const novaOferta = await criarOfertaTituloRequest(payload);
            if (novaOferta) {
              setOfertaTituloId(novaOferta.id);
            }
            setShowModal(true);
          } else {
            toast.warning(`Não são permitidos valores de desconto negativos!`);
          }
        } else {
          toast.warning(`O Valor do Desconto não poder ser maior do que o valor protestado disponível: R$ ${valorSaldoProtestoNumber}`);
        }
      } catch (err) {
        setShowModalError(true);
        const validationErrors: { [key: string]: string } = {};

        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: Yup.ValidationError) => {
            validationErrors[error.path] = error.message;
          });

          formRef.current?.setErrors(validationErrors);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [desconto, descontoPorcentagem]
  );

  const setValuesForm = useCallback(
    (titulos) => {
      let valorTitulos = 0;
      let valorSaldoProtestos = 0;
      let valorCustas = 0;
      let valorTotal = 0;

      if (titulos[0].status_codigo === '102') {
        valorTitulos = titulos.reduce((acc, titulo) => acc + Number(titulo.valor_titulo), 0);
        valorSaldoProtestos = titulos.reduce((acc, titulo) => acc + Number(titulo.valor_saldo_protesto), 0);
        valorCustas = titulos.reduce((acc, titulo) => acc + getValorCustas(titulo), 0);
        valorTotal = valorSaldoProtestos + valorCustas;
      }

      if (titulos[0].status_codigo === '144') {
        valorCustas = titulos.reduce((acc, titulo) => acc + getValorCustas(titulo), 0);
        valorTotal = valorCustas;
      }

      if (titulos[0].status_codigo === '320') {
        valorTitulos = titulos.reduce((acc, titulo) => acc + Number(titulo.valor_titulo), 0);
        valorTotal = valorTitulos;
      }

      setValorOriginalTitulo(valorTitulos);
      setValorSaldoProtesto(valorSaldoProtestos);
      setValorCustas(valorCustas);
      setValorTotal(valorTotal);

      if (valorCustas) {
        setEmolumentos(valorCustas.toFixed(2).replace('.', ','));
      }
      setDisableEmolumentos(true);

      if (!valorSaldoProtestos && !valorTitulos && ['102', '320'].includes(titulos[0].status_codigo)) {
        setDisableDiscountFields(true);
      }
    },
    [emolumentos]
  );

  const setDisableDiscountFields = useCallback((val: boolean) => {
    setDisableDescontoPorcentagem(val);
    setDisableDesconto(val);
  }, []);

  useEffect(() => {
    setValuesForm(selectedTitles);
  }, [selectedTitles]);

  useEffect(() => {
    if (accessToken) {
      const userDecode = jwtDecode<IUserCompanyProps>(accessToken);
      const id = userDecode.corporation_id;
      setEntidadeId(id);
    }
  }, [accessToken]);

  return (
    <>
      {isLoading && <FullScreenLoader loadingMessage={loadingMessage} />}
      {selectedTitles.length > 0 ? (
        <>
          <DefaultContainerPages paddingTop paddingBottom>
            <DefaultHeader label="Criar Oferta Título" marginTop="12px" />
            <Form className="editForm" ref={formRef} onSubmit={handleSubmitPayload}>
              <InformationContainer>
                <InformationRow isCenter padding="16px 24px 8px 24px">
                  <InformationTitle textPrimary>
                    {selectedTitles && (selectedTitles.length > 1 ? 'Resumo dos Títulos' : 'Resumo do Título')}
                  </InformationTitle>
                </InformationRow>

                <InformationRow padding="8px 24px 8px 24px">
                  <InformationData textPrimary>{selectedTitles && (selectedTitles.length > 1 ? 'Títulos' : 'Título')}</InformationData>
                </InformationRow>
                {selectedTitles.map((title) => (
                  <InformationRow padding="8px 24px 8px 24px" key={title.id}>
                    <InformationData textPrimary>Nome devedor</InformationData>
                    <InformationData textPrimary>{title.nome_devedor}</InformationData>
                    <InformationData textPrimary>Número título</InformationData>
                    <InformationData textPrimary>{title.nosso_numero}</InformationData>
                  </InformationRow>
                ))}
                <DefaultFilterRow padding="0px 10px 0px 10px">
                  <DefaultFilterButtonContainer>
                    <DefaultFilterButton>
                      <Button type="button" onClick={clearSelectedTitles} size="big" color="warning" width="100%" disabled={!!isLoading}>
                        Limpar seleção de títulos
                      </Button>
                    </DefaultFilterButton>
                  </DefaultFilterButtonContainer>
                </DefaultFilterRow>
                <InformationRow padding="12px 24px 12px 24px"></InformationRow>

                {selectedTitles && ['102', '320'].includes(selectedTitles[0].status_codigo) && (
                  <InformationRow padding="8px 24px 8px 24px">
                    <InformationData textPrimary>
                      {selectedTitles && (selectedTitles.length > 1 ? 'Valor Original Títulos' : 'Valor Original Título')}
                    </InformationData>
                    <InformationData textPrimary>{formatarDinheiro(valorOriginalTitulo)}</InformationData>
                  </InformationRow>
                )}
                {selectedTitles && selectedTitles[0].status_codigo === '102' && (
                  <InformationRow padding="8px 24px 8px 24px">
                    <InformationData textPrimary>Valor Protestado</InformationData>
                    <InformationData textPrimary>{formatarDinheiro(valorSaldoProtesto)}</InformationData>
                  </InformationRow>
                )}
                <InformationRow padding="8px 24px 8px 24px">
                  <InformationData textPrimary>Valor Custas/Emolumentos</InformationData>
                  <InformationData textPrimary>{formatarDinheiro(valorCustas)}</InformationData>
                </InformationRow>
                <InformationRow padding="8px 24px 8px 24px">
                  <InformationData textPrimary>Valor Desconto</InformationData>
                  <InformationData textPrimary>
                    {desconto ? formatarDinheiro(desconto) : formatarDinheiro(descontoPorcentagem)}
                  </InformationData>
                </InformationRow>
                <InformationRow padding="8px 24px 16px 24px">
                  <InformationData textPrimary>Valor Total</InformationData>
                  <InformationData textPrimary>
                    {valorTotalParcial ? formatarDinheiro(valorTotalParcial) : formatarDinheiro(valorTotal)}
                  </InformationData>
                </InformationRow>
              </InformationContainer>

              <InputMask
                disabled={disableEmolumentos}
                name="emolumentos"
                label="Emolumentos Valor"
                placeholder="Ex.: R$ 50"
                prefix="R$"
                value={emolumentos}
                onChange={handleChangeEmolumentos}
                dataCy="emolumentosValor"
              />

              <InputMask
                disabled={disableDesconto}
                name="desconto"
                label="Desconto Valor"
                placeholder="Ex.: R$ 50"
                prefix="R$"
                value={desconto}
                onChange={handleChangeDesconto}
                dataCy="descontoValor"
              />

              <Input
                disabled={disableDescontoPorcentagem}
                name="desconto_porcentagem"
                mask={'99%'}
                type="string"
                placeholder="Ex.: 20%"
                label="Desconto Porcentagem (%)"
                value={descontoPorcentagem}
                onChange={handleChangeDescontoPorcentagem}
                full
                data-cy="descontoPorcentagem"
              />
              <Input name="data_inicio" type="date" label="Data Inicial" full data-cy="dataInicio" />
              <Input name="data_fim" type="date" label="Data Final" full data-cy="dataFim" />

              <ContainerButton>
                <Button type="submit" size="big" full color="primary" loading={isLoading} disabled={!!isLoading} data-cy="submitButton">
                  Criar Oferta
                </Button>
              </ContainerButton>
            </Form>

            <ModalConfirm
              isOpen={showModal}
              onClose={() => history.push(`/ofertas/listar-ofertas-titulo/${ofertaTituloId}`)}
              handleButtonClick={() => history.push(`/ofertas/listar-ofertas-titulo/${ofertaTituloId}`)}
              label={`Oferta criada com sucesso! ID: ${ofertaTituloId}`}
              buttonLabel="Ok"
            />
            <ModalError
              isOpen={showModalError}
              onClose={() => setShowModalError(false)}
              handleButtonClick={() => setShowModalError(false)}
              label="Erro na solicitação, verifique os dados informados!"
              buttonLabel="Ok"
            />
          </DefaultContainerPages>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default CriarOfertaForm;
