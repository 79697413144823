import FeedbackImage from '@/assets/images/empty.png';
import EmptyFeedback from '@/components/Helpers/EmptyFeedback';
import Button from '@/components/Ui/Button';
import DefaultHeader from '@/components/Ui/DefaultHeader';
import { DefaultContainer } from '@/components/Ui/DefaultHeader/style';
import { FullScreenLoader } from '@/components/Ui/FullScreenLoader';
import Input from '@/components/Ui/Input';
import InputMask from '@/components/Ui/InputMask';
import ModalConfirm from '@/components/Ui/Modal/ModalConfirm';
import ModalError from '@/components/Ui/Modal/ModalError';
import Select from '@/components/Ui/Select';
import Title from '@/components/Ui/Title';
import Toggle from '@/components/Ui/Toggle';
import { parcelaBoletoOptions, parcelaCartaoOptions } from '@/utils/Consts';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { OfertaTituloPayload } from '../CriarOfertaTitulo/interfaces/ofertaTitulo';
import { criarOfertaTituloRequest } from '../CriarOfertaTitulo/providers/criarOfertaTitulo';
import {
  InformationContainer,
  InformationData,
  InformationRow,
  InformationRowContainer,
  InformationTitle
} from '../CriarOfertaTitulo/style';
import { getTituloById } from '../EditarTitulo/providers/getTituloRequest';
import { OfertaTitulo } from '../ListarOfertasTitulo/interfaces/interface';
import { FeedbackArea } from '../components/EditContainer/styles';
import ListaOfertaTitulo from './components/listaOfertaTitulo';
import { ContainerButton } from './components/listaOfertaTitulo/styles';
import { Titulo } from './interfaces/Titulo';
import {
  CompanyDescription,
  CompanyDetails,
  CompanyDetailsToggle,
  ContainerCriarOferta,
  ContainerNovaOferta,
  InputsGrid,
  OfertasContainer,
  ReactToggleRow
} from './styles';

interface Props {}

const EditarRenegociacao: React.FC<Props> = () => {
  const url = window.location.href.split('/');
  const tituloId = url[5];
  const [tituloEditado, setTituloEditado] = useState<Titulo | null>(null);
  const [ofertaId, setOfertaId] = useState<null | string>(null);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [telefone, setTelefone] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [numero_identificacao_devedor, setDocumentoDevedor] = useState('');
  const [dataEmissao, setDataEmissao] = useState('');
  const [dataVencimento, setDataVencimento] = useState('');
  const [listaOfertaTitulo, setListaOfertaTitulo] = useState<any[]>([]); // TODO TIPAR PARA OFERTA

  const [valorOriginalTitulo, setValorOriginalTitulo] = useState('');
  const [valorSaldoProtesto, setValorSaldoProtesto] = useState('');
  const [valorCustas, setValorCustas] = useState('');
  const [valorTotal, setValorTotal] = useState('');

  const [emailCallback, setEmailCallback] = useState(false);
  const handleChangeCallback = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailCallback(e.target.checked);
  };
  const [isDeleted, setIsDeleted] = useState(false);
  const handleChangeIsDeleted = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsDeleted(e.target.checked);
  };
  const [novaOferta, setNovaOferta] = useState<OfertaTitulo | null>(null);
  const [status, setStatus] = useState(true);
  const handleChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStatus(e.target.checked);
  };
  const [boleto, setBoleto] = useState(false);
  const handleChangeBoleto = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBoleto(e.target.checked);
  };
  const [juros_boleto, setJurosBoleto] = useState('');
  const handleChangeJurosBoleto = (e: React.ChangeEvent<HTMLInputElement>) => {
    setJurosBoleto(e.target.value);
  };
  const [juros_cartao, setJurosCartao] = useState('');
  const handleChangeJurosCartao = (e: React.ChangeEvent<HTMLInputElement>) => {
    setJurosCartao(e.target.value);
  };
  const [cartao, setCartao] = useState(false);
  const handleChangeCartao = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCartao(e.target.checked);
  };
  const [disableEmolumentos, setDisableEmolumentos] = useState(false);
  const [disableDescontoPorcentagem, setDisableDescontoPorcentagem] = useState(false);
  const [disableDesconto, setDisableDesconto] = useState(false);
  const [emolumentos, setEmolumentos] = useState('');
  const handleChangeEmolumentos = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmolumentos(e.target.value);
  };
  const [desconto, setDesconto] = useState('');
  const handleChangeDesconto = (e: React.ChangeEvent<HTMLInputElement>) => {
    discountCtrl(e);
    setDesconto(e.target.value);
  };
  const [desconto_porcentagem, setDescontoPorcentagem] = useState('');
  const handleChangeDescontoPorcentagem = (e: React.ChangeEvent<HTMLInputElement>) => {
    discountCtrl(e);
    setDescontoPorcentagem(e.target.value);
  };
  const ofertaFormRef = useRef<FormHandles>(null);
  const formRef = useRef<FormHandles>(null);

  const discountCtrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'desconto') {
      if (e.target.value) {
        setDisableDescontoPorcentagem(true);
      } else {
        setDisableDescontoPorcentagem(false);
      }
    } else if (e.target.id === 'desconto_porcentagem') {
      if (e.target.value && e.target.value !== '__%') {
        setDisableDesconto(true);
      } else {
        setDisableDesconto(false);
      }
    }
  };

  const resetarPreenchimento = useCallback(() => {
    setNovaOferta(null);
    setDesconto('');
    setDescontoPorcentagem('');
    setDisableDescontoPorcentagem(false);
    setBoleto(false);
    setJurosBoleto('');
    setCartao(false);
    setJurosCartao('');
    setOfertaId(null);
  }, []);

  const recarregarDados = useCallback(async (loadingMessage: string) => {
    setShowModal(false);
    resetarPreenchimento();
    await getTituloData(loadingMessage);
  }, []);

  const cancelarEdicao = useCallback(() => {
    resetarPreenchimento();
  }, []);

  const criarNovaOferta = useCallback(() => {
    const novaOferta = {} as OfertaTitulo;
    setNovaOferta(novaOferta);
  }, []);

  const handleSubmit = useCallback(async (fields: any) => {
    console.log(fields);
  }, []);

  function getFixedValue(val: number): string {
    return val ? val.toFixed(2) : '0.00';
  }

  const setDisableDiscountFields = useCallback((val: boolean) => {
    setDisableDescontoPorcentagem(val);
    setDisableDesconto(val);
  }, []);

  const getTituloData = useCallback(
    async (loadingMessage = 'Carregando Detalhes do Título...') => {
      try {
        setIsLoading(true);
        setLoadingMessage(loadingMessage);
        const titulo = await getTituloById(tituloId);
        setTituloEditado(titulo);

        const dadosTeste = [
          {
            id: 10,
            tipo_oferta: 'A',
            tipo_pagamento: 'B',
            status: '1',
            valor: '2000.00',
            valor_pago: '100.00',
            parcelas: 2,
            gerado: '2022-02-28T12:53:30-03:00',
            created_at: '2022-02-28T12:53:30-03:00',
            updated_at: '2022-05-27T10:34:45.546418-03:00',
            protestado_entidade_uuid: 'b0fc0a27-589b-4c5f-8392-fec521d84e52',
            titulo: 305,
            titulo_oferta: 0,
            apresentante_oferta: 1
          }
        ];

        formRef.current?.setData({
          //dados alocados no código apenas hardcode
          id_renegociacao: 10,
          tipo_oferta: 'Oferta Geral',
          tipo_pagamento: 'Boleto',
          status: 'Pagamento Confirmado',
          valor: '2000,00',
          valor_pago: '999,00',
          parcelas: 2,
          gerado: '01/06/2022',
          created_at: '01/06/2022',
          updated_at: '01/07/2022',
          protestado_entidade_uuid: 'b0fc0a27-589b-4c5f-8392-fec521d84e52',
          titulo_renegociacao: 305,
          titulo_oferta: 0,
          apresentante_oferta: 1,
          // final dos detalhes da oferta
          id: titulo?.id,
          nome_devedor: titulo?.nome_devedor,
          email_devedor: titulo?.email_devedor,
          numero_identificacao_devedor: titulo?.numero_identificacao_devedor,
          telefone_devedor: titulo?.telefone_devedor,
          uf: titulo?.uf,
          apresentante: titulo?.apresentante,
          cnpj: titulo?.cnpj,
          data_emissao: titulo?.data_emissao,
          data_vencimento: titulo?.data_vencimento,
          email_callback: titulo?.email_callback,
          is_deleted: titulo?.is_deleted,
          razao: titulo?.razao,
          nosso_numero: titulo?.nosso_numero,
          numero_parcela: titulo?.numero_parcela,
          numero_titulo: titulo?.numero_titulo,
          valor_saldo_protesto: titulo?.valor_saldo_protesto,
          valor_titulo: titulo?.valor_titulo,
          valor_custas: titulo?.valor_custas
        });

        formRef.current?.setData({
          id: titulo?.id,
          nome_devedor: titulo?.nome_devedor,
          email_devedor: titulo?.email_devedor,
          numero_identificacao_devedor: titulo?.numero_identificacao_devedor,
          telefone_devedor: titulo?.telefone_devedor,
          uf: titulo?.uf,
          apresentante: titulo?.apresentante,
          cnpj: titulo?.cnpj,
          data_emissao: titulo?.data_emissao,
          data_vencimento: titulo?.data_vencimento,
          email_callback: titulo?.email_callback,
          is_deleted: titulo?.is_deleted,
          razao: titulo?.razao,
          nosso_numero: titulo?.nosso_numero,
          numero_parcela: titulo?.numero_parcela,
          numero_titulo: titulo?.numero_titulo,
          valor_saldo_protesto: titulo?.valor_saldo_protesto,
          valor_titulo: titulo?.valor_titulo,
          valor_custas: titulo?.valor_custas
        });

        const valorTitulo = Number(titulo.valor_titulo);
        const valorSaldoProtesto = Number(titulo.valor_saldo_protesto);
        const valorCustas = Number(titulo.valor_custas);
        const valorTotal = valorSaldoProtesto + valorCustas;

        setValorOriginalTitulo(getFixedValue(valorTitulo));
        setValorSaldoProtesto(getFixedValue(valorSaldoProtesto));
        setValorCustas(getFixedValue(valorCustas));
        setValorTotal(getFixedValue(valorTotal));

        if (valorCustas) {
          setEmolumentos(valorCustas.toFixed(2));
        }
        setDisableEmolumentos(true);

        if ((!valorSaldoProtesto && !valorTitulo) || titulo.status_codigo === '144') {
          setDisableDiscountFields(true);
        }

        if (titulo?.ofertas && titulo?.ofertas.length > 0) {
          for (let index = 0; index < titulo.ofertas.length; index++) {
            const oferta = titulo.ofertas[index];
            oferta.key = `${oferta.titulo}/${oferta.id}`;
            oferta.icon_emolumentos = false;
            if (!oferta.desconto && !oferta.desconto_porcentagem) {
              oferta.icon_emolumentos = true;
            }
          }
          setListaOfertaTitulo(titulo?.ofertas);
        } else {
          setListaOfertaTitulo([]);
        }

        setTelefone(String(titulo?.telefone_devedor));
        setDocumentoDevedor(titulo?.numero_identificacao_devedor);
        setCnpj(titulo?.cnpj);
        setDataEmissao(moment(titulo?.data_emissao).format('DD/MM/YYYY'));
        setDataVencimento(moment(titulo?.data_vencimento).format('DD/MM/YYYY'));

        if (titulo.email_callback === true) {
          setEmailCallback(true);
        }
        if (titulo.is_deleted === true) {
          setIsDeleted(true);
        }
      } catch (error) {
        toast.error('Não foi possível carregar seu dados, tente mais tarde!');
      } finally {
        setIsLoading(false);
      }
    },
    [emailCallback]
  );

  const handleSubmitOferta = useCallback(
    async (fields: any) => {
      try {
        setIsLoading(true);
        setLoadingMessage('Cadastrando Oferta...');
        console.log(fields);

        ofertaFormRef.current?.setErrors({});
        const schema = Yup.object().shape({
          data_inicio: Yup.string().required('Por favor informe a Data Inicial'),
          data_fim: Yup.string().required('Por favor informe a Data Final'),
          apresentante: Yup.string().required('Por favor selecione o apresentante'),
          protestado_entidade_uuid: Yup.string().required('Por favor selecione a entidade')
        });
        await schema.validate(fields, {
          abortEarly: false
        });

        const desconto_formatado = desconto.replace('R$', '').replace('_', '').replace(',', '');
        const desconto_porcentagem_formatado = desconto_porcentagem.replace('%', '').replace('_', '');
        const juros_cartao_formatado = juros_cartao.replace('%', '').replace('_', '');
        const juros_boleto_formatado = juros_boleto.replace('%', '').replace('_', '');

        const payload = {
          titulo: Number(fields.titulo),
          cartao: cartao,
          parcela_cartao: fields.parcela_cartao ? fields.parcela_cartao : 1,
          juros_cartao: juros_cartao ? juros_cartao_formatado : '0',
          boleto: boleto,
          parcela_boleto: fields.parcela_boleto ? fields.parcela_boleto : 1,
          juros_boleto: juros_boleto ? juros_boleto_formatado : '0',
          status: status,
          data_inicio: fields.data_inicio,
          data_fim: fields.data_fim,
          apresentante: fields.apresentante,
          iof: fields.iof,
          taxa_servicos: fields.taxa_servicos,
          status_envio_sms: false,
          mensagem: '',
          protestado_entidade_uuid: fields.protestado_entidade_uuid,
          desconto: desconto ? desconto_formatado : null,
          desconto_porcentagem: desconto_porcentagem ? desconto_porcentagem_formatado : null
        } as OfertaTituloPayload;

        console.log(payload);
        const novaOferta = await criarOfertaTituloRequest(payload);
        if (novaOferta) {
          setOfertaId(novaOferta.id);
        }
        setShowModal(true);
      } catch (e) {
        setShowModalError(true);
        const validationErrors: { [key: string]: string } = {};

        if (e instanceof Yup.ValidationError) {
          e.inner.forEach((error: Yup.ValidationError) => {
            validationErrors[error.path] = error.message;
          });

          ofertaFormRef.current?.setErrors(validationErrors);
        }
      } finally {
        // resetarPreenchimento();
        setIsLoading(false);
      }
    },
    [cartao, boleto, status, desconto, desconto_porcentagem, juros_cartao, juros_boleto]
  );

  useEffect(() => {
    if (tituloEditado) {
      ofertaFormRef.current?.setData({
        apresentante: tituloEditado.apresentante,
        titulo: tituloEditado.id,
        protestado_entidade_uuid: tituloEditado.protestado_entidade_uuid
      });
    }
  }, [novaOferta]);

  useEffect(() => {
    getTituloData();
  }, []);

  return (
    <>
      {isLoading && <FullScreenLoader loadingMessage={loadingMessage} />}
      <DefaultContainer paddingTop paddingBottom ignoreWidth>
        <DefaultHeader
          label={`Detalhes da Renegociação`}
          pageMessage="Confira abaixo todos os detalhes da Renegociação Selecionada"
        ></DefaultHeader>
        <Form className="editForm" ref={formRef} onSubmit={handleSubmit}>
          <CompanyDescription>
            <CompanyDetails>
              <Input readOnly width="100%" name="id_renegociacao" type="string" placeholder="Ex.: 10" label="ID da Renegociação" />
            </CompanyDetails>
            <CompanyDetails>
              <Input readOnly width="100%" name="tipo_oferta" type="string" placeholder="Ex.: Oferta Título" label="Tipo da Oferta" />
            </CompanyDetails>
            <CompanyDetails>
              <Input
                readOnly
                width="100%"
                name="tipo_pagamento"
                type="string"
                placeholder="Ex.: Boleto Bancário"
                label="Tipo do Pagamento"
              />
            </CompanyDetails>
            <CompanyDetails>
              <Input width="100%" name="status" type="string" placeholder="Ex. Pagamento confirmado" label="Status" />
            </CompanyDetails>
          </CompanyDescription>
          <CompanyDescription>
            <CompanyDetails>
              <Input readOnly width="100%" name="valor" type="string" placeholder="Ex.: R$ 1.200,00" label="Valor Original" />
            </CompanyDetails>
            <CompanyDetails>
              <Input readOnly width="100%" name="valor_pago" type="string" placeholder="Ex.: R$ 999,00" label="Valor Pago" />
            </CompanyDetails>
            <CompanyDetails>
              <Input readOnly width="100%" name="parcelas" type="string" placeholder="6 parcelas" label="Parcelas" />
            </CompanyDetails>
            <CompanyDetails>
              <Input width="100%" name="protestado_entidade_uuid" type="string" placeholder="Ex. 0000.00000.00000" label="ID da Entidade" />
            </CompanyDetails>
          </CompanyDescription>
          <CompanyDescription>
            <CompanyDetails>
              <Input readOnly width="100%" name="created_at" type="string" placeholder="Ex.: 01/01/2022" label="Criado em:" />
            </CompanyDetails>
            <CompanyDetails>
              <Input readOnly width="100%" name="updated_at" type="string" placeholder="Ex.: 01/07/2022" label="Atualizado em:" />
            </CompanyDetails>
            <CompanyDetails>
              <Input
                readOnly
                width="100%"
                name="titulo_renegociacao"
                type="string"
                placeholder="ex.: 000000/01"
                label="Título da Renegociação"
              />
            </CompanyDetails>
            <CompanyDetails></CompanyDetails>
          </CompanyDescription>
          <OfertasContainer>
            <Title label="Detalhes do Título" isVisiblePixel />
          </OfertasContainer>
          <CompanyDescription>
            <CompanyDetails>
              <Input readOnly width="100%" name="id" type="string" placeholder="Ex.: exemplo" label="ID do Título" />
            </CompanyDetails>
            <CompanyDetails>
              <Input readOnly width="100%" name="nome_devedor" type="string" placeholder="Ex.: Fernando Alves" label="Nome do Devedor" />
            </CompanyDetails>
            <CompanyDetails>
              <Input
                readOnly
                width="100%"
                name="email_devedor"
                type="string"
                placeholder="Ex.: exemplo@email.com"
                label="E-mail do Devedor"
              />
            </CompanyDetails>
            <CompanyDetails>
              <Input
                width="100%"
                name="numero_identificacao_devedor"
                type="string"
                mask="999.999.999-99"
                value={numero_identificacao_devedor}
                placeholder="Ex.: "
                label="Documento do Devedor"
              />
            </CompanyDetails>
          </CompanyDescription>
          <CompanyDescription>
            <CompanyDetails>
              <Input
                width="100%"
                name="telefone_devedor"
                value={telefone}
                type="string"
                mask="(99) 9999-9999"
                placeholder="Ex.:"
                label="Telefone do Devedor"
              />
            </CompanyDetails>
            <CompanyDetails>
              <Input readOnly width="100%" name="uf" type="string" placeholder="Ex.: PR" label="UF do devedor" />
            </CompanyDetails>
            <CompanyDetails>
              <Input readOnly width="100%" name="apresentante" type="string" placeholder="Ex.:" label="Apresentante" />
            </CompanyDetails>
            <CompanyDetails>
              <Input width="100%" name="cnpj" type="string" value={cnpj} mask="99.999.999.9999-99" placeholder="Ex.: PR" label="CNPJ" />
            </CompanyDetails>
          </CompanyDescription>
          <CompanyDescription>
            <CompanyDetails>
              <Input
                width="100%"
                name="data_emissao"
                type="string"
                value={dataEmissao}
                placeholder="Ex.:"
                label="Data de Emissão do Título"
              />
            </CompanyDetails>
            <CompanyDetails>
              <Input
                width="100%"
                name="data_vencimento"
                value={dataVencimento}
                type="string"
                placeholder="Ex.:"
                label="Data de Vencimento do Título"
              />
            </CompanyDetails>
            <CompanyDetailsToggle>
              <Toggle
                fontWeight="500"
                onChange={handleChangeCallback}
                label="E-mail callback"
                value={emailCallback}
                name="email_callback"
                paddingRight
                disabled={true}
              />
            </CompanyDetailsToggle>
            <CompanyDetailsToggle>
              <Toggle
                fontWeight="500"
                onChange={handleChangeIsDeleted}
                label="Título Removido"
                value={isDeleted}
                name="is_deleted"
                paddingRight
                disabled={true}
              />
            </CompanyDetailsToggle>
          </CompanyDescription>
          <CompanyDescription>
            <CompanyDetails>
              <Input readOnly width="100%" name="razao" type="string" placeholder="Ex.:" label="Razão Social" />
            </CompanyDetails>
            <CompanyDetails>
              <Input readOnly width="100%" name="nosso_numero" type="string" placeholder="Ex.:" label="Nosso Número" />
            </CompanyDetails>
            <CompanyDetails>
              <Input readOnly width="100%" name="numero_parcela" type="string" placeholder="Ex.:" label="Número da parcela" />
            </CompanyDetails>
            <CompanyDetails>
              <Input readOnly width="100%" name="numero_titulo" type="string" placeholder="Ex.:" label="Número do Título" />
            </CompanyDetails>
          </CompanyDescription>
          <CompanyDescription>
            <CompanyDetails>
              <Input readOnly width="100%" name="valor_saldo_protesto" type="string" placeholder="Ex.:" label="Valor Protestado" />
            </CompanyDetails>
            <CompanyDetails>
              <Input readOnly width="100%" name="valor_titulo" type="string" placeholder="Ex.:" label="Valor do Título" />
            </CompanyDetails>
            <CompanyDetails>
              <Input readOnly width="100%" name="valor_custas" type="string" placeholder="Custas/Emolumentos" label="Custas Emolumentos" />
            </CompanyDetails>
            <CompanyDetails></CompanyDetails>
          </CompanyDescription>
        </Form>
        <OfertasContainer>
          <Title label="Ofertas do Título" isVisiblePixel />
          {listaOfertaTitulo?.length === 0 && !novaOferta ? (
            <FeedbackArea>
              <EmptyFeedback imagePath={FeedbackImage} title="" message="Não há Ofertas cadastradas para este Título." />
            </FeedbackArea>
          ) : (
            <ListaOfertaTitulo offers={listaOfertaTitulo} reloadData={recarregarDados} />
          )}
        </OfertasContainer>
        {novaOferta && (
          <ContainerNovaOferta>
            <InformationContainer>
              <InformationRow isCenter padding="16px 24px 8px 24px">
                <InformationTitle>Resumo do Título</InformationTitle>
              </InformationRow>
              <InformationRow padding="8px 24px 8px 24px">
                <InformationRowContainer padding="0px 48px 0px 12px">
                  <InformationData>Valor Original Título</InformationData>
                  <InformationData>R$ {valorOriginalTitulo}</InformationData>
                </InformationRowContainer>
                <InformationRowContainer padding="0px 12px 0px 48px">
                  <InformationData>Valor Protestado</InformationData>
                  <InformationData>R$ {valorSaldoProtesto}</InformationData>
                </InformationRowContainer>
              </InformationRow>
              <InformationRow padding="8px 24px 16px 24px">
                <InformationRowContainer padding="0px 48px 0px 12px">
                  <InformationData>Valor Custas/Emolumentos</InformationData>
                  <InformationData>R$ {valorCustas}</InformationData>
                </InformationRowContainer>
                <InformationRowContainer padding="0px 12px 0px 48px">
                  <InformationData>Valor Total</InformationData>
                  <InformationData>R$ {valorTotal}</InformationData>
                </InformationRowContainer>
              </InformationRow>
            </InformationContainer>
            <Form className="editForm" ref={ofertaFormRef} onSubmit={handleSubmitOferta}>
              <InputsGrid>
                <Input disabled name="titulo" type="number" value={tituloEditado?.id} label="ID do Título" />
                <Input disabled name="apresentante" type="string" label="Apresentante" />
                <Input isView={false} disabled name="protestado_entidade_uuid" type="string" label="Entidade ID" />
                <InputMask
                  disabled={disableEmolumentos}
                  name="emolumentos"
                  label="Emolumentos Valor"
                  placeholder="Ex.: R$ 50"
                  prefix="R$"
                  value={emolumentos}
                  onChange={handleChangeEmolumentos}
                />
                <InputMask
                  disabled={disableDesconto}
                  name="desconto"
                  label="Desconto Valor"
                  placeholder="Ex.: R$ 50"
                  prefix="R$"
                  value={desconto}
                  customMargin={'20px 0px 10px 0px'}
                  onChange={handleChangeDesconto}
                />
                <Input
                  disabled={disableDescontoPorcentagem}
                  name="desconto_porcentagem"
                  mask={'99%'}
                  type="string"
                  placeholder="Ex.: 20%"
                  label="Desconto Porcentagem (%)"
                  value={desconto_porcentagem}
                  onChange={handleChangeDescontoPorcentagem}
                  full
                />
                <Input name="data_inicio" type="date" label="Data Inicial" full />
                <Input name="data_fim" type="date" label="Data Final" full />
              </InputsGrid>

              <ReactToggleRow>
                <Toggle
                  margin="20px 10px"
                  fontWeight="500"
                  onChange={handleChangeBoleto}
                  label="Boleto"
                  value={boleto}
                  name="boleto"
                  paddingRight
                />
                <Select
                  name="parcela_boleto"
                  customMargin={'20px 0 0 0'}
                  isView={boleto}
                  label="Parcelas Boleto"
                  color="gray400"
                  isBold
                  options={parcelaBoletoOptions}
                />
                <Input
                  name="juros_boleto"
                  isView={boleto}
                  mask={'99%'}
                  type="string"
                  placeholder="Ex.: 2%"
                  value={juros_boleto}
                  onChange={handleChangeJurosBoleto}
                  label="Juros Boleto"
                  full
                />
              </ReactToggleRow>

              <ReactToggleRow>
                <Toggle
                  margin="20px 10px"
                  fontWeight="500"
                  onChange={handleChangeCartao}
                  label="Cartão"
                  value={cartao}
                  name="cartao"
                  paddingRight
                />
                <Select
                  name="parcela_cartao"
                  customMargin={'20px 0 0 0'}
                  isView={cartao}
                  label="Parcelas Cartão"
                  color="gray400"
                  isBold
                  options={parcelaCartaoOptions}
                />
                <Input
                  name="juros_cartao"
                  isView={cartao}
                  mask={'99%'}
                  type="string"
                  placeholder="Ex.: 2%"
                  label="Juros Cartão"
                  value={juros_cartao}
                  onChange={handleChangeJurosCartao}
                  full
                />
              </ReactToggleRow>

              {/* <ReactToggleRow>
                <ReactToggleContainerStyle>
                  <ReactToggleTextContainer paddingRight>
                    <span>Status</span>
                  </ReactToggleTextContainer>
                  <ReactToggle id="status" disabled={true} name="status" defaultChecked={status} onChange={handleChangeStatus} />
                </ReactToggleContainerStyle>
              </ReactToggleRow> */}

              <ContainerButton>
                <Button type="submit">Salvar</Button>
                <Button type="button" onClick={cancelarEdicao}>
                  Cancelar
                </Button>
              </ContainerButton>
            </Form>
          </ContainerNovaOferta>
        )}
        <ContainerCriarOferta className="containerCriarOferta">
          <Button onClick={criarNovaOferta}>Criar Nova Oferta</Button>
        </ContainerCriarOferta>
      </DefaultContainer>

      <ModalConfirm
        isOpen={showModal}
        onClose={() => recarregarDados('Carregando Informações Atualizadas...')}
        handleButtonClick={() => recarregarDados('Carregando Informações Atualizadas...')}
        label={`Oferta criada com sucesso! ID: ${ofertaId}`}
        buttonLabel="Ok"
      />
      <ModalError
        isOpen={showModalError}
        onClose={() => setShowModalError(false)}
        handleButtonClick={() => setShowModalError(false)}
        label="Erro na Solicitação, verifique os dados informados"
        buttonLabel="Ok"
      />
    </>
  );
};

export default EditarRenegociacao;
