import styled, { css } from 'styled-components';
import flex from '@/assets/styles/flex';
import theme from '@/assets/styles/theme/';

interface EditarTituloProps {
  marginTopBoleto?: boolean;
  marginTopCartao?: boolean;
}

const heights = {
  descriptionItem: css`
    height: 78px;

    @media only screen and (max-width: 768px) {
      height: 56px;
    }
  `
};

export const CompanyDescription = styled.div`
  ${flex.direction.row}
  ${heights.descriptionItem}
  margin-top: 50px;

  // zoom 200%
  @media (min-width: 768.99px) and (max-width: 1024px) {
    ${flex.justify.spaceBetween}
  }

  @media only screen and (max-width: 768px) {
    ${flex.justify.spaceBetween}
    display: block;
  }
`;

export const CompanyDetails = styled.div`
  ${flex.direction.column}
  ${flex.justifySpaceBetween.alignStart}
  margin-left: 10px;
  margin-top: 0px;
  width: 30%;

  @media only screen and (max-width: 768px) {
    margin: 0;
    height: 60px;
    margin-top: 30px;
  }
`;

export const CompanyDetailsToggle = styled.div`
  ${flex.direction.column}
  ${flex.justify.end}
  ${flex.align.center}
  margin-left: 10px;
  margin-top: 0px;
  width: 30%;
  align-items: center;

  @media only screen and (max-width: 768px) {
    margin: 0;
    height: 60px;
    margin-top: 30px;
  }
`;

export const OfertasContainer = styled.div`
  margin-top: 60px;
  width: 100%;
`;

export const ContainerCriarOferta = styled.div`
  display: grid;
  align-self: end;
  margin: -8px;
`;

export const ContainerNovaOferta = styled.div`
  ${flex.direction.column};
  ${flex.justify.spaceBetween}
  width: 100%;
  padding: 10px 40px;
  margin-bottom: 50px;
  border-radius: 10px;
  background-color: ${theme.colors.white};
  @media only screen and (max-width: 1366px) {
    margin-bottom: 30px;
  }
  @media only screen and (max-width: 768px) {
    padding: 20px;
    margin-bottom: 20px;
  }
`;

export const InputsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  padding: 0px 8px 0px 8px;
`;

export const ReactToggleRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  padding: 0px 8px 0px 8px;
  margin-top: 30px;
`;

export const ReactToggleContainerBoleto = styled.div<EditarTituloProps>`
  display: flex;
  align-items: center;
  margin-top: ${(EditarTituloProps) => (EditarTituloProps.marginTopBoleto ? '40px' : '')};
`;

export const ReactToggleContainerCartao = styled.div<EditarTituloProps>`
  display: flex;
  align-items: center;
  margin-top: ${(EditarTituloProps) => (EditarTituloProps.marginTopCartao ? '40px' : '')};
`;

export const ReactToggleContainerStyle = styled.div`
  display: flex;
  align-items: center;
`;
