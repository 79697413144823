import Text from '@/components/Ui/Text';
import DescriptionContainer from '@/modules/Deal/components/DescriptionContainer';
import React from 'react';
import { Container, ItemDetails } from './styles';

interface IProps {
  posts: any[];
}

const DataList: React.FC<IProps> = ({ posts }) => {
  return (
    <Container className="Container">
      <DescriptionContainer>
        <ItemDetails width="10%">
          <Text margin="10px 10px" size="small" bold>
            Contrato/Parcela
          </Text>
        </ItemDetails>
        <ItemDetails width="18%">
          <Text margin="10px 10px" size="small" bold>
            Status de Pagamento
          </Text>
        </ItemDetails>
        <ItemDetails width="18%">
          <Text margin="10px 10px" size="small" bold>
            Forma de Pagamento
          </Text>
        </ItemDetails>
        <ItemDetails width="10%">
          <Text margin="10px 10px" size="small" bold>
            Valor Pago
          </Text>
        </ItemDetails>
        <ItemDetails width="10%">
          <Text margin="10px 10px" size="small" bold>
            Atualização
          </Text>
        </ItemDetails>
        <ItemDetails width="15%">
          <Text margin="10px 10px" size="small" bold>
            Tipo de Oferta
          </Text>
        </ItemDetails>
      </DescriptionContainer>
    </Container>
  );
};

export default DataList;
