import React, { useRef, useState, useEffect, useCallback } from 'react';
import Logo from '@/assets/images/logo-white.svg';
import Nav from '@/components/Ui/Nav';
import { useHistory } from 'react-router-dom';
import UserSetting from './components/UserSetting';
import { Container, MenuIcon, ImageLogo, NavigationContainer, NavBar } from './styles';

const Header: React.FC = ({ ...rest }) => {
  const history = useHistory();
  const NavOptions = [
    {
      id: '/deal-offers',
      label: 'Ofertas de Acordo',
      onClickHandler: () => history.push('/deal-offers')
    },
    {
      id: '/my-deals',
      label: 'Meus Acordos',
      onClickHandler: () => history.push('/my-deals')
    }
  ];

  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLHeadingElement>(null);

  const handleClickOutSide = useCallback(
    (e) => {
      if (!ref.current?.contains(e.target) && isOpen) {
        setIsOpen(!isOpen);
      }
    },
    [isOpen]
  );

  useEffect(() => {
    window.addEventListener('click', handleClickOutSide);

    return () => {
      window.removeEventListener('click', handleClickOutSide);
    };
  }, [handleClickOutSide]);

  return (
    <Container>
      <MenuIcon onClick={() => setIsOpen(!isOpen)} />
      <ImageLogo onClick={() => history.push('/deal-offers')} src={Logo} alt="Protestado" />
      
      <NavigationContainer>
        <NavBar onClick={() => setIsOpen(!isOpen)} ref={ref}>
          <Nav items={NavOptions} isVisible mobileIsVisible={isOpen} navType="navBar" mobileNavType="dropNav" />
        </NavBar>
        <UserSetting />
      </NavigationContainer>
    </Container>
  );
};

export default Header;
