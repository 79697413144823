import React from 'react';
import ReactLoading from 'react-loading';
import theme from '@/assets/styles/theme';
import { Container, ReactLoadingContainer, ReactLoadingTextContainer } from './styles';

type LoadingType = 'blank' | 'balls' | 'bars' | 'bubbles' | 'cubes' | 'cylon' | 'spin' | 'spinningBubbles' | 'spokes';

interface IFullScreenLoaderProps {
  type?: LoadingType;
  loadingMessage?: string;
}

export const FullScreenLoader: React.FC<IFullScreenLoaderProps> = ({ type = 'spin', loadingMessage = undefined }) => (
  <Container>
    <div>
      <ReactLoadingContainer>
        <ReactLoading type={type} width="100px" height="100px" color={theme.colors.secondary} />
      </ReactLoadingContainer>
      <ReactLoadingTextContainer isView={loadingMessage}>
        <span>{loadingMessage}</span>
      </ReactLoadingTextContainer>
    </div>
  </Container>
);
