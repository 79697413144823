import { css } from 'styled-components';

export default {
  alignStart: css`
    justify-content: space-around;
    align-items: flex-start;
  `,
  alignCenter: css`
    justify-content: space-around;
    align-items: center;
  `,
  alignEnd: css`
    justify-content: space-around;
    align-items: flex-end;
  `
};
