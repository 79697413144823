import React from "react";
import {
  Container,
  UploadDetails,
  ListContainer
} from "./styles";
import DescriptionContainer from "@/modules/Deal/components/DescriptionContainer";
import Text from "@/components/Ui/Text";
import { UploadDetalhe } from "../../../interfaces/UploadDetalhe";

interface IProps {
  posts: UploadDetalhe[];
}

const DataList: React.FC<IProps> = ({ posts }) => {

  return (
    <ListContainer>

      {posts.map((item: UploadDetalhe) => {
        return (
          <Container key={item.arquivo}>
            <DescriptionContainer>
              <UploadDetails>
                <Text size="medium" bold>
                  Número do título: {item.numero_titulo}
                </Text>
                <Text size="small">Nome do Devedor: {item.nome_devedor}</Text>
              </UploadDetails>
              <UploadDetails>
                <Text size="small">
                  Valor do Título: {item.valor_titulo}
                </Text>
                <Text size="small">
                  Valor Saldo Protestado: {item.valor_saldo_protesto}
                </Text>
              </UploadDetails>
              <UploadDetails>
                <Text size="small">
                  Arquivo de Upload: {item.arquivo}
                </Text>
              </UploadDetails>

            </DescriptionContainer>
          </Container>
        );
      })}
    </ListContainer>
  );
};

export default DataList;
