interface EntidadesResponse {
  id: string;
  email: string;
  created_at: string;
  name: string;
  is_active: boolean;
}

export const handleListEntidade = (entidades: EntidadesResponse[]) => {
  return entidades.map((entidade) => {
    const entidadeId = entidade.id;
    return {
      email: entidade.email,
      name: entidade.name,
      created_at: entidade.created_at,
      is_active: entidade.is_active,
      id: entidade.id
    };
  });
};
