import styled, { css } from 'styled-components'

import { AgreementStatusEnum } from '@/@types/enum/agreementStatus'
import flex from '@/assets/styles/flex'
import theme from '@/assets/styles/theme/'

interface Props {
  status: string
}

const heights = {
  descriptionItem: css`
    height: 78px;

    @media only screen and (max-width: 768px) {
      height: 56px;
    }
  `,
}

interface ItemDetailsProps {
  width?: string;
}

export const ItemDetails = styled.div<ItemDetailsProps>`
  ${flex.direction.column}
  ${flex.justifySpaceBetween.alignStart}
  margin: 10px 10px;
  justify-content: center;
  width: ${(props) => (props.width ? props.width : '')};

  @media only screen and (max-width: 768px) {
    margin: 0;
    height: 60px;
    margin-top: 30px;
  }
`;

export const Container = styled.div`
  ${flex.direction.column};
  ${flex.justify.spaceBetween}
  width: 100%;
  padding: 10px 40px;
  margin-bottom: 5px;
  border-radius: 0px;
  background-color: ${theme.colors.white};
  @media only screen and (max-width: 1366px) {
    margin-bottom: 30px;
  }
  @media only screen and (max-width: 768px) {
    padding: 20px;
    margin-bottom: 20px;
  }
`
