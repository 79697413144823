import ScreenNavCard from '@/components/Ui/ScreenNavCard';
import { ScreenNavCardContainerDefault } from '@/components/Ui/ScreenNavCard/styles';
import Text from '@/components/Ui/Text';
import React from 'react';
import { Container, ContainerTitle } from '../GerenciarOfertas/components/EditContainer/styles';

const Mensageria: React.FC = () => {
  return (
    <>
      <Container className="Container" paddingTop paddingBottom>
        <ContainerTitle className="containerTitle">
          <Text color="primary" size="big" align="center" textJustify={false} bold>
            Mensageria
          </Text>
          <Text margin="20px" color="primary" size="medium" align="center" textJustify={false}>
            O que deseja fazer? Selecione uma opção abaixo:
          </Text>
        </ContainerTitle>
        <ScreenNavCardContainerDefault repeats={3}>
          <ScreenNavCard
            titleLabel="Ajustes"
            description="Ajustes de Mensageria"
            icon="config"
            btnLabel="Gerenciar Ajustes"
            path="/mensageria/ajustes"
            isDashboard={false}
            isManagement={true}
            btnDisable={true}
            btnSize="custom2"
            btnColor="secondary"
            tooltipId="ajustes"
          />
          <ScreenNavCard
            titleLabel="Templates de E-mail"
            description="Gerencie o envio de e-mails transacionais padronizados"
            icon="mensageria"
            btnLabel="Acessar"
            path="/mensageria/galeria-templates"
            isDashboard={false}
            isManagement={true}
            btnDisable={true}
            btnSize="custom2"
            btnColor="secondary"
          />
          <ScreenNavCard
            titleLabel="Campanhas"
            description="Crie templates e gerencie o envio de e-mails personalizados"
            icon="campanhas"
            btnLabel="Acessar"
            path="/mensageria/listar-campanhas"
            isDashboard={false}
            isManagement={true}
            btnDisable={true}
            btnSize="custom2"
            btnColor="secondary"
          />
          <ScreenNavCard
            titleLabel="Enviar SMS"
            description="Gerenciamento de SMS"
            icon="sms"
            btnLabel="Acessar"
            path="/mensageria/enviar-sms"
            isDashboard={false}
            isManagement={true}
            btnDisable={true}
            btnSize="custom2"
            btnColor="secondary"
            tooltipId="sms"
          />
          <ScreenNavCard
            titleLabel="Relatórios"
            description="Visualização de Relatórios de Mensageria"
            icon="relatorios"
            btnLabel="Acessar"
            path="/relatorios/mensageria-emails"
            isDashboard={false}
            isManagement={true}
            btnDisable={true}
            btnSize="custom2"
            btnColor="secondary"
          />
        </ScreenNavCardContainerDefault>
      </Container>
    </>
  );
};

export default Mensageria;
