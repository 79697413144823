import React from 'react'

import { Button } from './styles'

interface ButtonInterface {
  label: string
  target?: string
  onClick: () => void

  fontSize?: string
}

const ButtonLink: React.FC<ButtonInterface> = ({ label, target, onClick, fontSize }) => (
  <Button onClick={onClick} title={label} fontSize={fontSize} target={target}>
    {label}
  </Button>
)

export default ButtonLink
