import flex from '@/assets/styles/flex';
import styled from 'styled-components';

interface TitleProps {
  marginBottom?: string;
  centralize?: boolean;
}

export const Container = styled.h1<TitleProps>`
  ${flex.direction.row};
  ${flex.justifyStart.alignEnd};
  width: 100%;
  ${({ centralize }) =>
    centralize &&
    `
    display: flex;
    justify-content: center;
    align-items: center;
  `}
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '30px')};

  @media (max-width: 768px) {
    margin-bottom: 15px;
  }
`;
