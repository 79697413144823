import styled from 'styled-components';

interface ScreenNavCardProps {
  isHide?: boolean;
  padding?: string;
  margin?: string;
  repeats?: number;
  btnDisable?: boolean;
  isDashboard?: boolean;
  btnColor?: string;
}

export const ScreenNavCardContainer = styled.div<ScreenNavCardProps>`
  display: ${(props) => (props.isHide ? 'none !important' : 'grid')};
  align-items: normal;
  justify-content: center;
  justify-items: center;
  padding: 12px;
  height: 100%;

  @media only screen and (max-width: 1024px) {
    padding: 8px;
  }

  p {
    display: flex;
    align-items: center;
    text-align: center !important;
    padding: 8px 0px;

    @media only screen and (max-width: 1024px) {
      height: 58px !important;
    }
  }
`;

export const IconArea = styled.div<ScreenNavCardProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5.8rem;
  color: ${(props) => props.btnColor};
  padding-bottom: 12px;

  ${({ btnDisable }) =>
    btnDisable &&
    `
    opacity: 0.5; 
  `}

  @media only screen and (max-width: 1440px) {
    font-size: 5.2rem;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 4.6rem;
  }
`;

export const ScreenNavCardContainerDefault = styled.div<ScreenNavCardProps>`
  margin: 0px 0px 0px 0px;
  display: grid;
  grid-template-columns: ${({ repeats }) => (repeats ? `repeat(${repeats}, 1fr)` : 'repeat(2, 1fr)')};
  padding: 12px 12vw;
  gap: 32px;

  @media only screen and (max-width: 1440px) {
    padding: 12px 6vw;
  }

  @media only screen and (max-width: 1366px) {
    padding: 12px 64px;
  }
  @media only screen and (max-width: 1024px) {
    padding: 12px 32px;
  }
`;

export const ScreenNavTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ScreenNavBtnContainer = styled.div<ScreenNavCardProps>`
  display: flex;
  align-items: end;
  justify-content: center;

  width: ${(props) => (props.isDashboard ? '100%' : '')};
`;
