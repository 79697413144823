import React from 'react';
import {
  Container,  
  ItemDetails,
} from './styles';
import DescriptionContainer from '@/modules/Deal/components/DescriptionContainer';
import Text from '@/components/Ui/Text';

interface IProps {
  posts: any[];
}

const DataList: React.FC<IProps> = ({ posts }) => {
  
  return (
            <Container className="Container">
              <DescriptionContainer>
                <ItemDetails width="10%">
                  <Text margin="10px 10px" size="small" bold>
                    ID
                  </Text>
                </ItemDetails>
                <ItemDetails width="10%">
                  <Text margin="10px 10px" size="small" bold>
                    Título
                  </Text>
                </ItemDetails>
                <ItemDetails width="25%">
                  <Text margin="10px 10px" size="small" bold>
                    Destinatário
                  </Text>
                </ItemDetails>
                <ItemDetails width="15%">
                  <Text margin="10px 10px" size="small" bold>
                    Data de Envio
                  </Text>
                </ItemDetails>
                <ItemDetails width="15%">
                  <Text margin="10px 10px" size="small" bold>
                    Campanha
                  </Text>
                </ItemDetails>
                <ItemDetails width="15%">
                  <Text margin="10px 10px" size="small" bold>
                    Assunto Email
                  </Text>
                </ItemDetails>
                <ItemDetails width="15%">
                  <Text margin="10px 10px" size="small" bold>
                  </Text>
                </ItemDetails>
              </DescriptionContainer>
            </Container>
  );
};

export default DataList;
