import theme from '@/assets/styles/theme';
import { IoIosMenu } from 'react-icons/io';
import styled from 'styled-components';

interface Props {
  primaryCompany: string;
  headerFooterFontColor: string;
  dataFrameLogo: boolean;
}

export const MenuIcon = styled(IoIosMenu)`
  display: none;

  /* @media only screen and (max-width: 1200px) { */
  @media only screen and (max-width: 768px) {
    display: flex;
    width: 36px;
    height: 36px;
    color: ${theme.colors.white};
  }
`;

export const ImageLogo = styled.img<Props>`
  background-color: ${({ dataFrameLogo }) => (dataFrameLogo ? theme.colors.white : 'transparent')};
  border-color: ${({ dataFrameLogo }) => (dataFrameLogo ? theme.colors.white : 'transparent')};
  border-radius: 8px;
  border-width: 5px;
  border-style: solid;
  width: min(35vw, 200px);
  height: auto;
  cursor: pointer;

  @media only screen and (max-width: 1200px) {
    width: min(18vw, 200px);
    margin-left: 8px;
  }
`;
export const Container = styled.header<Props>`
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 999;
  width: 100%;
  height: 11.801896733403582vh;
  padding-left: 6vw;
  padding-right: 6vw;

  background-color: ${(props) => props.primaryCompany};
  color: ${(props) => props.headerFooterFontColor};

  @media only screen and (max-width: 1440px) {
    padding-left: 4.5vw;
    padding-right: 4.5vw;
    height: 10vh;
  }

  @media only screen and (max-width: 1366px) {
    padding-left: 3.5vw;
    padding-right: 3.5vw;
    height: 10vh;
  }

  @media only screen and (max-width: 1024px) {
    padding: 20px 10px;
    height: 10vh;
  }
`;

export const NavigationContainer = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const NavBar = styled.div`
  margin: 0 1.5vw 0 0;
  padding-top: 8px;
  z-index: 1;

  @media only screen and (max-width: 1440px) {
    margin: 0px;
  }

  @media only screen and (max-width: 1024px) {
    margin: 0 4vw 0 0;
  }
`;

export default Container;
