import styled from 'styled-components';

interface AccountBalanceProps {
  backgroundColor?: string;
}

export const AccountBalanceAreaContainer = styled.div<AccountBalanceProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  padding: 30px 45px;
  margin: 24px 48px 0px 48px;
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : '')};
`;

export const AccountBalanceTextArea = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
`;
