import api from '@/services/api';

export interface EmailTemplateFilter {
  protestado_entidade_uuid: string;
}

export interface EmailResultDTO {
  count: number;
  next?: number;
  previous?: number;
  results: EmailTemplate[];
}

export interface EmailTemplate {
  id: string;
  name: string;
  alias: string;
  html: string;
  is_active: boolean;
  created_at: string;
  updated_at: string;
  default: boolean;
  default_secondary: boolean;
  default_third_party: boolean;
  template_purpose: string;
  company_authorized: string;
}

export const getEmailTemplates = async (filtro: EmailTemplateFilter): Promise<EmailResultDTO | null> => {
  const { data } = await api.get('users/protested-titles/corporation/list-email-templates', {
    params: { ...(filtro.protestado_entidade_uuid ? { protestado_entidade_uuid: filtro.protestado_entidade_uuid } : null) }
  });
  return data;
};
