import api from '@/services/api';
import { convertTitlesV1 } from '@/utils/utils_v1/Title';
import { FiltrosTitulo } from '../interfaces/FiltrosTitulo';

export const getTituloRequestFiltrar = async (params: FiltrosTitulo) => {
  const { data } = await api.get(`/users/protested-titles/corporation/protested-titles`, {
    params:
      //params faz a verificação, se não houver dados, remove o parâmetro na requisição
      {
        ...(params.id ? { id: params.id } : {}),
        ...(params.numero_titulo ? { title_number: params.numero_titulo } : {}),
        ...(params.numero_identificacao_devedor ? { debtor_document: params.numero_identificacao_devedor } : {}),
        ...(params.is_deleted != null ? { is_deleted: params.is_deleted } : {}),
        ...(params.no_current_agreement != null ? { no_current_agreement: params.no_current_agreement } : {})
      }
  });
  const convertedTitles = convertTitlesV1(data.results);
  return convertedTitles;
};
