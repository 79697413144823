import styled from 'styled-components';

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  padding-left: 40px;
  padding-right: 40px;
`;

export const Image = styled.img`
  max-width: 294px;
  height: auto;
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0 0 0;
`;
