import React, { useMemo } from 'react';
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react';
import { BalanceTransferConfirmation } from '../..';
import { formatarCpfCnpj, formatarDinheiro } from '@/utils/Utilities';

interface Props {
  modalTitle: string;
  balanceTransferConfirmation: BalanceTransferConfirmation | null;
  isOpen: boolean;
  // onOpen: () => void;
  onClose: () => void;
  onConfirm: () => void;
}

const BalanceTransferModal = ({ modalTitle, balanceTransferConfirmation, isOpen, onClose, onConfirm }: Props) => {
  const includeDigitAccount = useMemo(() => {
    if (balanceTransferConfirmation && balanceTransferConfirmation.account_digit && balanceTransferConfirmation.account_digit !== '') {
      return '-' + balanceTransferConfirmation.account_digit;
    }
    return '';
  }, [balanceTransferConfirmation]);

  const includeDigitBankBranch = useMemo(() => {
    if (
      balanceTransferConfirmation &&
      balanceTransferConfirmation.bank_branch_digit &&
      balanceTransferConfirmation.bank_branch_digit !== ''
    ) {
      return '-' + balanceTransferConfirmation.bank_branch_digit;
    }
    return '';
  }, [balanceTransferConfirmation]);

  const confirmModal = () => {
    onConfirm();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader bg="blue.900" color="white">
          {modalTitle}
        </ModalHeader>
        <ModalCloseButton color="white" tabIndex={2} />
        <ModalBody>
          <Text fontSize="xl" mb="24px" mt="12px" fontWeight="500">
            Confira os dados de sua transferência:
          </Text>
          <Text fontSize="xl" my="8px">
            Nome do Favorecido: {balanceTransferConfirmation?.payee_name}{' '}
          </Text>
          <Text fontSize="xl" my="8px">
            Documento do Favorecido: {formatarCpfCnpj(balanceTransferConfirmation?.payee_legal_document)}{' '}
          </Text>
          <Text fontSize="xl" my="8px">
            Número Banco: {balanceTransferConfirmation?.bank_number}{' '}
          </Text>
          <Text fontSize="xl" my="8px">
            Nome Banco: {balanceTransferConfirmation?.bank_name}{' '}
          </Text>
          <Text fontSize="xl" my="8px">
            Agência: {balanceTransferConfirmation?.bank_branch}
            {includeDigitBankBranch}{' '}
          </Text>
          <Text fontSize="xl" my="8px">
            Conta: {balanceTransferConfirmation?.account}
            {includeDigitAccount}{' '}
          </Text>
          <Text fontSize="xl" my="8px">
            Valor: {formatarDinheiro(balanceTransferConfirmation?.transfer_value)}{' '}
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={onClose}>
            Fechar
          </Button>
          <Button colorScheme="green" onClick={confirmModal} tabIndex={1}>
            Confirmar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BalanceTransferModal;
