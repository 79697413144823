import theme from '@/assets/styles/theme';
import { IUserCompanyProps } from '@/components/Interfaces/UserCompanyProps';
import Button from '@/components/Ui/Button';
import DefaultHeader from '@/components/Ui/DefaultHeader';
import { DefaultContainer } from '@/components/Ui/DefaultHeader/style';
import { FullScreenLoader } from '@/components/Ui/FullScreenLoader';
import Input from '@/components/Ui/Input';
import InputMask from '@/components/Ui/InputMask';
import ModalConfirm from '@/components/Ui/Modal/ModalConfirm';
import ModalError from '@/components/Ui/Modal/ModalError';
import Text from '@/components/Ui/Text';
import { DefaultTextTooltip } from '@/components/Ui/Text/styles';
import Tooltip from '@/components/Ui/Tooltip';
import { ISignInProps } from '@/modules/_layouts/components/HeaderCompany/components/UserSetting';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { DiscountImage, PaymentDiscount } from '../ListarOfertasGlobais/components/paginate/styles';
import { ContainerButton } from '../components/EditContainer/styles';
import { getOptionsEntidadesCtrl } from '../controllers/getOptionsEntidadesCtrl';
import { TitulosAplicaveisPayload } from './interfaces/TitulosAplicaveisPayload';
import { OfertaGlobalPayload } from './interfaces/ofertaGlobal';
import { criarOfertaGlobalRequest } from './providers/criarOfertaGlobal';
import { getTitulosAplicaveisOfertaGlobal } from './providers/getTitulosAplicaveisOfertaGlobal';
import { ButtonRow } from './providers/styles';
import { ApplicableTitleContainer, LayoutApplicableTitles } from './styles';

const CriarOfertaGlobal: React.FC = () => {
  const accessToken = useSelector((state: ISignInProps) => state.SignIn.access_token);
  const [disableFilters, setDisableFilters] = useState(false);
  const [titulosAplicaveis, setTitulosAplicaveis] = useState(0);
  const [pageMessage, setPageMessage] = useState('');
  const [tempoVencimento, setTempoVencimento] = useState('Todos');
  const [tempoPlataforma, setTempoPlataforma] = useState('Todos');
  const [loadingMessage, setLoadingMessage] = useState('');
  const [ofertaGlobalId, setOfertaGlobalId] = useState('');
  const [showApresentante, setShowApresentante] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [entidadeOptions, setEntidadeOptions] = useState<any[]>([]);

  const msgCampos = {
    valor_de: 'Valor Inicial',
    valor_ate: 'Valor Final',
    juros_cartao: 'Juros Cartão',
    juros_boleto: 'Juros Boleto',
    desconto: 'Desconto Valor',
    desconto_porcentagem: 'Desconto Porcentagem'
  } as any;
  // const [apresentanteOptions, setApresentanteOptions] = useState<any[]>([]);

  const history = useHistory();

  const formRef = useRef<FormHandles>(null);

  const [disableDescontoPorcentagem, setDisableDescontoPorcentagem] = useState(false);
  const [disableDesconto, setDisableDesconto] = useState(false);
  const [status, setStatus] = useState(true);
  const handleChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStatus(e.target.checked);
  };
  const [cartao, setCartao] = useState(true);
  const handleChangeCartao = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCartao(e.target.checked);
    setJurosCartao('');
  };
  const [boleto, setBoleto] = useState(false);
  const handleChangeBoleto = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBoleto(e.target.checked);
    setJurosBoleto('');
  };
  const [status_envio_sms, setStatusEnvio] = useState(false);
  const handleChangeStatusEnvio = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStatusEnvio(e.target.checked);
  };
  const [valor_de, setValorDe] = useState('');
  const handleChangeValorDe = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValorDe(e.target.value);
  };
  const [valor_ate, setValorAte] = useState('');
  const handleChangeValorAte = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValorAte(e.target.value);
  };
  const [desconto, setDesconto] = useState('');
  const handleChangeDesconto = (e: React.ChangeEvent<HTMLInputElement>) => {
    discountCtrl(e);
    setDesconto(e.target.value);
  };
  const [desconto_porcentagem, setDescontoPorcentagem] = useState('0');
  const handleChangeDescontoPorcentagem = (e: React.ChangeEvent<HTMLInputElement>) => {
    discountCtrl(e);
    setDescontoPorcentagem(e.target.value);
  };
  const [juros_cartao, setJurosCartao] = useState('');
  const handleChangeJurosCartao = (e: React.ChangeEvent<HTMLInputElement>) => {
    setJurosCartao(e.target.value);
  };
  const [juros_boleto, setJurosBoleto] = useState('');
  const handleChangeJurosBoleto = (e: React.ChangeEvent<HTMLInputElement>) => {
    setJurosBoleto(e.target.value);
  };
  const [nome_oferta, setNomeOferta] = useState('');
  const handleChangeNomeOferta = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNomeOferta(e.target.value);
  };

  const [entidadeId, setEntidadeId] = useState('');

  const handleTempoVencimento = useCallback((tempoVencimento: string) => {
    setTempoVencimento(tempoVencimento);
  }, []);

  const handleTempoPlataforma = useCallback((tempoPlataforma: string) => {
    setTempoPlataforma(tempoPlataforma);
  }, []);

  const handleButtonColor = useCallback(
    (buttonType: string, value: string): 'primary' | 'inverse' => {
      if (buttonType === 'tempoVencimento') {
        if (value === tempoVencimento) {
          return 'primary';
        } else {
          return 'inverse';
        }
      } else {
        if (value === tempoPlataforma) {
          return 'primary';
        } else {
          return 'inverse';
        }
      }
    },
    [tempoVencimento, tempoPlataforma]
  );

  const discountCtrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'desconto') {
      if (e.target.value) {
        setDisableDescontoPorcentagem(true);
      } else {
        setDisableDescontoPorcentagem(false);
      }
    } else if (e.target.id === 'desconto_porcentagem') {
      if (e.target.value && e.target.value !== '__%') {
        setDisableDesconto(true);
      } else {
        setDisableDesconto(false);
      }
    }
  };

  // const apresentanteOptions = [
  //   { value: 'DRT', label: 'Darta - DRT' },
  //   { value: 'RCP', label: 'Recuperi - RCP' }
  // ];

  const limparBusca = useCallback(() => {
    setTitulosAplicaveis(0);
    setDisableFilters(false);
    setCartao(false);
    setBoleto(false);
    setDesconto('');
    setDisableDesconto(false);
    setDescontoPorcentagem('0');
    setDisableDescontoPorcentagem(false);
    setJurosCartao('');
    setJurosBoleto('');
  }, []);

  const definirApresentante = useCallback(() => {
    formRef.current?.setFieldValue('apresentante', '');
    setShowApresentante(!showApresentante);
  }, [showApresentante]);

  const getTitulosAplicaveis = useCallback(async () => {
    try {
      if (valor_de !== '' && valor_ate !== '') {
        setIsLoading(true);
        setLoadingMessage('Buscando Títulos Aplicáveis...');

        const valor_de_formatado = valor_de.replace(/\./g, '').replace(/,/g, '').replace('R$', '');
        const valor_ate_formatado = valor_ate.replace(/\./g, '').replace(/,/g, '').replace('R$', '');

        if (Number(valor_de_formatado) > Number(valor_ate_formatado)) {
          toast.warning('O Valor Final não pode ser menor que o Valor Inicial');
        } else {
          let dt_vencimento_de = null;
          let dt_vencimento_ate = null;
          let dt_cadastro_de = null;
          let dt_cadastro_ate = null;

          if (tempoVencimento !== 'Todos') {
            const tempoVencimentoNumber = Number(tempoVencimento);
            const dataVencimento = new Date();
            dataVencimento.setDate(dataVencimento.getDate() - tempoVencimentoNumber);
            dt_vencimento_de = moment(dataVencimento).format('YYYY-MM-DD');
            dt_vencimento_ate = moment().format('YYYY-MM-DD');
          }

          if (tempoPlataforma !== 'Todos') {
            if (tempoPlataforma === '90+') {
              const tempoPlataformaNumber = 90;
              const dataPlataforma = new Date();
              dataPlataforma.setDate(dataPlataforma.getDate() - tempoPlataformaNumber);
              dt_cadastro_ate = moment(dataPlataforma).format('YYYY-MM-DD');
            } else {
              const tempoPlataformaNumber = Number(tempoPlataforma);
              const dataPlataforma = new Date();
              dataPlataforma.setDate(dataPlataforma.getDate() - tempoPlataformaNumber);
              dt_cadastro_de = moment(dataPlataforma).format('YYYY-MM-DD');
              dt_cadastro_ate = moment().format('YYYY-MM-DD');
            }
          }

          const payload = { valor_de: valor_de_formatado, valor_ate: valor_ate_formatado } as TitulosAplicaveisPayload;

          const campoValorNegativo = possuiValoresNegativos(payload, true);
          if (!campoValorNegativo) {
            if (dt_vencimento_de && dt_vencimento_ate) {
              payload.dt_vencimento_de = dt_vencimento_de;
              payload.dt_vencimento_ate = dt_vencimento_ate;
            }

            // Tempo Plataforma tem tratamento diferenciado devido a opção 90+
            if (dt_cadastro_de) {
              payload.dt_cadastro_de = dt_cadastro_de;
            }
            if (dt_cadastro_ate) {
              payload.dt_cadastro_ate = dt_cadastro_ate;
            }

            const titulosAplicaveis = await getTitulosAplicaveisOfertaGlobal(payload);
            if (titulosAplicaveis) {
              setTitulosAplicaveis(titulosAplicaveis);
              setDisableFilters(true);
              setPageMessage('Informe abaixo os detalhes da nova Oferta');
            } else {
              toast.warning('Não foram encontrados Títulos aplicáveis para o Filtro selecionado');
            }
          } else {
            toast.warning(`Não são permitidos valores negativos. Favor verificar campo ${msgCampos[campoValorNegativo]}`);
          }
        }
      } else {
        toast.error('Para prosseguir, informe o Valor Inicial e o Valor Final');
      }
    } catch (e) {
      setShowModalError(true);
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }, [valor_de, valor_ate, tempoVencimento, tempoPlataforma]);

  const verificarValorNegativo = (campo: string): boolean => {
    if (Number(campo) < 0) {
      return true;
    }
    return false;
  };

  const possuiValoresNegativos = (payload: OfertaGlobalPayload | TitulosAplicaveisPayload, isPayloadCriar = false): string | null => {
    let camposNumericos = ['valor_de', 'valor_ate'];
    let possuiValorNegativo: string | null = null;

    if (isPayloadCriar) {
      camposNumericos = camposNumericos.concat(['juros_cartao', 'juros_boleto', 'desconto', 'desconto_porcentagem']);
    }

    for (const [key, value] of Object.entries(payload)) {
      if (camposNumericos.includes(key) && value != null) {
        const valorNegativo = verificarValorNegativo(value);
        if (valorNegativo) {
          possuiValorNegativo = key;
          break;
        }
      }
    }

    return possuiValorNegativo;
  };

  const handleSubmitPayload = useCallback(
    async (fields: OfertaGlobalPayload) => {
      try {
        setIsLoading(true);
        setLoadingMessage('Criando Oferta Global...');

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          start_date: Yup.date()
            .nullable()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .required('Por favor informe a Data Inicial'),
          end_date: Yup.date()
            .nullable()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .min(Yup.ref('start_date'), 'Data Final não pode ser menor do que a Inicial')
            .required('Por favor informe a Data Final')
        });

        await schema.validate(fields, {
          abortEarly: false
        });

        // if (!fields.apresentante || fields.apresentante === '') {
        //   fields.apresentante = '[1]';
        // }

        let due_from = null;
        let due_to = null;
        let uploaded_from = null;
        let uploaded_to = null;

        if (tempoVencimento !== 'Todos') {
          due_from = 0;
          due_to = Number(tempoVencimento);
        }

        if (tempoPlataforma !== 'Todos') {
          if (tempoPlataforma === '90+') {
            uploaded_from = 90;
          } else {
            uploaded_from = 0;
            uploaded_to = Number(tempoPlataforma);
          }
        }

        const valor_de_formatado = valor_de.replace(/\./g, '').replace(/,/g, '').replace('R$', '');
        const valor_ate_formatado = valor_ate.replace(/\./g, '').replace(/,/g, '').replace('R$', '');
        // const desconto_formatado = desconto?.replace('R$', '').replace(/,/g, '').replace('_', '');
        const desconto_porcentagem_formatado = desconto_porcentagem?.replace('%', '').replace('_', '');
        // const juros_boleto_formatado = juros_boleto.replace('%', '').replace('_', '');
        // const juros_cartao_formatado = juros_cartao.replace('%', '').replace('_', '');

        const payload = {
          value_from: valor_de_formatado,
          value_to: valor_ate_formatado,
          // cartao: cartao,
          // parcela_cartao: fields.parcela_cartao ? fields.parcela_cartao : 1,
          // juros_cartao: juros_cartao ? juros_cartao_formatado : '0',
          // boleto: boleto,
          // parcela_boleto: fields.parcela_boleto ? fields.parcela_boleto : 1,
          // juros_boleto: juros_boleto ? juros_boleto_formatado : '0',
          // status: status,
          start_date: fields.start_date,
          end_date: fields.end_date,
          // apresentante: fields.apresentante,
          // iof: fields.iof,
          // taxa_servicos: fields.taxa_servicos,
          // status_envio_sms: false,
          // mensagem: '',
          company: entidadeId,
          // desconto: desconto ? desconto_formatado : null,
          discount_value: desconto_porcentagem ? desconto_porcentagem_formatado : 0,
          name: fields.nome_oferta ? fields.nome_oferta : null,
          due_from,
          due_to,
          uploaded_from,
          uploaded_to
        } as OfertaGlobalPayload;

        const campoValorNegativo = possuiValoresNegativos(payload, true);
        if (!campoValorNegativo) {
          const novaOferta = await criarOfertaGlobalRequest(payload);
          if (novaOferta) {
            setOfertaGlobalId(novaOferta.id);
          }
          setShowModal(true);
        } else {
          toast.warning(`Não são permitidos valores negativos. Favor verificar campo ${msgCampos[campoValorNegativo]}`);
        }
      } catch (err) {
        setShowModalError(true);
        const validationErrors: { [key: string]: string } = {};

        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: Yup.ValidationError) => {
            validationErrors[error.path] = error.message;
          });

          formRef.current?.setErrors(validationErrors);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [
      cartao,
      boleto,
      status,
      status_envio_sms,
      valor_de,
      valor_ate,
      desconto,
      desconto_porcentagem,
      juros_cartao,
      juros_boleto,
      tempoVencimento,
      tempoPlataforma,
      entidadeId
    ]
  );

  const handleSubmit = useCallback(
    async (fields: OfertaGlobalPayload) => {
      if (titulosAplicaveis === 0) {
        await getTitulosAplicaveis();
      } else {
        if (desconto !== '' || desconto_porcentagem !== '') {
          await handleSubmitPayload(fields);
        } else {
          toast.error('É preciso preencher um valor de Desconto ou Desconto Porcentagem para criar uma Oferta Global');
        }
      }
    },
    [titulosAplicaveis, getTitulosAplicaveis, desconto, desconto_porcentagem, handleSubmitPayload]
  );

  const loadSelectOptions = useCallback(async () => {
    try {
      setIsLoading(true);
      setLoadingMessage('Preparando Criação de Oferta...');
      const entidadesOpt = await getOptionsEntidadesCtrl();
      // const apresentantesOpt = await getOptionsApresentantesCtrl();
      setEntidadeOptions(entidadesOpt);
      // setApresentanteOptions(apresentantesOpt);
    } catch (e) {
      toast.error('Erro ao carregar informações de Entidades/Apresentantes!');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    let continua = false;
    if (accessToken) {
      const userDecode = jwtDecode<IUserCompanyProps>(accessToken);
      const entidadeId = userDecode.corporation_id;
      setEntidadeId(entidadeId);
      if (userDecode && userDecode.is_superuser_protestado) {
        continua = true;
      } else {
        const entidadeOpt = [{ value: userDecode.corporation_id, label: userDecode.corporation_name }];
        setEntidadeOptions(entidadeOpt);
      }
    }

    if (continua) {
      loadSelectOptions();
    }

    setPageMessage('Preencha os Filtros para verificar os Títulos Aplicáveis');
  }, [accessToken, loadSelectOptions]);

  return (
    <>
      {isLoading && <FullScreenLoader loadingMessage={loadingMessage} />}
      <DefaultContainer paddingTop paddingBottom>
        <DefaultHeader label="Criar Oferta Global" pageMessage={pageMessage}></DefaultHeader>
        <Form className="editForm" ref={formRef} onSubmit={handleSubmit}>
          <InputMask
            name="valor_de"
            disabled={disableFilters}
            label="Valor Inicial"
            placeholder="Ex.: 100"
            prefix="R$"
            value={valor_de}
            onChange={handleChangeValorDe}
            dataCy="valorInicial"
          />
          <InputMask
            name="valor_ate"
            disabled={disableFilters}
            label="Valor Final"
            placeholder="Ex.: 1000"
            prefix="R$"
            value={valor_ate}
            onChange={handleChangeValorAte}
            dataCy="valorFinal"
          />
          <DefaultTextTooltip>
            <Text size="medium" margin="20px 0px 0px 0px" color="gray500" weight="medium">
              Tempo de vencimento do Título
            </Text>
            <Tooltip
              isView={true}
              margin="22px 0px 0px 0px"
              iconFontSize="1.1rem"
              type="dark"
              backgroundColor={theme.colors.secondary}
              tooltipMessage="Ao selecionar uma das opções, apenas títulos que estão com o vencimento dentro desse prazo (em dias) serão contemplados na Oferta"
            />
          </DefaultTextTooltip>
          <ButtonRow>
            <Button
              onClick={() => handleTempoVencimento('90')}
              disabled={disableFilters}
              type="button"
              size="small"
              color={handleButtonColor('tempoVencimento', '90')}
            >
              {'< 90D'}
            </Button>
            <Button
              onClick={() => handleTempoVencimento('120')}
              disabled={disableFilters}
              type="button"
              size="small"
              color={handleButtonColor('tempoVencimento', '120')}
            >
              {'< 120D'}
            </Button>
            <Button
              onClick={() => handleTempoVencimento('180')}
              disabled={disableFilters}
              type="button"
              size="small"
              color={handleButtonColor('tempoVencimento', '180')}
            >
              {'< 180D'}
            </Button>
            <Button
              onClick={() => handleTempoVencimento('365')}
              disabled={disableFilters}
              type="button"
              size="small"
              color={handleButtonColor('tempoVencimento', '365')}
            >
              {'< 365D'}
            </Button>
            <Button
              onClick={() => handleTempoVencimento('Todos')}
              disabled={disableFilters}
              type="button"
              size="small"
              color={handleButtonColor('tempoVencimento', 'Todos')}
            >
              Todos
            </Button>
          </ButtonRow>
          <DefaultTextTooltip>
            <Text size="medium" margin="20px 0px 0px 0px" color="gray500" weight="medium">
              Tempo de permanência do Título na Plataforma
            </Text>
            <Tooltip
              isView={true}
              margin="22px 0px 0px 0px"
              iconFontSize="1.1rem"
              type="dark"
              backgroundColor={theme.colors.secondary}
              tooltipMessage="Ao selecionar uma das opções, apenas títulos que estão alocados na Plataforma dentro desse prazo (em dias) serão contemplados na Oferta"
            />
          </DefaultTextTooltip>
          <ButtonRow>
            <Button
              onClick={() => handleTempoPlataforma('30')}
              disabled={disableFilters}
              type="button"
              size="small"
              color={handleButtonColor('tempoPlataforma', '30')}
            >
              {'< 30D'}
            </Button>
            <Button
              onClick={() => handleTempoPlataforma('60')}
              disabled={disableFilters}
              type="button"
              size="small"
              color={handleButtonColor('tempoPlataforma', '60')}
            >
              {'< 60D'}
            </Button>
            <Button
              onClick={() => handleTempoPlataforma('90')}
              disabled={disableFilters}
              type="button"
              size="small"
              color={handleButtonColor('tempoPlataforma', '90')}
            >
              {'< 90D'}
            </Button>
            <Button
              onClick={() => handleTempoPlataforma('90+')}
              disabled={disableFilters}
              type="button"
              size="small"
              color={handleButtonColor('tempoPlataforma', '90+')}
            >
              90D+
            </Button>
            <Button
              onClick={() => handleTempoPlataforma('Todos')}
              disabled={disableFilters}
              type="button"
              size="small"
              color={handleButtonColor('tempoPlataforma', 'Todos')}
            >
              Todos
            </Button>
          </ButtonRow>

          {titulosAplicaveis !== 0 && (
            <>
              <ApplicableTitleContainer>
                <LayoutApplicableTitles>
                  <Text size="medium" color="primary" bold>
                    Títulos Aplicáveis
                  </Text>
                  <PaymentDiscount>
                    <DiscountImage>
                      <Text size="medium" color="white" bold>
                        {titulosAplicaveis}
                      </Text>
                    </DiscountImage>
                  </PaymentDiscount>
                </LayoutApplicableTitles>
                <Button type="button" onClick={limparBusca} size="default" color="secondary" width="180px" disabled={!!isLoading}>
                  Limpar Busca
                </Button>
              </ApplicableTitleContainer>

              {/* <InputMask
                disabled={disableDesconto}
                name="desconto"
                label="Desconto Valor"
                placeholder="Ex.: R$ 50"
                prefix="R$"
                value={desconto}
                onChange={handleChangeDesconto}
                dataCy="descontoValor"
              /> */}

              <Input
                disabled={disableDescontoPorcentagem}
                name="nome_oferta"
                type="string"
                placeholder="Ex.: Oferta Black Friday"
                label="Nome da Oferta"
                value={nome_oferta}
                onChange={handleChangeNomeOferta}
                full
                data-cy="nomeOferta"
                tooltip
                tooltipMessage="Campo não obrigatório."
                tooltipBackgroundColor={theme.colors.secondary}
              />

              <Input
                disabled={disableDescontoPorcentagem}
                name="desconto_porcentagem"
                mask={'99%'}
                type="string"
                placeholder="Ex.: 20%"
                label="Desconto Porcentagem (%)"
                value={desconto_porcentagem}
                onChange={handleChangeDescontoPorcentagem}
                full
                data-cy="descontoPorcentagem"
                tooltip
                tooltipMessage="É obrigatório preencher este campo. Caso não deseje dar desconto, preencher com o valor 0 (zero)."
                tooltipBackgroundColor={theme.colors.secondary}
              />

              {/*<Toggle
                fontWeight="500"
                onChange={handleChangeBoleto}
                label="Boleto"
                value={boleto}
                disabled
                paddingRight
                name="boleto"
                margin="20px 10px 20px 10px"
              />
              {boleto && (
                <>
                  <Select
                    name="parcela_boleto"
                    label="Parcelas Boleto"
                    color="gray400"
                    isBold
                    isDisabled
                    isView={false}
                    options={parcelaBoletoOptions}
                    id="parcelaBoleto"
                    customMargin="10px 0px 0 0"
                  />
                  <Input
                    name="Juros Boleto"
                    mask={'99%'}
                    type="string"
                    placeholder="Ex.: 2%"
                    value={juros_boleto}
                    onChange={handleChangeJurosBoleto}
                    label="Juros Boleto"
                    isView={false}
                    full
                    data-cy="jurosBoleto"
                  />
                </>
              )} */}

              {/*<Toggle
                fontWeight="500"
                onChange={handleChangeCartao}
                label="Cartão"
                value={cartao}
                paddingRight
                disabled
                name="cartao"
                margin="20px 10px 20px 10px"
              />
              {cartao && (
                <>
                  <Select
                    name="parcela_cartao"
                    label="Parcelas Cartão"
                    color="gray400"
                    isBold
                    isView={false}
                    options={parcelaCartaoOptions}
                    customMargin="10px 0px 0 0"
                  />

                  <Input
                    name="juros_cartao"
                    mask={'99%'}
                    type="string"
                    placeholder="Ex.: 2%"
                    label="Juros Cartão"
                    disabled
                    isView={false}
                    value={juros_cartao}
                    onChange={handleChangeJurosCartao}
                    full
                  />
                </>
              )}*/}

              <Input name="start_date" type="date" label="Data Inicial de Validade da Oferta" full data-cy="dataInicio" />
              <Input name="end_date" type="date" label="Data Final de Validade da Oferta" full data-cy="dataFim" />
              {/*{showApresentante && <Input name="apresentante" label="Apresentante" color="gray400" isBold data-cy="apresentante" />}
              <ApresentanteBtnContainer>
                <Button
                  type="button"
                  onClick={definirApresentante}
                  size="small"
                  color={showApresentante ? 'error' : 'secondary'}
                  disabled={!!isLoading}
                >
                  {showApresentante ? 'Cancelar' : 'Definir Apresentante'}
                </Button>
                <Tooltip
                  isView
                  margin="4px 0px 0px 8px"
                  iconFontSize="1.1rem"
                  type="dark"
                  backgroundColor={theme.colors.secondary}
                  tooltipMessage="Campo não obrigatório. Utilize essa opção apenas se desejar informar a sigla de Apresentante."
                />
              </ApresentanteBtnContainer>*/}
              {/* <Select name="protestado_entidade_uuid" label="Entidade" color="gray400" isBold options={entidadeOptions} /> */}

              {/*<Toggle
                fontWeight="500"
                onChange={handleChangeStatus}
                label="Status"
                disabled={true}
                value={status}
                paddingRight
                name="status"
                margin="20px 10px 20px 10px"
              />*/}
            </>
          )}

          <ContainerButton>
            <Button type="submit" size="big" full color="primary" loading={isLoading} disabled={!!isLoading} data-cy="submitButton">
              {titulosAplicaveis !== 0 ? 'Criar Oferta' : 'Buscar Títulos Aplicáveis'}
            </Button>
          </ContainerButton>
        </Form>
      </DefaultContainer>

      <ModalConfirm
        isOpen={showModal}
        onClose={() => history.push('/ofertas/listar-ofertas-globais')}
        handleButtonClick={() => history.push('/ofertas/listar-ofertas-globais')}
        label={`Oferta criada com sucesso! ID: ${ofertaGlobalId}`}
        buttonLabel="Ok"
      />
      <ModalError
        isOpen={showModalError}
        onClose={() => setShowModalError(false)}
        handleButtonClick={() => setShowModalError(false)}
        label="Erro na Solicitação, verifique os dados informados"
        buttonLabel="Ok"
      />
    </>
  );
};

export default CriarOfertaGlobal;
