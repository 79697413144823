import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 44%;

  // zoom 150% até 175%
  @media (min-width: 1024.99px) and (max-width: 1366px) {
    width: 100%;
  }

  // zoom 200%
  @media (min-width: 768.99px) and (max-width: 1024px) {
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`
