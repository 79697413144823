import { AiOutlineLock } from 'react-icons/ai';
import { BsPencil } from 'react-icons/bs';
import { FiLogOut } from 'react-icons/fi';

import styled from 'styled-components';

import theme from '@/assets/styles/theme';

interface dropdownProps {
  color?: string;
}

export const Container = styled.div`
  background-color: ${theme.colors.white};
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 9.9vh;
  right: 14vw;
  padding: 4px 0px 4px 0px;
  box-shadow: 0px 0px 10px #00000033;
  z-index: 1;
  border-radius: 4px;
  width: 215px;

  @media only screen and (min-width: 414.99px) and (max-width: 768px) {
    top: 12vh;
  }

  @media only screen and (min-width: 360px) {
    top: 9vh;
  }

  @media only screen and (max-width: 1600px) {
    top: 10.5vh;
    right: 14vw;
  }

  @media only screen and (max-width: 1440px) {
    top: 13vh;
    right: 9vh;
  }

  @media only screen and (max-width: 1200px) {
    top: 9vh;
    right: 0px;
    margin-right: 10px;
    width: 214px;
  }
`;

export const NavItem = styled.div<dropdownProps>`
  display: flex;
  flex-direction: row;
  align-items: left;
  color: ${(dropdownProps) => dropdownProps.color || theme.colors.gray[500]};
  /* font-size: 0.7em; */
  font-weight: bolder;
  padding: 15px 40px 15px 20px;
  font-size: 16px;

  @media only screen and (max-width: 768px) {
    font-size: 1rem;
  }

  &:hover {
    background-color: #f0f0f0;
  }
`;

export const IconPencil = styled(BsPencil)`
  margin-right: 5px;
`;

export const IconLock = styled(AiOutlineLock)`
  margin-right: 5px;
`;

export const IconLogout = styled(FiLogOut)`
  margin-right: 5px;
`;

export default Container;
