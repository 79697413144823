import flex from '@/assets/styles/flex';
import theme from '@/assets/styles/theme/';
import styled from 'styled-components';

export const Container = styled.div`
  ${flex.direction.column};
  ${flex.justify.spaceBetween}
  padding: 10px 40px;
  overflow-y: auto;
  margin-bottom: 50px;
  border-radius: 10px;
  background-color: ${theme.colors.white};
  @media only screen and (max-width: 1366px) {
    margin-bottom: 30px;
  }
  @media only screen and (max-width: 768px) {
    padding: 20px;
    margin-bottom: 20px;
  }
`;

export const ItemDetails = styled.div`
  ${flex.direction.column}
  ${flex.justifySpaceBetween.alignStart}
  margin: 10px 10px;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    margin: 0;
    height: 60px;
    margin-top: 30px;
  }
`;

export const ContainerButton = styled.div`
  /* margin: 10px 0 0 0; */
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SectionDiscount = styled.div`
  width: 25%;
  display: flex;
  align-content: center;
  justify-content: center;
`;

export const PaymentDiscount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DiscountImage = styled.div`
  ${flex.direction.row};
  ${flex.justifyCenter.alignCenter};

  position: relative;
  background-repeat: no-repeat;
  width: 100%;
  align-itens: center;
  height: 100%;
`;

export const DiscountImageIcon = styled.div`
  font-size: 32px;
  color: white;
`;

export const DiscountDetails = styled.div`
  ${flex.direction.column};
  ${flex.justifySpaceBetween.alignStart};

  height: 87px;
  margin: 0 0 0 15px;

  @media (max-width: 768px) {
    height: 64px;
  }
`;

export const ListContainer = styled.div``;
