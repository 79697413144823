import React, { useEffect, useState, useRef, useCallback } from 'react';
import { toast } from 'react-toastify';
import { FullScreenLoader } from '@/components/Ui/FullScreenLoader';
import Title from '@/components/Ui/Title';
import Button from '@/components/Ui/Button';
import Input from '@/components/Ui/Input';
import EmptyFeedback from '@/components/Helpers/EmptyFeedback';
import FeedbackImage from '@/assets/images/magnifier.png';
import { ContainerTop, FeedbackArea, ResultsArea } from '../components/EditContainer/styles';
import { getCorporationsRequestFiltrar } from './providers/getCorpRequestFiltrar';
import { handleListEntidade } from './utils/handleListEntidade';
import { Entidades } from './interfaces/interface';
//pagination
import DefaultPagination from '@/components/Ui/DefaultPagination';
import DataList from './components/paginate/DataList';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import {
  DefaultFilterButton,
  DefaultFilterButtonContainer,
  DefaultFilterCheckboxArea,
  DefaultFilterContainer,
  DefaultFilterField,
  DefaultFilterInputs,
  DefaultFilterRow,
  DefaultTopContainer
} from '@/components/Ui/DefaultFilterContainer/styles';
import { DefaultContainerPages, DefaultListPageContainer } from '@/components/Ui/DefaultHeader/style';
import { useHistory } from 'react-router-dom';
import Toggle from '@/components/Ui/Toggle';
import GoBack from '@/components/Ui/GoBack';

const ListarEntidades: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Entidades[]>([]);
  const [displayedData, setDisplayedData] = useState<Entidades[]>([]);

  const [is_active, setIsActive] = useState(true);
  const handleChangeActive = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsActive(e.target.checked);
  };

  const itemsPerPage = 10;
  const allPages = Math.ceil(data.length / itemsPerPage);

  const onPageChange = (page: number = 1) => {
    const startItem = (page - 1) * itemsPerPage;
    const endItem = page * itemsPerPage;
    setDisplayedData(data.slice(startItem, endItem));
  };

  const clearFilter = useCallback((fieldValue: string) => {
    formRef.current?.clearField(fieldValue);
  }, []);

  const clearAllFilters = useCallback(() => {
    formRef.current?.setFieldValue('id', '');
    formRef.current?.setFieldValue('name', '');
    formRef.current?.setFieldValue('email', '');
    setIsActive(true);
  }, []);

  const handleSubmit = async (fields: Entidades) => {
    try {
      setIsLoading(true);

      const params = {
        name: fields.name,
        id: fields?.id,
        email: fields?.email,
        created_at: fields?.created_at,
        is_active: is_active
      };

      const entidadeList = await getCorporationsRequestFiltrar(params);

      if (entidadeList?.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const entidade: any[] = handleListEntidade(entidadeList);
        setData(entidade);
      } else {
        toast.warning('Não foram encontradas Entidades para este Filtro!');
        setData([]);
      }
    } catch (error) {
      toast.error('Não foi possível listar as Entidades!');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onPageChange();
  }, [data]);

  return (
    <>
      {isLoading && <FullScreenLoader loadingMessage="Carregando Entidades..." />}
      <DefaultContainerPages paddingTop paddingBottom>
        <DefaultTopContainer>
          <GoBack />
          <ContainerTop>
            <Button color="secondary" onClick={() => history.push('/entidades/criar-entidade')}>
              Criar Entidade
            </Button>
          </ContainerTop>
        </DefaultTopContainer>
        <Title label="Listar Entidades" isVisiblePixel />
        <DefaultListPageContainer>
          <DefaultFilterContainer>
            <Form className="editForm" ref={formRef} onSubmit={handleSubmit}>
              <DefaultFilterInputs>
                <DefaultFilterField>
                  <Input
                    width="100%"
                    name="id"
                    type="string"
                    placeholder="Ex.: 66f55250-1604-4b72-ba38-9d91d832f39e"
                    label="ID da Empresa"
                    padding="8px 8px 8px 6px"
                    iconSize="1.2rem"
                    isClearable={true}
                    clearFunction={() => clearFilter('id')}
                    data-cy="entidadeId"
                  />
                </DefaultFilterField>
                <DefaultFilterField>
                  <Input
                    width="100%"
                    name="name"
                    type="string"
                    placeholder="Ex.: Protestado Ltda"
                    label="Nome da Empresa"
                    padding="8px 8px 8px 6px"
                    iconSize="1.2rem"
                    isClearable={true}
                    clearFunction={() => clearFilter('name')}
                  />
                </DefaultFilterField>
                <DefaultFilterField>
                  <Input
                    width="100%"
                    name="email"
                    type="string"
                    placeholder="Ex.: exemplo@email.com"
                    label="E-mail"
                    padding="8px 8px 8px 6px"
                    iconSize="1.2rem"
                    isClearable={true}
                    clearFunction={() => clearFilter('email')}
                  />
                </DefaultFilterField>
                <DefaultFilterCheckboxArea>
                  <Toggle onChange={handleChangeActive} label="Ativa" value={is_active} name="is_active" paddingRight />
                </DefaultFilterCheckboxArea>
              </DefaultFilterInputs>
              <DefaultFilterRow padding="0px 10px 0px 10px">
                <DefaultFilterButtonContainer>
                  <DefaultFilterButton>
                    <Button type="button" onClick={clearAllFilters} size="big" color="warning" width="100%" disabled={!!isLoading}>
                      Limpar Filtros
                    </Button>
                  </DefaultFilterButton>
                </DefaultFilterButtonContainer>
                <DefaultFilterButtonContainer>
                  <DefaultFilterButton>
                    <Button
                      type="submit"
                      minWidth="180px"
                      size="big"
                      color="primary"
                      width="100%"
                      loading={isLoading}
                      disabled={!!isLoading}
                      data-cy="filtrarEntidades"
                    >
                      Filtrar
                    </Button>
                  </DefaultFilterButton>
                </DefaultFilterButtonContainer>
              </DefaultFilterRow>
            </Form>
          </DefaultFilterContainer>
          {data?.length === 0 ? (
            <FeedbackArea>
              <EmptyFeedback
                imagePath={FeedbackImage}
                title="Clique em Filtrar para buscar Resultados"
                message="Utilize os filtros acima para encontrar as Entidades/Empresas cadastradas"
              />
            </FeedbackArea>
          ) : (
            <ResultsArea>
              <DataList posts={displayedData} />
              <GoBack />
              <DefaultPagination allPagesNumber={allPages} itemsPerPage={10} itemsNumber={data.length} pageChange={onPageChange} />
            </ResultsArea>
          )}
        </DefaultListPageContainer>
      </DefaultContainerPages>
    </>
  );
};

export default ListarEntidades;
