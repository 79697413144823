import Button from '@/components/Ui/Button';
import { DefaultContainerPageTitle, DefaultPageContainer } from '@/components/Ui/DefaultPageContainer/styles';
import { FullScreenLoader } from '@/components/Ui/FullScreenLoader';
import IconController from '@/components/Ui/IconController';
import Input from '@/components/Ui/Input';
import Select from '@/components/Ui/Select';
import { SelectOptions } from '@/components/Ui/Select/interfaces/SelectOptions';
import Text from '@/components/Ui/Text';
import { getErrorMessage, getFirstDayOfMonth, getLastDayOfMonth } from '@/utils/Utilities';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useRef, useCallback, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import AccountBalanceArea from './components/AccountBalanceArea';
import { AccountDetail } from './interfaces/AccountDetail';
import { BalanceTransferPayload } from './interfaces/BalanceTransferPayload';
import { CorporationBankAccount } from './interfaces/CorporationBankAccount';
import { balanceTransfer } from './providers/balanceTransfer';
import { getAccountBalance } from './providers/getAccountBalance';
import { getAccountDetails } from './providers/getAccountDetails';
import { getAccountStatement } from './providers/getAccountStatement';
import { getNonBMPAccounts } from './providers/getNonBMPAccounts';
import { ButtonContainer, InputRow, SessionText } from './styles';
import * as Yup from 'yup';
import InputMask from '@/components/Ui/InputMask';
import { useSelector } from 'react-redux';
import { ISignInProps } from '@/modules/_layouts/components/HeaderCompany/components/UserSetting';
import AccountStatementTable from './components/AccountStatementTable';
import { AccountStatementBMP } from './interfaces/AccountStatementBMP';
import { Box, useDisclosure } from '@chakra-ui/react';
import BalanceTransferModal from './components/BalanceTransferModal';
import Monthpicker from '@/components/Ui/Monthpicker';
import GoBack from '@/components/Ui/GoBack';
import DefaultPagination from '@/components/Ui/DefaultPagination';
import EmptyFeedback from '@/components/Helpers/EmptyFeedback';
import FeedbackImage from '@/assets/images/magnifier.png';
import theme from '@/assets/styles/theme';

interface ContaBMPForm extends AccountDetail {}

export interface PayloadExtratoBMP extends AccountDetail {
  ano: string;
  dia_inicial: string;
  dia_final: string;
  mes: string;
}

export interface BalanceTransferConfirmation {
  bank_number: string;
  bank_name: string;
  bank_branch: string;
  bank_branch_digit: string;
  account: string;
  account_digit: string;
  transfer_value: number;
  payee_name: string;
  payee_legal_document: string;
}

const PainelFinanceiro: React.FC = () => {
  const accessToken = useSelector((state: ISignInProps) => state.SignIn.access_token);
  const formRef = useRef<FormHandles>(null);
  const extratoForm = useRef<FormHandles>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [optionsContasNaoBmp, setOptionsContasNaoBmp] = useState<SelectOptions[]>([]);
  const [nonBmpAccounts, setNonBmpAccounts] = useState<CorporationBankAccount[]>([]);
  const [bmpAccount, setBmpAccount] = useState<AccountDetail | null>(null);
  const [nonBmpAccount, setNonBmpAccount] = useState<CorporationBankAccount | null>(null);
  const [nonBmpDocumentMask, setNonBmpDocumentMask] = useState('999.999.999-99');
  const [loadingMessage, setLoadingMessage] = useState('');
  const [isAccountSelected, setIsAccountSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [accountBalance, setAccountBalance] = useState(0);
  const [valorTransferencia, setValorTransferencia] = useState('');

  const [accountStatementItems, setAccountStatementItems] = useState<AccountStatementBMP[]>([]);
  const [displayedAccountStatementItems, setDisplayedAccountStatementItems] = useState<AccountStatementBMP[]>([]);
  const itemsPerPage = 15;
  const allPages = Math.ceil(accountStatementItems.length / itemsPerPage);
  const onPageChange = (page: number = 1) => {
    const startItem = (page - 1) * itemsPerPage;
    const endItem = page * itemsPerPage;
    setDisplayedAccountStatementItems(accountStatementItems.slice(startItem, endItem));
  };

  const [balanceTransferConfirmation, setBalanceTransferConfirmation] = useState<BalanceTransferConfirmation | null>(null);
  const handleChangeValorTransferencia = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValorTransferencia(e.target.value);
  };

  const withdrawBalanceConditions = useCallback(() => {
    if (!isAccountSelected) {
      toast.error('Selecione a conta que irá receber a transferência para prosseguir');
      return false;
    }
    if (!bmpAccount) {
      toast.error('Não é possível transferir valores pois não identificamos uma conta BMP associada à entidade.');
      return false;
    }
    if (!nonBmpAccount?.payee_name || nonBmpAccount?.payee_name === '') {
      toast.error(
        'Não é possível transferir valores pois não identificamos o nome do favorecido da conta selecionada. Favor, contate o suporte para regularizar o cadastro.'
      );
      return false;
    }
    if (!nonBmpAccount?.payee_legal_document || nonBmpAccount?.payee_legal_document === '') {
      toast.error(
        'Não é possível transferir valores pois não identificamos o documento do favorecido da conta selecionada. Favor, contate o suporte para regularizar o cadastro.'
      );
      return false;
    }
    return true;
  }, [isAccountSelected, bmpAccount, nonBmpAccount]);

  const askWithdrawBalance = () => {
    if (withdrawBalanceConditions()) {
      const valorTransferenciaFormatado = valorTransferencia.replace(/\./g, '').replace(/,/g, '.').replace('R$', '');

      const data = {
        bank_number: nonBmpAccount!.bank_number,
        bank_name: nonBmpAccount!.bank_name,
        bank_branch: nonBmpAccount!.bank_branch,
        bank_branch_digit: nonBmpAccount!.bank_branch_digit,
        account: nonBmpAccount!.account,
        account_digit: nonBmpAccount!.account_digit,
        transfer_value: Number(valorTransferenciaFormatado),
        payee_name: nonBmpAccount!.payee_name,
        payee_legal_document: nonBmpAccount!.payee_legal_document
      } as BalanceTransferConfirmation;
      setBalanceTransferConfirmation(data);
      onOpen();
    }
  };

  const callResyncAccountBalance = useCallback(async () => {
    await resyncAccountBalance({
      agencia: bmpAccount!.agencia,
      agencia_digito: bmpAccount!.agencia_digito,
      conta: bmpAccount!.conta,
      conta_digito: bmpAccount!.conta_digito,
      numero_banco: bmpAccount!.numero_banco
    } as ContaBMPForm);
  }, [bmpAccount]);

  const withdrawBalance = useCallback(async () => {
    try {
      setIsLoading(true);
      setLoadingMessage('Solicitando transferência...');
      const contaPagamento = `${bmpAccount!.conta}${bmpAccount!.conta_digito}`;
      const favorecidoContaPagamento = `${nonBmpAccount!.account}${nonBmpAccount!.account_digit}`;
      const valorTransferenciaFormatado = valorTransferencia.replace(/\./g, '').replace(/,/g, '.').replace('R$', '');

      const payload = {
        bancoOrigemId: bmpAccount!.id,
        agencia: bmpAccount!.agencia,
        agenciaDigito: bmpAccount!.agencia_digito,
        conta: bmpAccount!.conta,
        contaDigito: bmpAccount!.conta_digito,
        contaPagamento,
        tipoConta: Number(bmpAccount!.tipo_conta),
        bancoDestinoId: nonBmpAccount!.id,
        documentoFederal: nonBmpAccount!.payee_legal_document,
        nome: nonBmpAccount!.payee_name,
        numeroBanco: nonBmpAccount!.bank_number,
        favorecidoAgencia: nonBmpAccount!.bank_branch,
        favorecidoAgenciaDigito: nonBmpAccount!.bank_branch_digit,
        favorecidoConta: nonBmpAccount!.account,
        favorecidoContaDigito: nonBmpAccount!.account_digit,
        favorecidoContaPagamento,
        favorecidoTipoConta: Number(nonBmpAccount!.account_type),
        vlrTransacao: Number(valorTransferenciaFormatado),
        finlddIF: 1,
        tipoProcessamento: 1,
        codOperacaoCli: '',
        descCliente: ''
      } as BalanceTransferPayload;

      const retornoSolicitacao = await balanceTransfer(payload);
      if (retornoSolicitacao && retornoSolicitacao.sucesso) {
        await callResyncAccountBalance();
        toast.success('Transferência realizada com sucesso!');
      } else {
        toast.error('Não foi possível realizar sua transferência, tente mais tarde!');
      }
    } catch (error) {
      const errorMsg = getErrorMessage(error, 'Não foi possível realizar sua transferência, tente mais tarde!');
      toast.error(errorMsg);
    } finally {
      setIsLoading(false);
    }
  }, [accountBalance, isAccountSelected, bmpAccount, valorTransferencia]);

  const resyncAccountBalance = useCallback(
    async (fields: ContaBMPForm) => {
      try {
        setIsLoading(true);
        setLoadingMessage('Ressincronizando saldo...');
        const saldoConta = await getAccountBalance(fields);
        if (saldoConta) {
          setAccountBalance(saldoConta.vlrSaldo);

          const valorSaldoFormatado = String(saldoConta.vlrSaldo).replace(/\./g, ',');
          setValorTransferencia(`R$${valorSaldoFormatado}`);
        }
      } catch (error) {
        toast.error('Não foi possível carregar seu dados, tente mais tarde!');
      } finally {
        setIsLoading(false);
      }
    },
    [accountBalance]
  );

  const checkAccountStatement = useCallback(async () => {
    if (bmpAccount) {
      try {
        setIsLoading(true);
        setLoadingMessage('Consultando Extrato...');
        const month = selectedDate?.getMonth();
        const year = selectedDate?.getFullYear();

        if (month != null && year != null) {
          const firstDay = getFirstDayOfMonth(year, month);
          const lastDay = getLastDayOfMonth(year, month);
          const payload = {
            ano: String(year),
            dia_inicial: String(firstDay),
            dia_final: String(lastDay),
            mes: String(month + 1),
            agencia: bmpAccount.agencia,
            agencia_digito: bmpAccount.agencia_digito,
            conta: bmpAccount.conta,
            conta_digito: bmpAccount.conta_digito,
            tipo_conta: bmpAccount.tipo_conta,
            numero_banco: bmpAccount.numero_banco
          } as PayloadExtratoBMP;

          const accountStatement = await getAccountStatement(payload);
          if (accountStatement) {
            setAccountStatementItems(accountStatement);
            if (!accountStatement.length) toast.warning('Não encontramos extrato para o mês selecionado.');
          }
        } else {
          toast.error('É preciso selecionar uma data (mês/ano) para filtrar o extrato bancário.');
        }
      } catch (err) {
        const validationErrors: { [key: string]: string } = {};

        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: Yup.ValidationError) => {
            validationErrors[error.path] = error.message;
          });

          extratoForm.current?.setErrors(validationErrors);
        }

        const errorMsg = getErrorMessage(err, 'Não foi possível consultar o extrato, tente novamente mais tarde!');
        toast.error(errorMsg);
      } finally {
        setIsLoading(false);
      }
    } else {
      toast.error('Não é possível consultar o extrato pois não identificamos uma conta BMP associada à entidade.');
    }
  }, [bmpAccount, accountStatementItems, selectedDate]);

  const setarContaNaoBMP = (selection: any) => {
    setIsAccountSelected(true);
    const contaSelecionada = nonBmpAccounts.find((conta) => conta.id === selection.value);

    if (contaSelecionada) {
      setNonBmpAccount(contaSelecionada);
      formRef.current?.setData({
        numero_banco_transfer: contaSelecionada.bank_number,
        nome_banco_transfer: contaSelecionada.bank_name,
        agencia_transfer: contaSelecionada.bank_branch,
        agencia_digito_transfer: contaSelecionada.bank_branch_digit,
        conta_transfer: contaSelecionada.account,
        conta_digito_transfer: contaSelecionada.account_digit,
        nome_favorecido_transfer: contaSelecionada.payee_name,
        documento_favorecido_transfer: contaSelecionada.payee_legal_document
      });

      const mask = contaSelecionada.payee_legal_document?.length > 11 ? '99.999.999/9999-99' : '999.999.999-99';
      setNonBmpDocumentMask(mask);
    }
  };

  const getDadosSaldoContaBMP = useCallback(async () => {
    const contaBMP = await getAccountDetails();

    if (contaBMP) {
      setBmpAccount(contaBMP);
      formRef.current?.setData({
        numero_banco: contaBMP.numero_banco,
        nome_banco: contaBMP.nome_banco,
        agencia: contaBMP.agencia,
        agencia_digito: contaBMP.agencia_digito,
        conta: contaBMP.conta,
        conta_digito: contaBMP.conta_digito
      });

      setLoadingMessage('Consultando saldo...');
      const saldoConta = await getAccountBalance(contaBMP);
      if (saldoConta) {
        setAccountBalance(saldoConta.vlrSaldo);

        const valorSaldoFormatado = String(saldoConta.vlrSaldo).replace(/\./g, ',');
        setValorTransferencia(`R$${valorSaldoFormatado}`);
      }
    } else {
      toast.error('Não foi possível carregar seu dados, tente mais tarde!');
    }
  }, [isMounted]);

  const getDadosContasNaoBMP = useCallback(async () => {
    setLoadingMessage('Buscando contas cadastradas...');
    const contasNaoBMP = await getNonBMPAccounts();
    if (contasNaoBMP && contasNaoBMP.length > 0) {
      const optionsContasNaoBmp = [] as SelectOptions[];
      contasNaoBMP.forEach((conta) => {
        optionsContasNaoBmp.push({ value: conta.id, label: conta.bank_name });
      });
      setNonBmpAccounts(contasNaoBMP);
      setOptionsContasNaoBmp(optionsContasNaoBmp);
    }
  }, [isMounted]);

  const getDadosBancarios = useCallback(async () => {
    try {
      setIsLoading(true);
      setLoadingMessage('Carregando Painel Financeiro...');

      await getDadosSaldoContaBMP();
      await getDadosContasNaoBMP();
      setIsMounted(true);
    } catch (error) {
      const errorMsg = getErrorMessage(error, 'Não foi possível carregar os dados de Contas.');
      toast.error(errorMsg);
    } finally {
      setIsLoading(false);
    }
  }, [isMounted]);

  useEffect(() => {
    if (!isMounted) {
      getDadosBancarios();
    }
  }, [isMounted, accessToken]);

  useEffect(() => {
    onPageChange();
  }, [accountStatementItems]);

  return (
    <>
      {isLoading && <FullScreenLoader loadingMessage={loadingMessage} />}
      <DefaultPageContainer className="Container" paddingTop paddingBottom>
        <DefaultContainerPageTitle className="containerTitle">
          <label className="label">Painel Financeiro</label>
          <label className="labelDescricao">Acompanhe os dados de sua Conta Corrente em tempo real</label>
        </DefaultContainerPageTitle>
        <Form className="editForm" ref={formRef} onSubmit={askWithdrawBalance}>
          <SessionText>
            <Text size="medium" margin="0px 0px 0px 0px" color="primary" weight="bold">
              Conta BMP
            </Text>
          </SessionText>
          <InputRow>
            <Input disabled width="100%" name="numero_banco" type="string" placeholder="Ex.: 274" label="Número do Banco" />
            <Input disabled width="100%" name="nome_banco" type="string" placeholder="Ex.: 274" label="Banco" />
            <div></div>
            <ButtonContainer>
              <Button type="button" onClick={callResyncAccountBalance} size="custom2" full color="secondary">
                <IconController margin="0px 8px -5px 0px" icon="sync" fontSize="1.25em" iconColor="white" />
                Ressincronizar
              </Button>
            </ButtonContainer>
          </InputRow>
          <InputRow>
            <Input disabled width="100%" name="agencia" type="string" placeholder="Ex.: 0001" label="Agência" />
            <Input disabled width="100%" name="agencia_digito" type="string" placeholder="Ex.: 8" label="Agência Dígito" />
            <Input disabled width="100%" name="conta" type="string" placeholder="Ex.: 0120922" label="Conta" />
            <Input disabled width="100%" name="conta_digito" type="string" placeholder="Ex.: 0" label="Conta Dígito" />
          </InputRow>
          <SessionText>
            <Text size="medium" margin="24px 0px 0px 0px" color="primary" weight="bold">
              Contas Ativas - Transferência
            </Text>
          </SessionText>
          <InputRow margin="10px 0px 0px 0px">
            <Select
              name="conta_bancaria"
              isView
              label="Conta Selecionada"
              color="gray400"
              options={optionsContasNaoBmp}
              id="contaSelecionada"
              customMargin="0 0 0 0"
              onChange={(e) => setarContaNaoBMP(e)}
            />
            <InputMask
              name="valorTransferencia"
              disabled={!isAccountSelected}
              label="Valor da Transferência"
              placeholder="Ex.: 100"
              prefix="R$"
              value={valorTransferencia}
              customMarginLabel="0px 0px 0px 0px"
              onChange={handleChangeValorTransferencia}
              dataCy="valorInicial"
              tooltip
              tooltipMessage="Digite o valor que deseja transferir"
              tooltipBackgroundColor={theme.colors.secondary}
            />
          </InputRow>
          <InputRow>
            <Input
              isView={isAccountSelected}
              disabled
              width="100%"
              name="numero_banco_transfer"
              type="string"
              placeholder="Ex.: 274"
              label="Número do Banco"
            />
            <Input
              isView={isAccountSelected}
              disabled
              width="100%"
              name="nome_banco_transfer"
              type="string"
              placeholder="Ex.: 274"
              label="Banco"
            />
            <Input
              isView={isAccountSelected}
              disabled
              width="100%"
              name="nome_favorecido_transfer"
              type="string"
              placeholder="Ex.: João Pedro"
              label="Nome Favorecido"
            />
            <Input
              isView={isAccountSelected}
              disabled
              value={nonBmpAccount?.payee_legal_document}
              width="100%"
              name="documento_favorecido_transfer"
              type="string"
              mask={nonBmpDocumentMask}
              placeholder="Ex.: 043.105.100-35"
              label="Documento Favorecido"
            />
          </InputRow>
          <InputRow>
            <Input
              isView={isAccountSelected}
              disabled
              width="100%"
              name="agencia_transfer"
              type="string"
              placeholder="Ex.: 0001"
              label="Agência"
            />
            <Input
              isView={isAccountSelected}
              disabled
              width="100%"
              name="agencia_digito_transfer"
              type="string"
              placeholder="Ex.: 8"
              label="Agência Dígito"
            />
            <Input
              isView={isAccountSelected}
              disabled
              width="100%"
              name="conta_transfer"
              type="string"
              placeholder="Ex.: 0120922"
              label="Conta"
            />
            <Input
              isView={isAccountSelected}
              disabled
              width="100%"
              name="conta_digito_transfer"
              type="string"
              placeholder="Ex.: 0"
              label="Conta Dígito"
            />
          </InputRow>
        </Form>
        <AccountBalanceArea accountBalance={accountBalance} withdrawBalance={askWithdrawBalance}></AccountBalanceArea>
        <SessionText>
          <Text size="medium" margin="24px 0px 0px 0px" color="primary" weight="bold">
            Extrato Bancário
          </Text>
        </SessionText>
        <Form className="extratoForm" ref={extratoForm} onSubmit={checkAccountStatement}>
          <InputRow>
            <Monthpicker
              tooltip={true}
              tooltipMessage="O filtro irá utilizar o primeiro e último dia do mês selecionado como parâmetros"
              tooltipBackgroundColor={theme.colors.secondary}
              label="Mês/Ano do Filtro"
              selectedDate={selectedDate}
              onChange={setSelectedDate}
              limitDate
            ></Monthpicker>
            <ButtonContainer justifyContent="flex-start">
              <Button size="custom2" color="secondary">
                <IconController margin="0px 8px -5px 0px" icon="filter" fontSize="1.25em" iconColor="white" />
                Filtrar
              </Button>
            </ButtonContainer>
          </InputRow>
        </Form>
        {accountStatementItems.length > 0 ? (
          <>
            <AccountStatementTable statementItems={displayedAccountStatementItems}></AccountStatementTable>
            <Box mt="24px" mx="48px">
              <GoBack />
              <DefaultPagination
                allPagesNumber={allPages}
                itemsPerPage={itemsPerPage}
                itemsNumber={accountStatementItems.length}
                pageChange={onPageChange}
              />
            </Box>
          </>
        ) : (
          <EmptyFeedback
            paddingTop="24px"
            imagePath={FeedbackImage}
            title="Clique em Filtrar para buscar Resultados"
            message="
              Utilize o filtro de Mês/Ano para buscar o extrato da sua conta bancária"
          />
        )}
        <BalanceTransferModal
          modalTitle="Confirmar Transferência"
          balanceTransferConfirmation={balanceTransferConfirmation}
          isOpen={isOpen}
          onClose={onClose}
          onConfirm={withdrawBalance}
        ></BalanceTransferModal>
      </DefaultPageContainer>
    </>
  );
};

export default PainelFinanceiro;
