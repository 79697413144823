import api from '@/services/api';

export const cancelarOferta = async (params: any) => {
  const { data } = await api.delete(`/users/protested-titles/corporation/ofertas-globais`, {
    params: {
      ...(params.id ? { id: params.id } : {})
    }
  });
  return data;
};
