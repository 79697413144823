import theme from '@/assets/styles/theme';
import styled from 'styled-components';

interface HeaderDropdownProps {
  hideMenu?: boolean;
  disabled?: boolean;
  color?: boolean;
}

export const DropdownMenu = styled.ul<HeaderDropdownProps>`
  width: 20rem;
  /* top: 11.801896733403582vh; */
  position: absolute;
  list-style: none;
  display: grid;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  top: 0;
  padding-top: 11.801896733403582vh;
  z-index: 1;

  display: ${(HeaderDropdownProps) => (HeaderDropdownProps.hideMenu ? 'none' : '')};

  @media only screen and (max-width: 1440px) {
    padding-top: 10vh;
    /* top: 15vh; */
  }

  @media only screen and (max-width: 1200px) {
    padding-top: 10vh;
    width: 12rem;
    /* top: 10vh; */
  }

  li {
    display: flex;
    align-items: center;
    background: ${({ color }) => (color ? `${theme.colors.primaryCompany}e0` : `${theme.colors.headerFooterColor}e0`)};
    cursor: pointer;
  }

  li:last-child {
    border-radius: 0px 0px 6px 6px;
  }
`;

export const DropdownList = styled.li<HeaderDropdownProps>`
  display: block;
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  display: flex;
  align-self: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.5s;

  @media only screen and (max-width: 1200px) {
    font-size: 0.9rem;
    padding: 6px 12px;
  }

  cursor: ${(props) => (props.disabled ? 'not-allowed !important' : '')};
  color: ${(props) => (props.disabled ? theme.colors.gray[300] : theme.colors.headerFooterFontColor)};

  &:hover {
    color: ${(props) => (!props.disabled ? theme.colors.secondary : '')};
  }
`;
