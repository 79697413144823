import Button from '@/components/Ui/Button';
import IconController from '@/components/Ui/IconController';
import Text from '@/components/Ui/Text';
import DescriptionContainer from '@/modules/Deal/components/DescriptionContainer';
import { formatarDinheiro } from '@/utils/Utilities';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { TituloDetail } from '../../interfaces/interfaceRelatorio';
import { Container, ItemDetails, PaymentContainer } from './styles';

interface IProps {
  posts: TituloDetail[];
}

const DataList: React.FC<IProps> = ({ posts }) => {
  const history = useHistory();
  return (
    <div className="list__container">
      <div className="list_data">
        {posts.map((item: TituloDetail) => {
          return (
            <Container className="Container" key={item.id}>
              <DescriptionContainer>
                <ItemDetails width="15%">
                  <Text margin="12px 0px 0px 0px" size="medium" bold>
                    Duração da Oferta
                  </Text>
                  <Text margin="12px 0px 0px 0px" size="small">
                    Início: {moment(item.data_inicio).format('DD/MM/YYYY')}
                  </Text>
                  <Text margin="12px 0px 0px 0px" size="small">
                    Encerramento: {moment(item.data_fim).format('DD/MM/YYYY')}
                  </Text>
                </ItemDetails>
                <ItemDetails width="15%">
                  <PaymentContainer>
                    <Text size="medium" bold margin="12px 0px 0px 0px">
                      {item.status == true ? 'Oferta Ativa' : 'Oferta Inativa'}
                    </Text>
                    {item.status == true ? (
                      <IconController
                        paddingLeft={'8px'}
                        margin="12px 0px 0px 0px"
                        icon="checkCircle"
                        fontSize="1.25em"
                        iconColor="green"
                      />
                    ) : (
                      <IconController paddingLeft={'8px'} margin="12px 0px 0px 0px" icon="closeCircle" fontSize="1.25em" iconColor="red" />
                    )}
                  </PaymentContainer>
                  {/* <PaymentContainer>
                    <Text size="medium" margin="12px 0px 0px 0px" bold>
                      Boleto:
                    </Text>
                    {item.boleto == true ? (
                      <IconController
                        paddingLeft={'8px'}
                        margin="12px 0px 0px 0px"
                        icon="checkCircle"
                        fontSize="1.25em"
                        iconColor="green"
                      />
                    ) : (
                      <IconController paddingLeft={'8px'} margin="12px 0px 0px 0px" icon="closeCircle" fontSize="1.25em" iconColor="red" />
                    )}
                  </PaymentContainer> */}
                </ItemDetails>
                <ItemDetails width="15%">
                  <Text size="medium" margin="12px 0px 0px 0px" bold>
                    Desconto:
                  </Text>
                  <Text margin="12px 0px 0px 0px" size="small">
                    Porcentagem (%):{item.desconto_porcentagem ? item.desconto_porcentagem : 'n/d'}
                  </Text>
                </ItemDetails>
                <ItemDetails width="15%">
                  <Text size="medium" margin="12px 0px 0px 0px" bold>
                    Valor:
                  </Text>
                  <Text margin="12px 0px 0px 0px" size="small">
                    Valor Inicial: {item.valor_de ? formatarDinheiro(Number(item.valor_de)) : 'R$ 0,00'}
                  </Text>
                  <Text margin="12px 0px 0px 0px" size="small">
                    Valor Final: {item.valor_ate ? formatarDinheiro(Number(item.valor_ate)) : 'R$ 0,00'}
                  </Text>
                </ItemDetails>
                <ItemDetails width="15%">
                  <Button full onClick={() => history.push(`/ofertas/listar-ofertas-globais/${item.id}`)}>
                    Detalhes
                  </Button>
                </ItemDetails>
              </DescriptionContainer>
            </Container>
          );
        })}
      </div>
    </div>
  );
};

export default DataList;
