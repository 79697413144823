import styled from 'styled-components'

import theme from '@/assets/styles/theme'

interface IButtonProps {
  fontSize?: string
}

export const Button = styled.a<IButtonProps>`
  color: ${theme.colors.primary};
  align-self: center;
  transition: all ease-in-out 0.1s;
  background-color: transparent;
  border-width: 0;
  text-decoration: underline;
  font-size: ${props => (props.fontSize ? props.fontSize : '20px')};
  font-weight: 500;
  margin-left: 10px;
  color: ${theme.colors.secondary};
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`
