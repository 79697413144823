import FeedbackImage from '@/assets/images/magnifier.png';
import EmptyFeedback from '@/components/Helpers/EmptyFeedback';
import Button from '@/components/Ui/Button';
import { FullScreenLoader } from '@/components/Ui/FullScreenLoader';
import Input from '@/components/Ui/Input';
import Title from '@/components/Ui/Title';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { ContainerTop, FeedbackArea, ResultsArea } from '../components/EditContainer/styles';
import { OfertaGlobal } from './interfaces/interface';
//pagination
import theme from '@/assets/styles/theme';
import { IUserCompanyProps } from '@/components/Interfaces/UserCompanyProps';
import {
  DefaultFilterButton,
  DefaultFilterButtonContainer,
  DefaultFilterCheckboxArea,
  DefaultFilterContainer,
  DefaultFilterField,
  DefaultFilterInputs,
  DefaultFilterRow,
  DefaultTopContainer
} from '@/components/Ui/DefaultFilterContainer/styles';
import { DefaultContainerPages, DefaultListPageContainer } from '@/components/Ui/DefaultHeader/style';
import DefaultPagination from '@/components/Ui/DefaultPagination';
import GoBack from '@/components/Ui/GoBack';
import Toggle from '@/components/Ui/Toggle';
import { ISignInProps } from '@/modules/_layouts/components/HeaderCompany/components/UserSetting';
import jwtDecode from 'jwt-decode';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DataList from './components/ItemList/DataList';
import { listaOfertasGlobaisCtrl } from './controllers/listaOfertasGlobaisCtrl';

const ListarOfertasGlobais: React.FC = () => {
  const history = useHistory();
  const [data, setData] = useState<OfertaGlobal[]>([]);
  const [displayedData, setDisplayedData] = useState<OfertaGlobal[]>([]);
  const itemsPerPage = 10;
  const allPages = Math.ceil(data.length / itemsPerPage);
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = useSelector((state: ISignInProps) => state.SignIn.access_token);
  const [status, setIsDeleted] = useState(true);
  const [entidadeId, setEntidadeId] = useState('');
  const handleChangeDel = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsDeleted(e.target.checked);
  };

  const formRef = useRef<FormHandles>(null);

  const clearFilter = useCallback((fieldValue: string) => {
    formRef.current?.clearField(fieldValue);
  }, []);

  const clearAllFilters = useCallback(() => {
    formRef.current?.setFieldValue('id', '');
    formRef.current?.setFieldValue('valor_de', '');
    formRef.current?.setFieldValue('valor_ate', '');
    setIsDeleted(true);
  }, []);

  const onPageChange = (page = 1) => {
    const startItem = (page - 1) * itemsPerPage;
    const endItem = page * itemsPerPage;
    setDisplayedData(data.slice(startItem, endItem));
  };

  const getOffers = async (filtros: any) => {
    const listaOfertasGlobais = await listaOfertasGlobaisCtrl(filtros);
    if (listaOfertasGlobais && listaOfertasGlobais.length > 0) {
      setData(listaOfertasGlobais);
    } else {
      toast.warning('Não foram encontradas Ofertas Globais para este Filtro!');
      setData([]);
    }
  };

  const handleSubmit = async (fields: OfertaGlobal) => {
    try {
      setIsLoading(true);
      const params = {
        id: fields.id,
        valor_de: fields.valor_de,
        valor_ate: fields.valor_ate,
        // desconto: fields.desconto,
        status: status
        // data_inicial: fields.data_inicial,
        // data_final: fields.data_final,
        // cartao: cartao,
        // boleto: boleto,
      };

      await getOffers(params);
    } catch (error) {
      toast.error('Não foi possível listar as Ofertas Globais!');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const userDecode = jwtDecode<IUserCompanyProps>(accessToken);
    const entidadeId = userDecode.corporation_id;
    setEntidadeId(entidadeId);
    onPageChange();
  }, [data]);

  return (
    <>
      {isLoading && <FullScreenLoader loadingMessage="Carregando Ofertas Globais..." />}
      <DefaultContainerPages paddingTop paddingBottom>
        <DefaultTopContainer>
          <GoBack />
          <ContainerTop>
            <Button color="secondary" onClick={() => history.push('/ofertas/listar-ofertas-globais/criar-oferta-global')}>
              Criar Oferta Global
            </Button>
          </ContainerTop>
        </DefaultTopContainer>
        <Title label="Listar Ofertas Globais" isVisiblePixel />
        <DefaultListPageContainer>
          <DefaultFilterContainer>
            <Form className="editForm" ref={formRef} onSubmit={handleSubmit}>
              <DefaultFilterInputs>
                <DefaultFilterField>
                  <Input
                    width="100%"
                    name="id"
                    type="string"
                    placeholder="Ex.: 3200"
                    label="ID da Oferta"
                    padding="8px 8px 8px 6px"
                    iconSize="1.2rem"
                    isClearable={true}
                    clearFunction={() => clearFilter('id')}
                    data-cy="ofertaId"
                  />
                </DefaultFilterField>
                <DefaultFilterField>
                  <Input
                    tooltip={true}
                    tooltipMessage="Filtra Ofertas com Valor Inicial maior ou igual ao valor informado"
                    tooltipBackgroundColor={theme.colors.secondary}
                    iconFontSize="1rem"
                    width="100%"
                    name="valor_de"
                    type="string"
                    placeholder="Ex.: 100"
                    label="Valor Inicial"
                    padding="8px 8px 8px 6px"
                    iconSize="1.2rem"
                    isClearable={true}
                    clearFunction={() => clearFilter('valor_de')}
                  />
                </DefaultFilterField>
                <DefaultFilterField>
                  <Input
                    tooltip={true}
                    tooltipMessage="Filtra Ofertas com Valor Final menor ou igual ao valor informado"
                    tooltipBackgroundColor={theme.colors.secondary}
                    iconFontSize="1rem"
                    width="100%"
                    name="valor_ate"
                    type="string"
                    placeholder="Ex.: 12000"
                    label="Valor Final"
                    padding="8px 8px 8px 6px"
                    iconSize="1.2rem"
                    isClearable={true}
                    clearFunction={() => clearFilter('valor_ate')}
                  />
                </DefaultFilterField>
                <DefaultFilterCheckboxArea>
                  <Toggle onChange={handleChangeDel} label="Somente Ofertas Habilitadas" value={status} name="status" paddingRight />
                </DefaultFilterCheckboxArea>
              </DefaultFilterInputs>
              <DefaultFilterRow padding="0px 10px 0px 10px">
                <DefaultFilterButtonContainer>
                  <DefaultFilterButton>
                    <Button type="button" onClick={clearAllFilters} size="big" color="warning" width="100%" disabled={!!isLoading}>
                      Limpar Filtros
                    </Button>
                  </DefaultFilterButton>
                </DefaultFilterButtonContainer>
                <DefaultFilterButtonContainer>
                  <DefaultFilterButton>
                    <Button
                      type="submit"
                      minWidth="180px"
                      size="big"
                      color="primary"
                      width="100%"
                      loading={isLoading}
                      disabled={!!isLoading}
                      data-cy="filtrarOfertasGlobais"
                    >
                      Filtrar
                    </Button>
                  </DefaultFilterButton>
                </DefaultFilterButtonContainer>
              </DefaultFilterRow>
            </Form>
          </DefaultFilterContainer>
          {data?.length === 0 ? (
            <FeedbackArea>
              <EmptyFeedback
                imagePath={FeedbackImage}
                title="Clique em Filtrar para buscar Resultados"
                message="Utilize os filtros acima para encontrar as Ofertas Globais cadastradas"
              />
            </FeedbackArea>
          ) : (
            <ResultsArea>
              <DataList posts={displayedData} />
              <GoBack />
              <DefaultPagination allPagesNumber={allPages} itemsPerPage={10} itemsNumber={data.length} pageChange={onPageChange} />
            </ResultsArea>
          )}
        </DefaultListPageContainer>
      </DefaultContainerPages>
    </>
  );
};

export default ListarOfertasGlobais;
