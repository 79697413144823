import React from 'react'

import SvgWarning from '@/assets/images/modal/warning-modal.svg'
import Button from '@/components/Ui/Button'
import Text from '@/components/Ui/Text'

import ModalLayout from '../ModalLayout'
import { TextContainer, ImageSuccess, ContainerButton } from './styles'

interface ModalInterface {
  isOpen: boolean
  onClose: () => void
  handleButtonClick: () => void
  label: string
  buttonLabel: string
}

const ModalError: React.FC<ModalInterface> = ({
  isOpen = false,
  onClose,
  handleButtonClick,
  label,
  buttonLabel,
}) => {
  if (isOpen) {
    return (
      <ModalLayout padding="15px" height="57%" onClose={onClose}>
        <ImageSuccess src={SvgWarning} alt="Erro na Solicitação!" />
        <TextContainer>
          <Text size="medium" bold>
            {label}
          </Text>
        </TextContainer>
        <ContainerButton>
          <Button color="primary" onClick={handleButtonClick} full={false}>
            {buttonLabel}
          </Button>
        </ContainerButton>
      </ModalLayout>
    )
  }
  return null
}

export default ModalError
