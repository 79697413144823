import api from '@/services/api';
import { convertTitleOffersV1 } from '@/utils/utils_v1/TitleOffer';
import { FiltroRelatorioOfertaTitulo } from '../interfaces/FiltroRelatorioOfertaTitulo';

export const getRelatorioOfertasTitulo = async (params: FiltroRelatorioOfertaTitulo) => {
  const { data } = await api.get(`/users/protested-titles/corporation/reports/ofertas-titulo`, {
    params: {
      ...(params.dt_cadastro_de ? { created_start: params.dt_cadastro_de } : null),
      ...(params.dt_cadastro_ate ? { created_end: params.dt_cadastro_ate } : null),
      ...(params.numero_titulo ? { title_number: params.numero_titulo } : null),
      ...(params.desconto_tipo && params.desconto_tipo !== 'ALL_TYPES' ? { discount_type: params.desconto_tipo } : null),
      ...(params.valor_de ? { discount_start: params.valor_de } : null),
      ...(params.valor_ate ? { discount_end: params.valor_ate } : null),
      // ...(params.oferta_negociacao ? { oferta_negociacao: params.oferta_negociacao } : null),
      ...(params.oferta_status && params.oferta_status !== 'ALL_OFFERS' ? { is_active: params.oferta_status == 'ACTIVE_OFFERS' } : null),
      ...(params.titulo_status && params.titulo_status !== 'ALL_OFFERS' ? { current_status: params.titulo_status } : null),
      // ...(params.formas_pagamento ? { formas_pagamento: params.formas_pagamento } : null),
      ...(params.ofertas_expiradas && params.ofertas_expiradas !== 'ALL_OFFERS' ? { expired_offers: params.ofertas_expiradas } : null),
      ...(params.url ? { url: params.url } : null)
    }
  });
  const convertedTitleOffers = convertTitleOffersV1(data.results);

  const response = {
    data: convertedTitleOffers,
    count: data.count,
    next: data.next,
    previous: data.previous
  };

  return response;
};
