import React from 'react';
import DescriptionContainer from '@/modules/Deal/components/DescriptionContainer';
import Text from '@/components/Ui/Text';
import { Container, ItemDetails } from '../paginate/styles';

const Header: React.FC = () => {
  return (
    <Container className="Container" padding="0px 40px">
      <DescriptionContainer>
        <ItemDetails width="5%">
          <Text margin="10px 10px" size="small" bold>
            ID
          </Text>
        </ItemDetails>
        <ItemDetails width="30%">
          <Text margin="10px 10px" size="small" bold>
            Título/Assunto
          </Text>
        </ItemDetails>
        <ItemDetails width="20%">
          <Text margin="10px 10px" size="small" bold>
            Data Inicial
          </Text>
        </ItemDetails>
        <ItemDetails width="20%">
          <Text margin="10px 10px" size="small" bold>
            Data Final
          </Text>
        </ItemDetails>
        <ItemDetails width="10%" isCenter>
          <Text margin="10px 10px" size="small" bold>
            Ativa
          </Text>
        </ItemDetails>
        <ItemDetails width="15%"></ItemDetails>
      </DescriptionContainer>
    </Container>
  );
};

export default Header;
