import Button from '@/components/Ui/Button';
import IconController from '@/components/Ui/IconController';
import Text from '@/components/Ui/Text';
import DescriptionContainer from '@/modules/Deal/components/DescriptionContainer';
import { formatarDinheiro } from '@/utils/Utilities';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { OfertaTitulo } from '../../ListarOfertasTitulo/interfaces/interface';
import { Container, ContainerButton, ItemDetails, PaymentContainer } from './styles';

interface IProps {
  posts: OfertaTitulo[];
}

const DataList: React.FC<IProps> = ({ posts }) => {
  const history = useHistory();

  return (
    <div className="list__container">
      <div className="list_data">
        {posts.map((ofertaTitulo: OfertaTitulo) => {
          return (
            <Container className="Container" key={ofertaTitulo.id}>
              <DescriptionContainer>
                <ItemDetails width="25%">
                  <Text margin="12px 0px 0px 0px" size="medium" bold>
                    Duração da Oferta
                  </Text>
                  <Text margin="12px 0px 0px 0px" size="small">
                    Início: {moment(ofertaTitulo.data_inicio).format('DD/MM/YYYY')}
                  </Text>
                  <Text margin="12px 0px 0px 0px" size="small">
                    Encerramento: {moment(ofertaTitulo.data_fim).format('DD/MM/YYYY')}
                  </Text>
                </ItemDetails>
                {/* <ItemDetails width="15%">
                  <PaymentContainer>
                    <Text size="medium" bold>
                      Cartão:
                    </Text>
                    {ofertaTitulo.cartao == true ? (
                      <IconController paddingLeft={'8px'} margin="0" icon="checkCircle" fontSize="1.25em" iconColor="green" />
                    ) : (
                      <IconController paddingLeft={'8px'} margin="0" icon="closeCircle" fontSize="1.25em" iconColor="red" />
                    )}
                  </PaymentContainer>
                  <Text margin="12px 0px 0px 0px" size="small">
                    Juros: {ofertaTitulo.juros_cartao ? ofertaTitulo.juros_cartao : '0.00'} %
                  </Text>
                  <Text margin="12px 0px 0px 0px" size="small">
                    Parcela: {ofertaTitulo.parcela_cartao ? ofertaTitulo.parcela_cartao : 'n/d'} Parcela(s)
                  </Text>
                </ItemDetails> */}
                <ItemDetails width="20%">
                  <PaymentContainer>
                    <Text size="medium" bold margin="12px 0px 0px 0px">
                      {ofertaTitulo.status == true ? 'Oferta Ativa' : 'Oferta Inativa'}
                    </Text>
                    {ofertaTitulo.status == true ? (
                      <IconController
                        paddingLeft={'8px'}
                        margin="12px 0px 0px 0px"
                        icon="checkCircle"
                        fontSize="1.25em"
                        iconColor="green"
                      />
                    ) : (
                      <IconController paddingLeft={'8px'} margin="12px 0px 0px 0px" icon="closeCircle" fontSize="1.25em" iconColor="red" />
                    )}
                  </PaymentContainer>
                </ItemDetails>
                <ItemDetails width="20%">
                  <Text size="medium" bold>
                    ID do Título: {ofertaTitulo.id_titulo ? ofertaTitulo.id_titulo : 'n/d'}
                  </Text>
                  {ofertaTitulo.desconto === null ? (
                    <Text margin="12px 0px 0px 0px" size="small">
                      Oferta sem desconto fixo (R$)
                    </Text>
                  ) : (
                    <Text margin="12px 0px 0px 0px" size="small">
                      Desconto fixo: {ofertaTitulo.desconto ? formatarDinheiro(Number(ofertaTitulo.desconto)) : 'R$ 0,00'}
                    </Text>
                  )}
                  {ofertaTitulo.desconto_porcentagem === null ? (
                    <Text margin="12px 0px 0px 0px" size="small">
                      Oferta sem desc porcentagem (%)
                    </Text>
                  ) : (
                    <Text margin="12px 0px 0px 0px" size="small">
                      Desconto porcentagem: {ofertaTitulo.desconto_porcentagem ? ofertaTitulo.desconto_porcentagem : '0.00'} %
                    </Text>
                  )}
                </ItemDetails>

                <ContainerButton>
                  <Button full onClick={() => history.push(`/ofertas/listar-ofertas-titulo/${ofertaTitulo.id}`)}>
                    Detalhes
                  </Button>
                </ContainerButton>
              </DescriptionContainer>
            </Container>
          );
        })}
      </div>
    </div>
  );
};

export default DataList;
