import styled from 'styled-components';

interface InputRow {
  repeats?: number;
  padding?: string;
  margin?: string;
  justifyContent?: 'flex-start' | 'center';
}

export const InputRow = styled.div<InputRow>`
  display: grid;
  grid-gap: 12px;
  padding: ${({ padding }) => (padding ? padding : '0px 48px')};
  margin: ${({ margin }) => (margin ? margin : '0px 0px')};
  grid-template-columns: ${({ repeats }) => (repeats ? `repeat(${repeats}, 1fr)` : 'repeat(4, 1fr)')};
`;

export const SessionText = styled.div<InputRow>`
  padding: ${({ padding }) => (padding ? padding : '0px 48px')};
`;

export const ButtonContainer = styled.div<InputRow>`
  display: flex;
  justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'center')};
  align-items: end;
`;
