import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { MonthpickerContainer } from './styles';
import './styles.css';
import ptBR from 'date-fns/locale/pt-BR';
import moment from 'moment';
import Tooltip from '../Tooltip';
registerLocale('ptBR', ptBR);

interface Props {
  label: string;
  customMargin?: string;
  height?: string;
  selectedDate: Date | null;
  onChange: (date: Date | null) => void;
  tooltip?: boolean;
  tooltipMessage?: string;
  tooltipPlace?: 'top' | 'right' | 'bottom' | 'left';
  tooltipType?: 'dark' | 'success' | 'warning' | 'error' | 'info' | 'light';
  tooltipEffect?: 'float' | 'solid';
  iconFontSize?: string;
  tooltipBackgroundColor?: string;
  limitDate?: boolean;
}

const Monthpicker = ({
  label,
  selectedDate,
  onChange,
  tooltip = false,
  tooltipMessage,
  tooltipPlace,
  tooltipType,
  tooltipEffect,
  iconFontSize,
  tooltipBackgroundColor,
  limitDate = false
}: Props) => {
  const handleDateChangeRaw = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
  };

  return (
    <MonthpickerContainer full isView>
      {label && (
        <label>
          {label}
          <Tooltip
            isView={tooltip}
            tooltipMessage={tooltipMessage}
            place={tooltipPlace}
            type={tooltipType}
            effect={tooltipEffect}
            iconFontSize={iconFontSize}
            backgroundColor={tooltipBackgroundColor}
          />
        </label>
      )}
      <DatePicker
        className="month-picker-input"
        locale="ptBR"
        selected={selectedDate}
        onChange={(date) => onChange(date)}
        dateFormat="MM/yyyy"
        showMonthYearPicker
        showTwoColumnMonthYearPicker
        showPopperArrow={false}
        onChangeRaw={handleDateChangeRaw}
        maxDate={limitDate ? new Date() : null}
      />
    </MonthpickerContainer>
  );
};

export default Monthpicker;
