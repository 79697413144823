import api from '@/services/api';

export const getEntidades = async () => {
  const { data } = await api.get(`/corporation/`, {
    params: {
      is_active: true
    }
  });
  return data;
};
