import FeedbackImage from '@/assets/images/magnifier.png';
import EmptyFeedback from '@/components/Helpers/EmptyFeedback';
import Button from '@/components/Ui/Button';
import { FullScreenLoader } from '@/components/Ui/FullScreenLoader';
import Input from '@/components/Ui/Input';
import Title from '@/components/Ui/Title';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { ContainerTop, FeedbackArea, ResultsArea } from '../components/EditContainer/styles';
import { Usuarios } from './interfaces/interface';
import { getUsuariosRequestFiltrar } from './providers/getUsuariosRequestFiltrar';
import { handleListUsuario } from './utils/handleListUsuario';
//pagination
import {
  DefaultFilterButton,
  DefaultFilterButtonContainer,
  DefaultFilterCheckboxArea,
  DefaultFilterContainer,
  DefaultFilterField,
  DefaultFilterInputs,
  DefaultFilterRow,
  DefaultTopContainer
} from '@/components/Ui/DefaultFilterContainer/styles';
import { DefaultContainerPages, DefaultListPageContainer } from '@/components/Ui/DefaultHeader/style';
import DefaultPagination from '@/components/Ui/DefaultPagination';
import GoBack from '@/components/Ui/GoBack';
import Toggle from '@/components/Ui/Toggle';
import { ISignInProps } from '@/modules/_layouts/components/HeaderCompany/components/UserSetting';
import { checkIsSuperUser } from '@/utils/Utilities';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DataList from './components/paginate/DataList';

const ListarUsuarios: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const accessToken = useSelector((state: ISignInProps) => state.SignIn.access_token);
  const history = useHistory();

  const [data, setData] = useState<Usuarios[]>([]);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [displayedData, setDisplayedData] = useState<Usuarios[]>([]);
  const itemsPerPage = 10;
  const allPages = Math.ceil(data.length / itemsPerPage);

  const [is_active, setIsActive] = useState(true);
  const handleChangeIsActive = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsActive(e.target.checked);
  };

  const [is_admin, setIsAdmin] = useState(false);
  const handleChangeIsAdmin = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsAdmin(e.target.checked);
  };

  const [is_superuser_protestado, setIsSuperUser] = useState(false);
  const handleChangeIsSuperUser = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsSuperUser(e.target.checked);
  };

  const onPageChange = (page = 1) => {
    const startItem = (page - 1) * itemsPerPage;
    const endItem = page * itemsPerPage;
    setDisplayedData(data.slice(startItem, endItem));
  };

  const clearFilter = useCallback((fieldValue: string) => {
    formRef.current?.clearField(fieldValue);
  }, []);

  const clearAllFilters = useCallback(() => {
    formRef.current?.setFieldValue('id', '');
    formRef.current?.setFieldValue('email', '');
    setIsActive(true);
    setIsAdmin(false);
    setIsSuperUser(false);
  }, []);

  useEffect(() => {
    onPageChange();
  }, [data]);

  const handleSubmit = async (fields: Usuarios) => {
    try {
      setIsLoading(true);

      const params = {
        email: fields.email,
        id: fields.id,
        is_active: is_active,
        is_admin: is_admin,
        is_superuser_protestado: is_superuser_protestado,
        created_at: fields.created_at
      };

      const UsuarioList = await getUsuariosRequestFiltrar(params);

      if (UsuarioList?.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const usuario: any[] = handleListUsuario(UsuarioList);
        setData(usuario);
      } else {
        toast.warning('Não foram encontrados Usuários para este Filtro!');
        setData([]);
      }
    } catch (error) {
      toast.error('Não foi possível listar os Usuários!');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (accessToken) {
      const isSuperUser = checkIsSuperUser(accessToken);
      setIsSuperAdmin(isSuperUser);
    }
  }, []);

  return (
    <>
      {isLoading && <FullScreenLoader loadingMessage="Carregando Usuários..." />}
      <DefaultContainerPages paddingTop paddingBottom>
        <DefaultTopContainer>
          <GoBack />
          <ContainerTop>
            <Button color="secondary" onClick={() => history.push('/usuarios/criar-usuario')}>
              Criar Usuário
            </Button>
          </ContainerTop>
        </DefaultTopContainer>
        <Title label="Listar Usuários" isVisiblePixel />
        <DefaultListPageContainer>
          <DefaultFilterContainer>
            <Form className="editForm" ref={formRef} onSubmit={handleSubmit}>
              <DefaultFilterInputs>
                <DefaultFilterField>
                  <Input
                    width="100%"
                    name="id"
                    type="string"
                    placeholder="Ex.: 827333a5-f6a4-442a-892f-d23b964a0204"
                    label="ID do Usuário"
                    padding="8px 8px 8px 6px"
                    iconSize="1.2rem"
                    isClearable={true}
                    clearFunction={() => clearFilter('id')}
                    data-cy="usuarioId"
                  />
                </DefaultFilterField>
                <DefaultFilterField>
                  <Input
                    width="100%"
                    name="email"
                    type="string"
                    placeholder="Ex.: exemplo@protestado.com.br"
                    label="E-mail"
                    padding="8px 8px 8px 6px"
                    iconSize="1.2rem"
                    isClearable={true}
                    clearFunction={() => clearFilter('email')}
                    data-cy="emailUsuario"
                  />
                </DefaultFilterField>
                <DefaultFilterCheckboxArea>
                  <Toggle onChange={handleChangeIsActive} label="Ativo" value={is_active} name="is_active" paddingRight />
                </DefaultFilterCheckboxArea>
                <DefaultFilterCheckboxArea>
                  <Toggle onChange={handleChangeIsAdmin} label="Admin" value={is_admin} name="is_admin" paddingRight />
                </DefaultFilterCheckboxArea>
                <DefaultFilterCheckboxArea hide={!isSuperAdmin}>
                  <Toggle
                    onChange={handleChangeIsSuperUser}
                    label="Super Usuário"
                    value={is_superuser_protestado}
                    name="is_superuser_protestado"
                    paddingRight
                  />
                </DefaultFilterCheckboxArea>
              </DefaultFilterInputs>
              <DefaultFilterRow padding="0px 10px 0px 10px">
                <DefaultFilterButtonContainer>
                  <DefaultFilterButton>
                    <Button type="button" onClick={clearAllFilters} size="big" color="warning" width="100%" disabled={!!isLoading}>
                      Limpar Filtros
                    </Button>
                  </DefaultFilterButton>
                </DefaultFilterButtonContainer>
                <DefaultFilterButtonContainer>
                  <DefaultFilterButton>
                    <Button
                      type="submit"
                      minWidth="180px"
                      size="big"
                      color="primary"
                      width="100%"
                      loading={isLoading}
                      disabled={!!isLoading}
                      data-cy="filtrarUsuarios"
                    >
                      Filtrar
                    </Button>
                  </DefaultFilterButton>
                </DefaultFilterButtonContainer>
              </DefaultFilterRow>
            </Form>
          </DefaultFilterContainer>
          {data?.length === 0 ? (
            <FeedbackArea>
              <EmptyFeedback
                imagePath={FeedbackImage}
                title="Clique em Filtrar para buscar Resultados"
                message="
            Utilize os filtros acima para encontrar os Usuários cadastrados"
              />
            </FeedbackArea>
          ) : (
            <ResultsArea>
              <DataList posts={displayedData} />
              <GoBack />
              <DefaultPagination allPagesNumber={allPages} itemsPerPage={10} itemsNumber={data.length} pageChange={onPageChange} />
            </ResultsArea>
          )}
        </DefaultListPageContainer>
      </DefaultContainerPages>
    </>
  );
};

export default ListarUsuarios;
