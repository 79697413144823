import ScreenNavCard from '@/components/Ui/ScreenNavCard';
import { ScreenNavCardContainerDefault } from '@/components/Ui/ScreenNavCard/styles';
import Text from '@/components/Ui/Text';
import React from 'react';
import { Container, ContainerTitle } from './components/EditContainer/styles';

const ListarOfertas: React.FC = () => {
  return (
    <>
      <Container className="Container" paddingTop paddingBottom>
        <ContainerTitle className="containerTitle">
          <Text color="primary" size="big" align="center" textJustify={false} bold>
            Gerenciar Ofertas
          </Text>
          <Text margin="20px" color="primary" size="medium" align="center" textJustify={false}>
            O que deseja fazer? Selecione uma opção abaixo:
          </Text>
        </ContainerTitle>
        <ScreenNavCardContainerDefault repeats={3}>
          <ScreenNavCard
            titleLabel="Oferta Global"
            description="Crie e Gerencie Ofertas Globais aplicáveis à todos os Títulos da base"
            icon="ofertas"
            btnLabel="Acessar"
            path="/ofertas/listar-ofertas-globais"
            isDashboard={false}
            isManagement={true}
            btnSize="custom2"
            btnColor="secondary"
          />
          <ScreenNavCard
            titleLabel="Criar Oferta Global"
            description="Atalho rápido para Criar Oferta Global"
            icon="criar-ofertas"
            btnLabel="Criar Oferta"
            path="/ofertas/listar-ofertas-globais/criar-oferta-global"
            isDashboard={false}
            isManagement={true}
            btnSize="custom2"
            btnColor="secondary"
          />
          <ScreenNavCard
            titleLabel="Relatório Ofertas Globais"
            description="Acesso à informações detalhadas de Ofertas Globais"
            icon="relatorios"
            btnLabel="Acessar"
            path="/relatorios/ofertas-globais"
            isDashboard={false}
            isManagement={true}
            btnSize="custom2"
            btnColor="secondary"
          />
          <ScreenNavCard
            titleLabel="Oferta Título"
            description="Crie e Gerencie Ofertas específicas para o Título desejado"
            icon="ofertas"
            btnLabel="Acessar"
            path="/ofertas/listar-ofertas-titulo"
            isDashboard={false}
            isManagement={true}
            btnSize="custom2"
            btnColor="secondary"
          />
          <ScreenNavCard
            titleLabel="Criar Oferta Título"
            description="Atalho rápido para Criar Oferta Título"
            icon="criar-ofertas"
            btnLabel="Criar Oferta"
            path="/ofertas/listar-ofertas-titulo/criar-oferta-titulo"
            isDashboard={false}
            isManagement={true}
            btnSize="custom2"
            btnColor="secondary"
          />
          <ScreenNavCard
            titleLabel="Relatório Ofertas Títulos"
            description="Acesso à informações detalhadas de Ofertas Título"
            icon="relatorios"
            btnLabel="Acessar"
            path="/relatorios/ofertas-titulo"
            isDashboard={false}
            isManagement={true}
            btnSize="custom2"
            btnColor="secondary"
          />
        </ScreenNavCardContainerDefault>
      </Container>
    </>
  );
};

export default ListarOfertas;
