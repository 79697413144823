import api from '@/services/api';
import { convertGlobalOffersV1 } from '@/utils/utils_v1/GlobalOffer';
import { Titulos } from '../interfaces/interface';

export const getRelatorioOfertasGlobais = async (params: Titulos) => {
  const { data } = await api.get(`/users/protested-titles/corporation/reports/ofertas-globais`, {
    params: {
      ...(params.oferta_id ? { id: params.oferta_id } : {}),
      ...(params.status_oferta ? { is_active: params.status_oferta } : {}),
      // ...(params.status_renegociacao ? { status_renegociacao: params.status_renegociacao } : {}),
      // ...(params.status_pagamento ? { status_pagamento: params.status_pagamento } : {}),
      // ...(params.tipo_pagamento ? { tipo_pagamento: params.tipo_pagamento } : {}),
      // ...(params.permanencia_inicio ? { permanencia_inicio: params.permanencia_inicio } : {}),
      // ...(params.permanencia_fim ? { permanencia_fim: params.permanencia_fim } : {}),
      ...(params.tempo_plataforma_inicio ? { uploaded_from: params.tempo_plataforma_inicio } : {}),
      ...(params.tempo_plataforma_fim ? { uploaded_to: params.tempo_plataforma_fim } : {}),
      ...(params.vencimento_inicio ? { due_from: params.vencimento_inicio } : {}),
      ...(params.vencimento_fim ? { due_to: params.vencimento_fim } : {}),
      ...(params.desconto_porcentagem_inicial ? { discount_value_start: params.desconto_porcentagem_inicial } : {}),
      ...(params.desconto_porcentagem_final ? { discount_value_end: params.desconto_porcentagem_final } : {})
      // ...(params.desconto_valor_inicial ? { desconto_valor_inicial: params.desconto_valor_inicial } : {}),
      // ...(params.desconto_valor_final ? { desconto_valor_final: params.desconto_valor_final } : {})
    }
  });
  const convertedGlobalOffers = convertGlobalOffersV1(data.results);
  return convertedGlobalOffers;
};

export const enviaRelatorioEmail = (payload: any) => {
  console.log(payload);
};
