import React from 'react';
import { Container, IconContainer, ItemDetails, ListContainer } from './styles';
import DescriptionContainer from '@/modules/Deal/components/DescriptionContainer';
import Button from '@/components/Ui/Button';
import { useHistory } from 'react-router-dom';
import Text from '@/components/Ui/Text';
import moment from 'moment';
import IconController from '@/components/Ui/IconController';

interface EmailTemplateProps {
  templates: any[];
}

const DataList: React.FC<EmailTemplateProps> = ({ templates }) => {
  const history = useHistory();

  return (
    <ListContainer margin="0px 0px 50px 0px">
      {templates.map((item: any, index: number) => {
        return (
          <Container className="Container" key={item.id}>
            <DescriptionContainer>
              <ItemDetails width="5%">
                <Text margin="10px 10px" size="small" bold>
                  {item.id}
                </Text>
              </ItemDetails>
              <ItemDetails width="30%">
                <Text margin="10px 10px" size="small" bold>
                  {item.title}
                </Text>
              </ItemDetails>
              <ItemDetails width="20%">
                <Text margin="10px 10px" size="small" bold>
                  {moment(item.start_date).format('DD/MM/YYYY - HH:mm')}
                </Text>
              </ItemDetails>
              <ItemDetails width="20%">
                <Text margin="10px 10px" size="small" bold>
                  {moment(item.end_date).format('DD/MM/YYYY - HH:mm')}
                </Text>
              </ItemDetails>
              <ItemDetails width="10%">
                <IconContainer>
                  {item.is_active == true ? (
                    <IconController margin="0" icon="checkCircle" fontSize="1.25em" iconColor="green" />
                  ) : (
                    <IconController margin="0" icon="closeCircle" fontSize="1.25em" iconColor="red" />
                  )}
                </IconContainer>
              </ItemDetails>
              <ItemDetails width="15%">
                <Button
                  full
                  size="small"
                  onClick={() => history.push(`/mensageria/listar-campanhas/${item.id}`)}
                  data-cy={`visualizarCampanha${index}`}
                  id={`visualizarCampanha${index}`}
                >
                  Detalhes
                </Button>
              </ItemDetails>
            </DescriptionContainer>
          </Container>
        );
      })}
    </ListContainer>
  );
};

export default DataList;
