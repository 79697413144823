import styled, { css } from 'styled-components';
import theme from '@/assets/styles/theme';

interface ContainerProps {
  full: boolean | undefined;
  isBold?: boolean;
  height?: string;
  width?: string;
  isView?: boolean;
}

type ClearableInputContainerProps = {
  isErrored: boolean;
  customMargin?: string;
} & Pick<ContainerProps, 'height'>;

interface IconContainerProps {
  isClearable: boolean;
  iconSize?: string;
  padding?: string;
}

export const Container = styled.div<ContainerProps>`
  display: ${(props) => !props.isView && 'none !important'};
  width: ${(props) => (props.full ? '100%' : props.width)};

  label {
    display: flex;
    margin-top: 20px;
    color: ${theme.colors.gray[500]};
    font-size: 15px;
    gap: 12px;
    font-weight: ${(props) => (props.isBold ? 'bold' : 300)};
  }

  > small {
    font-size: 12px;
    display: flex;
    opacity: 0.6;
  }
`;

export const ClearableInputContainer = styled.div<ClearableInputContainerProps>`
  display: flex;
  align-items: center;

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }

  > input,
  > textarea {
    border: 0.063em solid ${theme.colors.gray[300]};
    color: ${theme.colors.gray[700]};
    display: block;
    font-size: 20px;
    padding: 12px;
    margin-top: 10px;
    margin: ${(props) => props.customMargin || '10px 0px 0px 0px'};
    background-color: ${theme.colors.white};
    border-radius: 5px;
    transition: border-color 0.2s;
    width: 100%;
    height: ${(props) => props.height || '45px'};

    ${(props) =>
      props.isErrored &&
      css`
        border-color: ${theme.colors.red[200]};
      `}

    &:focus {
      border-color: ${theme.colors.gray[400]};

      ${(props) =>
        props.isErrored &&
        css`
          border-color: ${theme.colors.red[200]};
        `}
    }

    &[disabled] {
      background: ${theme.colors.gray[200]};
      cursor: not-allowed;
    }

    &::placeholder {
      color: ${theme.colors.gray[300]};
    }
  }

  > textarea {
    min-height: 200px;
  }

  > span {
    color: ${theme.colors.red[200]};
    font-size: 14px;
    display: block;
    margin-top: 8px;
  }
`;

export const IconContainer = styled.div<IconContainerProps>`
  display: ${(props) => !props.isClearable && 'none !important'};
  font-size: ${(props) => (props.iconSize ? props.iconSize : '1.6rem')};
  padding: ${(props) => (props.padding ? props.padding : '8px')};
  margin-top: 12px;
  cursor: pointer;
`;
