import { OfertaTitulo } from '@/modules/Entidades/ListarOfertasTitulo/interfaces/interface';
import { TitleV1 } from './Title';

export interface TitleOfferV1 {
  id: string;
  name: string;
  discount_type: TitleOfferDiscountType;
  discount_value: string;
  is_active: boolean;
  start_date: string;
  end_date: string;
  created_at: string;
  updated_at: string;
  company: string;
  titles: TitleWithoutOffersDTO[];
  type: string;
}

export enum TitleOfferDiscountType {
  PERCENTAGE = 'PERCENTAGE',
  VALUE = 'VALUE'
}

export type TitleWithoutOffersDTO = Omit<TitleV1, 'title_offers' | 'global_offers'>;

export function convertTitleOfferV1(titleOffer: TitleOfferV1): OfertaTitulo {
  let titleId = null;
  if (typeof titleOffer.titles[0] === 'string') {
    titleId = titleOffer.titles[0];
  } else {
    titleId = titleOffer.titles[0].id;
  }
  return {
    id: titleOffer.id,
    id_titulo: titleOffer.type === 'DEFAULT' ? titleId : '',
    status: titleOffer.is_active,
    titulo: titleOffer.type === 'DEFAULT' ? titleId : '',
    desconto: titleOffer.discount_type === 'VALUE' ? titleOffer.discount_value : undefined,
    desconto_porcentagem: titleOffer.discount_type === 'PERCENTAGE' ? titleOffer.discount_value : undefined,
    numero_titulo: '',
    apresentante: null,
    boleto: false, // integração com simpay não permite boleto atualmente
    juros_boleto: '',
    parcela_boleto: '',
    cartao: true, // integração com simpay permite cartão
    juros_cartao: '',
    parcela_cartao: 0,
    data_inicio: titleOffer.start_date,
    data_fim: titleOffer.end_date,
    nosso_numero: '',
    status_envio_sms: false,
    mensagem: '',
    key: titleOffer.id,
    protestado_entidade_uuid: titleOffer.company
  };
}

export function convertTitleOffersV1(titleOffers: TitleOfferV1[]): OfertaTitulo[] {
  return titleOffers.map(convertTitleOfferV1);
}
