import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import * as Interfaces from './types'

/* ------------- Types and Action Creators ------------- */

export enum AuthEnum {
  EMAIL_CONFIRMATION_REQUEST = 'EMAIL_CONFIRMATION_REQUEST',
  EMAIL_CONFIRMATION_SUCCESS = 'EMAIL_CONFIRMATION_SUCCESS',
  EMAIL_CONFIRMATION_FAILURE = 'EMAIL_CONFIRMATION_FAILURE',
}

type AuthType = typeof AuthEnum

// CreateActions
export const { Types, Creators } = createActions<AuthType, Interfaces.ActionTypes>({
  emailConfirmationRequest: { user_id: '' },
  emailConfirmationSuccess: {},
  emailConfirmationFailure: {},
})

export interface AuthStateType {
  data: {
    name: string
  }
  user_id: string
}

/* ------------- Initial State ------------- */

export const INITIAL_STATE: AuthStateType = Immutable({
  data: {
    name: '',
  },
  user_id: '',
})

/* ------------- Reducers ------------- */
export const EmailConfirmationRequest = (
  state: AuthStateType = INITIAL_STATE,
  { user_id }: Interfaces.EmailConfirmationRequestPayload,
): AuthStateType => {
  return {
    ...state,
    user_id,
  }
}

export const EmailConfirmationSuccess = (state: AuthStateType = INITIAL_STATE): AuthStateType => {
  return {
    ...state,
  }
}

export const EmailConfirmationFailure = (state: AuthStateType = INITIAL_STATE): AuthStateType => {
  return {
    ...state,
  }
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer<AuthStateType, Interfaces.ActionTypes>(INITIAL_STATE, {
  [Types.EMAIL_CONFIRMATION_REQUEST]: EmailConfirmationRequest,
  [Types.EMAIL_CONFIRMATION_SUCCESS]: EmailConfirmationSuccess,
  [Types.EMAIL_CONFIRMATION_FAILURE]: EmailConfirmationFailure,
})

// /* ------------- Selectors ------------- */
