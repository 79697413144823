import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import { ActionTypes, AuthEnum, AuthStateType, LoginSuccessPayload, ErrorPayload } from './types'

/* ------------- Types and Action Creators ------------- */

type AuthType = typeof AuthEnum

// CreateActions
export const { Types, Creators } = createActions<AuthType, ActionTypes>({
  loginRequest: ['login'],
  loginSuccess: ['access_token'],
  loginFailure: { code: 0, message: '' },
  logoutRequest: {},
  logoutSuccess: {},
  logoutFailure: {},
})

/* ------------- Initial State ------------- */

export const INITIAL_STATE: AuthStateType = Immutable({
  data: {
    name: '',
  },
  error: {
    code: 0,
    message: '',
  },
  access_token: null,
  cpf: null,
  email: null,
  password: null,
  isAuthorized: false,
  loading: false,
})

/* ------------- Reducers ------------- */
export const loginRequest = (state: AuthStateType = INITIAL_STATE): AuthStateType => ({
  ...state,
  error: {
    code: 0,
    message: '',
  },
  loading: true,
})

export const loginSuccess = (
  state: AuthStateType = INITIAL_STATE,
  { access_token }: LoginSuccessPayload,
): AuthStateType => ({
  ...state,
  access_token,
  loading: false,
  error: {
    code: 0,
    message: '',
  },
  isAuthorized: true,
})

export const loginFailure = (
  state: AuthStateType = INITIAL_STATE,
  { code, message }: ErrorPayload,
): AuthStateType => {
  return {
    ...state,
    error: {
      code,
      message,
    },
    loading: false,
  }
}

export const logoutRequest = (state: AuthStateType = INITIAL_STATE): AuthStateType => ({
  ...state,
  loading: true,
})

export const logoutSuccess = (state: AuthStateType = INITIAL_STATE): AuthStateType => ({
  ...state,
  loading: false,
})

export const logoutFailure = (state: AuthStateType = INITIAL_STATE): AuthStateType => ({
  ...state,
  loading: false,
})

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer<AuthStateType, ActionTypes>(INITIAL_STATE, {
  [Types.LOGIN_REQUEST]: loginRequest,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_FAILURE]: loginFailure,
  [Types.LOGOUT_REQUEST]: logoutRequest,
  [Types.LOGOUT_SUCCESS]: logoutSuccess,
  [Types.LOGOUT_FAILURE]: logoutFailure,
})

// /* ------------- Selectors ------------- */
