import theme from '@/assets/styles/theme';
import React from 'react';
import {
  BsBarChart,
  BsBuilding,
  BsChatRightDots,
  BsClipboardData,
  BsClipboardPlus,
  BsEnvelope,
  BsFiles,
  BsGear,
  BsPerson
} from 'react-icons/bs';
import { MdFormatListBulleted } from 'react-icons/md';
import { RiFileEditLine, RiFileUploadLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import Button from '../Button';
import Text from '../Text';
// import Tooltip from '../Tooltip';
import { IconArea, ScreenNavBtnContainer, ScreenNavCardContainer, ScreenNavTitleContainer } from './styles';

interface ScreenNavCardProps {
  isHide?: boolean;
  padding?: string;
  margin?: string;
  titleLabel: string;
  description?: string;
  icon: string;
  btnLabel: string;
  btnDisable?: boolean;
  path: string;
  isDashboard?: boolean;
  isManagement?: boolean;
  btnSize?: 'small' | 'custom' | 'custom2' | 'default' | 'big';
  tooltipId?: string;
  btnColor?: 'primary' | 'inverse' | 'cancelar' | 'error' | 'warning' | 'secondary';
}

const ScreenNavCard: React.FC<ScreenNavCardProps> = ({
  isHide,
  padding,
  margin,
  titleLabel,
  description,
  icon,
  btnLabel,
  btnDisable,
  path,
  isDashboard = true,
  isManagement = false,
  btnSize = 'custom',
  tooltipId = 'none'
}) => {
  const history = useHistory();

  return (
    <>
      <a data-for={tooltipId} data-tip="Disponível em breve!" data-iscapture="true">
        <ScreenNavCardContainer isHide={isHide} padding={padding} margin={margin}>
          <IconArea btnDisable={btnDisable} btnColor={theme.colors.primary}>
            {icon === 'entidades' && <BsBuilding />}
            {icon === 'usuarios' && <BsPerson />}
            {icon === 'mensageria' && <BsEnvelope />}
            {icon === 'campanhas' && <RiFileEditLine />}
            {icon === 'titulos' && <BsFiles />}
            {icon === 'ofertas' && <BsClipboardData />}
            {icon === 'criar-ofertas' && <BsClipboardPlus />}
            {icon === 'config' && <BsGear />}
            {icon === 'sms' && <BsChatRightDots />}
            {icon === 'relatorios' && <BsBarChart />}
            {icon === 'upload' && <RiFileUploadLine />}
            {icon === 'list' && <MdFormatListBulleted />}
          </IconArea>
          <ScreenNavTitleContainer>
            <Text size="medium2" color="primary" bold>
              {titleLabel}
            </Text>
            {/* <Tooltip
              isView={btnDisable}
              tooltipMessage="Disponível em breve!"
              place="top"
              type="dark"
              effect="float"
              iconFontSize="1.1rem"
              backgroundColor={theme.colors.secondary}
              padding="6px 0px 0px 8px"
              iconColor={theme.colors.primary}
            /> */}
          </ScreenNavTitleContainer>
          <Text size="default" color="primary">
            {description}
          </Text>
          <ScreenNavBtnContainer isDashboard={isDashboard}>
            <Button
              size={btnSize}
              isDashboard={isDashboard}
              isManagement={isManagement}
              margin="12px 0px 0px 0px"
              disabled={btnDisable}
              color="primary"
              full
              onClick={() => history.push(path)}
            >
              {btnLabel}
            </Button>
          </ScreenNavBtnContainer>
        </ScreenNavCardContainer>
      </a>
      <ReactTooltip
        disable={tooltipId === 'none'}
        id={tooltipId}
        className="customClass"
        place="top"
        type="dark"
        effect="float"
        backgroundColor={theme.colors.secondary}
        textColor="#FFF"
      />
    </>
  );
};

export default ScreenNavCard;
