import React, { useEffect, useState, useRef, useCallback } from 'react';
import { toast } from 'react-toastify';
import { FullScreenLoader } from '@/components/Ui/FullScreenLoader';
import { ResultsArea } from '../components/EditContainer/styles';
import { FormHandles } from '@unform/core';
//pagination
import DefaultPagination from '@/components/Ui/DefaultPagination';
import { DefaultContainerPages, DefaultListPageContainer } from '@/components/Ui/DefaultHeader/style';
import GoBack from '@/components/Ui/GoBack';
import TemplateList from './components/TemplateList';
import DefaultHeader from '@/components/Ui/DefaultHeader';
import { TemplateEmail } from './types/TemplateEmail';

let data = [
  { id: 1, name: 'Template Padrão Protesto', template: 'protesto' },
  { id: 2, name: 'Template Padrão Emolumentos', template: 'emolumentos' }
] as TemplateEmail[];

const GaleriaTemplates: React.FC = () => {
  const itemsPerPage = 10;
  //   const [data, setData] = useState<any[]>([]); // TODO TIPAR COM MENSAGERIA E IMPLEMENTAR COM REQUISIÇÕES
  const [displayedData, setDisplayedData] = useState<any[]>([]); // TODO TIPAR COM MENSAGERIA
  const [isLoading, setIsLoading] = useState(false);
  const allPages = Math.ceil(data.length / itemsPerPage);
  const formRef = useRef<FormHandles>(null);

  const onPageChange = (page: number = 1) => {
    const startItem = (page - 1) * itemsPerPage;
    const endItem = page * itemsPerPage;
    setDisplayedData(data.slice(startItem, endItem));
  };

  const clearFilter = useCallback((fieldValue: string): void => {
    formRef.current?.clearField(fieldValue);
  }, []);

  const clearAllFilters = useCallback((): void => {
    formRef.current?.setFieldValue('template_id', '');
    formRef.current?.setFieldValue('template_name', '');
  }, []);

  const handleSubmit = async (fields: any) => {
    try {
      setIsLoading(true);
      console.log(fields);
      setTimeout(() => {
        data = [
          { id: 1, name: 'Template Padrão Protesto', template: 'protesto' },
          { id: 2, name: 'Template Padrão Emolumentos', template: 'emolumentos' }
        ];
        setIsLoading(false);
      }, 1500);
    } catch (error) {
      toast.error('Não foi possível listar os templates de E-mail');
    } finally {
      //   setIsLoading(false);
    }
  };

  useEffect(() => {
    onPageChange();
  }, [data]);

  return (
    <>
      {isLoading && <FullScreenLoader loadingMessage="Buscando Templates..." />}
      <DefaultContainerPages paddingTop paddingBottom>
        <DefaultHeader
          label="Templates de E-mail"
          pageMessage="Visualize os Templates de e-mail padronizados e realize ajustes de nome, frequência de envio e outras configurações."
          marginTop="12px"
        />
        <DefaultListPageContainer marginTop="30px">
          <ResultsArea>
            <TemplateList templates={displayedData} />
            <GoBack />
            <DefaultPagination allPagesNumber={allPages} itemsPerPage={10} itemsNumber={data.length} pageChange={onPageChange} />
          </ResultsArea>
        </DefaultListPageContainer>
      </DefaultContainerPages>
    </>
  );
};

export default GaleriaTemplates;
