import FeedbackImage from '@/assets/images/magnifier.png';
import EmptyFeedback from '@/components/Helpers/EmptyFeedback';
import Button from '@/components/Ui/Button';
import {
  DefaultFilterButton,
  DefaultFilterButtonContainer,
  DefaultFilterContainer,
  DefaultFilterField,
  DefaultFilterInputs,
  DefaultFilterRow
} from '@/components/Ui/DefaultFilterContainer/styles';
import DefaultHeader from '@/components/Ui/DefaultHeader';
import { DefaultContainerPages } from '@/components/Ui/DefaultHeader/style';
import DefaultPagination from '@/components/Ui/DefaultPagination';
import { FullScreenLoader } from '@/components/Ui/FullScreenLoader';
import GoBack from '@/components/Ui/GoBack';
import Input from '@/components/Ui/Input';
import Title from '@/components/Ui/Title';
import { Titulos } from '@/modules/Entidades/ListarTitulos/interfaces/interface';
import { getErrorMessage, getOnlyNumbers } from '@/utils/Utilities';
import { Box } from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { convertResponseToTitulos } from '..//ListarTitulos/utils/handleListTitulo';
import BatchTitlesTable, { CheckedItemsBackTitles } from '../ComandosLote/components/BatchTitlesTable';
import { FeedbackArea } from '../components/EditContainer/styles';
import { FormFiltrosTitulo } from '../ListarTitulos';
import { FiltrosTitulo } from '../ListarTitulos/interfaces/FiltrosTitulo';
import { getTituloRequestFiltrar } from '../ListarTitulos/providers/getTituloRequestFiltrar';
import CriarOfertaForm from './components/CriarOfertaForm';

const CriarOfertaTitulo: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { id } = useParams();
  const [mask, setMask] = useState('99.999.999/9999-99');
  const [selectedTitles, setSelectedTitles] = useState<Titulos[]>([]);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Titulos[]>([]);
  const [displayedData, setDisplayedData] = useState<Titulos[]>([]);
  const itemsPerPage = 30;
  const allPages = Math.ceil(data.length / itemsPerPage);

  const onPageChange = (page = 1) => {
    const startItem = (page - 1) * itemsPerPage;
    const endItem = page * itemsPerPage;
    setDisplayedData(data.slice(startItem, endItem));
  };

  const handleSetMask = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (!inputValue.length || inputValue.length > 14) {
      setMask('99.999.999/9999-99');
    } else {
      setMask('999.999.999-999');
    }
  }, []);

  const handleSelectTitles = async (checkedItems: CheckedItemsBackTitles[]) => {
    const selectedTitles = checkedItems.filter((item) => item.isChecked);

    if (selectedTitles.length > 0) {
      try {
        setIsLoading(true);
        setLoadingMessage('Selecioando Títulos...');

        const selectedTitlesIds = checkedItems.filter((item) => item.isChecked).map((item) => item.title);

        // verificar se todos os titulos tem o mesmo statu_codigo 102, 144 e 320
        const statusCodigos = selectedTitles.map((item) => item.title.status_codigo);
        const allStatusValid = statusCodigos.every((status) => status === statusCodigos[0]);
        if (!allStatusValid) {
          toast.warning('Títulos possuem status diferentes, selecione apenas títulos custas ou protesto!');
          return;
        }

        // verificar se o devedor tem o mesmo documento
        const documentosDevedores = selectedTitles.map((item) => item.title.numero_identificacao_devedor);
        const allDocumentsSame = documentosDevedores.every((doc, _, arr) => doc === arr[0]);
        if (!allDocumentsSame) {
          toast.warning('Títulos possuem diferentes devedores!');
          return;
        }
        setSelectedTitles(selectedTitlesIds);
      } catch (e) {
        const errorMsg = getErrorMessage(e, 'Não foi possível selecionar Título!');
        toast.error(errorMsg);
      } finally {
        setIsLoading(false);
      }
    } else {
      toast.warning('É preciso selecionar um ou mais títulos para criar uma oferta!');
    }
  };

  const handleSubmit = async (fields: FormFiltrosTitulo) => {
    try {
      setIsLoading(true);
      setLoadingMessage('Carregando Títulos...');

      const numero_identificacao_devedor = getOnlyNumbers(fields.numero_identificacao_devedor);

      const params = {
        id: fields.id,
        is_deleted: false,
        numero_titulo: fields.numero_titulo,
        numero_identificacao_devedor: numero_identificacao_devedor,
        no_current_agreement: true
      } as FiltrosTitulo;

      const titlesList = await getTituloRequestFiltrar(params);

      if (titlesList?.length > 0) {
        const titles = convertResponseToTitulos(titlesList);
        setData(titles);
      } else {
        toast.warning('Não foram encontrados Títulos para este Filtro!');
        setData([]);
      }
    } catch (error) {
      toast.error('Não foi possível listar os Títulos!');
    } finally {
      setIsLoading(false);
    }
  };

  const clearFilter = useCallback((fieldValue: string) => {
    formRef.current?.clearField(fieldValue);
  }, []);

  const clearAllFilters = useCallback(() => {
    formRef.current?.reset();
  }, []);

  const clearSelectedTitles = useCallback(() => {
    setSelectedTitles([]);
  }, []);

  useEffect(() => {
    onPageChange();
  }, [data]);

  return (
    <>
      {isLoading && <FullScreenLoader loadingMessage={loadingMessage} />}
      {selectedTitles.length > 0 ? (
        <CriarOfertaForm selectedTitles={selectedTitles} clearSelectedTitles={clearSelectedTitles} />
      ) : (
        <DefaultContainerPages paddingTop paddingBottom>
          <DefaultHeader label="Criar Oferta Título" marginTop="12px" />
          <DefaultFilterContainer margin="0px 0px 24px 0px">
            <Form className="editForm" ref={formRef} onSubmit={handleSubmit}>
              <DefaultFilterInputs>
                <DefaultFilterField>
                  <Input
                    width="100%"
                    name="id"
                    type="string"
                    placeholder="Ex.: 3200"
                    label="ID do Título"
                    padding="8px 8px 8px 6px"
                    iconSize="1.2rem"
                    isClearable={true}
                    defaultValue={id}
                    clearFunction={() => clearFilter('id')}
                  />
                </DefaultFilterField>
                <DefaultFilterField>
                  <Input
                    width="100%"
                    name="numero_titulo"
                    type="string"
                    placeholder="Ex.: 01303030"
                    label="Número do Título"
                    padding="8px 8px 8px 6px"
                    iconSize="1.2rem"
                    isClearable={true}
                    clearFunction={() => clearFilter('numero_titulo')}
                  />
                </DefaultFilterField>
                <DefaultFilterField>
                  <Input
                    width="100%"
                    name="numero_identificacao_devedor"
                    type="string"
                    placeholder="Ex.: 000.000.000-00"
                    mask={mask}
                    onChange={handleSetMask}
                    maskChar=""
                    label="CPF/CNPJ"
                    padding="8px 8px 8px 6px"
                    iconSize="1.2rem"
                    isClearable={true}
                    data-cy="cpfCnpj"
                    clearFunction={() => clearFilter('numero_identificacao_devedor')}
                  />
                </DefaultFilterField>
              </DefaultFilterInputs>
              <DefaultFilterRow padding="0px 10px 0px 10px">
                <DefaultFilterButtonContainer>
                  <DefaultFilterButton>
                    <Button type="button" onClick={clearAllFilters} size="big" color="warning" width="100%" disabled={!!isLoading}>
                      Limpar Filtros
                    </Button>
                  </DefaultFilterButton>
                </DefaultFilterButtonContainer>
                <DefaultFilterButtonContainer>
                  <DefaultFilterButton>
                    <Button
                      id="filterButton"
                      type="submit"
                      minWidth="180px"
                      size="big"
                      color="primary"
                      width="100%"
                      loading={isLoading}
                      disabled={!!isLoading}
                      data-cy="filtrarTitulos"
                    >
                      Filtrar
                    </Button>
                  </DefaultFilterButton>
                </DefaultFilterButtonContainer>
              </DefaultFilterRow>
            </Form>
          </DefaultFilterContainer>
          {displayedData?.length === 0 ? (
            <FeedbackArea>
              <EmptyFeedback
                imagePath={FeedbackImage}
                title="Clique em Filtrar para buscar Resultados"
                message="
            Utilize os filtros acima para encontrar os Títulos para criação de ofertas"
              />
            </FeedbackArea>
          ) : (
            <>
              <Title label="Títulos encontrados" isVisiblePixel marginBottom="24px" />
              <BatchTitlesTable
                isTitleOffers={true}
                allTitles={data}
                displayedTitles={displayedData}
                sendCommands={handleSelectTitles}
              ></BatchTitlesTable>
              <Box mt="24px">
                <GoBack />
                <DefaultPagination
                  allPagesNumber={allPages}
                  itemsPerPage={itemsPerPage}
                  itemsNumber={data.length}
                  pageChange={onPageChange}
                />
              </Box>
            </>
          )}
        </DefaultContainerPages>
      )}
    </>
  );
};

export default CriarOfertaTitulo;
