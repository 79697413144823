import { Action } from 'redux'

export enum AuthEnum {
  LOGIN_EMPRESAS_REQUEST = 'LOGIN_EMPRESAS_REQUEST',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAILURE = 'LOGIN_FAILURE',

  LOGOUT_REQUEST = 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE = 'LOGOUT_FAILURE',
}

export interface AuthStateType {
  data: {
    name: string
  }
  error: {
    code: number
    message: string
  }
  access_token: string | null
  email: string | null
  isAuthorized: boolean
  corporation_alias: string | null
  corporation_white_label_data: {
    logo_url: string
    logo_frame: boolean
    primary_color: string
    secondary_color: string
    email: string
    phone: string
  }
  loading: boolean
}

export interface LoginEmpresasRequestPayload {
  email?: string
  password?: string
  recaptchaToken?: string
}

export interface LoginSuccessPayload {
  access_token: string
}

export interface ErrorPayload {
  code: number
  message: string
}

export interface ActionTypes extends Action<AuthEnum> {
  loginEmpresasRequest: (login: LoginEmpresasRequestPayload) => Action
  loginSuccess: (data: LoginSuccessPayload) => Action
  loginFailure: (data: ErrorPayload) => Action

  logoutRequest: () => Action
  logoutSuccess: () => Action
  logoutFailure: () => Action
}
