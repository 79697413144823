import { MenuItem } from '@/modules/_layouts/components/HeaderCompany/interfaces/MenuItem';
import lodash from 'lodash';
import React, { useCallback, useState } from 'react';
import HeaderDropdown from '../HeaderDropdown';
import IconController from '../IconController';
import { Container, NavItem, Notifications } from './styles';

interface Props {
  items: MenuItem[];
  isVisible?: boolean;
  mobileIsVisible?: boolean;
  navType?: 'navBar' | 'dropNav';
  mobileNavType?: 'navBar' | 'dropNav';
  notify?: boolean;
  colorHover?: 'primary';
}

interface DropdownController {
  index: number;
  isDropdownOpen: boolean;
}

const Nav: React.FC<Props> = ({ items, navType, mobileNavType, isVisible, mobileIsVisible, notify = false, ...rest }) => {
  const pathname = window.location.pathname;
  const [dropdownMenus, setDropdownMenus] = useState<DropdownController[]>([]);

  const handleDropdownMenus = useCallback(
    (index: number, value: boolean) => {
      let dropdownMenusClone: DropdownController[] = [];
      if (!dropdownMenus.length) {
        for (let i = 0; i < items.length; i++) {
          const isDropdownOpen = i === index ? value : false;
          dropdownMenusClone.push({ index: i, isDropdownOpen: isDropdownOpen });
        }
      } else {
        dropdownMenusClone = lodash.cloneDeep(dropdownMenus);
        dropdownMenusClone.forEach((dmc) => (dmc.index === index ? (dmc.isDropdownOpen = value) : ''));
      }
      setDropdownMenus(dropdownMenusClone);
    },
    [items]
  );

  const checkPathname = useCallback(
    (navItemId: string): boolean => {
      if (navItemId === '/titulos') {
        const pathTitulos = pathname.split('/');
        if ((pathname === '/titulos' || pathTitulos.includes('titulos')) && !pathTitulos.includes('relatorios')) {
          return true;
        }
        return false;
      }
      if (navItemId === '/ofertas') {
        const pathOfertas = pathname.split('/');
        if (
          pathname === '/ofertas' ||
          pathname === '/ofertas/listar-ofertas-globais' ||
          pathname === '/ofertas/listar-ofertas-globais/criar-oferta-global' ||
          pathname === '/ofertas/listar-ofertas-titulo' ||
          pathname === '/ofertas/listar-ofertas-titulo/criar-oferta-titulo' ||
          pathOfertas.includes('ofertas/listar-ofertas-globais') ||
          pathOfertas.includes('ofertas/listar-ofertas-titulo')
        ) {
          return true;
        }
        return false;
      }
      if (navItemId === '/mensageria') {
        const pathMensageria = pathname.split('/');
        if (pathname === '/mensageria' || pathMensageria.includes('mensageria')) {
          return true;
        }
        return false;
      }
      if (navItemId === '/relatorios') {
        const pathRelatorios = pathname.split('/');
        if (pathname === '/relatorios' || pathRelatorios.includes('relatorios')) {
          return true;
        }
        return false;
      }
      if (navItemId === '/entidades') {
        const pathEntidades = pathname.split('/');
        if (pathname === '/entidades' || pathname === '/criar-entidade' || pathEntidades.includes('entidades')) {
          return true;
        }
        return false;
      }
      if (navItemId === '/usuarios') {
        const pathUsuarios = pathname.split('/');
        if (pathname === '/usuarios' || pathname === '/criar-usuario' || pathUsuarios.includes('usuarios')) {
          return true;
        }
        return false;
      }
      if (navItemId === '/usuarios') {
        const pathUsuarios = pathname.split('/');
        if (pathname === '/usuarios' || pathname === '/criar-usuario' || pathUsuarios.includes('usuarios')) {
          return true;
        }
        return false;
      }
      if (navItemId === '/my-deals' && pathname === '/my-deals') {
        return true;
      }
      if (navItemId === '/deal-offers' && pathname === '/deal-offers') {
        return true;
      }
      return false;
    },
    [pathname]
  );

  const handleCloseDropdowns = useCallback(() => {
    handleDropdownMenus(-1, false);
  }, []);

  const handleHover = useCallback(
    (index: number, value: boolean) => {
      handleDropdownMenus(index, value);
    },
    [items]
  );

  return (
    <Container
      isVisible={isVisible}
      mobileIsVisible={mobileIsVisible}
      navType={navType}
      mobileNavType={mobileNavType}
      colorHover="secondary"
    >
      {items.map((item, index) => (
        <NavItem
          key={item.id}
          onClick={() => {
            handleCloseDropdowns();
            item.onClickHandler();
          }}
          onMouseEnter={() => handleHover(index, true)}
          onMouseLeave={() => handleHover(index, false)}
          value={item.id}
          selected={checkPathname(item.id)}
          data-cy={item.dataCy}
        >
          {item.label}
          {item.icon && <IconController paddingLeft={'8px'} paddingRight={'8px'} icon={item.icon} />}
          {notify && <Notifications>{item.notifications}</Notifications>}
          {dropdownMenus[index] && dropdownMenus[index].isDropdownOpen && item.dropdown && (
            <HeaderDropdown
              closeDropdown={handleCloseDropdowns}
              isDropdownOpen={dropdownMenus[index].isDropdownOpen}
              dropdownItems={item.dropdown}
            ></HeaderDropdown>
          )}
        </NavItem>
      ))}
    </Container>
  );
};

export default Nav;
