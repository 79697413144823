import styled from 'styled-components';
import theme from '@/assets/styles/theme';

interface InputMaskProps {
  customMargin?: string;
  isView?: boolean;
}

export const AreaInputMask = styled.div<InputMaskProps>`
  display: ${(props) => !props.isView && 'none !important'};

  label {
    display: flex;
    margin: ${(InputMaskProps) => (InputMaskProps.customMargin ? InputMaskProps.customMargin : '10px 0px')};
    color: ${theme.colors.gray[500]};
    font-size: 15px;
    gap: 12px;
    font-weight: 400;
  }
  .NumberFormat {
    border: 0.063em solid ${theme.colors.gray[300]};
    color: ${theme.colors.gray[700]};
    display: block;
    font-size: 20px;
    padding: 12px;
    margin-top: 10px;
    background-color: ${theme.colors.white};
    border-radius: 5px;
    transition: border-color 0.2s;
    width: 100%;
    height: 45px;

    &[disabled] {
      background: ${theme.colors.gray[200]};
      cursor: not-allowed;
    }

    &::placeholder {
      color: ${theme.colors.gray[300]};
    }
  }
`;

export const InputMaskLabel = styled.div<InputMaskProps>`
  margin: ${({ customMargin }) => (customMargin ? customMargin : '20px 0 10px 0')};
`;
