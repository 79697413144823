import Button from '@/components/Ui/Button';
import { FullScreenLoader } from '@/components/Ui/FullScreenLoader';
import IconController from '@/components/Ui/IconController';
import ModalConfirm from '@/components/Ui/Modal/ModalConfirm';
import ModalError from '@/components/Ui/Modal/ModalError';
import Text from '@/components/Ui/Text';
import DescriptionContainer from '@/modules/Deal/components/DescriptionContainer';
import {
  BtnItemActive,
  BtnItemDisabled,
  PaymentDiscount,
  SectionBtn,
  TextContainer
} from '@/modules/Entidades/ListarOfertasGlobais/components/paginate/styles';
import ChipStatus from '@/modules/Entidades/ListarOfertasTitulo/components/chipStatus/ChipStatus';
import ChipStatusContainer from '@/modules/Entidades/ListarOfertasTitulo/components/chipStatusContainer';
import { DiscountImageIcon, SectionDiscount } from '@/modules/Entidades/ListarOfertasTitulo/components/paginate/styles';
import { OfertaTitulo } from '@/modules/Entidades/ListarOfertasTitulo/interfaces/interface';
import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { CancelarOfertaTitulo } from '../../providers/cancelarOfertaTitulo';
import { Container, ContainerButton, DiscountImage, ItemDetails, ListContainer } from './styles';
import { is } from 'immutable';

interface IProps {
  offers: OfertaTitulo[];
  reloadData: (loadingMessage: string) => Promise<void>;
}

const ListaOfertaTitulo: React.FC<IProps> = ({ offers, reloadData }) => {
  const [showModalSucess, setShowModalSucess] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [ofertaTituloId, setOfertaTituloId] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleOferta = useCallback((oferta: OfertaTitulo, tipoDescricao: string) => {
    if (!oferta.desconto && !oferta.desconto_porcentagem) {
      if (tipoDescricao === 'label') {
        return 'Emolumentos';
      }
    } else {
      return getTipoDesconto(oferta, tipoDescricao);
    }
  }, []);

  const getTipoDesconto = (oferta: OfertaTitulo, tipoDescricao: string): string => {
    let ofertaDescontoNumber = oferta.desconto;
    if (typeof oferta.desconto === 'string') {
      ofertaDescontoNumber = Number(oferta.desconto);
    }
    if (ofertaDescontoNumber) {
      if (tipoDescricao === 'label') {
        return 'Desconto (R$)';
      } else {
        return Number(oferta.desconto).toFixed(2);
      }
    } else {
      if (tipoDescricao === 'label') {
        return 'Desconto (%)';
      } else {
        return oferta.desconto_porcentagem;
      }
    }
  };

  const handleSendCancelOffer = async (id: any) => {
    try {
      setIsLoading(true);
      setOfertaTituloId(id);
      const payload = {
        id: id,
        is_active: false
      };

      await CancelarOfertaTitulo(payload);
      setShowModalSucess(true);
    } catch (error) {
      toast.error('Não foi possível Desabilitar a Oferta');
      setShowModalError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <FullScreenLoader loadingMessage="Desabilitando Oferta..." />}
      <ListContainer>
        {offers.map((item: OfertaTitulo) => {
          return (
            <Container key={item.key}>
              <DescriptionContainer>
                <ItemDetails>
                  <Text size="medium" bold>
                    Id da Oferta: {item.id}
                  </Text>
                  <Text margin="12px 0px 0px 0px" size="small">
                    Id do Título: {item.titulo}
                  </Text>
                </ItemDetails>
                <ItemDetails>
                  <ChipStatusContainer size="small" bold>
                    <ChipStatus statusArquivo={item.status} />
                  </ChipStatusContainer>
                </ItemDetails>

                

                <SectionDiscount>
                  <TextContainer>
                    <Text margin="0px 0px 12px 0px" size="medium" bold>
                      {handleOferta(item, 'label')}
                    </Text>
                  </TextContainer>
                  <PaymentDiscount>
                    <DiscountImage>
                      {Number(item.desconto) > 0 || Number(item.desconto_porcentagem) > 0 ? (
                        <DiscountImageIcon>
                          <Text align="center" color="white" size="medium" bold>
                            {item.desconto ? `R$ ${item.desconto}` : `% ${item.desconto_porcentagem}`}
                          </Text>
                        </DiscountImageIcon>
                      ) : (
                        <Text align="center" size="small" color="white" bold>
                          Oferta sem desconto
                        </Text>
                      )}
                    </DiscountImage>
                  </PaymentDiscount>
                </SectionDiscount>

                {item.status === true ? (
                  <ContainerButton>
                    <Button full color="error" onClick={() => handleSendCancelOffer(item.id)}>
                      Desabilitar Oferta
                    </Button>
                  </ContainerButton>
                ) : (
                  <ContainerButton>
                    <Button disabled full color="cancelar">
                      Oferta Desabilitada
                    </Button>
                  </ContainerButton>
                )}

                <ModalConfirm
                  isOpen={showModalSucess}
                  onClose={() => {
                    setShowModalSucess(false);
                    reloadData('Carregando Informações Atualizadas...');
                  }}
                  handleButtonClick={() => {
                    setShowModalSucess(false);
                    reloadData('Carregando Informações Atualizadas...');
                  }}
                  label={`Oferta Titulo ${ofertaTituloId} Desabilitada com Sucesso`}
                  buttonLabel="Ok"
                />
                <ModalError
                  isOpen={showModalError}
                  onClose={() => setShowModalError(false)}
                  handleButtonClick={() => setShowModalError(false)}
                  label="Erro na Solicitação, verifique os dados informados"
                  buttonLabel="Ok"
                />
              </DescriptionContainer>
            </Container>
          );
        })}
      </ListContainer>
    </>
  );
};

export default ListaOfertaTitulo;
