import { FaUserCircle } from 'react-icons/fa'
import { IoIosArrowDown } from 'react-icons/io'

import styled from 'styled-components'

import theme from '@/assets/styles/theme'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`

export const UserAvatar = styled(FaUserCircle)`
  width: 43px;
  height: 43px;
  color: ${theme.colors.white};

  @media only screen and (max-width: 1200px) {
    width: 30px;
    height: 30px;
  }
`

export const UserText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 10px;

  @media only screen and (max-width: 1200px) {
    display: none;
  }
`
export const HelloUser = styled.div`
  font-size: 1.25rem;
  font-weight: bolder;
  padding: 4px 0;

  @media only screen and (max-width: 1200px) {
    display: none;
  }
`

export const CpfUser = styled.div`
  font-size: 1rem;
  padding: 4px 0;

  @media only screen and (max-width: 1200px) {
    display: none;
  }
`

export const MenuIcon = styled(IoIosArrowDown)`
  font-size: 1.25rem;

  @media only screen and (max-width: 1200px) {
    display: none;
  }
`

export default Container
