import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from '@/store/ducks/SignIn';

import { IconLogout, Container, NavItem, IconPencil } from './styles';
import { useHistory } from 'react-router-dom';
import { ISignInProps } from '../UserSetting';
import jwtDecode from 'jwt-decode';
import { IUserCompanyProps } from '@/components/Interfaces/UserCompanyProps';

const DropdownNav: React.FC = () => {
  const accessToken = useSelector((state: ISignInProps) => state.SignIn.access_token);
  const dispatch = useDispatch();
  const history = useHistory();

  const [isMounted, setIsMounted] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (accessToken && !isMounted) {
      const userDecode = jwtDecode<IUserCompanyProps>(accessToken);
      if (userDecode) {
        const isAdmin = userDecode.is_superuser_protestado || userDecode.is_admin ? true : false;
        setIsAdmin(isAdmin);
        setIsMounted(true);
      }
    }
  }, []);
  return (
    <Container>
      {/* {isAdmin && (
        <NavItem color={theme.colors.secondary} onClick={() => history.push('/painel-financeiro')}>
          <IconPencil />
          Painel Financeiro
        </NavItem>
      )} */}
      <NavItem color="#FF4040" onClick={() => dispatch(Creators.logoutRequest())}>
        <IconLogout />
        Sair
      </NavItem>
    </Container>
  );
};

export default DropdownNav;
