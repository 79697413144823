import styled from 'styled-components';

export const ApplicableTitleContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const LayoutApplicableTitles = styled.div`
  display: grid;
  gap: 12px;
`;

export const ApresentanteBtnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
`;
