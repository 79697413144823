import styled, { css } from "styled-components";

import flex from "@/assets/styles/flex";
import theme from "@/assets/styles/theme/";


export const Container = styled.div`
  ${flex.direction.column};
  ${flex.justify.spaceBetween}
  padding: 0px 0px;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: ${theme.colors.white};
  @media only screen and (max-width: 1366px) {
    margin-bottom: 30px;
  }
  @media only screen and (max-width: 768px) {
    padding: 20px;
    margin-bottom: 20px;
  }
`;

export const UploadDetails = styled.div`
  ${flex.direction.column}
  ${flex.justifySpaceBetween.alignStart}
  margin: 0 10px;

  @media only screen and (max-width: 768px) {
    margin: 0;
    height: 60px;
    margin-top: 30px;
  }
`;

export const ListContainer = styled.div`
  overflow-y:auto;
  width: 80%;
  margin: 10px 10px 10px 10px;
`
