import api from '@/services/api';
import { Entidades } from '../interfaces/interface';

export const getCorporationsRequestFiltrar = async (params: Entidades) => {
  const { data } = await api.get(`/corporation/`, {
    params: {
      ...(params.email ? { email: params.email } : {}),
      ...(params.name ? { name: params.name } : {}),
      ...(params.id ? { id: params.id } : {}),
      is_active: params.is_active
    }
  });
  return data;
};
