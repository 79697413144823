import styled, { css } from 'styled-components';

import flex from '@/assets/styles/flex';
import theme from '@/assets/styles/theme/';

interface ItemDetailsProps {
  width?: string;
}
interface Props {
  status: string;
}

const heights = {
  descriptionItem: css`
    height: 78px;

    @media only screen and (max-width: 768px) {
      height: 56px;
    }
  `
};

export const Container = styled.div`
  ${flex.direction.column};
  ${flex.justify.spaceBetween}
  padding: 20px 40px;
  overflow-y: auto;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: ${theme.colors.white};
  @media only screen and (max-width: 1366px) {
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 768px) {
    padding: 20px;
    margin-bottom: 20px;
  }
`;

export const ItemDetails = styled.div<ItemDetailsProps>`
  ${flex.direction.column}
  ${flex.justifySpaceBetween.alignStart}
  margin: 10px 10px;
  justify-content: center;
  width: ${(props) => (props.width ? props.width : '')};
  height: 50px;

  @media only screen and (max-width: 768px) {
    margin: 0;
    height: 60px;
    margin-top: 30px;
  }
`;

export const ContainerButton = styled.div`
  margin: 10px 0 0 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const ChipStatus = styled.div<Props>`
  background-color: #26d076;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0px 10px 5px 10px;
  padding: 5px 0px 5px 10px;
  border-radius: 10px;
  color: white;
  min-width: 200px;
`;

export const ListContainer = styled.div``;

export const BtnItemActive = styled.div`
  display: flex;
  background-color: #5fd17d;
  margin: 0px 10px 5px 10px;
  padding: 15px 15px 15px 15px;
  border-radius: 10px;
  color: white;
  min-width: 175px;
  min-height: 25px;
`;

export const BtnItemError = styled.div`
  display: flex;
  background-color: red;
  margin: 0px 10px 5px 10px;
  padding: 15px 15px 15px 15px;
  border-radius: 10px;
  color: white;
  min-width: 175px;
  min-height: 25px;
`;

export const BtnItemPending = styled.div`
  display: flex;
  background-color: gray;
  margin: 0px 10px 5px 10px;
  padding: 15px 15px 15px 15px;
  border-radius: 10px;
  color: white;
  min-width: 175px;
  min-height: 25px;
`;
