import flex from '@/assets/styles/flex';
import theme from '@/assets/styles/theme';
import styled, { css } from 'styled-components';

interface NavProps {
  isVisible?: boolean;
  mobileIsVisible?: boolean;
  navType?: 'navBar' | 'dropNav';
  mobileNavType?: 'navBar' | 'dropNav';
  notify?: boolean;
  selected?: boolean;
  colorHover?: string;
  headerFooterFontColor?: string;
}

const directions = {
  row: css`
    flex-direction: row;
    width: 100%;
    height: auto;
  `,
  column: css`
    flex-direction: column;
    width: auto;
  `
};

const selectors = {
  bottom: css`
    border-bottom: 3px solid transparent;
  `
};

const visibles = {
  show: css`
    display: flex;
  `,
  hide: css`
    display: none;
  `
};

const isSelected = (type = 'secondary') => {
  switch (type) {
    case 'secondary':
      return css`
        border-color: ${theme.colors.secondary};
      `;
  }
};

const hoverColor = (type = 'secondary') => {
  const headerFooterFontColor = `${theme.colors.secondary}`;

  switch (type) {
    case 'secondary':
      return css`
        color: ${theme.colors.secondary};
      `;
    case 'headerFooterFontColor':
      return css`
        color: ${headerFooterFontColor};
      `;
  }
};

export const Notifications = styled.div<NavProps>`
  ${flex.direction.row}
  ${flex.justifyCenter.alignCenter}

  width: 25px;
  height: 25px;
  margin: 0 0 0 10px;
  background-color: ${theme.colors.white};
  border-radius: 50%;
  font-size: 1.25rem;
  color: ${(props) => props.headerFooterFontColor};

  /* @media (max-width: 1366px) {
    width: 15px;
    height: 15px;
  } */
`;

export const NavItem = styled.button<NavProps>`
  ${flex.direction.row}
  ${flex.align.center}

  padding: 6px;
  margin: 0px 6px;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 3;

  ${selectors.bottom};
  ${({ selected }) => selected && isSelected('secondary')};

  &:focus {
    outline: none;
  }
`;

const navTypes = {
  navBar: css`
    ${directions.row};

    > ${NavItem} {
      color: ${({ headerFooterFontColor }) => headerFooterFontColor};
      font-size: 1.15rem;
      font-weight: bold;
      transition: all 0.1s;

      @media only screen and (max-width: 1440px) {
        font-size: 1.05rem;
      }

      @media only screen and (max-width: 1366px) {
        font-size: 1rem;
      }

      @media only screen and (max-width: 1024px) {
        font-size: 0.9rem;
      }

      &:hover {
        ${({ colorHover }) => colorHover && hoverColor('headerFooterFontColor')};
      }
    }
  `,

  dropNav: css`
    ${directions.column};
    position: absolute;
    align-items: center;
    top: 9vh;
    left: 0px;
    margin-left: 10px;
    z-index: 1;
    padding: 15px;
    border-radius: 4px;
    background-color: ${theme.colors.white};
    box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.1);

    > ${NavItem} {
      color: ${({ headerFooterFontColor }) => headerFooterFontColor};
      font-size: 15px;
      font-weight: bold;
    }
  `
};

export const Container = styled.div<NavProps>`
  ${(props) => (props.isVisible ? visibles.show : visibles.hide)};
  ${(props) => props.navType && navTypes[props.navType]}

  @media (max-width: 768px) {
    ${(props) => (props.mobileIsVisible ? visibles.show : visibles.hide)};
    ${(props) => props.mobileNavType && navTypes[props.mobileNavType]}
  }
`;

export default Container;
