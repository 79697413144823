import Button from '@/components/Ui/Button';
import Text from '@/components/Ui/Text';
import DescriptionContainer from '@/modules/Deal/components/DescriptionContainer';
import ChipStatusContainer from '@/modules/Entidades/ListarOfertasTitulo/components/chipStatusContainer';
import { formatarDinheiro } from '@/utils/Utilities';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Titulos } from '../../interfaces/interface';
import { BtnItemActive, BtnItemDisabled, Container, ContainerButton, ItemDetails, ListButtonContainer } from './styles';

interface IProps {
  posts: Titulos[];
}

const DataList: React.FC<IProps> = ({ posts }) => {
  const history = useHistory();

  const getValorCustas = (item: Titulos): number => {
    const valorCustas =
      item.status_codigo && item.status_codigo === '102'
        ? Number(item.valor_custas_calculado)
        : item.valor_custas
        ? Number(item.valor_custas)
        : 0;
    return valorCustas;
  };

  const getValorTotal = (item: Titulos) => {
    const saldoProtesto = item.valor_saldo_protesto ? Number(item.valor_saldo_protesto) : 0;
    const valorCustas = getValorCustas(item);
    return item.status_codigo == '320' ? formatarDinheiro(item.valor_titulo) : formatarDinheiro(Number(saldoProtesto) + Number(valorCustas));
  };

  return (
    <div className="list__container">
      <div className="list_data">
        {posts.map((item: Titulos, index: number) => {
          return (
            <Container key={item.id}>
              <DescriptionContainer>
                <ItemDetails width="25%">
                  <Text margin="0px 0px 0px 0px" size="medium" bold>
                    ID do título:{item.id}
                  </Text>
                  <Text margin="12px 0px 0px 0px" size="small">
                    Valor Total: R$ {getValorTotal(item)}
                  </Text>
                  <Text margin="12px 0px 0px 0px" size="small">
                    Valor Título: {formatarDinheiro(Number(item.valor_titulo))}
                  </Text>
                  <Text margin="12px 0px 0px 0px" size="small">
                    Valor Protestado: {formatarDinheiro(Number(item.valor_saldo_protesto))}
                  </Text>
                  <Text margin="12px 0px 0px 0px" size="small">
                    Valor Custas: {formatarDinheiro(getValorCustas(item))}
                  </Text>
                </ItemDetails>
                <ItemDetails width="25%">
                  <Text margin="0px 0px 0px 0px" size="medium" bold>
                    Nome: {item.nome_devedor}
                  </Text>
                  <Text margin="12px 0px 0px 0px" size="small">
                    Número do Título: {item.numero_titulo}
                  </Text>
                  <Text margin="12px 0px 0px 0px" size="small">
                    Nosso Número: {item.nosso_numero}
                  </Text>
                </ItemDetails>
                <ItemDetails width="25%">
                  <Text margin="12px 0px 0px 0px" size="small">
                    Data do vencimento: {moment(item.data_vencimento).format('DD/MM/YYYY')}
                  </Text>
                  <ChipStatusContainer size="small">
                    {item.is_deleted === true ? (
                      <BtnItemDisabled>
                        <h2>Título Desabilitado</h2>
                      </BtnItemDisabled>
                    ) : (
                      <BtnItemActive>
                        <h2>Título Ativo</h2>
                      </BtnItemActive>
                    )}
                    {item.email_callback === false ? (
                      <BtnItemDisabled>
                        <h2>Mensageria</h2>
                      </BtnItemDisabled>
                    ) : (
                      <BtnItemActive>
                        <h2>Mensageria</h2>
                      </BtnItemActive>
                    )}
                  </ChipStatusContainer>
                </ItemDetails>
                <ListButtonContainer>
                  <ContainerButton>
                    <Button
                      data-cy={`criarOfertaTitulo${index}`}
                      margin="0px !important"
                      color="secondary"
                      full
                      onClick={() => history.push(`/ofertas/listar-ofertas-titulo/criar-oferta-titulo/${item.id}`)}
                    >
                      Criar Oferta
                    </Button>
                  </ContainerButton>
                  <ContainerButton>
                    <Button
                      data-cy={`selecionarTitulo${index}`}
                      margin="0px !important"
                      full
                      onClick={() => history.push(`/titulos/listar-titulos/${item.id}`)}
                    >
                      Ver Título
                    </Button>
                  </ContainerButton>
                </ListButtonContainer>
              </DescriptionContainer>
            </Container>
          );
        })}
      </div>
    </div>
  );
};

export default DataList;
