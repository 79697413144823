import React, { useRef, useCallback, useState, useEffect } from 'react';
import * as Yup from 'yup';
import Button from '@/components/Ui/Button';
import Input from '@/components/Ui/Input';
import ModalConfirm from '@/components/Ui/Modal/ModalConfirm';
import ModalError from '@/components/Ui/Modal/ModalError';
import DefaultHeader from '@/components/Ui/DefaultHeader';
import { DefaultContainer } from '@/components/Ui/DefaultHeader/style';
import { FullScreenLoader } from '@/components/Ui/FullScreenLoader';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import Toggle from '@/components/Ui/Toggle';
import { TextEditorContainer, InputGrid, TemplateContainer, TemplateContainerButton } from './styles';
import TextEditor from '@/components/Ui/TextEditor';
import { toast } from 'react-toastify';
import moment from 'moment';
import { getTemplateEmailById } from './providers/getTemplateEmailById';
import { EmailTemplate } from './interfaces/EmailTemplate';
import { updateTemplateEmail } from './providers/updateTemplateEmail';
import { EmailTemplatePayload } from '../CriarTemplateEmail/interfaces/EmailTemplatePayload';
import { EmailTemplateForm } from '../CriarTemplateEmail/interfaces/EmailTemplateForm';
import { TituloStatusPayload } from '@/components/Interfaces/TituloStatusPayload';
import { getTituloStatus } from '@/services/getTituloStatus';
import TemplateProtesto from '@/components/Ui/Modal/ModalTemplates/DefaultTemplate/TemplateProtesto';
import TemplateEmolumentos from '@/components/Ui/Modal/ModalTemplates/DefaultTemplate/TemplateEmolumentos';
import { Box } from '@chakra-ui/react'
import TemplateList from '../GaleriaTemplates/components/TemplateList';


const EditarTemplateEmail: React.FC = () => {
  const history = useHistory();
  const url = window.location.href.split('/');
  const emailTemplateId = url[5];
  const [contentHtml, setContentHtml] = useState('<p></p>');
  const [loadingMessage, setLoadingMessage] = useState('');
  const [emailText, setEmailText] = useState('');
  const [templateStatus, setTemplateStatus] = useState('');
  // const [emailTemplateId, setEmailTemplateId] = useState('');
  const [isMounted, setIsMounted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const [isRecorrente, setIsRecorrente] = useState(false);
  const handleChangeRecorrente = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsRecorrente(e.target.checked);
  };

  const [isActive, setIsActive] = useState(false);
  const handleChangeIsActive = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsActive(e.target.checked);
  };

  const getHtmlContent = (htmlContent: string) => {
    setEmailText(htmlContent);
  };

  const verificarStatusTitulo = async (templateEmail: EmailTemplate): Promise<string> => {
    const statusCodigos = [];
    for (let index = 0; index < templateEmail.status.length; index++) {
      const statusId = templateEmail.status[index];
      const statusTitulo = await getTituloStatus({ id: statusId } as TituloStatusPayload);
      if (statusTitulo?.results?.length > 0) {
        const statusTituloCodigo = statusTitulo.results[0].codigo;
        statusCodigos.push(statusTituloCodigo);
      }
    }
    let protestado = false;
    let anuido = false;
    statusCodigos.forEach((codigo) => {
      if (Number(codigo) === 102) protestado = true;
      if (Number(codigo) === 144) anuido = true;
    });

    if (protestado && anuido) {
      return 'Protestados e Anuídos';
    }
    if (protestado) {
      return 'Protestados';
    }
    if (anuido) {
      return 'Anuidos';
    }
    return '';
  };

  const handleSubmit = useCallback(
    async (fields: EmailTemplateForm) => {
      try {
        setIsLoading(true);
        setLoadingMessage('Atualizando Campanha...');

        const payload = {
          id: Number(emailTemplateId),
          is_active: isActive,
          title: fields.assunto,
        } as EmailTemplatePayload;
        const templateAtualizado = await updateTemplateEmail(payload);
        if (templateAtualizado) {
          setShowModal(true);
        }
      } catch (err) {
        setShowModalError(true);
        const validationErrors: { [key: string]: string } = {};

        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: Yup.ValidationError) => {
            validationErrors[error.path] = error.message;
          });
          formRef.current?.setErrors(validationErrors);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [emailText, isActive]
  );

  const getEmailTemplate = useCallback(async () => {
    try {
      setIsLoading(true);
      setLoadingMessage('Carregando Detalhes da Oferta...');
      const templateEmail = await getTemplateEmailById(emailTemplateId);

      if (templateEmail) {
        const data_inicio = moment(templateEmail.start_date).format('DD/MM/YYYY - HH:mm');
        const data_fim = moment(templateEmail.end_date).format('DD/MM/YYYY - HH:mm');
        const status_titulo = await verificarStatusTitulo(templateEmail);
        console.log(status_titulo)
        setTemplateStatus(status_titulo)
        console.log(templateStatus)
        console.log(templateEmail)
        formRef.current?.setData({
          data_inicio: data_inicio,
          data_fim: data_fim,
          assunto: templateEmail.title,
          status_titulo: status_titulo,
          frequencia: templateEmail.intervalo_dias
        });
        setIsActive(templateEmail.is_active);
        setContentHtml(templateEmail.html);
      } else {
        toast.error('Não foi possível carregar seu dados, tente mais tarde!');
      }
    } catch (error) {
      toast.error('Não foi possível carregar seu dados, tente mais tarde!');
    } finally {
      setIsMounted(true);
      setIsLoading(false);
    }
  }, [emailTemplateId, isMounted]);

  useEffect(() => {
    if (!isMounted) getEmailTemplate();
  }, [isMounted]);

  return (
    <>
      {isLoading && <FullScreenLoader loadingMessage={loadingMessage} />}
      <Box w='100%' pt={'100px'} display={'grid'}>
        <Box w='100%' px={10} display={'grid'}>
          <DefaultHeader
            label={`Editar Campanha ${emailTemplateId}`}
            pageMessage="Habilite ou Desabilite uma campanha vigente"
          ></DefaultHeader>
        </Box>
        <Form className="editForm" ref={formRef} onSubmit={handleSubmit}>
          <Box w='100%' px={10} alignItems='center' display={'grid'}>
            <Box w='100%' alignItems='center' px={10} display={'flex'}>
              <Input name="data_inicio" customMargin='10px 5px' type="text" label="Início da Vigência" full readOnly />
              <Input name="data_fim" type="text" customMargin='10px 5px' label="Encerramento da Vigência" full readOnly />
            </Box>
            <Box w='100%' alignItems='center' px={10} display={'flex'}>
              <Input name="status_titulo" customMargin='10px 5px' type="text" label="Status vinculados à Campanha" full readOnly />
              <Input name="frequencia" customMargin='10px 5px' type="text" label="Frequência de envios" full />
              <Toggle
              margin="10px 5px"
              onChange={handleChangeIsActive}
              label="Campanha Ativa"
              value={isActive}
              name="isActive"
              paddingRight
            />
            </Box>
            <Box w='100%' alignItems='center' display={'grid'}>
              <Box w='100%' alignContent={'center'} my={5} px={10} display={'grid'}>
                <Input customMargin='10px 5px' name="assunto" type="text" placeholder="Assunto do E-mail" full />
                <Box>
                  {templateStatus == 'Anuidos' ? (
                    <Box width='100%' my={10}>
                      <TemplateEmolumentos />
                    </Box>
                  )
                    :
                    (null)}
                  {templateStatus == 'Protestados' ? (
                    <Box width='100%' my={10}>
                      <TemplateProtesto />
                    </Box>)
                    :
                    (null)}
                </Box>
              </Box>
              <Box w='100%' justifyContent='center' alignContent={'center'} my={10} px={10} display={'grid'}>
                <Button
                  type="submit"
                  size="big"
                  width="240px"
                  color="primary"
                  loading={isLoading}
                  disabled={!!isLoading}
                  data-cy="atualizarTemplate"
                >
                  Salvar Alterações
                </Button>
              </Box>
            </Box>
          </Box>

        </Form>
      </Box>

      <ModalConfirm
        isOpen={showModal}
        onClose={() => history.push(`/mensageria/listar-campanhas`)}
        handleButtonClick={() => history.push(`/mensageria/listar-campanhas`)}
        label={`Template atualizado com Sucesso! ID: ${emailTemplateId}`}
        buttonLabel="Ok"
      />
      <ModalError
        isOpen={showModalError}
        onClose={() => setShowModalError(false)}
        handleButtonClick={() => setShowModalError(false)}
        label="Erro na solicitação, verifique os dados informados"
        buttonLabel="Ok"
      />
    </>
  );
};

export default EditarTemplateEmail;
