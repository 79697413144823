import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import * as Interfaces from './types'

/* ------------- Types and Action Creators ------------- */

export enum ForgotEnum {
  FORGOT_COMPANY_REQUEST = 'FORGOT_REQUEST',
  FORGOT_SUCCESS = 'FORGOT_SUCCESS',
  FORGOT_FAILURE = 'FORGOT_FAILURE',
}

type ForgotType = typeof ForgotEnum

// CreateActions
export const { Types, Creators } = createActions<ForgotType, Interfaces.ActionTypes>({
  ForgotCompanyRequest: { email: ''},
  ForgotSuccess: ['email'],
  ForgotFailure: { code: 0, message: '' },
})

export interface ForgotStateType {
  data: {
    name: string
  }
  email: string | null
  username: string | null
  cpf: string | null
  loading: boolean
  error: {
    code: number
    message: string
  }
}

/* ------------- Initial State ------------- */

export const INITIAL_STATE: ForgotStateType = Immutable({
  data: {
    name: '',
  },
  cpf: null,
  email: null,
  username: null,
  loading: false,
  error: {
    code: 0,
    message: '',
  },
})

/* ------------- Reducers ------------- */
export const ForgotCompanyRequest = (
  state: ForgotStateType = INITIAL_STATE,
  { email }: Interfaces.ForgotCompanyRequestPayload,
): ForgotStateType => {
  return {
    ...state,
    email,
    loading: true,
  }
}


export const ForgotSuccess = (
  state: ForgotStateType = INITIAL_STATE,
  { email }: Interfaces.ForgotSuccessPayload,
): ForgotStateType => {
  return {
    ...state,
    email,
    loading: false,
  }
}

export const ForgotFailure = (
  state: ForgotStateType = INITIAL_STATE,
  { code, message }: Interfaces.ErrorPayload,
): ForgotStateType => {
  return {
    ...state,
    error: {
      code,
      message,
    },
    loading: false,
  }
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer<ForgotStateType, Interfaces.ActionTypes>(INITIAL_STATE, {
  [Types.FORGOT_COMPANY_REQUEST]: ForgotCompanyRequest,
  [Types.FORGOT_SUCCESS]: ForgotSuccess,
  [Types.FORGOT_FAILURE]: ForgotFailure,
})

// /* ------------- Selectors ------------- */
