import { Titulos } from '../interfaces/interface';

export interface TitulosResponse {
  email: string;
  created_at: string;
  name: string;
  is_active: string;
  apresentante: string;
  apresentante_ofertas: object;
  cidade: string;
  cnpj: string;
  data_emissao: string;
  data_vencimento: string;
  email_callback: boolean;
  email_devedor: string;
  id: string;
  nome_devedor: string;
  nosso_numero: string;
  numero_identificacao_devedor: string;
  numero_parcela: number;
  numero_titulo: string;
  ofertas: object;
  razao: string;
  telefone_devedor: string;
  uf: string;
  valor_saldo_protesto: string;
  valor_titulo: string;
  valor_custas: string;
  valor_custas_calculado: string;
  is_deleted: boolean;
  status_codigo: string;
}

export const handleListTitulo = (titulos: TitulosResponse[]): TitulosResponse[] => {
  return titulos.map((titulo) => {
    return {
      email: titulo.email,
      name: titulo.name,
      created_at: titulo.created_at,
      is_active: titulo.is_active,
      apresentante: titulo.apresentante,
      apresentante_ofertas: titulo.apresentante_ofertas,
      cidade: titulo.cidade,
      cnpj: titulo.cnpj,
      data_emissao: titulo.data_emissao,
      data_vencimento: titulo.data_vencimento,
      email_callback: titulo.email_callback,
      email_devedor: titulo.email_devedor,
      id: titulo.id,
      nome_devedor: titulo.nome_devedor,
      nosso_numero: titulo.nosso_numero,
      numero_identificacao_devedor: titulo.numero_identificacao_devedor,
      numero_parcela: titulo.numero_parcela,
      numero_titulo: titulo.numero_titulo,
      ofertas: titulo.ofertas,
      razao: titulo.razao,
      telefone_devedor: titulo.telefone_devedor,
      uf: titulo.uf,
      valor_saldo_protesto: titulo.valor_saldo_protesto,
      valor_titulo: titulo.valor_titulo,
      valor_custas: titulo.valor_custas,
      valor_custas_calculado: titulo.valor_custas_calculado,
      is_deleted: titulo.is_deleted,
      status_codigo: titulo.status_codigo
    };
  });
};

export const convertResponseToTitulos = (titulos: TitulosResponse[]): Titulos[] => {
  return titulos.map((titulo: TitulosResponse, index: number) => {
    return {
      id: titulo.id,
      nome_devedor: titulo.nome_devedor,
      valor_titulo: titulo.valor_titulo,
      valor_saldo_protesto: titulo.valor_saldo_protesto,
      numero_titulo: titulo.numero_titulo,
      nosso_numero: titulo.nosso_numero,
      data_emissao: titulo.data_emissao,
      data_vencimento: titulo.data_vencimento,
      is_deleted: titulo.is_deleted,
      valor_custas: titulo.valor_custas,
      valor_custas_calculado: titulo.valor_custas_calculado,
      email_callback: titulo.email_callback,
      numero_identificacao_devedor: titulo.numero_identificacao_devedor,
      is_checked: false,
      index_titulo: index,
      email_devedor: titulo.email_devedor,
      status_codigo: titulo.status_codigo
    };
  });
};
