import React from 'react';
import { BsArrowReturnRight, BsChatRightDotsFill } from 'react-icons/bs';
import { FaBuilding, FaCog, FaUser } from 'react-icons/fa';
import { IoMdCheckmarkCircleOutline, IoMdCloseCircleOutline, IoMdSad, IoMdStats } from 'react-icons/io';
import {
  MdEmail,
  MdFormatListBulleted,
  MdHistoryEdu,
  MdKeyboardArrowDown,
  MdLocalOffer,
  MdOutlineBatchPrediction,
  MdOutlineHelpOutline,
  MdSpaceDashboard,
  MdSync,
  MdTagFaces
} from 'react-icons/md';
import { RiFileEditFill, RiFileUploadFill, RiFilter2Fill } from 'react-icons/ri';
import { TbCurrencyReal } from 'react-icons/tb';
import { IconContainer } from './style';

interface Props {
  icon: string;
  paddingLeft?: string;
  paddingRight?: string;
  margin?: string;
  fontSize?: string;
  iconColor?: string;
}

const IconController: React.FC<Props> = ({ icon, paddingLeft = undefined, paddingRight = undefined, margin, fontSize, iconColor }) => {
  return (
    <IconContainer paddingLeft={paddingLeft} paddingRight={paddingRight} margin={margin} fontSize={fontSize} iconColor={iconColor}>
      {icon === 'menuDropdown' && <MdKeyboardArrowDown />}
      {icon === 'upload' && <RiFileUploadFill />}
      {icon === 'list' && <MdFormatListBulleted />}
      {icon === 'offer' && <MdLocalOffer />}
      {icon === 'user' && <FaUser />}
      {icon === 'company' && <FaBuilding />}
      {icon === 'reports' && <IoMdStats />}
      {icon === 'email' && <MdEmail />}
      {icon === 'campanhas' && <RiFileEditFill />}
      {icon === 'mensageria' && <MdHistoryEdu />}
      {icon === 'config' && <FaCog />}
      {icon === 'sms' && <BsChatRightDotsFill />}
      {icon === 'help' && <MdOutlineHelpOutline />}
      {icon === 'dashboard' && <MdSpaceDashboard />}
      {icon === 'checkCircle' && <IoMdCheckmarkCircleOutline />}
      {icon === 'closeCircle' && <IoMdCloseCircleOutline />}
      {icon === 'subitem' && <BsArrowReturnRight />}
      {icon === 'money' && <TbCurrencyReal />}
      {icon === 'happyFace' && <MdTagFaces />}
      {icon === 'sadFace' && <IoMdSad />}
      {icon === 'sync' && <MdSync />}
      {icon === 'filter' && <RiFilter2Fill />}
      {icon === 'lote' && <MdOutlineBatchPrediction />}
    </IconContainer>
  );
};

export default IconController;
