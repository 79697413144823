import { IoMdArrowRoundBack } from 'react-icons/io';

import styled, { css } from 'styled-components';

import theme from '@/assets/styles/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const ButtonGoBack = styled.button`
  border: none;
  text-decoration: none;
  padding: 0px;
  background-color: transparent;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;

  color: ${theme.colors.secondary};
  font-size: 20px;
  font-weight: 700;

  &:focus {
    outline: none;
  }
`;

export const LeftIcon = styled(IoMdArrowRoundBack)`
  width: 25px;
  height: 25px;
  margin-right: 8px;
  color: ${theme.colors.gray[500]};
`;
