import api from '@/services/api';
import { IEmails } from '../interfaces/interface';

export const getTemplatesEmail = async (params: IEmails) => {
  const { data } = await api.get('users/protested-titles/corporation/listar-emails', {
    params: {
      ...(params.id ? { id: params.id } : {}),
      ...(params.assunto ? { assunto: params.assunto } : {}),
      ...(params.data_inicial ? { data_inicial: params.data_inicial } : {}),
      ...(params.data_final ? { data_final: params.data_final } : {}),
      ...(params.status ? { status: params.status } : {})
    }
  });
  return data;
};
