import styled from 'styled-components';

interface IconControllerProps {
  paddingLeft?: string;
  paddingRight?: string;
  margin?: string;
  fontSize?: string;
  iconColor?: string;
}

export const IconContainer = styled.div<IconControllerProps>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '1em')};
  margin: ${(props) => (props.margin ? props.margin : '8px 0px 0px 0px')};
  padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : '')};
  padding-right: ${(props) => (props.paddingRight ? props.paddingRight : '')};
  color: ${(props) => (props.iconColor ? props.iconColor : '')};
`;
