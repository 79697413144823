import api from '@/services/api';

export const CancelarOfertaTitulo = async (params: any) => {
  return api
    .delete(`/users/protested-titles/corporation/ofertas-titulo`, {
      params: {
        ...(params.id ? { id: params.id } : {})
      }
    })
    .then(({ data }) => data);
};
