import { IUserCompanyProps } from '@/components/Interfaces/UserCompanyProps';
import Button from '@/components/Ui/Button';
import DefaultHeader from '@/components/Ui/DefaultHeader';
import { FullScreenLoader } from '@/components/Ui/FullScreenLoader';
import Input from '@/components/Ui/Input';
import ModalConfirm from '@/components/Ui/Modal/ModalConfirm';
import ModalError from '@/components/Ui/Modal/ModalError';
import Select from '@/components/Ui/Select';
import Title from '@/components/Ui/Title';
import { ISignInProps } from '@/modules/_layouts/components/HeaderCompany/components/UserSetting';
import { Box } from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import jwtDecode from 'jwt-decode';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { ContactNotificaDTO, EmailCampaignNotificaDTO } from './interfaces/EmailCampaignNotificaDTO';
import { EmailTemplateForm } from './interfaces/EmailTemplateForm';
import { CreateEmailCampaign } from './providers/CreateEmailCampaign';
import { FilterTitlesCampaign, GetAvailableTitles } from './providers/GetAvailableTitles';
import { EmailTemplate, getEmailTemplates } from './providers/getTemplatesEmail';
import theme from '@/assets/styles/theme';

const tituloStatusOptions = [
  { value: 'TODOS_TITULOS', label: 'Todos' },
  { value: 'PROTESTADOS', label: 'Apenas Protestados' },
  { value: 'ANUIDOS', label: 'Apenas Anuídos (Custas/Emolumentos)' }
];

interface TemplateOptions {
  value: string;
  label: string;
}

interface AvailableTitleStatus {
  TODOS_TITULOS: null;
  PROTESTADOS: number;
  ANUIDOS: number;
}

const availableTitleStatus: AvailableTitleStatus = {
  TODOS_TITULOS: null,
  PROTESTADOS: 102,
  ANUIDOS: 144
};

const CriarTemplateEmail: React.FC = () => {
  const history = useHistory();
  // const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([]);
  const [emailTemplateOptions, setEmailTemplateOptions] = useState<TemplateOptions[]>([]);
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<EmailTemplate>({} as EmailTemplate);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [corporationName, setCorporationName] = useState('');
  const accessToken = useSelector((state: ISignInProps) => state.SignIn.access_token);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const formRef = useRef<FormHandles>(null);

  const clearTemplate = useCallback(() => {
    formRef.current?.clearField('selected_template');
    formRef.current?.clearField('status_titulo');
    formRef.current?.clearField('subject');

    setSelectedTemplate({} as EmailTemplate);
  }, []);

  const handleSelectTemplate = useCallback((): void => {
    setTimeout(() => {
      const templateId = formRef.current?.getFieldValue('selected_template');
      if (templateId && templateId !== '') {
        const template = emailTemplates.find((template) => template.id === templateId);
        if (template) {
          setSelectedTemplate(template);
        }
      }
    }, 50);
  }, [emailTemplates]);

  const mountNotificaPayload = useCallback(
    async (basePayload: EmailTemplateForm): Promise<EmailCampaignNotificaDTO[]> => {
      const finalPayload = [] as EmailCampaignNotificaDTO[];
      const selectedStatus = availableTitleStatus[basePayload.status_titulo as keyof AvailableTitleStatus];
      const availableTitles = await GetAvailableTitles({ status_codigos: selectedStatus } as FilterTitlesCampaign);

      if (availableTitles.length > 0) {
        for (let index = 0; index < availableTitles.length; index++) {
          const title = availableTitles[index];
          const baseFinalPayload = {
            campaign_name: `Campanha Título ${title.nosso_numero}`,
            email_subject: basePayload.subject,
            destination_name: corporationName,
            destination_url: 'https://app.protestado.com.br/',
            ds_nosso_numero: title.nosso_numero,
            time_stamp: false,
            send_email: true,
            email_template_id: basePayload.selected_template,
            send_sms: false,
            sms_template_id: '',
            send_whatsapp: false,
            whatsapp_template_id: '',
            data_table_content: '',
            email_sender: 'naoresponder@mensagemprotesto.com.br'
          } as EmailCampaignNotificaDTO;

          const contacts = [
            {
              name: title.nome_devedor,
              type: 'email',
              value: title.email_devedor
            }
          ] as ContactNotificaDTO[];

          baseFinalPayload.contacts = contacts;
          finalPayload.push(baseFinalPayload);
        }
      }

      return finalPayload;
    },
    [corporationName]
  );

  const handleSubmit = useCallback(
    async (fields: EmailTemplateForm) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          selected_template: Yup.string().required('Por favor selecione o template de email'),
          status_titulo: Yup.string().required('Por favor selecione o status dos Títulos'),
          subject: Yup.string().required('Por favor informe o assunto do e-mail')
        });

        await schema.validate(fields, {
          abortEarly: false
        });

        setIsLoading(true);
        setLoadingMessage('Buscando Títulos disponíveis...');
        const payload = await mountNotificaPayload(fields);
        if (!payload.length) {
          toast.warning('Nenhum título disponível para o status selecionado');
        } else {
          setLoadingMessage('Criando Campanha...');
          const novoTemplate = await CreateEmailCampaign(payload);
          if (novoTemplate) {
            setShowModal(true);
          }
        }
      } catch (err) {
        setShowModalError(true);
        const validationErrors: { [key: string]: string } = {};

        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: Yup.ValidationError) => {
            validationErrors[error.path] = error.message;
          });
          formRef.current?.setErrors(validationErrors);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [mountNotificaPayload]
  );

  useEffect(() => {
    async function getTemplates() {
      setIsLoading(true);
      setLoadingMessage('Buscando Templates...');
      if (accessToken) {
        const userDecode = jwtDecode<IUserCompanyProps>(accessToken);
        setCorporationName(userDecode.corporation_name);
        const response = await getEmailTemplates({ protestado_entidade_uuid: userDecode.corporation_id });
        if (response?.results?.length) {
          const templateOptions = response.results.map((template) => {
            return { label: template.name, value: template.id };
          });
          setEmailTemplateOptions(templateOptions);
          setEmailTemplates(response.results);
        }

        setIsLoading(false);
      }
    }

    getTemplates();
  }, [accessToken]);

  return (
    <>
      {isLoading && <FullScreenLoader loadingMessage={loadingMessage} />}
      <Box w="100%" pt={'100px'} display={'grid'}>
        <Box w="100%" px={10} display={'grid'}>
          <DefaultHeader label="Criar Campanha" pageMessage="Informe abaixo os detalhes do Template"></DefaultHeader>
        </Box>
        <Form className="editForm" ref={formRef} onSubmit={handleSubmit}>
          <Box w="100%" px={10} alignItems="center" display={'grid'}>
            <Box w="100%" alignItems="center" my={5} justifyContent={'center'} px={10} display={'flex'}>
              <Select
                name="selected_template"
                label="Template de E-mail"
                color="gray400"
                isFull
                options={emailTemplateOptions}
                customMargin="0px 6px 0px 0px"
                id="selected_template"
                onChange={handleSelectTemplate}
              />
              <Select
                isFull
                name="status_titulo"
                label="Selecione um Status:"
                color="gray400"
                options={tituloStatusOptions}
                customMargin="0px 0px 0px 6px"
                id="statusTitulo"
              />
            </Box>
            <Box w="100%" alignItems="center" my={5} justifyContent={'center'} px={10} display={'flex'}>
              <Input name="subject" label="Assunto do E-mail" type="text" placeholder="Lembrete de Protesto" full />
            </Box>
            <Box w="100%" alignItems="center" display={'grid'}>
              <Box w="100%" alignContent={'center'} my={5} px={10} display={'grid'}>
                <Title
                  label="Template selecionado"
                  centralize
                  tooltip
                  tooltipBackgroundColor={theme.colors.secondary}
                  tooltipMessage="Selecione um dos templates para visualizá-lo abaixo"
                />
                <div style={{ marginTop: '30px' }} dangerouslySetInnerHTML={{ __html: selectedTemplate.html }} />
              </Box>
            </Box>
          </Box>
          <Box w="100%" alignItems="center" justifyContent={'center'} my={5} px={10} display={'flex'}>
            <Button
              type="button"
              onClick={clearTemplate}
              size="big"
              width="240px"
              color="warning"
              margin="10px"
              loading={isLoading}
              disabled={!!isLoading}
            >
              Limpar Template
            </Button>
            <Button
              type="submit"
              size="big"
              width="240px"
              color="primary"
              margin="10px"
              loading={isLoading}
              disabled={!!isLoading}
              data-cy="criarTemplate"
            >
              Criar Campanha
            </Button>
          </Box>
        </Form>
      </Box>

      {/* <ModalConfirm
        isOpen={showModal}
        onClose={() => history.push(`/mensageria/listar-campanhas`)}
        handleButtonClick={() => history.push(`/mensageria/listar-campanhas`)}
        label={`Campanha gerada com Sucesso!`}
        buttonLabel="Ok"
      /> */}
      <ModalConfirm
        isOpen={showModal}
        onClose={() => history.push(`/minha-conta-empresarial`)}
        handleButtonClick={() => history.push(`/minha-conta-empresarial`)}
        label={`Campanha gerada com Sucesso!`}
        buttonLabel="Ok"
      />
      <ModalError
        isOpen={showModalError}
        onClose={() => setShowModalError(false)}
        handleButtonClick={() => setShowModalError(false)}
        label="Erro na solicitação, verifique os dados informados"
        buttonLabel="Ok"
      />
    </>
  );
};

export default CriarTemplateEmail;
