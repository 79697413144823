import api from '@/services/api';
import { convertTitleOffersV1 } from '@/utils/utils_v1/TitleOffer';

export const getListaOfertasTitulo = async (params: any) => {
  // TODO params quando os filtros estiverem 100%
  const { data } = await api.get(`/users/protested-titles/corporation/ofertas-titulo`, {
    params: {
      ...(params.id ? { id: params.id } : {}),
      ...(params.numero_titulo ? { title_id: params.numero_titulo } : {}),
      ...(params.status ? { is_active: true } : {})
    }
  });
  const convertedTitleOffers = convertTitleOffersV1(data.results);
  return convertedTitleOffers;
};
