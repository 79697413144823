import { IUserProps } from '@/components/Interfaces/UserProps';
import { CNPJ, CPF } from '@/utils/formatters/string/document';
import jwtDecode from 'jwt-decode';
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import DropdownNav from '../DropdownNav';
import { Container, UserAvatar, UserText, HelloUser, CpfUser, MenuIcon } from './styles';

interface ISignInProps {
  SignIn: {
    access_token: string;
  };
}

const UserSetting: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLHeadingElement>(null);

  const accessToken = useSelector((state: ISignInProps) => state.SignIn.access_token);
  const user = jwtDecode<IUserProps>(accessToken);

  const labelCpfCnpj = useMemo(() => {
    if (user.cpf.length > 11) {
      return 'CNPJ: ' + CNPJ(user.cpf);
    }
    return 'CPF: ' + CPF(user.cpf);
  }, [user]);

  const handleClickOutSide = useCallback(
    (e) => {
      if (!ref.current?.contains(e.target) && isOpen) {
        setIsOpen(!isOpen);
      }
    },
    [isOpen]
  );

  useEffect(() => {
    window.addEventListener('click', handleClickOutSide);

    return () => {
      window.removeEventListener('click', handleClickOutSide);
    };
  }, [handleClickOutSide]);

  return (
    <Container onClick={() => setIsOpen(!isOpen)} ref={ref}>
      <UserAvatar />
      <UserText>
        <HelloUser>Olá, {user.name.split(' ').shift()} </HelloUser>
        <CpfUser>{labelCpfCnpj}</CpfUser>
      </UserText>
      <MenuIcon />
      {isOpen && <DropdownNav />}
    </Container>
  );
};

export default UserSetting;
