import styled from 'styled-components';
import theme from '@/assets/styles/theme';

interface Props {
  paddingTop?: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.primary};
`;

export const FeedbackContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  padding-top: ${({ paddingTop }) => (paddingTop ? paddingTop : '10vh')};

  @media only screen and (max-width: 1440px) {
    padding-top: ${({ paddingTop }) => (paddingTop ? paddingTop : '15vh')};
  }

  @media only screen and (max-width: 768px) {
    padding-top: ${({ paddingTop }) => (paddingTop ? paddingTop : '150px')};
  }
`;

export const Image = styled.img`
  width: 332px;
  height: auto;

  @media only screen and (max-width: 1366px) {
    width: 237px;
    height: auto;
  }

  @media only screen and (max-width: 768px) {
    width: 166px;
    height: auto;
  }
`;

export const MessageTitle = styled.h1`
  text-align: center;
  font-size: 42px;
  line-height: 50px;
  font-weight: 600;
  padding: 20px 0px;

  @media only screen and (max-width: 1440px) {
    font-size: 26px;
    padding: 20px 0px 0px 0px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

export const MessageContent = styled.div`
  text-align: center;
  font-size: 26px;
  line-height: 30px;
  padding: 0px 150px;

  @media only screen and (max-width: 1440px) {
    font-size: 14px;
    line-height: 20px;
    padding: 0px 350px;
  }

  @media only screen and (max-width: 1366px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 12px;
    line-height: 17px;
    padding: 20px 90px;
  }

  @media only screen and (max-width: 768px) {
    font-size: 12px;
    line-height: 17px;
    padding: 20px 0px;
  }
`;

export default Container;
