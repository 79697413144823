import theme from '@/assets/styles/theme';
import styled from 'styled-components';

interface ContainerProps {
  full: boolean | undefined;
  isBold?: boolean;
  height?: string;
  width?: string;
  isView?: boolean;
}

export const MonthpickerContainer = styled.div<ContainerProps>`
  display: ${(props) => !props.isView && 'none !important'};
  width: ${(props) => (props.full ? '100%' : props.width)};

  label {
    display: flex;
    margin-top: 20px;
    color: ${theme.colors.gray[500]};
    font-size: 15px;
    gap: 12px;
    font-weight: ${(props) => (props.isBold ? 'bold' : 300)};
  }

  > small {
    font-size: 12px;
    display: flex;
    opacity: 0.6;
  }
`;
