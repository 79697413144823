import React from 'react';
import Button from '@/components/Ui/Button';
import logoProtestado from '@/assets/images/logo-blue-green.png';
import { LogoProtestado, LogoProtestadoContainer, ContainerButton } from '../../DefaultTemplateModalStyles/styles';
import DefaultModalTemplateLayout from '../DefaultModalTemplateLayout';
import TemplateProtesto from './TemplateProtesto';
import TemplateEmolumentos from './TemplateEmolumentos';

interface ModalInterface {
  isOpen: boolean;
  onClose: () => void;
  handleButtonClick: () => void;
  buttonLabel: string;
  template: string;
  extraBtnVisible?: boolean;
  extraBtnLabel?: string;
  extraBtnFunction?: () => void;
}

const DefaultTemplate: React.FC<ModalInterface> = ({
  isOpen = false,
  onClose,
  handleButtonClick,
  buttonLabel,
  template,
  extraBtnVisible = false,
  extraBtnLabel,
  extraBtnFunction
}) => {
  if (isOpen) {
    return (
      <DefaultModalTemplateLayout
        padding="20px 0px"
        paddingCloseButton="0px 40px"
        ignoreHeight
        onClose={onClose}
        justifyContentType="space-between"
      >
        <LogoProtestadoContainer>
          {template && template === 'protesto' && <LogoProtestado src={logoProtestado} alt="Protestado" />}
          {template && template === 'emolumentos' && <LogoProtestado src="https://i.ibb.co/WyTL8p7/brasaocolorido.png" alt="Protestado" />}
        </LogoProtestadoContainer>

        {template && template === 'protesto' && <TemplateProtesto handleButtonClick={handleButtonClick} />}
        {template && template === 'emolumentos' && <TemplateEmolumentos handleButtonClick={handleButtonClick} />}

        <ContainerButton padding="12px 0px 0px 0px">
          {extraBtnVisible && (
            <Button color="primary" onClick={extraBtnFunction} full={false}>
              {extraBtnLabel}
            </Button>
          )}
          <Button color="primary" onClick={handleButtonClick} full={false}>
            {buttonLabel}
          </Button>
        </ContainerButton>
      </DefaultModalTemplateLayout>
    );
  }
  return null;
};

export default DefaultTemplate;
