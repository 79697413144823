import FeedbackImage from '@/assets/images/magnifier.png';
import EmptyFeedback from '@/components/Helpers/EmptyFeedback';
import Button from '@/components/Ui/Button';
import {
  DefaultFilterButton,
  DefaultFilterButtonContainer,
  DefaultFilterContainer,
  DefaultFilterField,
  DefaultFilterInputs,
  DefaultFilterRow
} from '@/components/Ui/DefaultFilterContainer/styles';
import DefaultHeader from '@/components/Ui/DefaultHeader';
import { DefaultContainerPages } from '@/components/Ui/DefaultHeader/style';
import DefaultPagination from '@/components/Ui/DefaultPagination';
import { FullScreenLoader } from '@/components/Ui/FullScreenLoader';
import GoBack from '@/components/Ui/GoBack';
import Input from '@/components/Ui/Input';
import Title from '@/components/Ui/Title';
import { Titulos } from '@/modules/Entidades/ListarTitulos/interfaces/interface';
import { getErrorMessage, getOnlyNumbers } from '@/utils/Utilities';
import { Box, useDisclosure } from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { FormFiltrosTitulo } from '../ListarTitulos';
import { FiltrosTitulo } from '../ListarTitulos/interfaces/FiltrosTitulo';
import { getTituloRequestFiltrar } from '../ListarTitulos/providers/getTituloRequestFiltrar';
import { convertResponseToTitulos } from '../ListarTitulos/utils/handleListTitulo';
import { FeedbackArea } from '../components/EditContainer/styles';
import BatchTitlesResponseModal from './components/BatchTitlesResponseModal';
import BatchTitlesTable, { CheckedItemsBackTitles } from './components/BatchTitlesTable';
import { DisableTitlesBatchResponse } from './interfaces/DisableTitlesBatchResponse';
import { disableTitlesBatch } from './providers/disableTitlesBatch';

const ComandosLote = () => {
  const formRef = useRef<FormHandles>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [mask, setMask] = useState('99.999.999/9999-99');
  const [loadingMessage, setLoadingMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [disabledTitlesResponse, setDisabledTitlesResponse] = useState<DisableTitlesBatchResponse | null>(null);
  const [data, setData] = useState<Titulos[]>([]);
  const [displayedData, setDisplayedData] = useState<Titulos[]>([]);
  const itemsPerPage = 30;
  const allPages = Math.ceil(data.length / itemsPerPage);

  const onPageChange = (page = 1) => {
    const startItem = (page - 1) * itemsPerPage;
    const endItem = page * itemsPerPage;
    setDisplayedData(data.slice(startItem, endItem));
  };

  const handleSetMask = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (!inputValue.length || inputValue.length > 14) {
      setMask('99.999.999/9999-99');
    } else {
      setMask('999.999.999-999');
    }
  }, []);

  const callFilter = async () => {
    setDisabledTitlesResponse(null);
    const formData = formRef.current?.getData() as FormFiltrosTitulo;
    await handleSubmit(formData);
  };

  const sendCommands = async (checkedItems: CheckedItemsBackTitles[]) => {
    const selectedTitles = checkedItems.filter((item) => item.isChecked);
    if (selectedTitles.length > 0) {
      try {
        setIsLoading(true);
        setLoadingMessage('Desabilitando Títulos...');

        const selectedTitlesIds = checkedItems.filter((item) => item.isChecked).map((item) => item.titleId);
        const disabledTitles = await disableTitlesBatch({ titles_ids: selectedTitlesIds });
        if (disabledTitles) {
          setDisabledTitlesResponse(disabledTitles);
          onOpen();
        }
      } catch (e) {
        const errorMsg = getErrorMessage(e, 'Não foi possível Desabilitar o Título');
        toast.error(errorMsg);
      } finally {
        setIsLoading(false);
      }
    } else {
      toast.warning('É preciso selecionar um ou mais títulos para executar um comando em lote.');
    }
  };

  const handleSubmit = async (fields: FormFiltrosTitulo) => {
    try {
      setIsLoading(true);
      setLoadingMessage('Carregando Títulos...');

      const numero_identificacao_devedor = getOnlyNumbers(fields.numero_identificacao_devedor);

      const params = {
        id: fields.id,
        nome_devedor: fields.nome_devedor,
        is_deleted: false,
        numero_titulo: fields.numero_titulo,
        numero_identificacao_devedor: numero_identificacao_devedor
      } as FiltrosTitulo;

      const titlesList = await getTituloRequestFiltrar(params);

      if (titlesList?.length > 0) {
        const titles = convertResponseToTitulos(titlesList);
        setData(titles);
      } else {
        toast.warning('Não foram encontrados Títulos para este Filtro!');
        setData([]);
      }
    } catch (error) {
      toast.error('Não foi possível listar os Títulos!');
    } finally {
      setIsLoading(false);
    }
  };

  const clearFilter = useCallback((fieldValue: string) => {
    formRef.current?.clearField(fieldValue);
  }, []);

  const clearAllFilters = useCallback(() => {
    formRef.current?.reset();
  }, []);

  useEffect(() => {
    onPageChange();
  }, [data]);

  return (
    <>
      {isLoading && <FullScreenLoader loadingMessage={loadingMessage} />}
      <DefaultContainerPages paddingTop paddingBottom>
        <DefaultHeader label="Comandos em Lote" marginTop="12px" />
        <DefaultFilterContainer margin="0px 0px 24px 0px">
          <Form className="editForm" ref={formRef} onSubmit={handleSubmit}>
            <DefaultFilterInputs>
              <DefaultFilterField>
                <Input
                  width="100%"
                  name="id"
                  type="string"
                  placeholder="Ex.: 3200"
                  label="ID do Título"
                  padding="8px 8px 8px 6px"
                  iconSize="1.2rem"
                  isClearable={true}
                  clearFunction={() => clearFilter('id')}
                />
              </DefaultFilterField>
              <DefaultFilterField>
                <Input
                  width="100%"
                  name="nome_devedor"
                  type="string"
                  placeholder="Ex.: Fernando Alves"
                  label="Nome do Devedor"
                  padding="8px 8px 8px 6px"
                  iconSize="1.2rem"
                  isClearable={true}
                  clearFunction={() => clearFilter('nome_devedor')}
                />
              </DefaultFilterField>
              <DefaultFilterField>
                <Input
                  width="100%"
                  name="numero_titulo"
                  type="string"
                  placeholder="Ex.: 01303030"
                  label="Número do Título"
                  padding="8px 8px 8px 6px"
                  iconSize="1.2rem"
                  isClearable={true}
                  clearFunction={() => clearFilter('numero_titulo')}
                />
              </DefaultFilterField>
              <DefaultFilterField>
                <Input
                  width="100%"
                  name="numero_identificacao_devedor"
                  type="string"
                  placeholder="Ex.: 000.000.000-00"
                  mask={mask}
                  onChange={handleSetMask}
                  maskChar=""
                  label="CPF/CNPJ"
                  padding="8px 8px 8px 6px"
                  iconSize="1.2rem"
                  isClearable={true}
                  data-cy="cpfCnpj"
                  clearFunction={() => clearFilter('numero_identificacao_devedor')}
                />
              </DefaultFilterField>
            </DefaultFilterInputs>
            <DefaultFilterRow padding="0px 10px 0px 10px">
              <DefaultFilterButtonContainer>
                <DefaultFilterButton>
                  <Button type="button" onClick={clearAllFilters} size="big" color="warning" width="100%" disabled={!!isLoading}>
                    Limpar Filtros
                  </Button>
                </DefaultFilterButton>
              </DefaultFilterButtonContainer>
              <DefaultFilterButtonContainer>
                <DefaultFilterButton>
                  <Button
                    id="filterButton"
                    type="submit"
                    minWidth="180px"
                    size="big"
                    color="primary"
                    width="100%"
                    loading={isLoading}
                    disabled={!!isLoading}
                    data-cy="filtrarTitulos"
                  >
                    Filtrar
                  </Button>
                </DefaultFilterButton>
              </DefaultFilterButtonContainer>
            </DefaultFilterRow>
          </Form>
        </DefaultFilterContainer>
        {displayedData?.length === 0 ? (
          <FeedbackArea>
            <EmptyFeedback
              imagePath={FeedbackImage}
              title="Clique em Filtrar para buscar Resultados"
              message="
            Utilize os filtros acima para encontrar os Títulos cadastrados"
            />
          </FeedbackArea>
        ) : (
          <>
            <Title label="Títulos encontrados" isVisiblePixel marginBottom="24px" />
            <BatchTitlesTable allTitles={data} displayedTitles={displayedData} sendCommands={sendCommands}></BatchTitlesTable>
            <Box mt="24px">
              <GoBack />
              <DefaultPagination
                allPagesNumber={allPages}
                itemsPerPage={itemsPerPage}
                itemsNumber={data.length}
                pageChange={onPageChange}
              />
            </Box>
          </>
        )}
        <BatchTitlesResponseModal
          response={disabledTitlesResponse}
          isOpen={isOpen}
          onClose={onClose}
          onConfirm={callFilter}
        ></BatchTitlesResponseModal>
      </DefaultContainerPages>
    </>
  );
};

export default ComandosLote;
