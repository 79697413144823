// import api from '@/services/api';
// import { PayloadExtratoBMP } from '..';
import { AccountStatementBMP } from '../interfaces/AccountStatementBMP';

export const getAccountStatement = async (params: any): Promise<AccountStatementBMP[]> => {
  console.log('Rota desativada: não integramos mais extrato com BMP.', params);
  return [];
  // const { data } = await api.get(`/users/protested-titles/corporation/consultar-extrato`, {
  //   params: {
  //     ...(params.dia_inicial ? { dia_inicial: params.dia_inicial } : {}),
  //     ...(params.dia_final ? { dia_final: params.dia_final } : {}),
  //     ...(params.mes ? { mes: params.mes } : {}),
  //     ...(params.ano ? { ano: params.ano } : {}),
  //     ...(params.numero_banco ? { numero_banco: params.numero_banco } : {}),
  //     ...(params.agencia ? { agencia: params.agencia } : {}),
  //     ...(params.agencia_digito ? { agencia_digito: params.agencia_digito } : {}),
  //     ...(params.conta ? { conta: params.conta } : {}),
  //     ...(params.conta_digito ? { conta_digito: params.conta_digito } : {})
  //   }
  // });

  // const orderedData = data.sort((a: any, b: any) => {
  //   return new Date(b.dtMovimento).getTime() - new Date(a.dtMovimento).getTime();
  // });

  // return orderedData;
};
