interface UsuariosResponse {
  id: string;
  email: string;
  created_at: string;
  name: string;
  is_active: boolean;
  is_admin: boolean;
  is_superuser_protestado: boolean;
}

export const handleListUsuario = (usuarios: UsuariosResponse[]) => {
  return usuarios.map((usuario) => {
    return {
      email: usuario.email,
      name: usuario.name,
      created_at: usuario.created_at,
      is_active: usuario.is_active,
      is_admin: usuario.is_admin,
      is_superuser_protestado: usuario.is_superuser_protestado,
      id: usuario.id
    };
  });
};
