import styled, { css } from 'styled-components';

import flex from '@/assets/styles/flex';
import theme from '@/assets/styles/theme';

interface Props {
  isFull?: boolean;
  isErrored: boolean;
  isView?: boolean;
  customMargin?: string;
  isBold?: boolean;
}

interface IInputContainerProps {
  isErrored?: boolean;
  customMargin?: string;
}

export const Container = styled.div<Props>`
  display: ${(props) => !props.isView && 'none !important'};

  ${flex.direction.column};
  margin: ${(Props) => (Props.customMargin ? Props.customMargin : '30px 0 0 0')};
  font-size: 18px;

  ${(props) =>
    props.isFull &&
    css`
      width: 100%;
    `}

  ${(props) =>
    props.isErrored &&
    css`
      border-color: ${theme.colors.red[200]};
    `}

  > span {
    font-weight: ${(props) => (props.isBold ? 700 : 500)};
    display: flex;
    margin-top: 20px;
    color: ${theme.colors.gray[500]};
    font-size: 15px;
    gap: 12px;
  }
`;

export const InputContainer = styled.div<IInputContainerProps>`
  margin: ${(Props) => (Props.customMargin ? Props.customMargin : '10px 30px 0 0')};
  border-radius: 5px;
  ${(props) =>
    props.isErrored &&
    css`
      border: 1px solid ${theme.colors.red[200]};
    `}
`;
