import IconController from '@/components/Ui/IconController';
import Text from '@/components/Ui/Text';
import DescriptionContainer from '@/modules/Deal/components/DescriptionContainer';
import ChipStatusContainer from '@/modules/Entidades/ListarOfertasTitulo/components/chipStatusContainer';
import { DiscountImageIcon } from '@/modules/Entidades/ListarOfertasTitulo/components/paginate/styles';
import { formatarDinheiro } from '@/utils/Utilities';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../Button';
import { OfertaGlobal } from '../../interfaces/interface';
import {
  BtnItemActive,
  BtnItemDisabled,
  Container,
  ContainerButton,
  DiscountImage,
  PaymentDiscount,
  Section1,
  SectionBtn,
  TextContainer
} from '../paginate/styles';

interface IProps {
  posts: OfertaGlobal[];
}

const DataList: React.FC<IProps> = ({ posts }) => {
  const history = useHistory();

  const getTipoDesconto = (oferta: OfertaGlobal, tipoDescricao: string): string => {
    if (Number(oferta.desconto)) {
      if (tipoDescricao === 'label') {
        return '(R$)';
      } else {
        return oferta.desconto;
      }
    } else {
      if (tipoDescricao === 'label') {
        return '(%)';
      } else {
        return oferta.desconto_porcentagem;
      }
    }
  };

  return (
    <div className="list__container">
      <div className="list_data">
        {posts.map((item: OfertaGlobal, index: number) => {
          return (
            <Container key={item.key}>
              <DescriptionContainer>
                <Section1>
                  <Text margin="0px 0px 12px 0px" size="medium" bold>
                    ID da Oferta:{item.id}
                  </Text>
                  <ChipStatusContainer size="small">
                    {item.status === true ? (
                      <BtnItemActive>
                        <h2>Oferta Ativa</h2>
                      </BtnItemActive>
                    ) : (
                      <BtnItemDisabled>
                        <h2>Oferta Desabilitada</h2>
                      </BtnItemDisabled>
                    )}
                  </ChipStatusContainer>
                </Section1>
                <Section1>
                  <Text size="small">Valor Inicial: {formatarDinheiro(Number(item.valor_de))}</Text>
                  <Text margin="12px 0px 0px 0px" size="small">
                    Valor Final: {formatarDinheiro(Number(item.valor_ate))}
                  </Text>
                  {item.data_inicio && item.data_fim && (
                    <>
                      <Text margin="12px 0px 0px 0px" size="small">
                        Data Inicial: {item.data_inicio}
                      </Text>
                      <Text margin="12px 0px 0px 0px" size="small">
                        Data Final: {item.data_fim}
                      </Text>
                    </>
                  )}
                  {!item.data_inicio && !item.data_fim && (
                    <>
                      <Text margin="12px 0px 0px 0px" size="small">
                        Oferta sem data de expiração
                      </Text>
                    </>
                  )}
                </Section1>
                <SectionBtn>
                  <ChipStatusContainer size="small">
                    {item.cartao === true ? (
                      <BtnItemActive>
                        <IconController margin="3px 8px 0px 0px" icon="checkCircle" fontSize="20px" iconColor="white" />
                        <h2>Cartão de Crédito</h2>
                      </BtnItemActive>
                    ) : (
                      <BtnItemDisabled>
                        <IconController margin="3px 8px 0px 0px" icon="closeCircle" fontSize="20px" iconColor="white" />
                        <h2>Cartão de Crédito</h2>
                      </BtnItemDisabled>
                    )}
                  </ChipStatusContainer>

                  <ChipStatusContainer size="small">
                    {item.boleto === true ? (
                      // <BtnItemActive margin="12px 0px 0px 0px">
                      <BtnItemActive margin="12px 0px 0px 0px">
                        <IconController margin="3px 8px 0px 0px" icon="checkCircle" fontSize="20px" iconColor="white" />
                        <h2>Boleto</h2>
                      </BtnItemActive>
                    ) : (
                      // <BtnItemDisabled margin="12px 0px 0px 0px">
                      <BtnItemDisabled margin="12px 0px 0px 0px">
                        <IconController margin="3px 8px 0px 0px" icon="closeCircle" fontSize="20px" iconColor="white" />
                        <h2>Boleto</h2>
                      </BtnItemDisabled>
                    )}
                  </ChipStatusContainer>
                </SectionBtn>
                <SectionBtn>
                  <TextContainer>
                    <Text margin="0px 0px 12px 0px" size="medium" bold>
                      Desconto {getTipoDesconto(item, 'label')}
                    </Text>
                  </TextContainer>
                  <PaymentDiscount>
                    <DiscountImage>
                      {Number(item.desconto) > 0 || Number(item.desconto_porcentagem) > 0 ? (
                        <DiscountImageIcon>
                          <Text align="center" color="white" size="medium" bold>
                            {item.desconto ? `R$ ${item.desconto}` : `${formatarDinheiro(Number(item.desconto_porcentagem), false)}`}
                          </Text>
                        </DiscountImageIcon>
                      ) : (
                        <Text align="center" size="small" color="white" bold>
                          Oferta sem desconto
                        </Text>
                      )}
                    </DiscountImage>
                  </PaymentDiscount>
                </SectionBtn>
                <ContainerButton>
                  <Button
                    full
                    onClick={() => history.push(`/ofertas/listar-ofertas-globais/${item.id}`)}
                    data-cy={`visualizarOferta${index}`}
                    id={`visualizarOferta${index}`}
                  >
                    Visualizar Oferta
                  </Button>
                </ContainerButton>
              </DescriptionContainer>
            </Container>
          );
        })}
      </div>
    </div>
  );
};

export default DataList;
