import { TituloStatusPayload } from '@/components/Interfaces/TituloStatusPayload';
import api from '@/services/api';

export const getTituloStatus = async (filtro: TituloStatusPayload) => {
  const { data } = await api.get('users/protested-titles/corporation/titulo-status', {
    params: {
      ...(filtro.id ? { id: filtro.id } : null),
      ...(filtro.codigo ? { codigo: filtro.codigo } : null)
    }
  });
  return data;
};
