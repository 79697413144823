import FeedbackImage from '@/assets/images/magnifier.png';
import EmptyFeedback from '@/components/Helpers/EmptyFeedback';
import Button from '@/components/Ui/Button';
import { FullScreenLoader } from '@/components/Ui/FullScreenLoader';
import Input from '@/components/Ui/Input';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { FeedbackArea, ResultsArea } from '../components/EditContainer/styles';
import { TituloDetail } from './interfaces/interfaceRelatorio';
import { getRelatorioOfertasGlobais } from './providers/getRelatorioOfertasGlobais';
import { handleListTitulo } from './utils/handleListTitulo';
//pagination
import {
  ButtonEndArea,
  DefaultContainerIcon,
  DefaultContainerIconText,
  DefaultFilterButton,
  DefaultFilterButtonContainer,
  DefaultFilterContainer,
  DefaultFilterField,
  DefaultFilterInputs,
  DefaultFilterRow,
  DefaultHeaderFiltro
} from '@/components/Ui/DefaultFilterContainer/styles';
import DefaultHeader from '@/components/Ui/DefaultHeader';
import { DefaultContainerPages, DefaultListPageContainer } from '@/components/Ui/DefaultHeader/style';
import DefaultPagination from '@/components/Ui/DefaultPagination';
import GoBack from '@/components/Ui/GoBack';
import ModalConfirm from '@/components/Ui/Modal/ModalConfirm';
import ModalWarning from '@/components/Ui/Modal/ModalWarning';
import Select from '@/components/Ui/Select';
import Text from '@/components/Ui/Text';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import DataList from './components/paginate/DataList';
import { enviaRelatorioEmail } from './providers/getRelatorioOfertasGlobais';

const RelatoriosOfertasGlobais: React.FC = () => {
  const [data, setData] = useState<TituloDetail[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [displayedData, setDisplayedData] = useState<TituloDetail[]>([]);
  const itemsPerPage = 10;
  const allPages = Math.ceil(data.length / itemsPerPage);
  const [showModalWarning, setShowModalWarning] = useState(false);
  const [showModalSucess, setShowModalSucess] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [email, setEmail] = useState('');
  const [clearSelect, setClearSelect] = useState(false);

  const [statusOferta, setStatusOferta] = useState(false);
  const handleChangeStatusOferta = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStatusOferta(e.target.checked);
  };
  const [oferta_renegociacoes, setOfertaRenegociacoes] = useState(false);
  const handleChangeOfertaRenegociacoes = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOfertaRenegociacoes(e.target.checked);
  };
  const [renegociacoes_ativas, setRenegociacoesAtivas] = useState(false);
  const handleChangeRenegociacoesAtivas = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRenegociacoesAtivas(e.target.checked);
  };
  const [renegociacoes_sem_evolucao, setRenegociacoesSemEvolucao] = useState(false);
  const handleChangeRenegociacoesSemEvolucao = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRenegociacoesSemEvolucao(e.target.checked);
  };

  const [renegociacoes_pagas, setRenegociacoesPagas] = useState(false);
  const handleChangeRenegociacoesPagas = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRenegociacoesPagas(e.target.checked);
  };

  const [ofertas_inativas, setOfertasInativas] = useState(false);
  const handleChangeOfertasInativas = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOfertasInativas(e.target.checked);
  };

  const [boleto, setBoleto] = useState(false);
  const handleChangeBoleto = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBoleto(e.target.checked);
  };

  const [cartao, setCartao] = useState(false);
  const handleChangeCartao = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCartao(e.target.checked);
  };

  const onPageChange = (page = 1) => {
    const startItem = (page - 1) * itemsPerPage;
    const endItem = page * itemsPerPage;
    setDisplayedData(data.slice(startItem, endItem));
  };

  const statusPagamentoOptions = [
    { value: '', label: 'Todos' },
    { value: 'DEBT_PAID_OFF', label: 'Pagamento Confirmado' },
    { value: 'WAITING_PAYMENT', label: 'Pendente' }
  ];
  const statusOfertaOptions = [
    { value: '', label: 'Todos' },
    { value: 'true', label: 'Ativo' },
    { value: 'false', label: 'Inativo' }
  ];

  const statusRenegociacaoOptions = [
    { value: '', label: 'Todos' },
    { value: 'true', label: 'Ativo' },
    { value: 'false', label: 'Inativo' }
  ];

  const pagamentoOptions = [
    { value: '', label: 'Todos' },
    { value: 'boleto', label: 'Boleto' },
    { value: 'cartao', label: 'Cartão de Crédito' }
  ];

  const formRef = useRef<FormHandles>(null);

  const clearFilter = useCallback((fieldValue: string) => {
    formRef.current?.clearField(fieldValue);
  }, []);

  const clearAllFilters = useCallback(() => {
    formRef.current?.reset();
    setClearSelect(true);
    setStatusOferta(false);
    setRenegociacoesAtivas(false);
    setCartao(false);
    setBoleto(false);
  }, []);

  useEffect(() => {
    onPageChange();
  }, [data]);

  const handleSendEmail = async (id: any) => {
    try {
      setIsLoading(true);
      setShowModalWarning(false);
      const payload = {
        email: 'email@email.com'
      };

      await enviaRelatorioEmail(payload);
      setShowModalSucess(true);
    } catch (error) {
      toast.error('Não foi possível Desabilitar a Oferta');
      setShowModalError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (fields: TituloDetail) => {
    try {
      setIsLoading(true);

      const params = {
        oferta_id: fields.oferta_id,
        status_oferta: fields.status_oferta,
        status_renegociacao: fields.status_renegociacao,
        status_pagamento: fields.status_pagamento,
        tipo_pagamento: fields.tipo_pagamento,
        permanencia_inicio: fields.permanencia_inicio,
        permanencia_fim: fields.permanencia_fim,
        tempo_plataforma_inicio: fields.tempo_plataforma_inicio,
        tempo_plataforma_fim: fields.tempo_plataforma_fim,
        vencimento_inicio: fields.vencimento_inicio,
        vencimento_fim: fields.vencimento_fim,
        desconto_porcentagem_inicial: fields.desconto_porcentagem_inicial,
        desconto_porcentagem_final: fields.desconto_porcentagem_final,
        desconto_valor_inicial: fields.desconto_valor_inicial,
        desconto_valor_final: fields.desconto_valor_final
      };

      const TituloList = await getRelatorioOfertasGlobais(params);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const titulo: any[] = handleListTitulo(TituloList);

      if (!titulo.length) {
        toast.warning('Não foram encontradas Ofertas Globais para este Filtro!');
      }

      setData(titulo);
    } catch (error) {
      toast.error('Não foi possível listar os Títulos!');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <FullScreenLoader loadingMessage="Carregando Ofertas..." />}
      <DefaultContainerPages paddingTop paddingBottom>
        <DefaultHeader label="Relatório de Ofertas Globais" marginTop="12px" />
        <DefaultListPageContainer>
          <DefaultFilterContainer>
            <DefaultHeaderFiltro>
              <Text bold color="secondary" size="medium2">
                Filtro
              </Text>
              <DefaultContainerIconText onClick={() => setIsOpen(!isOpen)}>
                {!isOpen ? (
                  <Text bold color="secondary">
                    Mostrar mais filtros
                  </Text>
                ) : (
                  <Text bold color="secondary">
                    Mostrar menos filtros
                  </Text>
                )}
                <DefaultContainerIcon>{!isOpen ? <IoIosArrowDown /> : <IoIosArrowUp />}</DefaultContainerIcon>
              </DefaultContainerIconText>
            </DefaultHeaderFiltro>

            <Form className="editForm" ref={formRef} onSubmit={handleSubmit}>
              <DefaultFilterInputs>
                <DefaultFilterField>
                  <Input
                    width="100%"
                    name="desconto_porcentagem_inicial"
                    type="string"
                    placeholder="Ex.: 10"
                    label="Porcentagem Desconto Inicial"
                    padding="8px 8px 8px 6px"
                    iconSize="1.2rem"
                    isClearable={true}
                    clearFunction={() => clearFilter('desconto_porcentagem_inicial')}
                  />
                </DefaultFilterField>
                <DefaultFilterField>
                  <Input
                    width="100%"
                    name="desconto_porcentagem_final"
                    type="string"
                    placeholder="Ex.: 80"
                    label="Porcentagem Desconto Final"
                    padding="8px 8px 8px 6px"
                    iconSize="1.2rem"
                    isClearable={true}
                    clearFunction={() => clearFilter('desconto_porcentagem_final')}
                  />
                </DefaultFilterField>
                <DefaultFilterField>
                  <Select
                    clearSelect={clearSelect}
                    setClearSelect={setClearSelect}
                    isFull
                    name="status_oferta"
                    label="Status Oferta"
                    color="gray400"
                    options={statusOfertaOptions}
                  />
                </DefaultFilterField>
                <DefaultFilterField>
                  <Select
                    clearSelect={clearSelect}
                    setClearSelect={setClearSelect}
                    isFull
                    name="status_renegociacao"
                    label="Status Renegociação"
                    color="gray400"
                    options={statusRenegociacaoOptions}
                    isDisabled={true}
                  />
                </DefaultFilterField>
              </DefaultFilterInputs>
              {isOpen && (
                <>
                  <DefaultFilterInputs margin="20px 0px" isView={false}>
                    <DefaultFilterField>
                      <Input
                        width="100%"
                        name="vencimento_inicio"
                        type="string"
                        placeholder="Ex.: 02 dias"
                        label="Vencimento Inicial"
                        padding="8px 8px 8px 6px"
                        iconSize="1.2rem"
                        isClearable={true}
                        clearFunction={() => clearFilter('vencimento_inicio')}
                      />
                    </DefaultFilterField>
                    <DefaultFilterField>
                      <Input
                        width="100%"
                        name="vencimento_fim"
                        type="string"
                        placeholder="Ex.: 30 dias"
                        label="Vencimento Final"
                        padding="8px 8px 8px 6px"
                        iconSize="1.2rem"
                        isClearable={true}
                        clearFunction={() => clearFilter('vencimento_fim')}
                      />
                    </DefaultFilterField>
                    <DefaultFilterField>
                      <Input
                        width="100%"
                        name="tempo_plataforma_inicio"
                        type="string"
                        placeholder="Ex.: 02 dias"
                        label="Tempo Plataforma Inicial"
                        padding="8px 8px 8px 6px"
                        iconSize="1.2rem"
                        isClearable={true}
                        clearFunction={() => clearFilter('tempo_plataforma_inicio')}
                      />
                    </DefaultFilterField>
                    <DefaultFilterField>
                      <Input
                        width="100%"
                        name="tempo_plataforma_fim"
                        type="string"
                        placeholder="Ex.: 30 dias"
                        label="Tempo Plataforma Final"
                        padding="8px 8px 8px 6px"
                        iconSize="1.2rem"
                        isClearable={true}
                        clearFunction={() => clearFilter('tempo_plataforma_fim')}
                      />
                    </DefaultFilterField>
                  </DefaultFilterInputs>
                  <DefaultFilterInputs margin="20px 0px" isView={false}>
                    <DefaultFilterField>
                      <Select
                        clearSelect={clearSelect}
                        setClearSelect={setClearSelect}
                        isFull
                        name="status_pagamento"
                        label="Status Pagamento"
                        color="gray400"
                        options={statusPagamentoOptions}
                        isDisabled={true}
                      />
                    </DefaultFilterField>
                    {/* <DefaultFilterField>
                      <Select isFull name="tipo_pagamento" label="Tipo de pagamento" color="gray400" options={pagamentoOptions} />
                    </DefaultFilterField> */}
                  </DefaultFilterInputs>
                </>
              )}
              <DefaultFilterRow padding="0px 10px 0px 10px">
                <DefaultFilterButtonContainer>
                  <DefaultFilterButton>
                    <Button type="button" onClick={clearAllFilters} size="big" color="warning" width="100%" disabled={!!isLoading}>
                      Limpar Filtros
                    </Button>
                  </DefaultFilterButton>
                </DefaultFilterButtonContainer>
                <DefaultFilterButtonContainer>
                  {data && data.length > 0 && (
                    <DefaultFilterButton padding="0px 12px 0px 0px">
                      <Button
                        onClick={() => setShowModalWarning(true)}
                        type="button"
                        minWidth="180px"
                        size="big"
                        color="primary"
                        width="100%"
                        loading={isLoading}
                        disabled={!!isLoading}
                      >
                        Exportar
                      </Button>
                    </DefaultFilterButton>
                  )}
                  <DefaultFilterButton>
                    <Button
                      type="submit"
                      minWidth="180px"
                      size="big"
                      color="primary"
                      width="100%"
                      loading={isLoading}
                      disabled={!!isLoading}
                    >
                      Filtrar
                    </Button>
                  </DefaultFilterButton>
                </DefaultFilterButtonContainer>
              </DefaultFilterRow>
            </Form>
          </DefaultFilterContainer>
          {data?.length === 0 ? (
            <FeedbackArea>
              <EmptyFeedback
                imagePath={FeedbackImage}
                title="Clique em Filtrar para buscar Resultados"
                message="Aqui serão exibidos resultados de Ofertas Globais"
              />
            </FeedbackArea>
          ) : (
            <ResultsArea>
              <DataList posts={displayedData} />
              <ButtonEndArea>
                <Button
                  onClick={() => setShowModalWarning(true)}
                  size="big"
                  color="primary"
                  width="16%"
                  loading={isLoading}
                  disabled={!!isLoading}
                  margin="0 15px 0 0"
                >
                  Exportar
                </Button>
              </ButtonEndArea>
              <GoBack />
              <DefaultPagination allPagesNumber={allPages} itemsPerPage={10} itemsNumber={data.length} pageChange={onPageChange} />
            </ResultsArea>
          )}
        </DefaultListPageContainer>
        <ModalWarning
          isOpen={showModalWarning}
          buttonLabelConfirm="Gerar Relatório"
          label="Clique em confirmar para receber seu relatório por e-mail"
          handleButtonClick={() => {
            handleSendEmail(email);
          }}
          handleClickCancelButton={() => setShowModalWarning(false)}
          onClose={() => setShowModalWarning(false)}
        />
        <ModalConfirm
          isOpen={showModalSucess}
          onClose={() => setShowModalSucess(false)}
          handleButtonClick={() => setShowModalSucess(false)}
          label={`E-mail enviado com Sucesso`}
          buttonLabel="Ok"
        />
      </DefaultContainerPages>
    </>
  );
};

export default RelatoriosOfertasGlobais;
