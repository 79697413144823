import { IUserCompanyProps } from '@/components/Interfaces/UserCompanyProps';
import jwtDecode from 'jwt-decode';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import DropdownNav from '../DropdownNav';
import { Container, MenuIcon, UserAvatar, UserData, UserText } from './styles';

export interface ISignInProps {
  SignIn: {
    access_token: string;
  };
}

const UserSetting: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLHeadingElement>(null);

  const accessToken = useSelector((state: ISignInProps) => state.SignIn.access_token);
  const user = jwtDecode<IUserCompanyProps>(accessToken);

  const handleClickOutSide = useCallback(
    (e) => {
      if (!ref.current?.contains(e.target) && isOpen) {
        setIsOpen(!isOpen);
      }
    },
    [isOpen]
  );

  useEffect(() => {
    window.addEventListener('click', handleClickOutSide);

    return () => {
      window.removeEventListener('click', handleClickOutSide);
    };
  }, [handleClickOutSide]);

  return (
    <Container onClick={() => setIsOpen(!isOpen)} ref={ref}>
      <UserAvatar />
      <UserText>
        <UserData>
          Autenticado como: <br />
          {user.email && user.email.trim() !== '' ? user.email : user.username}{' '}
        </UserData>
      </UserText>
      <MenuIcon />
      {isOpen && <DropdownNav />}
    </Container>
  );
};

export default UserSetting;
