import Button from '@/components/Ui/Button';
import DefaultHeader from '@/components/Ui/DefaultHeader';
import { DefaultContainer } from '@/components/Ui/DefaultHeader/style';
import { FullScreenLoader } from '@/components/Ui/FullScreenLoader';
import Input from '@/components/Ui/Input';
import ModalConfirm from '@/components/Ui/Modal/ModalConfirm';
import ModalError from '@/components/Ui/Modal/ModalError';
import ModalWarning from '@/components/Ui/Modal/ModalWarning';
import Toggle from '@/components/Ui/Toggle';
import { formatarDinheiro } from '@/utils/Utilities';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ContainerButton } from '../components/EditContainer/styles';
import { UserPayload } from './interfaces/user';
import { cancelarOferta } from './providers/cancelarOferta';
import { getOfertaGlobalById } from './providers/getOfertaGlobalDetalhe';

interface Props {}

const EditarOfertaGlobal: React.FC<Props> = () => {
  const history = useHistory();
  const url = window.location.href.split('/');
  const ofertaGlobalId = url[5];
  const [loadingMessage, setLoadingMessage] = useState('');
  const [showModalSucess, setShowModalSucess] = useState(false);
  const [showModalWarning, setShowModalWarning] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [id, setId] = useState('');
  const [status, setStatus] = useState(false);
  const handleChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStatus(e.target.checked);
  };
  const [statusEnvioSms, setStatusEnvioSms] = useState(false);
  const handleChangeStatusEnvioSms = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStatusEnvioSms(e.target.checked);
  };
  const [cartao, setCartao] = useState(false);
  const handleChangeCartao = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCartao(e.target.checked);
  };
  const [boleto, setBoleto] = useState(false);
  const handleChangeBoleto = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBoleto(e.target.checked);
  };
  const [notificacao, setNotificacao] = useState(false);
  const handleChangeNotificacao = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNotificacao(e.target.checked);
  };
  const [desconto, setDesconto] = useState('');
  const handleChangeDesconto = (e: React.ChangeEvent<HTMLInputElement>) => {
    discountCtrl(e);
    setDesconto(e.target.value);
  };
  const [desconto_porcentagem, setDescontoPorcentagem] = useState('');
  const handleChangeDescontoPorcentagem = (e: React.ChangeEvent<HTMLInputElement>) => {
    discountCtrl(e);
    setDescontoPorcentagem(e.target.value);
  };
  const [juros_cartao, setJurosCartao] = useState('');
  const handleChangeJurosCartao = (e: React.ChangeEvent<HTMLInputElement>) => {
    setJurosCartao(e.target.value);
  };
  const [juros_boleto, setJurosBoleto] = useState('');
  const handleChangeJurosBoleto = (e: React.ChangeEvent<HTMLInputElement>) => {
    setJurosBoleto(e.target.value);
  };
  const formRef = useRef<FormHandles>(null);

  const discountCtrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'desconto') {
      setDescontoPorcentagem('00');
    } else if (e.target.id === 'desconto_porcentagem') {
      setDesconto('0.00');
    }
  };

  const getOfertaData = useCallback(async () => {
    try {
      setIsLoading(true);
      setLoadingMessage('Carregando Detalhes da Oferta...');
      const oferta = await getOfertaGlobalById(ofertaGlobalId);
      // TODO setNotificacao

      if (!oferta.desconto) {
        oferta.desconto = '0.00';
      }

      if (!oferta.desconto_porcentagem) {
        oferta.desconto_porcentagem = '0.00';
      }

      formRef.current?.setData({
        id: oferta.id,
        valor_de: formatarDinheiro(Number(oferta.valor_de)),
        valor_ate: formatarDinheiro(Number(oferta.valor_ate)),
        parcela_boleto: oferta.parcela_boleto,
        parcela_cartao: oferta.parcela_cartao,
        iof: oferta.iof,
        taxa_servicos: oferta.taxa_servicos,
        data_inicio: oferta.data_inicio,
        data_fim: oferta.data_fim,
        apresentante: oferta.apresentante,
        mensagem: oferta.mensagem,
        protestado_entidade_uuid: oferta.protestado_entidade_uuid
      });

      setDesconto(oferta.desconto);
      setDescontoPorcentagem(oferta.desconto_porcentagem);
      setJurosCartao(oferta.juros_cartao);
      setJurosBoleto(oferta.juros_boleto);

      setStatus(oferta.status);
      setCartao(oferta.cartao);
      setBoleto(oferta.boleto);
      setId(oferta.id);
    } catch (error) {
      toast.error('Não foi possível carregar seu dados, tente mais tarde!');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getOfertaData();
  }, []);

  const handleSubmit = useCallback(async (fields: UserPayload) => {
    try {
      setIsLoading(true);
      setLoadingMessage('Atualizando Oferta...');
      setShowModalWarning(true);
    } catch (err) {
      setShowModalError(true);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleSendCancelOffer = async (id: any) => {
    try {
      setIsLoading(true);
      setShowModalWarning(false);
      setLoadingMessage('Desabilitando Oferta...');
      const payload = {
        id: id
      };

      await cancelarOferta(payload);
      setShowModalSucess(true);
    } catch (error) {
      toast.error('Não foi possível Desabilitar a Oferta');
      setShowModalError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <FullScreenLoader loadingMessage={loadingMessage} />}
      <DefaultContainer paddingTop paddingBottom>
        <DefaultHeader
          label={`Detalhes Oferta Global ${ofertaGlobalId}`}
          pageMessage="Confira abaixo os detalhes da Oferta"
        ></DefaultHeader>
        <Form className="editForm" ref={formRef} onSubmit={handleSubmit}>
          <Input readOnly name="id" type="string" placeholder="Ex.: " label="Id da Oferta" isBold full />
          {/*<Input readOnly name="apresentante" type="text" label="Apresentante" isBold full />*/}
          <Input
            readOnly
            name="protestado_entidade_uuid"
            type="string"
            placeholder="Ex.: b0fc0a27-589b-4c5f-8392-fec521d84e52"
            label="Protestado Entidade UUID"
            isBold
            full
          />
          <Input readOnly name="valor_de" type="text" placeholder="Ex.: 99" label="Valor Inicial" isBold full />
          <Input readOnly name="valor_ate" type="text" placeholder="Ex.: 999" label="Valor Final" isBold full />
          {/*<Input name="desconto" label="Desconto Valor" readOnly value={desconto} onChange={handleChangeDesconto} isBold />*/}
          <Input
            name="desconto_porcentagem"
            mask={'99%'}
            readOnly
            type="string"
            placeholder="Ex.: 20%"
            label="Desconto Porcentagem (%)"
            value={desconto_porcentagem}
            onChange={handleChangeDescontoPorcentagem}
            isBold
            full
          />
          {/*<Input readOnly name="iof" type="string" placeholder="Ex.: 2.59" label="IOF" isBold full />*/}
          {/*<Input readOnly name="taxa_servicos" type="string" placeholder="Ex.: 2.59" label="Taxa Serviços" isBold full />*/}
          <Input readOnly name="data_inicio" type="date" placeholder="Ex.: 01/01/2022" label="Data Inicial" isBold full />
          <Input readOnly name="data_fim" type="date" placeholder="Ex.: 01/01/2022" label="Data Final" isBold full />

          <Toggle
            margin="20px 10px"
            fontWeight="500"
            onChange={handleChangeCartao}
            label="Cartão"
            value={cartao}
            name="cartao"
            paddingRight
            disabled={true}
          />
          <Input isView={false} readOnly name="parcela_cartao" type="number" placeholder="Ex.: 2" label="Parcelas Cartão" isBold full />
          <Input
            isView={false}
            readOnly
            name="juros_cartao"
            mask={'99%'}
            type="string"
            placeholder="Ex.: 2%"
            label="Juros Cartão"
            value={juros_cartao}
            onChange={handleChangeJurosCartao}
            isBold
            full
          />

          <Toggle
            margin="20px 10px"
            fontWeight="500"
            onChange={handleChangeBoleto}
            label="Boleto"
            value={boleto}
            name="boleto"
            paddingRight
            disabled={true}
          />
          <Input isView={false} readOnly name="parcela_boleto" type="number" placeholder="Ex.: 2" label="Parcelas Boleto" isBold full />
          <Input
            isView={false}
            readOnly
            name="juros_boleto"
            mask={'99%'}
            type="string"
            placeholder="Ex.: 2%"
            value={juros_boleto}
            onChange={handleChangeJurosBoleto}
            label="Juros Boleto"
            isBold
            full
          />

          <Toggle
            margin="20px 10px"
            fontWeight="500"
            onChange={handleChangeStatus}
            label="Status"
            value={status}
            name="status"
            paddingRight
            disabled={true}
          />
          {/* <ReactToggleArea>
            <ReactToggleTextContainer paddingRight>
              <span>Enviar Sms</span>
            </ReactToggleTextContainer>
            <ReactToggle id="statusEnvioSms" checked={statusEnvioSms} onChange={handleChangeStatusEnvioSms} />
          </ReactToggleArea>

          <ReactToggleArea>
            <ReactToggleTextContainer paddingRight>
              <span>Habilitar Notificações</span>
            </ReactToggleTextContainer>
            <ReactToggle id="notificacao" checked={notificacao} onChange={handleChangeNotificacao} />
          </ReactToggleArea> */}
          {status === true ? (
            <>
              <ContainerButton>
                <Button type="submit" size="big" full color="error" loading={isLoading} disabled={false} data-cy="desabilitarOfertaGlobal">
                  Desabilitar Oferta
                </Button>
              </ContainerButton>
            </>
          ) : (
            <ContainerButton>
              <Button type="submit" size="big" full color="cancelar" loading={isLoading} disabled={true}>
                Oferta Desabilitada
              </Button>
            </ContainerButton>
          )}
        </Form>
      </DefaultContainer>

      <ModalWarning
        isOpen={showModalWarning}
        buttonLabelConfirm="Confirmar Cancelamento"
        label="Você tem certeza do cancelamento dessa oferta? Após cancelar uma oferta ela não será mais mostrada aos devedores"
        handleButtonClick={() => {
          setShowModalWarning(false);
          handleSendCancelOffer(id);
        }}
        handleClickCancelButton={() => setShowModalWarning(false)}
        onClose={() => setShowModalWarning(false)}
      />

      <ModalConfirm
        isOpen={showModalSucess}
        onClose={() => history.push('/ofertas/listar-ofertas-globais')}
        handleButtonClick={() => history.push('/ofertas/listar-ofertas-globais')}
        label={`Oferta Global ${ofertaGlobalId} Desabilitada com Sucesso`}
        buttonLabel="Ok"
      />
      <ModalError
        isOpen={showModalError}
        onClose={() => setShowModalError(false)}
        handleButtonClick={() => setShowModalError(false)}
        label="Erro na Solicitação, verifique os dados informados"
        buttonLabel="Ok"
      />
    </>
  );
};

export default EditarOfertaGlobal;
