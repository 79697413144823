// import api from '@/services/api';
import { AccountBalanceBMP } from '../interfaces/AccountBalanceBMP';
import { AccountDetail } from '../interfaces/AccountDetail';

export const getAccountBalance = async (params: AccountDetail): Promise<AccountBalanceBMP> => {
  console.log('Rota desativada: não integramos mais saldo com BMP.', params);
  return {} as AccountBalanceBMP;
  // const { data } = await api.get(`/users/protested-titles/corporation/consultar-saldo`, {
  //   params: {
  //     ...(params.numero_banco ? { numero_banco: params.numero_banco } : {}),
  //     ...(params.agencia ? { agencia: params.agencia } : {}),
  //     ...(params.agencia_digito ? { agencia_digito: params.agencia_digito } : {}),
  //     ...(params.conta ? { conta: params.conta } : {}),
  //     ...(params.conta_digito ? { conta_digito: params.conta_digito } : {})
  //   }
  // });
  // return data;
};
