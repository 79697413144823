import ScrollToTop from '@/utils/ScrollToTop';
import jwtDecode from 'jwt-decode';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps, RouteComponentProps } from 'react-router-dom';

import AuthLayout from '../modules/_layouts/auth';
import DefaultLayout from '../modules/_layouts/default';

interface Props extends RouteProps {
  isPrivate?: boolean;
}

export interface Authenticated {
  SignIn: {
    access_token: string;
  };
}

const RouteWrapper: React.FC<Props> = ({ component: Component, isPrivate = false, ...rest }) => {
  const authenticated = useSelector((state: Authenticated) => state.SignIn.access_token);
  const user: any = authenticated ? jwtDecode(authenticated) : null;

  if (!authenticated && isPrivate) {
    return <Redirect to="/" />;
  }

  if (authenticated && !isPrivate) {
    if (user.cpf) {
      return <Redirect to="/deal-offers" />;
    }
    return <Redirect to="/minha-conta-empresarial" />;
  }

  const Layout = authenticated ? DefaultLayout : AuthLayout;

  if (!Component) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps) => (
        <>
          <ScrollToTop />
          <Layout>
            <Component {...props} />
          </Layout>
        </>
      )}
    />
  );
};

export default RouteWrapper;
