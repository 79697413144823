import React from "react";

import { Container } from "./styles";

interface Props {
  size?: "small" | "default" | "medium" | "medium2" | "medium3" | "big" | "big2" | "biggest";
  weight?: "bold" | "bolder" | "lighter" | "normal" | "medium";
  color?: "secondary" | "primary" | "orange200" | "white" | "gray400" | "yellow" | "red" | "gray500";
  bold?: boolean;
  lineThrough?: boolean;
  textDecorationColor?: "primary" | "red";
  align?: "left" | "center" | "right";
  margin?: string;
}

const ChipStatusContainer: React.FC<Props> = ({
  size,
  weight,
  color,
  align,
  bold,
  lineThrough,
  textDecorationColor,
  children,
  margin,
  ...rest
}) => {
  return (
    <Container
      size={size}
      weight={weight}
      color={color}
      bold={bold}
      lineThrough={lineThrough}
      textDecorationColor={textDecorationColor}
      align={align}
      margin={margin}
    >
      {children}
    </Container>
  );
};

export default ChipStatusContainer;
