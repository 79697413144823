import Text from '@/components/Ui/Text';
import DescriptionContainer from '@/modules/Deal/components/DescriptionContainer';
import { checkEmptyObject } from '@/utils/utils_v1/Title';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../Button';
import { TitleV1 } from '../../interfaces/Titulo';
import { CompanyDetails, Container, ContainerButton, PaymentsDescription, Section1, Section2 } from './styles';

interface IProps {
  posts: TitleV1[];
}

const DataList: React.FC<IProps> = ({ posts }) => {
  const history = useHistory();

  return (
    <div className="list__container">
      <div className="list_data">
        {posts.map((item: TitleV1) => {
          return (
            <Container key={item.id}>
              <DescriptionContainer>
                <Section1>
                  <CompanyDetails>
                    <Text size="medium" bold>
                      ID do título:{item.id}
                    </Text>
                    <Text size="small">Apresentante: {item.presenter_name ? item.presenter_name : 'Não informado'}</Text>
                    <Text size="medium" bold>
                      Nome Devedor:{item.debtor_name}
                    </Text>
                    <Text size="small">E-mail: {checkEmptyObject(item.debtor_email)}</Text>
                  </CompanyDetails>
                </Section1>
                <Section2>
                  <CompanyDetails>
                    <Text size="small">
                      Data da Vencimento: {item.issue_date ? moment(item.issue_date).format('DD/MM/YYYY') : 'Não informada'}
                    </Text>
                  </CompanyDetails>
                </Section2>
                <Section2>
                  <CompanyDetails>
                    <Text size="medium" bold>
                      Número Título: {item.title_number}
                    </Text>
                  </CompanyDetails>
                </Section2>

                <PaymentsDescription>
                  <ContainerButton>
                    <Button full onClick={() => history.push(`/titulos/listar-titulos/${item.id}`)}>
                      Ver Título
                    </Button>
                  </ContainerButton>
                </PaymentsDescription>
              </DescriptionContainer>
            </Container>
          );
        })}
      </div>
    </div>
  );
};

export default DataList;
