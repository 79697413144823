import flex from '@/assets/styles/flex';
import theme from '@/assets/styles/theme';
import styled from 'styled-components';

interface StyledProps {
  width?: string;
  padding?: string;
  isCenter?: boolean;
}

interface ListContainerProps {
  margin?: string;
  padding?: string;
}

export const Container = styled.div<StyledProps>`
  ${flex.direction.column};
  ${flex.justify.spaceBetween}
  width: 100%;
  padding: ${(props) => (props.padding ? props.padding : '20px 40px')};
  margin-bottom: 5px;
  border-radius: 0px;
  background-color: ${theme.colors.white};
  @media only screen and (max-width: 1366px) {
    margin-bottom: 30px;
  }
  @media only screen and (max-width: 768px) {
    padding: 20px;
    margin-bottom: 20px;
  }
`;

export const ItemDetails = styled.div<StyledProps>`
  ${flex.direction.column}
  ${flex.justifySpaceBetween.alignStart}
  margin: 10px 10px;
  justify-content: center;
  width: ${(props) => (props.width ? props.width : '')};
  height: 30px;

  ${({ isCenter }) =>
    isCenter &&
    `
    ${flex.direction.row}
    ${flex.justify.center}
  `};

  @media only screen and (max-width: 768px) {
    margin: 0;
    height: 60px;
    margin-top: 30px;
  }
`;

export const ListContainer = styled.div<ListContainerProps>`
  margin: ${(props) => (props.margin ? props.margin : '')};
  padding: ${(props) => (props.padding ? props.padding : '')};
`;

export const IconContainer = styled.div`
  ${flex.direction.row}
  ${flex.justify.center}
  width: 100%;
`;
