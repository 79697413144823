import Button from '@/components/Ui/Button';
import Text from '@/components/Ui/Text';
import DescriptionContainer from '@/modules/Deal/components/DescriptionContainer';
import moment from 'moment';
import React, { useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { MdError } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { UploadHistorico, UploadTitle } from '../../interfaces/UploadHistorico';
import ModalList from '../ModalList';
import { BtnItemActive, BtnItemError, BtnItemPending, Container, ItemDetails, ListContainer } from './styles';

interface IProps {
  posts: UploadHistorico[];
  cancelaRemessa: (id: string) => void;
  confirmaRemessa: (title_upload_id: string, valid_titles: any) => void;
}

const DataList: React.FC<IProps> = ({ posts, cancelaRemessa, confirmaRemessa }) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [validTitles, setValidTitles] = useState<Record<string, UploadTitle[]>>({});

  return (
    <ListContainer>
      {posts.map((item: UploadHistorico) => {
        const itemValidTitles = validTitles[item.id] || [];

        return (
          <Container key={item.id}>
            <DescriptionContainer>
              <ItemDetails width="25%">
                <Text size="medium" bold>
                  ID do Upload: {item.id}
                </Text>
                <Text size="small">Origem da Remessa: {item.origin}</Text>
              </ItemDetails>
              <ItemDetails width="25%">
                <Text margin="10px 10px" size="small" bold>
                  {item.status === 'C' && (
                    <BtnItemActive>
                      <FaCheckCircle />
                      <h2>Criado</h2>
                    </BtnItemActive>
                  )}
                  {item.status === 'P' && (
                    <BtnItemPending>
                      <MdError />
                      <h2>Pendente</h2>
                    </BtnItemPending>
                  )}
                  {item.status === 'E' && (
                    <BtnItemError>
                      <MdError />
                      <h2>Erro</h2>
                    </BtnItemError>
                  )}
                  {item.status === 'R' && (
                    <BtnItemError>
                      <MdError />
                      <h2>Recusado</h2>
                    </BtnItemError>
                  )}
                  {item.status === 'F' && (
                    <BtnItemActive>
                      <MdError />
                      <h2>Finalizado</h2>
                    </BtnItemActive>
                  )}
                </Text>
              </ItemDetails>
              <ItemDetails width="25%">
                <Text size="small">Data da Criação: {moment(item.created_at).format('DD/MM/YYYY HH:mm:ss')}</Text>
              </ItemDetails>
              <ItemDetails width="25%">
                {item.status === 'P' ? (
                  <>
                    <Button color="error" full onClick={() => cancelaRemessa(item.id)}>
                      Cancelar Remessa
                    </Button>
                    <Button disabled color="primary" full onClick={() => confirmaRemessa(item.id, itemValidTitles)}>
                      Confirmar Remessa
                    </Button>
                  </>
                ) : (
                  <Button disabled={item.status === 'R'} full onClick={() => history.push(`/titulos/upload-titulos/${item.id}`)}>
                    Ver Resultados
                  </Button>
                )}
              </ItemDetails>
            </DescriptionContainer>

            <ModalList
              idArquivo={item.id}
              key={`upload${item.id}`}
              isOpen={showModal}
              onClose={() => setShowModal(false)}
              handleButtonClick={() => setShowModal(false)}
              label="Detalhes do Upload"
              buttonLabel="Ok"
            />
          </Container>
        );
      })}
    </ListContainer>
  );
};

export default DataList;
