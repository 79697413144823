import { FaUserCircle } from 'react-icons/fa';
import { IoIosArrowDown } from 'react-icons/io';

import styled from 'styled-components';

import theme from '@/assets/styles/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  z-index: 5;
`;

export const UserAvatar = styled(FaUserCircle)`
  width: 30px;
  height: 30px;
  margin: 10px;
  color: ${theme.colors.white};

  @media only screen and (max-width: 1200px) {
    width: 30px;
    height: 30px;
  }
`;

export const UserText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 10px;

  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;
export const MenuItem = styled.div`
  font-size: 1.25rem;
  font-weight: bolder;
  padding: 4px 0;

  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;

export const UserData = styled.div`
  font-size: 13px;
  padding: 4px 0;

  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;

export const MenuIcon = styled(IoIosArrowDown)`
  font-size: 1.25rem;

  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;

export default Container;
