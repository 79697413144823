import { Table, TableContainer, Tbody, Td, Tfoot, Th, Thead, Tr } from '@chakra-ui/react';
import React from 'react';
import moment from 'moment';
import { formatarDinheiro } from '@/utils/Utilities';
import { AccountStatementBMP } from '../../interfaces/AccountStatementBMP';

interface Props {
  statementItems?: AccountStatementBMP[];
}

const AccountStatementTable = ({ statementItems = [] }: Props) => (
  <TableContainer mx="48px" mt="24px" p="8px" border="1px" borderColor="gray.300" borderRadius="10px">
    <Table variant="striped" colorScheme="whatsapp">
      <Thead>
        <Tr>
          <Th>Data Movimentação</Th>
          <Th>Tipo Movimentação</Th>
          <Th>Nome Favorecido</Th>
          <Th isNumeric>Valor</Th>
        </Tr>
      </Thead>
      <Tbody>
        {statementItems.map((item: AccountStatementBMP, index: number) => (
          <Tr key={`${item.descricaoOperacao}${index}`}>
            <Td>{moment(item.dtMovimento).format('DD/MM/YYYY HH:mm')}</Td>
            <Td>{item.descricaoOperacao}</Td>
            <Td>{item.nome}</Td>
            <Td isNumeric>{formatarDinheiro(item.vlrMovimento)}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  </TableContainer>
);

export default AccountStatementTable;
