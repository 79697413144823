import { IUserCompanyProps } from '@/components/Interfaces/UserCompanyProps';
import { ISignInProps } from '@/modules/_layouts/components/HeaderCompany/components/UserSetting';
import ScrollToTop from '@/utils/ScrollToTop';
import jwtDecode from 'jwt-decode';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import DefaultLayout from '../modules/_layouts/default';
import { Authenticated } from './Route';

interface PrivateRouteProps extends RouteProps {
  unauthorizedPath: string;
  superUserOnly?: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, unauthorizedPath, superUserOnly = false, ...rest }) => {
  const accessToken = useSelector((state: ISignInProps) => state.SignIn.access_token);
  const authenticated = useSelector((state: Authenticated) => state.SignIn.access_token);

  const [user, setUserDecode] = useState({} as IUserCompanyProps);
  const [isRendered, setIsRendered] = useState(false);

  const renderScreen = useMemo(() => {
    return (superUserOnly && user.is_superuser_protestado) || (!superUserOnly && (user.is_admin || user.is_superuser_protestado));
  }, [superUserOnly, user]);

  const redirectScreen = useMemo(() => {
    return (superUserOnly && !user.is_superuser_protestado) || (!superUserOnly && !user.is_admin && !user.is_superuser_protestado);
  }, [superUserOnly, user]);

  //   const Layout =
  //     user.is_superuser_protestado || user.is_admin ? DefaultLayout : AuthLayout;

  useEffect(() => {
    if (accessToken && Object.keys(user).length === 0) {
      let userDecode = jwtDecode<IUserCompanyProps>(accessToken);
      if (userDecode) {
        setUserDecode(userDecode);
      }
    }

    if (!isRendered) {
      setIsRendered(true);
    }
  }, []);

  if (!authenticated) {
    return <Redirect to="/" />;
  }

  if (!Component) {
    return <Redirect to={{ pathname: unauthorizedPath }} />;
  }

  return isRendered ? (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            <ScrollToTop />
            {renderScreen && (
              <DefaultLayout>
                <Component {...props} />
              </DefaultLayout>
            )}

            {redirectScreen && <Redirect to={{ pathname: unauthorizedPath }} />}
          </>
        );
      }}
    />
  ) : null;
};

export default PrivateRoute;
