import React, { useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import queryString from 'query-string';
import * as Yup from 'yup';

import Button from '@/components/Ui/Button';
import GoBack from '@/components/Ui/GoBack';
import Input from '@/components/Ui/Input';
import Modal from '@/components/Ui/Modal/ModalConfirm';
import Title from '@/components/Ui/Title';
import history from '@/services/history';
import { Creators } from '@/store/ducks/RecoverPassword';
import { FormHandles } from '@unform/core';

import AuthTitle from '../components/AuthTitle';
import { Container, ContainerButton, Form } from '../components/Container/styles';
import ErrorMessage from '../components/ErrorMessage';

interface DataValidation {
  newPassword: string;
  newPasswordConfirmation: string;
}

interface Error {
  RecoverPassword: {
    error: { code: number; message: string };
  };
}

interface Loading {
  RecoverPassword: {
    loading: boolean;
    redefined: boolean;
  };
}

const RecoverPassword: React.FC = () => {
  const dispatch = useDispatch();
  const formRef = useRef<FormHandles>(null);
  const location = useLocation();
  const qString = queryString.parse(location.search);
  const token = qString.token as string;

  const errorMessage = useSelector((state: Error) => state.RecoverPassword.error);
  const loading = useSelector((state: Loading) => state.RecoverPassword.loading);
  const isRedefined = useSelector((state: Loading) => state.RecoverPassword.redefined);

  const toggleModal = useCallback(() => {
    dispatch(
      Creators.RecoverPasswordSuccess({
        redefined: !isRedefined
      })
    );
    history.push('/');
  }, [isRedefined, dispatch]);
  const handleSubmit = useCallback(
    async (data: DataValidation) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          newPassword: Yup.string()
            .min(6, 'A senha deve conter pelo menos 6 caracteres')
            .max(32, 'A senha deve conter no máximo 32 caracteres')
            .required('Por favor informe uma nova senha'),
          newPasswordConfirmation: Yup.string()
            .min(6, 'A confirmação deve conter pelo menos 6 caracteres')
            .max(32, 'A confirmação deve conter no máximo 32 caracteres')
            .required('Por favor confirme sua nova senha')
            .oneOf([Yup.ref('newPassword'), ''], 'As senhas são diferentes')
        });

        await schema.validate(data, {
          abortEarly: false
        });
        const payload = {
          token,
          password: data.newPassword,
          confirm_password: data.newPasswordConfirmation
        };
        dispatch(Creators.RecoverPasswordRequest(payload));
      } catch (err) {
        const validationErrors: { [key: string]: string } = {};

        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: Yup.ValidationError) => {
            validationErrors[error.path] = error.message;
          });

          formRef.current?.setErrors(validationErrors);
        }
      }
    },
    [dispatch, token]
  );
  if (!token) {
    history.push('/');
  }
  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Modal
          isOpen={isRedefined}
          onClose={toggleModal}
          handleButtonClick={toggleModal}
          label="Senha alterada com sucesso"
          buttonLabel="Concluir"
        />
        <GoBack label="Voltar para login" url="/login" />
        <AuthTitle>
          <Title label="Redefinir senha" isVisiblePixel />
        </AuthTitle>
        {errorMessage && errorMessage.message && <ErrorMessage label={errorMessage.message} />}
        <Input name="newPassword" type="password" placeholder="Digite sua senha" label="Nova Senha*" full />
        <Input
          name="newPasswordConfirmation"
          type="password"
          placeholder="Digite a confirmação de senha"
          label="Confirme a Nova Senha*"
          full
        />

        <ContainerButton>
          <Button full color="primary" type="submit" loading={loading} disabled={!!loading}>
            Alterar senha
          </Button>
        </ContainerButton>
      </Form>
    </Container>
  );
};

export default RecoverPassword;
