import React from 'react';
import Pixel from '@/components/Ui/Pixel';
import Text from '@/components/Ui/Text';
import { Container } from './styles';
import Tooltip from '../Tooltip';
import theme from '@/assets/styles/theme';

interface Props {
  label: string;
  isVisiblePixel?: boolean;
  marginBottom?: string;
  centralize?: boolean;
  tooltip?: boolean;
  tooltipMessage?: string;
  tooltipPlace?: 'top' | 'right' | 'bottom' | 'left';
  tooltipType?: 'dark' | 'success' | 'warning' | 'error' | 'info' | 'light';
  tooltipEffect?: 'float' | 'solid';
  iconFontSize?: string;
  tooltipBackgroundColor?: string;
}

const Title: React.FC<Props> = ({
  label,
  isVisiblePixel,
  marginBottom,
  centralize,
  tooltip = false,
  tooltipMessage,
  tooltipPlace,
  tooltipType,
  tooltipEffect,
  iconFontSize,
  tooltipBackgroundColor,
  ...rest
}) => {
  return (
    <Container marginBottom={marginBottom} centralize={centralize}>
      <Text size="big" bold>
        {label}
      </Text>
      {isVisiblePixel && <Pixel />}
      <Tooltip
        padding="0px 0px 0px 8px"
        isView={tooltip}
        tooltipMessage={tooltipMessage}
        place={tooltipPlace}
        type={tooltipType}
        effect={tooltipEffect}
        iconFontSize={iconFontSize}
        backgroundColor={tooltipBackgroundColor}
        iconColor={theme.colors.primary}
      />
    </Container>
  );
};

export default Title;
