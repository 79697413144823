import api from '@/services/api';
import { TitulosAplicaveisPayload } from '../interfaces/TitulosAplicaveisPayload';

export const getTitulosAplicaveisOfertaGlobal = async (params: TitulosAplicaveisPayload): Promise<number> => {
  return api
    .get(`/users/protested-titles/corporation/titulos-aplicaveis-oferta`, {
      params: {
        ...(params.valor_de ? { value_from: params.valor_de } : {}),
        ...(params.valor_ate ? { value_to: params.valor_ate } : {}),
        ...(params.dt_vencimento_de ? { due_from: params.dt_vencimento_de } : {}),
        ...(params.dt_vencimento_ate ? { due_to: params.dt_vencimento_ate } : {}),
        ...(params.dt_cadastro_de ? { uploaded_from: params.dt_cadastro_de } : {}),
        ...(params.dt_cadastro_ate ? { uploaded_to: params.dt_cadastro_ate } : {})
      }
    })
    .then(({ data }) => data);
};
