import { IUserCompanyProps } from '@/components/Interfaces/UserCompanyProps';
import { Titulo } from '@/modules/Entidades/EditarTitulo/interfaces/Titulo';
import { TitulosResponse } from '@/modules/Entidades/ListarTitulos/utils/handleListTitulo';
import jwtDecode from 'jwt-decode';

export function getRandomNumber(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getUserDecode(accessToken: string): IUserCompanyProps {
  return jwtDecode<IUserCompanyProps>(accessToken);
}

export function checkIsSuperUser(accessToken: string): boolean {
  if (accessToken) {
    const userDecode = jwtDecode<IUserCompanyProps>(accessToken);
    if (userDecode) {
      return userDecode.is_superuser_protestado;
    }
    return false;
  }
  return false;
}

export function formatarDinheiro(valor?: number, incluirSimbolo = true): string {
  if (!valor) {
    valor = 0;
  }

  let valorFormatado = valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

  if (valor === 0) {
    valorFormatado = 'R$ 0,00';
  }

  if (!incluirSimbolo) {
    valorFormatado = valorFormatado.replace(/^R\$\s?/, '');
  }

  return `${valorFormatado}`;
}

export function formatarCpfCnpj(valor?: string): string {
  if (valor) {
    if (valor.length === 14) {
      valor = valor.replace(/^(\d{12})(\d)/, '$1-$2');
      valor = valor.replace(/^(\d{8})(\d)/, '$1/$2');
      valor = valor.replace(/^(\d{5})(\d)/, '$1.$2');
      valor = valor.replace(/^(\d{2})(\d)/, '$1.$2');
    } else {
      valor = valor.replace(/^(\d{9})(\d)/, '$1-$2');
      valor = valor.replace(/^(\d{6})(\d)/, '$1.$2');
      valor = valor.replace(/^(\d{3})(\d)/, '$1.$2');
    }
    return valor;
  }
  return '';
}

export function getOnlyNumbers(string: string): string {
  if (string && string !== '') {
    return string.match(/\d/g)?.join('') ?? '';
  }
  return '';
}

export function getStringBetweenParentheses(string: string): string[] | null {
  const regExp = /\(([^)]+)\)/g;
  return string.match(regExp);
}

export function getErrorMessage(error: any, defaultMessage: string): string {
  const err = error as any;
  if (err?.response?.data?.message) {
    return err.response.data.message;
  }
  return defaultMessage;
}

export function getSuccessMessage(response: any, defaultMessage: string): string {
  if (response.detail) {
    return response.detail;
  }
  return defaultMessage;
}

export function getFirstDayOfMonth(year: number, month: number): number {
  const firstDayOfMonth = new Date(year, month, 1);
  return firstDayOfMonth.getDate();
}

export function getLastDayOfMonth(year: number, month: number): number {
  const lastDayOfMonth = new Date(year, month + 1, 0);
  return lastDayOfMonth.getDate();
}

export function gerarNumeroRandom(n: number): number {
  const num = Math.round(Math.random() * n);
  return num;
}

export function mod(dividendo: number, divisor: number): number {
  return Math.round(dividendo - Math.floor(dividendo / divisor) * divisor);
}

export function gerarCnpjValido(): string {
  const n = 9;
  const n1 = gerarNumeroRandom(n);
  const n2 = gerarNumeroRandom(n);
  const n3 = gerarNumeroRandom(n);
  const n4 = gerarNumeroRandom(n);
  const n5 = gerarNumeroRandom(n);
  const n6 = gerarNumeroRandom(n);
  const n7 = gerarNumeroRandom(n);
  const n8 = gerarNumeroRandom(n);
  const n9 = 0;
  const n10 = 0;
  const n11 = 0;
  const n12 = 1;
  let d1 = n12 * 2 + n11 * 3 + n10 * 4 + n9 * 5 + n8 * 6 + n7 * 7 + n6 * 8 + n5 * 9 + n4 * 2 + n3 * 3 + n2 * 4 + n1 * 5;
  d1 = 11 - mod(d1, 11);
  if (d1 >= 10) d1 = 0;
  let d2 = d1 * 2 + n12 * 3 + n11 * 4 + n10 * 5 + n9 * 6 + n8 * 7 + n7 * 8 + n6 * 9 + n5 * 2 + n4 * 3 + n3 * 4 + n2 * 5 + n1 * 6;
  d2 = 11 - mod(d2, 11);
  if (d2 >= 10) d2 = 0;
  return '' + n1 + n2 + '.' + n3 + n4 + n5 + '.' + n6 + n7 + n8 + '/' + n9 + n10 + n11 + n12 + '-' + d1 + d2;
}

export function getValorCustas(titulo: Titulo | TitulosResponse): number {
  const valorCustas =
    titulo.status_codigo && titulo.status_codigo === '102'
      ? Number(titulo.valor_custas_calculado)
      : titulo.valor_custas
      ? Number(titulo.valor_custas)
      : 0;
  return valorCustas;
}

export function splitName(fullName: string) {
  const nameParts = fullName.trim().split(' ');
  const firstName = nameParts[0];
  const lastName = nameParts.slice(1).join(' ');

  return { firstName, lastName };
}
