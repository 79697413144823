import FeedbackImage from '@/assets/images/magnifier.png';
import EmptyFeedback from '@/components/Helpers/EmptyFeedback';
import Button from '@/components/Ui/Button';
import { FullScreenLoader } from '@/components/Ui/FullScreenLoader';
import Input from '@/components/Ui/Input';
import Title from '@/components/Ui/Title';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { ContainerTop, FeedbackArea, ResultsArea } from '../components/EditContainer/styles';
import { Titulos } from './interfaces/interface';
import { getTituloRequestFiltrar } from './providers/getTituloRequestFiltrar';
import { handleListTitulo } from './utils/handleListTitulo';
//pagination
import {
  DefaultFilterButton,
  DefaultFilterButtonContainer,
  DefaultFilterContainer,
  DefaultFilterField,
  DefaultFilterInputs,
  DefaultFilterRow,
  DefaultTopContainer
} from '@/components/Ui/DefaultFilterContainer/styles';
import { DefaultContainerPages, DefaultListPageContainer } from '@/components/Ui/DefaultHeader/style';
import DefaultPagination from '@/components/Ui/DefaultPagination';
import GoBack from '@/components/Ui/GoBack';
import Select from '@/components/Ui/Select';
import { getOnlyNumbers } from '@/utils/Utilities';
import { useHistory } from 'react-router-dom';
import DataList from './components/paginate/DataList';
import { FiltrosTitulo } from './interfaces/FiltrosTitulo';

export const situacaoTituloOptions = [
  { value: 'TODOS', label: 'Todos' },
  { value: 'false', label: 'Apenas Habilitados' },
  { value: 'true', label: 'Apenas Desabilitados' }
];

export interface FormFiltrosTitulo {
  id: string | number;
  nome_devedor: string;
  is_deleted: string;
  numero_titulo: string | number;
  numero_identificacao_devedor: string;
}

const ListarTitulos: React.FC = () => {
  const history = useHistory();
  const [data, setData] = useState<Titulos[]>([]);
  const itemsPerPage = 10;
  const allPages = Math.ceil(data.length / itemsPerPage);
  const [isLoading, setIsLoading] = useState(false);
  const [mask, setMask] = useState('99.999.999/9999-99');
  const [displayedData, setDisplayedData] = useState<Titulos[]>([]);
  const [clearSelect, setClearSelect] = useState(false);

  const [is_deleted, setIsDeleted] = useState(false);
  const handleChangeDel = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsDeleted(e.target.checked);
  };

  const formRef = useRef<FormHandles>(null);

  const onPageChange = (page = 1) => {
    const startItem = (page - 1) * itemsPerPage;
    const endItem = page * itemsPerPage;
    setDisplayedData(data.slice(startItem, endItem));
  };

  const clearFilter = useCallback((fieldValue: string) => {
    formRef.current?.clearField(fieldValue);
  }, []);

  const clearAllFilters = useCallback(() => {
    formRef.current?.reset();
    setClearSelect(true);
    setIsDeleted(false);
  }, []);

  const handleSetMask = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const valorDigitado = e.target.value;
    if (!valorDigitado.length || valorDigitado.length > 14) {
      setMask('99.999.999/9999-99');
    } else {
      setMask('999.999.999-999');
    }
  }, []);

  useEffect(() => {
    onPageChange();
  }, [data]);

  const handleSubmit = async (fields: FormFiltrosTitulo) => {
    try {
      setIsLoading(true);

      let isDeleted: null | boolean = null;
      if (fields.is_deleted !== '' && fields.is_deleted !== 'TODOS') {
        isDeleted = JSON.parse(fields.is_deleted);
      }

      const numero_identificacao_devedor = getOnlyNumbers(fields.numero_identificacao_devedor);

      const params = {
        id: fields.id,
        nome_devedor: fields.nome_devedor,
        is_deleted: isDeleted,
        numero_titulo: fields.numero_titulo,
        numero_identificacao_devedor: numero_identificacao_devedor
        // valor_titulo: fields.valor_titulo,
        // valor_saldo_protesto: fields.valor_saldo_protesto,
        // nosso_numero: fields.nosso_numero,
        // data_emissao: fields.data_emissao,
        // data_vencimento: fields.data_vencimento,
      } as FiltrosTitulo;

      const TituloList = await getTituloRequestFiltrar(params);

      if (TituloList?.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const titulo: any[] = handleListTitulo(TituloList);
        setData(titulo);
      } else {
        toast.warning('Não foram encontrados Títulos para este Filtro!');
        setData([]);
      }
    } catch (error) {
      toast.error('Não foi possível listar os Títulos!');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <FullScreenLoader loadingMessage="Carregando Títulos..." />}
      <DefaultContainerPages paddingTop paddingBottom>
        <DefaultTopContainer>
          <GoBack />
          <ContainerTop>
            <Button onClick={() => history.push('/ofertas/listar-ofertas-titulo')}>Listar Ofertas</Button>
            <Button color="secondary" onClick={() => history.push('/titulos/upload-titulos')}>
              Upload de Títulos
            </Button>
          </ContainerTop>
        </DefaultTopContainer>
        <Title label="Listar Títulos" isVisiblePixel />
        <DefaultListPageContainer>
          <DefaultFilterContainer>
            <Form className="editForm" ref={formRef} onSubmit={handleSubmit}>
              <DefaultFilterInputs>
                <DefaultFilterField>
                  <Input
                    width="100%"
                    name="id"
                    type="string"
                    placeholder="Ex.: 3200"
                    label="ID do Título"
                    padding="8px 8px 8px 6px"
                    iconSize="1.2rem"
                    isClearable={true}
                    clearFunction={() => clearFilter('id')}
                  />
                </DefaultFilterField>
                <DefaultFilterField>
                  <Input
                    width="100%"
                    name="nome_devedor"
                    type="string"
                    placeholder="Ex.: Fernando Alves"
                    label="Nome do Devedor"
                    padding="8px 8px 8px 6px"
                    iconSize="1.2rem"
                    isClearable={true}
                    clearFunction={() => clearFilter('nome_devedor')}
                  />
                </DefaultFilterField>
                <DefaultFilterField>
                  <Input
                    width="100%"
                    name="numero_titulo"
                    type="string"
                    placeholder="Ex.: 01303030"
                    label="Número do Título"
                    padding="8px 8px 8px 6px"
                    iconSize="1.2rem"
                    isClearable={true}
                    clearFunction={() => clearFilter('numero_titulo')}
                  />
                </DefaultFilterField>
                <DefaultFilterField>
                  <Input
                    width="100%"
                    name="numero_identificacao_devedor"
                    type="string"
                    placeholder="Ex.: 000.000.000-00"
                    mask={mask}
                    onChange={handleSetMask}
                    maskChar=""
                    label="CPF/CNPJ"
                    padding="8px 8px 8px 6px"
                    iconSize="1.2rem"
                    isClearable={true}
                    data-cy="cpfCnpj"
                    clearFunction={() => clearFilter('numero_identificacao_devedor')}
                  />
                </DefaultFilterField>
              </DefaultFilterInputs>
              <DefaultFilterInputs margin="20px 0 0 0">
                <DefaultFilterField>
                  <Select
                    id="situacaoTitulo"
                    name="is_deleted"
                    label="Situação do Título"
                    color="gray500"
                    isFull
                    options={situacaoTituloOptions}
                    clearSelect={clearSelect}
                    setClearSelect={setClearSelect}
                  />
                </DefaultFilterField>
              </DefaultFilterInputs>
              <DefaultFilterRow padding="0px 10px 0px 10px">
                <DefaultFilterButtonContainer>
                  <DefaultFilterButton>
                    <Button type="button" onClick={clearAllFilters} size="big" color="warning" width="100%" disabled={!!isLoading}>
                      Limpar Filtros
                    </Button>
                  </DefaultFilterButton>
                </DefaultFilterButtonContainer>
                <DefaultFilterButtonContainer>
                  <DefaultFilterButton>
                    <Button
                      type="submit"
                      minWidth="180px"
                      size="big"
                      color="primary"
                      width="100%"
                      loading={isLoading}
                      disabled={!!isLoading}
                      data-cy="filtrarTitulos"
                    >
                      Filtrar
                    </Button>
                  </DefaultFilterButton>
                </DefaultFilterButtonContainer>
              </DefaultFilterRow>
            </Form>
          </DefaultFilterContainer>
          {data?.length === 0 ? (
            <FeedbackArea>
              <EmptyFeedback
                imagePath={FeedbackImage}
                title="Clique em Filtrar para buscar Resultados"
                message="
            Utilize os filtros acima para encontrar os Títulos cadastrados"
              />
            </FeedbackArea>
          ) : (
            <ResultsArea>
              <DataList posts={displayedData} />
              <GoBack />
              <DefaultPagination allPagesNumber={allPages} itemsPerPage={10} itemsNumber={data.length} pageChange={onPageChange} />
            </ResultsArea>
          )}
        </DefaultListPageContainer>
      </DefaultContainerPages>
    </>
  );
};

export default ListarTitulos;
