import styled from 'styled-components'

import theme from '@/assets/styles/theme'

export const ErrorMessage = styled.div`
  background-color: ${theme.colors.white};
  border-radius: 5px;
  border-color: ${theme.colors.error};
  border-style: solid;
  border-width: 0.5px;
  padding: 10px 20px;
  margin-bottom: 20px;
  color: ${theme.colors.error};
`
