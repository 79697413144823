import api from '@/services/api';
import { Usuarios } from '../interfaces/interface';

export const getUsuariosRequestFiltrar = async (params: Usuarios) => {
  try {
    const response = await api.get(`/corporation/user/`, {
      params: {
        ...(params.id ? { id: params.id } : {}),
        ...(params.email ? { email: params.email } : {}),
        ...(params.is_admin ? { is_admin: params.is_admin } : {}),
        ...(params.is_superuser_protestado ? { is_superuser_protestado: params.is_superuser_protestado } : {}),
        is_active: params.is_active
      }
    });
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar Usuários:', error);
    throw error;
  }
};
