import api from '@/services/api';

export interface FilterTitlesCampaign {
  status_codigos: null | number[];
}

export interface TitleDTO {
  id: number;
  apresentante: string;
  razao: string;
  cnpj: string;
  numero_titulo: string;
  numero_parcela: number;
  nosso_numero: string;
  data_emissao: string;
  data_vencimento: string;
  valor_titulo: string;
  valor_saldo_protesto: string;
  nome_devedor: string;
  numero_identificacao_devedor: string;
  email_devedor: string;
  telefone_devedor: number;
  id_titulo: number;
  uf: string;
  cidade: string;
  email_callback: boolean;
  is_deleted: boolean;
  protestado_entidade_uuid: string;
  valor_custas: string;
  status_codigo: string;
  //   ofertas: any[];
  //   apresentante_ofertas: ApresentanteOferta[];
  dt_sent: string;
}

export const GetAvailableTitles = async (params: FilterTitlesCampaign): Promise<TitleDTO[]> => {
  try {
    const response = await api.get(`/users/protested-titles/corporation/protested-titles`, {
      params: {
        ...(params.status_codigos ? { status_codigos: params.status_codigos } : {}),
        ...{ is_deleted: false }
      }
    });
    return response.data;
  } catch (error) {
    console.error(`Erro ao buscar títulos: ${error}`);
    throw error;
  }
};
