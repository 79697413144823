import React from 'react';
import SvgCheck from '@/assets/images/modal/check-modal.svg';
import Button from '@/components/Ui/Button';
import Text from '@/components/Ui/Text';
import ModalLayout from '../ModalLayout';
import { TextContainer, ImageSuccess, ContainerButton } from './styles';

interface ModalInterface {
  isOpen: boolean;
  onClose: () => void;
  handleButtonClick: () => void;
  label: string;
  buttonLabel: string;
  extraBtnVisible?: boolean;
  extraBtnLabel?: string;
  extraBtnFunction?: () => void;
}

const ModalConfirm: React.FC<ModalInterface> = ({
  isOpen = false,
  onClose,
  handleButtonClick,
  label,
  buttonLabel,
  extraBtnVisible = false,
  extraBtnLabel,
  extraBtnFunction
}) => {
  if (isOpen) {
    return (
      <ModalLayout padding="16px" height="450px" onClose={onClose}>
        <ImageSuccess src={SvgCheck} alt="Sucesso!" />
        <TextContainer>
          <Text size="medium" bold>
            {label}
          </Text>
        </TextContainer>
        <ContainerButton>
          {extraBtnVisible && (
            <Button color="primary" onClick={extraBtnFunction} full={false}>
              {extraBtnLabel}
            </Button>
          )}
          <Button color="primary" onClick={handleButtonClick} full={false} data-cy="confirmModal">
            {buttonLabel}
          </Button>
        </ContainerButton>
      </ModalLayout>
    );
  }
  return null;
};

export default ModalConfirm;
