import { IoIosMenu } from 'react-icons/io';

import styled from 'styled-components';

import theme from '@/assets/styles/theme';

export const MenuIcon = styled(IoIosMenu)`
  display: none;

  @media only screen and (max-width: 768px) {
    display: flex;
    width: 36px;
    height: 36px;
    color: ${theme.colors.white};
  }
`;

export const ImageLogo = styled.img`
  width: 13vw;
  height: auto;
  cursor: pointer;

  @media only screen and (max-width: 1200px) {
    width: 170px;
    margin-left: 0px;
  }

  @media only screen and (max-width: 768px) {
    width: 170px;
    margin-left: 0px;
  }
`;

export const Container = styled.header`
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 999;
  width: 100%;
  height: 11.801896733403582vh;
  padding-left: 14vw;
  padding-right: 14vw;

  background-color: ${theme.colors.primary};
  color: ${theme.colors.white};

  @media only screen and (max-width: 1440px) {
    padding-left: 4.5vw;
    padding-right: 4.5vw;
    height: 10vh;
  }

  @media only screen and (max-width: 1366px) {
    padding-left: 3.5vw;
    padding-right: 3.5vw;
    height: 10vh;
  }

  @media only screen and (max-width: 1024px) {
    padding: 20px 10px;
    height: 10vh;
  }
`;

export const NavigationContainer = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const NavBar = styled.div`
  margin: 0 40px 0 0;
`;

export default Container;
