import Text from '@/components/Ui/Text';
import { useField } from '@unform/core';
import React, { useEffect, useRef } from 'react';
import ReactSelect, { OptionTypeBase, Props as SelectProps } from 'react-select';
import { Container, InputContainer } from './styles';

interface Props extends SelectProps<OptionTypeBase> {
  id?: string;
  name: string;
  label?: string;
  options: { value: string | number; label: string | number }[];
  placeholder?: string;
  isFull?: boolean;
  customMargin?: string;
  isSearchable?: boolean;
  color?: 'secondary' | 'primary' | 'orange200' | 'white' | 'gray400' | 'gray500';
  isBold?: boolean;
  isView?: boolean;
  clearSelect?: boolean;
  setCLearSelect?: React.Dispatch<React.SetStateAction<boolean>>;
}

const Select: React.FC<Props> = ({
  id,
  name,
  label,
  options,
  placeholder = 'Selecionar',
  isFull,
  customMargin = undefined,
  isSearchable = false,
  color,
  isBold,
  clearSelect,
  setClearSelect,
  isView = true,
  ...rest
}) => {
  const customStyles = {
    control: (base: any) => ({
      ...base,
      height: 45,
      minHeight: 45
    })
  };
  const selectRef = useRef(null);
  const { fieldName, registerField, error, defaultValue } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option: OptionTypeBase) => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value.value;
      }
    });
  }, [fieldName, registerField, rest.isMulti]);

  useEffect(() => {
    if (clearSelect && selectRef.current) {
      selectRef.current.select.clearValue();
      setClearSelect(false);
    }
  }, [clearSelect]);

  return (
    <Container customMargin={customMargin} isFull={isFull} isErrored={!!error} isView={isView}>
      <Text color={color} margin="10px 0px" bold={isBold}>
        {label}
      </Text>
      <InputContainer className="select" isErrored={!!error} customMargin={customMargin}>
        <ReactSelect
          id={id}
          name={name}
          options={options}
          defaultValue={defaultValue}
          placeholder={placeholder}
          isSearchable={isSearchable}
          ref={selectRef}
          menuPlacement="auto"
          styles={customStyles}
          {...rest}
        />
      </InputContainer>
      {error && <span>{error}</span>}
    </Container>
  );
};

export default Select;
