import styled from 'styled-components';

export const ContainerUpload = styled.div`
  background-color: white;
  border-radius: 10px;
  margin: 0px 0px 50px 0px;
  padding: 40px 40px 40px 40px;
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0px 0px 0px 0px;
  width: 100%;
`;

export const FormContainerLeft = styled.div`
  display: flex;
  width: 30%;
  justify-content: space-between;
  align-items: center;
`;
export const FormContainerRight = styled.div`
  display: flex;
  width: 70%;
  justify-content: flex-end;
  align-items: center;
`;

export const IconArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 20px 0px 20px;
`;

export const FormContainer = styled.div`
  display: flex;
`;

export const DisplayButton = styled.div`
  display: grid;
`;

export const DisplayText = styled.div`
  margin: 10px 10px 10px 10px;
`;

export const TextDescUpload = styled.div`
  display: grid;
  margin: 10px 50px 0px 0px;
`;
