import { getEntidades } from '../EditarUsuario/providers/getEntidades';

export const getOptionsEntidadesCtrl = async (): Promise<any[]> => {
  const entidadesOpt = [];
  const entidades = await getEntidades();
  if (entidades?.length > 0) {
    for (let index = 0; index < entidades.length; index++) {
      const entidade = entidades[index];
      const entidadeOption = {
        value: entidade.id,
        label: entidade.name
      };
      entidadesOpt.push(entidadeOption);
    }
  }
  return entidadesOpt;
};
