import theme from '@/assets/styles/theme';
import { IUserCompanyProps } from '@/components/Interfaces/UserCompanyProps';
import api from '@/services/api';
import history from '@/services/history';
import jwtDecode from 'jwt-decode';
import { call, put } from 'redux-saga/effects';
import { Creators, LoginEmpresasRequestPayload } from '../ducks/SignInEmpresas';

interface ResponseType {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

/**
 * Login
 *
 * @param param0
 */
export function* loginEmpresasRequest({ loginEmpresasRequest }: { loginEmpresasRequest: LoginEmpresasRequestPayload }) {
  try {
    const response: ResponseType = yield call(api.post, '/auth/corporation/login', loginEmpresasRequest);
    const { access_token } = response.data;
    yield put(Creators.loginSuccess(access_token));

    const userDecode: IUserCompanyProps = jwtDecode(access_token);
    if (userDecode && userDecode?.corporation_white_label_data?.primary_color) {
      theme.colors.primary = userDecode?.corporation_white_label_data.primary_color;
      theme.colors.primaryCompany = userDecode?.corporation_white_label_data.primary_color;
      theme.colors.secondary = userDecode?.corporation_white_label_data.secondary_color;
    }

    history.push('minha-conta-empresarial');
  } catch (e) {
    const error = e as any;
    const { code, message } = error.response.data;

    if (code === 2002) {
      history.push(`email-nao-confirmado?document=${loginEmpresasRequest.email}`);
    }

    yield put(Creators.loginFailure({ code, message }));
  }
}

/**
 * Logout
 *
 */
export function* logout() {
  try {
    yield call(api.post, '/auth/logout');
    yield put(Creators.logoutSuccess());
  } catch (error) {
    yield put(Creators.logoutFailure());
  }
}
function dispatch(arg0: any) {
  throw new Error('Function not implemented.');
}
