import styled from 'styled-components';

interface DashboardProps {
  padding?: string;
  height?: string;
  width?: string;
  isView?: boolean;
  minHeight?: string;
  repeats?: number;
}

export const ChartContainer = styled.div<DashboardProps>`
  height: ${(props) => (props.height ? props.height : '33vh')};
  min-height: ${(props) => (props.minHeight ? props.minHeight : '230px')};
  width: ${(props) => (props.width ? props.width : '50%')};
  padding: ${(props) => (props.padding ? props.padding : '30px 20px 30px 20px')};
`;

export const ChartRowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ScreenNavCardContainer = styled.div<DashboardProps>`
  align-items: end;
  margin: 0px 0px 0px 0px;
  display: grid;
  grid-template-columns: ${(props) => (props.repeats ? `repeat(${props.repeats}, 1fr)` : 'repeat(5, 1fr)')};

  ${({ repeats }) =>
    repeats &&
    repeats > 5 &&
    `
    @media (max-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
    }
  `}

  padding: 12px 48px;
`;

export const QuickAccessContainer = styled.div<DashboardProps>`
  display: ${(props) => !props.isView && 'none !important'};
  padding: ${(props) => (props.padding ? props.padding : '')};
  margin: 30px 0 0 0;
  width: 100%;
  display: grid;
  align-items: normal;
  justify-content: center;
  justify-items: center;
`;
