import { HeaderDropdownType } from '@/modules/_layouts/components/HeaderCompany/interfaces/HeaderDropdownType';
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import IconController from '../IconController';
import { DropdownList, DropdownMenu } from './style';

interface Props {
  dropdownItems: HeaderDropdownType[];
  isDropdownOpen: boolean;
  closeDropdown: () => void;
}

const HeaderDropdown: React.FC<Props> = ({ dropdownItems, isDropdownOpen = false, closeDropdown }) => {
  const history = useHistory();
  const [isDropdownOpenLocal, setDropdownOpen] = useState(false);
  const [dropdownItemsClone, setDropdownItemsClone] = useState(dropdownItems);

  const handleHover = useCallback(
    (index: number, value: boolean) => {
      const dropdownItems = cloneDeep(dropdownItemsClone);
      // if (dropdownItems[index - 1]?.isHovered) dropdownItems[index - 1].isHovered = false;
      // if (dropdownItems[index + 1]?.isHovered) dropdownItems[index + 1].isHovered = false;
      dropdownItems[index].isHovered = value;

      setDropdownItemsClone(dropdownItems);
    },
    [dropdownItemsClone]
  );

  useEffect(() => {
    setDropdownOpen(isDropdownOpen);
  }, [setDropdownOpen, isDropdownOpen]);

  return (
    <DropdownMenu
      onClick={() => {
        setDropdownOpen(!isDropdownOpenLocal);
      }}
      onMouseLeave={() => {
        setDropdownOpen(false);
      }}
    >
      {dropdownItemsClone.map((item, index) => {
        return (
          <React.Fragment key={item.id}>
            <DropdownList
              onMouseEnter={() => handleHover(index, true)}
              onClick={(e) => {
                e.stopPropagation();
                if (!item.disabled) {
                  closeDropdown();
                  history.push(item.path);
                }
              }}
              disabled={item.disabled}
              data-cy={item.dataCy}
            >
              {item.icon && <IconController paddingLeft={item.isSubitem ? '40px' : '10px'} paddingRight={'8px'} icon={item.icon} />}
              {item.title}
            </DropdownList>
            {item.isHovered &&
              item.subitems?.length &&
              item.subitems.map((subitem) => {
                return (
                  <React.Fragment key={subitem.id}>
                    <DropdownList
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!subitem.disabled) {
                          closeDropdown();
                          history.push(subitem.path);
                        }
                      }}
                      disabled={subitem.disabled}
                      data-cy={subitem.dataCy}
                    >
                      {subitem.icon && (
                        <IconController paddingLeft={subitem.isSubitem ? '40px' : '10px'} paddingRight={'8px'} icon={subitem.icon} />
                      )}
                      {subitem.title}
                    </DropdownList>
                  </React.Fragment>
                );
              })}
          </React.Fragment>
        );
      })}
    </DropdownMenu>
  );
};

export default HeaderDropdown;
