import DefaultHeader from '@/components/Ui/DefaultHeader';
import { DefaultContainer } from '@/components/Ui/DefaultHeader/style';
import { FullScreenLoader } from '@/components/Ui/FullScreenLoader';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import DataList from './components/paginate/DataList';
import { TitleV1 } from './interfaces/Titulo';
import { getUploadDetail } from './providers/getUploadRequest';
import { UploadsContainer } from './styles';

interface Props {}

const EditarUpload: React.FC<Props> = () => {
  const url = window.location.href.split('/');
  const uploadId = url[5];
  const [displayedData, setDisplayedData] = useState<TitleV1[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getTituloData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getUploadDetail(uploadId);
      if (response?.results?.length > 0) {
        const tituloList = response.results[0]?.titles || [];
        if (tituloList.length > 0) {
          setDisplayedData(tituloList);
        } else {
          toast.error(`Não foram encontrados dados para o upload de ID ${uploadId}!`);
        }
      } else {
        toast.error(`Não foram encontrados dados para o upload de ID ${uploadId}!`);
      }
    } catch (error) {
      toast.error('Não foi possível carregar seus dados, tente mais tarde!');
    } finally {
      setIsLoading(false);
    }
  }, [uploadId]);

  useEffect(() => {
    getTituloData();
  }, [getTituloData]);

  return (
    <>
      {isLoading && <FullScreenLoader loadingMessage="Carregando Detalhes do Upload..." />}
      <DefaultContainer paddingTop paddingBottom ignoreWidth>
        <DefaultHeader
          url={'/titulos/upload-titulos'}
          label={`Detalhes do Upload ${uploadId}`}
          pageMessage="Confira abaixo a listagem de títulos"
        ></DefaultHeader>
        <UploadsContainer>
          <DataList posts={displayedData} />
        </UploadsContainer>
      </DefaultContainer>
    </>
  );
};

export default EditarUpload;
