import React, { useState, useEffect } from 'react'
import { toast } from "react-toastify";

import Button from '@/components/Ui/Button'
import Text from '@/components/Ui/Text'
import DataList from './components/DataList'
import { MdRefresh } from 'react-icons/md'

import ModalLayout from '../ModalLayout'
import { TextContainer, ContainerButton } from './styles'

import { UploadDetalhe } from "../../interfaces/UploadDetalhe";
import { getUploadTitulosById } from "./providers/uploadById";

interface ModalInterface {
  isOpen: boolean
  onClose: () => void
  handleButtonClick: () => void
  label: string
  buttonLabel: string
  idArquivo: number
}

const ModalList: React.FC<ModalInterface> = ({
  isOpen = false,
  onClose,
  handleButtonClick,
  label,
  buttonLabel,
  idArquivo
}) => {

  
  const [data, setData] = useState<UploadDetalhe[]>([]);
  const [id, setId] =useState({idArquivo})

  const getUploadTitulos = async () => {
    console.log(`IdArquivo: ${idArquivo}`)
    try {
      let params = id.idArquivo
      const uploadsList = await getUploadTitulosById(params);
      setData(uploadsList)
      console.log(`id: ${JSON.stringify(id)}`)

    } catch (error) {
      toast.error("Não foi possível listar os Uploads de Títulos!");
    } finally {
      console.log("finally")
    }
  };

  // useEffect(() => {
  //   first
  
  //   return () => {
  //     second
  //   }
  // }, [third])
  

  if (isOpen) {
    return (
      <ModalLayout height='90%' padding="15px" onClose={onClose}>
        <TextContainer>
          <Text size="medium" bold>
            {label}
          </Text>
        </TextContainer>
        <DataList posts={data} />
        <ContainerButton>
          <Button color="primary" onClick={getUploadTitulos} full={false}>
            <MdRefresh /> Atualizar Resultados
          </Button>
          <Button color="primary" onClick={handleButtonClick} full={false}>
            {buttonLabel}
          </Button>
        </ContainerButton>
      </ModalLayout>
    )
  }
  return null
}

export default ModalList
