import { Titulo } from '@/modules/Entidades/EditarTitulo/interfaces/Titulo';
import { TitulosResponse } from '@/modules/Entidades/ListarTitulos/utils/handleListTitulo';
import { convertGlobalOffersV1, GlobalOfferV1 } from './GlobalOffer';
import { convertTitleOffersV1, TitleOfferV1 } from './TitleOffer';

export interface TitleV1 {
  id: string;
  recuperi_id: number;
  upload_line: number;
  partner_field: string;
  title_number: string;
  parcel_number: number;
  partner_number: string;
  issue_date: string;
  due_date: string;
  title_value: string;
  protest_value: string;
  calculated_emolument_value: string;
  informed_emolument_value: string;
  debtor_name: string;
  debtor_document: string;
  debtor_postal_code: string;
  debtor_state: string;
  debtor_city: string;
  debtor_address: string;
  debtor_neighborhood: string;
  debtor_email: object;
  debtor_phone: object;
  presenter_name: string;
  presenter_document: string;
  presenter_code: string;
  presenter_postal_code: string;
  presenter_city: string;
  issuer_name: string;
  issuer_document: string;
  issuer_postal_code: string;
  issuer_city: string;
  issuer_address: string;
  current_status:
    | '101'
    | '102'
    | '103'
    | '105'
    | '107'
    | '121'
    | '144'
    | '220'
    | '221'
    | '225'
    | '230'
    | '231'
    | '240'
    | '241'
    | '250'
    | '251'
    | '261'
    | '320'
    | '998'
    | '999';
  type_declaration: string;
  type_endorsment: string;
  type_debtor_acceptance: string;
  protocol_city_ibge_code: string;
  protocol_notary_number: string;
  protocol_number: string;
  protocol_date: string;
  allow_messaging: boolean;
  enriched_date: string;
  is_deleted: boolean;
  created_at: string;
  updated_at: string;
  company: CompanyV1;
  title_upload: string;
  //   debt_specie: any;
  global_offers: GlobalOfferV1[];
  title_offers: TitleOfferV1[];
}

export interface CompanyV1 {
  id: string;
  name: string;
  document: string;
}

export function convertTitleV1(title: TitleV1): TitulosResponse {
  return {
    id: title.id,
    email: checkEmptyObject(title.debtor_email),
    created_at: title.created_at,
    name: title.debtor_name,
    is_active: '',
    apresentante: title.presenter_name,
    cidade: title.debtor_city,
    razao: title.presenter_name,
    cnpj: title.presenter_document,
    data_emissao: title.issue_date,
    data_vencimento: title.due_date,
    email_callback: title.allow_messaging,
    email_devedor: checkEmptyObject(title.debtor_email),
    nome_devedor: title.debtor_name,
    nosso_numero: title.partner_number,
    numero_identificacao_devedor: title.debtor_document,
    numero_parcela: title.parcel_number,
    numero_titulo: title.title_number,
    telefone_devedor: checkEmptyObject(title.debtor_phone),
    uf: title.debtor_state,
    valor_saldo_protesto: title.protest_value,
    valor_titulo: title.title_value,
    valor_custas: title.informed_emolument_value,
    valor_custas_calculado: title.calculated_emolument_value ? title.calculated_emolument_value : '0',
    is_deleted: title.is_deleted,
    status_codigo: title.current_status,
    apresentante_ofertas: [],
    ofertas: []
  };
}

export function convertTitlesV1(titles: TitleV1[]): TitulosResponse[] {
  return titles.map(convertTitleV1);
}

export function convertTitleV1toDetailTitle(title: TitleV1): Titulo {
  return {
    apresentante: title.presenter_code,
    cidade: title.debtor_city,
    cnpj: title.presenter_document,
    data_emissao: title.issue_date,
    data_vencimento: title.due_date,
    email_callback: title.allow_messaging,
    email_devedor: checkEmptyObject(title.debtor_email),
    id: title.id,
    id_titulo: title.recuperi_id,
    is_deleted: title.is_deleted,
    nome_devedor: title.debtor_name,
    nosso_numero: title.partner_number,
    numero_identificacao_devedor: title.debtor_document,
    numero_parcela: title.parcel_number,
    numero_titulo: title.title_number,
    protestado_entidade_uuid: title.company.id,
    razao: title.presenter_name,
    telefone_devedor: checkEmptyObject(title.debtor_phone),
    uf: title.debtor_state,
    valor_saldo_protesto: title.protest_value,
    valor_titulo: title.title_value,
    valor_custas: title.informed_emolument_value,
    valor_custas_calculado: title.calculated_emolument_value ? title.calculated_emolument_value : '0',
    status_codigo: title.current_status,
    ofertas: convertTitleOffersV1(title.title_offers),
    apresentante_ofertas: convertGlobalOffersV1(title.global_offers)
  };
}

export function convertTitlesV1DetailTitle(titles: TitleV1[]): Titulo[] {
  return titles.map(convertTitleV1toDetailTitle);
}

export function checkEmptyObject(obj: object) {
  if (!obj || !Object.keys(obj).length) {
    return 'Não informado';
  }
  return Object.values(obj).join(', ');
}
