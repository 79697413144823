import { OfertaTitulo } from '../interfaces/interface';
import { getListaOfertasTitulo } from '../providers/getListaOfertasTitulo';

export const listaOfertasTituloCtrl = async (filtros: any): Promise<OfertaTitulo[]> => {
  // TODO fazer e testar filtro
  const listaOfertasTitulo: OfertaTitulo[] = [];
  const ofertas = await getListaOfertasTitulo(filtros);
  if (ofertas && ofertas.length > 0) {
    for (let j = 0; j < ofertas.length; j++) {
      const ofertaTitulo = ofertas[j] as OfertaTitulo;
      ofertaTitulo.key = `${ofertaTitulo.titulo}/${ofertaTitulo.id}`;
      ofertaTitulo.icon_emolumentos = false;
      if (!ofertaTitulo.desconto && !ofertaTitulo.desconto_porcentagem) {
        ofertaTitulo.icon_emolumentos = true;
      }
      listaOfertasTitulo.push(ofertaTitulo);
    }
  }
  return listaOfertasTitulo;
};
