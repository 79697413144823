import axios from 'axios'

import store, { RootState } from '@/store'
import { Creators } from '@/store/ducks/SignIn'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

api.interceptors.request.use(async config => {
  const state = store.getState() as RootState
  const token = state.SignIn.access_token
  if (!token) {
    return config
  }
  config.headers.Authorization = `Bearer ${token}`
  return config
})

api.interceptors.response.use(
  response => {
    return response
  },

  error => {
    if (error?.response?.status === 401 && error.response.data.statusCode === 401) {
      store.dispatch(Creators.logoutRequest())
    }
    throw error
  },
)

export default api
