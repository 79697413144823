import Button from '@/components/Ui/Button';
import DefaultHeader from '@/components/Ui/DefaultHeader';
import { DefaultContainer } from '@/components/Ui/DefaultHeader/style';
import { FullScreenLoader } from '@/components/Ui/FullScreenLoader';
import Input from '@/components/Ui/Input';
import ModalConfirm from '@/components/Ui/Modal/ModalConfirm';
import ModalError from '@/components/Ui/Modal/ModalError';
import Toggle from '@/components/Ui/Toggle';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { ContainerButton } from '../components/EditContainer/styles';
import { IEntidadePayload } from './interfaces/entidade';
import { getCorporation } from './providers/getCorporation';
import { updateCorporation } from './providers/updateCorporation';

const EditarEntidade: React.FC<IEntidadePayload> = (fields: IEntidadePayload) => {
  const url = window.location.href.split('/');
  const entidadeId = url[4];
  const [loadingMessage, setLoadingMessage] = useState('');
  const [showModalSucess, setShowModalSucess] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const [status, setIsCheckedA] = useState(false);
  const handleChangeActive = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckedA(e.target.checked);
  };

  const formRef = useRef<FormHandles>(null);

  const getCorporationData = async () => {
    try {
      setIsLoading(true);
      setLoadingMessage('Carregando Detalhes da Entidade...');
      const entidade = await getCorporation(entidadeId);

      formRef.current?.setData({
        id: entidade?.id,
        email: entidade?.email,
        name: entidade?.name,
        is_active: entidade?.is_active,
        notifica_company_uuid: entidade?.notifica_company_uuid,
        notifica_company_username: entidade?.notifica_company_username,
        notifica_company_password: entidade?.notifica_company_password,
        notifica_send_days_interval: entidade?.notifica_send_days_interval
      });

      if (entidade.is_active === true) {
        setIsCheckedA(true);
      } else {
        setIsCheckedA(false);
      }
    } catch (error) {
      toast.error('Não foi possível carregar seu dados, tente mais tarde!');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCorporationData();
  }, []);

  const handleSubmit = useCallback(
    async (fields: IEntidadePayload) => {
      try {
        setIsLoading(true);
        setLoadingMessage('Atualizando Entidade...');

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          id: Yup.string().required('Por favor preencha o Id'),
          email: Yup.string()
            .required('Por favor informe um email')
            .matches(/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/, 'Insira um email válido'),
          name: Yup.string().required('Por favor preencha o Nome completo'),
          is_active: Yup.boolean().optional()
        });

        await schema.validate(fields, {
          abortEarly: false
        });

        const payload = {
          is_active: status,
          name: fields.name,
          id: fields.id,
          email: fields.email
        };
        await updateCorporation(payload);
        setShowModalSucess(true);
      } catch (err) {
        setShowModalError(true);
        const validationErrors: { [key: string]: string } = {};

        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: Yup.ValidationError) => {
            validationErrors[error.path] = error.message;
          });
          formRef.current?.setErrors(validationErrors);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [status]
  );

  return (
    <>
      {isLoading && <FullScreenLoader loadingMessage={loadingMessage} />}
      <DefaultContainer paddingTop paddingBottom>
        <DefaultHeader label="Editar Entidade" pageMessage="Altere abaixo os detalhes da Entidade"></DefaultHeader>
        <Form className="editForm" ref={formRef} onSubmit={handleSubmit}>
          <Input name="id" type="string" placeholder="Ex.: " disabled label="Id da Entidade" full />
          <Input name="name" type="string" placeholder="Ex.: Nome da Entidade" label="Nome da Entidade*" full />
          <Input name="email" type="string" placeholder="Ex.: protestado@protestado.com.br" label="E-mail*" full />
          <Input name="notifica_company_uuid" readOnly type="string" placeholder="XXX" label="UUID Notifica*" full />
          <Input
            name="notifica_company_username"
            readOnly
            type="string"
            placeholder="Exemplo: notifica@notifica"
            label="Usuário Notifica*"
            full
          />
          <Input name="notifica_company_password" readOnly type="string" placeholder="***" label="Senha Notifica*" full />
          <Input name="notifica_send_days_interval" readOnly type="string" placeholder="Exemplo: 1" label="Intervalo Notifica*" full />
          <Toggle onChange={handleChangeActive} label="Ativa" value={status} name="is_active" paddingRight margin="20px 0px 0px 0px" />
          <ContainerButton>
            <Button type="submit" size="big" full color="primary" loading={isLoading} disabled={!!isLoading} data-cy="atualizarEntidade">
              Salvar Alterações
            </Button>
          </ContainerButton>
        </Form>
      </DefaultContainer>

      <ModalConfirm
        isOpen={showModalSucess}
        onClose={() => setShowModalSucess(false)}
        handleButtonClick={() => history.push('/entidades')}
        label="Entidade Editada com Sucesso"
        buttonLabel="Ok"
      />
      <ModalError
        isOpen={showModalError}
        onClose={() => setShowModalError(false)}
        handleButtonClick={() => setShowModalError(false)}
        label="Erro na Solicitação, verifique os dados informados"
        buttonLabel="Ok"
      />
    </>
  );
};

export default EditarEntidade;
