import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Text from '@/components/Ui/Text';


import { Container, ButtonGoBack, LeftIcon } from './styles';

interface Props {
  label?: string;
  url?: string;
}

const GoBack: React.FC<Props> = ({ label = 'Voltar', url }) => {
  const history = useHistory();

  const handleHistory = useCallback(() => {
    if (url) {
      if (url === 'https://protestado.com.br') {
        window.location.replace(url);
      } else {
        history.push(url);
      }
    } else {
      history.goBack();
    }
  }, [url]);

  return (
    <Container>
      <ButtonGoBack onClick={handleHistory}>
        <LeftIcon />
        <Text bold size='medium2' color="secondary">
          {label}
        </Text>
      </ButtonGoBack>
    </Container>
  );
};

export default GoBack;
