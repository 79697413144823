import { Action } from 'redux'

export enum AuthEnum {
  LOGIN_REQUEST = 'LOGIN_REQUEST',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAILURE = 'LOGIN_FAILURE',

  LOGOUT_REQUEST = 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE = 'LOGOUT_FAILURE',
}

export interface AuthStateType {
  data: {
    name: string
  }
  error: {
    code: number
    message: string
  }
  access_token: string | null
  email: string | null
  isAuthorized: boolean
  loading: boolean
}

export interface LoginRequestPayload {
  cpf?: string
  password?: string
}

export interface LoginRequestEmpresasPayload {
  email?: string
  password?: string
}

export interface LoginSuccessPayload {
  access_token: string
}

export interface ErrorPayload {
  code: number
  message: string
}

export interface ActionTypes extends Action<AuthEnum> {
  loginRequest: (login: LoginRequestPayload) => Action
  loginEmpresasRequest: (login: LoginRequestEmpresasPayload) => Action
  loginSuccess: (data: LoginSuccessPayload) => Action
  loginFailure: (data: ErrorPayload) => Action

  logoutRequest: () => Action
  logoutSuccess: () => Action
  logoutFailure: () => Action
}
