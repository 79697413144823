import React, { useEffect, useRef, useState } from 'react';
import Button from '@/components/Ui/Button';
import { PaginationContainer, PaginationSpacer, PaginationTextContainer } from './styles';

interface PaginationProps {
  allPagesNumber: number;
  itemsPerPage: number;
  itemsNumber: number;
  resetPagination?: boolean;
  pageChange: (navigationType: string) => void;
}

const DefaultPaginationV2: React.FC<PaginationProps> = ({
  allPagesNumber,
  itemsPerPage,
  itemsNumber,
  resetPagination = false,
  pageChange
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const onFirstPage = (): void => {
    setCurrentPage(1);
    setPageChange('FIRST');
  };

  const onPreviousPage = (): void => {
    setCurrentPage(currentPage - 1);
    setPageChange('PREVIOUS');
  };

  const onNextPage = (): void => {
    setCurrentPage(currentPage + 1);
    setPageChange('NEXT');
  };

  const onLastPage = (): void => {
    setCurrentPage(allPagesNumber);
    setPageChange('LAST');
  };

  const setPageChange = (navigationType: string) => {
    pageChange(navigationType);
  };

  useEffect(() => {
    if (resetPagination) {
      setCurrentPage(1);
    }
  }, [resetPagination]);

  return (
    <>
      <PaginationSpacer>
        <PaginationContainer>
          <Button disabled={currentPage === 1} size="small" onClick={() => onFirstPage()} margin="0px 8px 0px 0px">
            Primeira
          </Button>
          <Button
            size="small"
            disabled={currentPage === 1}
            className={`pagination__button pagination__page-previous ${currentPage === 1 && 'pagination__button--disabled'}`}
            onClick={() => onPreviousPage()}
          >
            Anterior
          </Button>
          <PaginationTextContainer>
            <span>Página: {currentPage}</span>/<span>{allPagesNumber}</span>
          </PaginationTextContainer>
          <Button size="small" disabled={currentPage === allPagesNumber} onClick={() => onNextPage()}>
            Próxima
          </Button>
          <Button size="small" disabled={currentPage === allPagesNumber} onClick={() => onLastPage()} margin="0px 0px 0px 8px">
            Última
          </Button>
        </PaginationContainer>
      </PaginationSpacer>
    </>
  );
};

export default DefaultPaginationV2;
