import api from '@/services/api';
import { AgreementReportFilter } from '..';
import { Intervalos } from '../interfaces/interface';
import { AgreementV1 } from '../interfaces/interfaceRelatorio';

export const getAgreementReport = async (params: AgreementReportFilter): Promise<AgreementV1[]> => {
  const { data } = await api.get(`/users/protested-titles/corporation/reports/renegociacoes`, {
    params: {
      ...(params.id ? { id: params.id } : {}),
      ...(params.status_pagamento ? { status: params.status_pagamento } : {}),
      ...(params.forma_pagamento ? { payment_type: params.forma_pagamento } : {}),
      ...(params.tipo_oferta ? { offer_type: params.tipo_oferta } : {}),
      ...(params.tempo_plataforma ? { paid_since: params.tempo_plataforma } : {}),
      ...(params.data_inicio ? { start_date: params.data_inicio } : {}),
      ...(params.data_fim ? { end_date: params.data_fim } : {})
    }
  });
  return data.results;
};

export const getIntervaloRequestFiltrar = (params: Intervalos) => {
  return api
    .get(`/users/protested-titles/corporation/reports/renegociacoes-intervalo`, {
      params: {
        ...(params.tempo_intervalo ? { tempo_intervalo: params.tempo_intervalo } : {})
      }
    })
    .then(({ data }) => data);
};

export const enviaRelatorioEmail = (payload: any) => {
  console.log(payload);
};
