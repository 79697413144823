import React from 'react'

import { Container, Content, ModalHeader, ContainerContent, ModalCloseIcon } from './styles'

interface ModalInterface {
  onClose?: () => void
  showCloseButton?: boolean
  padding?: string
  height?: string
}

const ModalLayout: React.FC<ModalInterface> = ({
  onClose,
  children,
  showCloseButton,
  padding,
  height,
}) => {
  if (String(showCloseButton) !== 'false') showCloseButton = true

  return (
    <>
      <Container>
        <ContainerContent padding={padding} height={height}>
          {showCloseButton && (
            <ModalHeader>
              <ModalCloseIcon onClick={onClose} />
            </ModalHeader>
          )}
          <Content>{children}</Content>
        </ContainerContent>
      </Container>
    </>
  )
}

export default ModalLayout
