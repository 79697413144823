import { FormHandles } from '@unform/core';
import React, { useRef, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import Button from '@/components/Ui/Button';
import ButtonLink from '@/components/Ui/ButtonLink';
import GoBack from '@/components/Ui/GoBack';
import Input from '@/components/Ui/Input';
import Text from '@/components/Ui/Text';
import Title from '@/components/Ui/Title';
import { Creators, LoginEmpresasRequestPayload } from '@/store/ducks/SignInEmpresas';

import AuthMessage from '../components/AuthMessage';
import AuthTitle from '../components/AuthTitle';
import { ContainerButton, Form } from '../components/Container/styles';
import ErrorMessage from '../components/ErrorMessage';


import { LinkGroup, LinkGroupItem, Container } from './styles';
import { useHistory } from 'react-router-dom';
import { Link } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import { HiExternalLink } from 'react-icons/hi';
import theme from '@/assets/styles/theme';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'; // Importa o hook useGoogleReCaptcha

interface DataValidation {
  email: string;
  password: string;
}

interface Error {
  SignIn: {
    error: { code: number; message: string };
  };
}

interface Loading {
  SignIn: {
    loading: boolean;
  };
}

const SignInCompany: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const errorMessage = useSelector((state: Error) => state.SignIn.error);
  const loading = useSelector((state: Loading) => state.SignIn.loading);

  // Obtém o hook useGoogleReCaptcha
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSubmit = useCallback(
    async (data: DataValidation) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string().required('Por favor preencha seu e-mail'),
          password: Yup.string().required('Por favor preencha sua senha')
        });

        await schema.validate(data, {
          abortEarly: false
        });

        // Obter o token do reCAPTCHA
        const recaptchaToken = await executeRecaptcha('login'); // 'login' é o action name configurado no Google reCAPTCHA Admin Console

        const emailWithoutMask = data.email;
        const payload: LoginEmpresasRequestPayload = {
          email: emailWithoutMask,
          password: data.password,
          recaptchaToken // Adiciona o token do reCAPTCHA ao payload
        };

        dispatch(Creators.loginEmpresasRequest(payload));
      } catch (err) {
        const validationErrors: { [key: string]: string } = {};

        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: Yup.ValidationError) => {
            validationErrors[error.path] = error.message;
          });

          formRef.current?.setErrors(validationErrors);
        }
      }
    },
    [dispatch, executeRecaptcha]
  );

  return (
    <Container>
      <GoBack label="Voltar para o site" url="https://protestado.com.br" />

      <AuthTitle>
        <Title label="Login Empresarial" isVisiblePixel />
      </AuthTitle>

      <AuthMessage>
        <Text size="medium2">Preencha os dados abaixo para fazer o login</Text>
      </AuthMessage>

      <Form ref={formRef} onSubmit={handleSubmit}>
        {errorMessage && errorMessage.message && <ErrorMessage label={errorMessage.message} />}

        <Input name="email" type="text" placeholder="Digite seu email" label="Digite seu email" full height="50px" data-cy="logInCompany" />
        <Input
          name="password"
          type="password"
          placeholder="Digite sua senha"
          label="Digite sua senha"
          full
          height="50px"
          data-cy="pwdCompany"
        />
        <Box mt={5} alignItems="center">
          <Link color={theme.colors.primary} display={'flex'} href="https://protestado.com.br/contato" isExternal>
            Problemas ao acessar sua conta? Fale Conosco <HiExternalLink />
          </Link>
        </Box>
        <ContainerButton>
          <Button full color="primary" type="submit" loading={loading} disabled={!!loading} size="big" data-cy="signInCompany">
            Entrar
          </Button>
        </ContainerButton>
        <LinkGroup>
          <LinkGroupItem>
            <ButtonLink
              onClick={() => {
                history.push('/esqueci-minha-senha-empresas');
              }}
              label="Esqueci minha senha"
            />
          </LinkGroupItem>
        </LinkGroup>
      </Form>
    </Container>
  );
};

export default SignInCompany;
