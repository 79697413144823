import React, { useState } from 'react';
import { Entidades } from '../../interfaces/interface';
import { Container, ContainerButton, CompanyDetails, BtnItemActive, BtnItemDisabled, Section1 } from './styles';
import DescriptionContainer from '@/modules/Deal/components/DescriptionContainer';
import Text from '@/components/Ui/Text';
import Button from '../../Button';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

interface IProps {
  posts: Entidades[];
}

const DataList: React.FC<IProps> = ({ posts }) => {
  const history = useHistory();
  return (
    <div className="list__container">
      <div className="list_data">
        {posts.map((item: Entidades, index: number) => {
          return (
            <Container>
              <DescriptionContainer>
                <Section1>
                  <CompanyDetails>
                    <Text size="medium" bold>
                      ID da Empresa:{item.id}
                    </Text>
                    <Text size="small">Data da Criação: {moment(item.created_at).format('DD/MM/YYYY')}</Text>
                  </CompanyDetails>
                </Section1>

                <Section1>
                  <CompanyDetails>
                    <Text size="medium" bold>
                      Nome: {item.name}
                    </Text>
                    <Text size="small">E-mail: {item.email}</Text>
                    <Text size="small">
                      {item.is_active === true ? (
                        <BtnItemActive>
                          <h2>Ativo</h2>
                        </BtnItemActive>
                      ) : (
                        <BtnItemDisabled>
                          <h2>Desabilitado</h2>
                        </BtnItemDisabled>
                      )}
                    </Text>
                  </CompanyDetails>
                </Section1>

                <ContainerButton>
                  <Button
                    full
                    onClick={() => history.push(`/entidades/${item.id}`)}
                    data-cy={`visualizarEntidade${index}`}
                    id={`visualizarEntidade${index}`}
                  >
                    Editar Entidade
                  </Button>
                </ContainerButton>
              </DescriptionContainer>
            </Container>
          );
        })}
      </div>
    </div>
  );
};

export default DataList;
