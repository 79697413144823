import React from 'react';
import Button from '@/components/Ui/Button';
import Text from '@/components/Ui/Text';
import { BodyContainer, TextContainer } from '../styles';

interface TemplateProtestoProps {
  handleButtonClick?: () => void;
}

const TemplateProtesto: React.FC<TemplateProtestoProps> = ({ handleButtonClick }) => {
  return (
    <BodyContainer margin="0px 40px" padding="16px 56px 16px 56px">
      <TextContainer isCentered>
        <Text color="gray400" size="big" bold>
          {'Olá {nome_devedor}'},
        </Text>
      </TextContainer>
      <TextContainer>
        <Text color="gray400" size="medium">
          Nosso sistema indica que você permanece protestado, pois ainda existem débitos pendentes com a empresa {'{nome_sacador}'} ou junto
          aos cartórios de protesto.
        </Text>
      </TextContainer>
      <TextContainer>
        <Text color="gray400" size="medium">
          Para regularizar sua situação, você pode acessar a plataforma Protestado para consultar as oportunidades de negociação e quitação
          do seu débito.
        </Text>
      </TextContainer>
      <TextContainer>
        <Text color="gray400" size="medium">
          Lembramos ainda que, em acordos parcelados, o não pagamento de alguma das parcelas permite que o protesto seja reestabelecido com
          o saldo devedor restante aplicado.
        </Text>
      </TextContainer>
      <TextContainer>
        <Text color="gray400" size="medium">
        Consulte os valores pendentes através do botão abaixo:
        </Text>
      </TextContainer>
      <TextContainer isCentered>
        <Button color="primary" onClick={handleButtonClick} full={false}>
          Acessar a Protestado
        </Button>
      </TextContainer>
      <TextContainer padding="16px 0px 4px 0px">
        <Text color="gray400" size="medium">
          Você também pode consultar sua situação no{' '}
          <a href="https://www.protestodetitulos.org.br/" target="_blank">
            site dos Cartórios de Protesto/IEPTB
          </a>
          .
        </Text>
      </TextContainer>
      <TextContainer isCentered padding="10px 0px 16px 0px">
        <Text color="gray400" size="medium">
          Caso o pagamento já tenha sido realizado, por favor desconsidere essa comunicação.
        </Text>
      </TextContainer>
      <TextContainer isCentered padding="16px 0px 4px 0px">
        <Text color="gray400" size="default">
          Obrigado, Equipe Protestado
        </Text>
      </TextContainer>
      <TextContainer isCentered padding="0px 0px 10px 0px">
        <Text color="gray400" size="default">
          Deseja cancelar o recebimento de e-mails? <a href="{url_link}">Clique Aqui</a>
        </Text>
      </TextContainer>
    </BodyContainer>
  );
};

export default TemplateProtesto;
