import theme from '@/assets/styles/theme';
import styled from 'styled-components';

interface containerProps {
  marginTop?: string;
  paddingTop?: boolean;
  paddingBottom?: boolean;
}

export const DefaultPageContainer = styled.div<containerProps>`
  width: 90%;
  margin-top: 40vh;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  padding-top: ${(containerProps) => (containerProps.paddingTop ? '24px' : '')};
  padding-bottom: ${(containerProps) => (containerProps.paddingBottom ? '24px' : '')};
  margin-top: ${(containerProps) => (containerProps.marginTop ? containerProps.marginTop : 'calc(11.801896733403582vh + 24px)')};
  border-radius: 12px;
  background-color: #ffffff70;

  @media only screen and (max-width: 1440px) {
    margin-top: calc(10vh + 24px);
    margin-bottom: 30px;
  }

  @media only screen and (max-width: 1024px) {
    margin-top: calc(10vh + 24px);
    margin-bottom: 20px;
  }

  @media only screen and (max-width: 768px) {
    margin-top: calc(10vh + 24px);
    margin-bottom: 20px;
  }

  .label {
    margin: 10px 10px;
    font-size: 20px;
    color: ${theme.colors.primary};
    font-weight: normal;
    font-weight: bold;
    -webkit-text-decoration-color: ${theme.colors.primary};
    text-decoration-color: ${theme.colors.primary};
  }
  .labelDescricao {
    margin: 10px 10px;
    font-size: 16px;
    color: ${theme.colors.primary};
    font-weight: normal;
    -webkit-text-decoration-color: ${theme.colors.primary};
    text-decoration-color: ${theme.colors.primary};
  }
  .labelDescricaoCenter {
    text-align: center;
    margin: 10px 10px;
    font-size: 16px;
    color: ${theme.colors.primary};
    font-weight: normal;
    -webkit-text-decoration-color: ${theme.colors.primary};
    text-decoration-color: ${theme.colors.primary};
  }
  .iconsUpload {
    display: flex;
  }
`;

export const DefaultContainerPageTitle = styled.div`
  display: grid;
  align-self: center;
  text-align: center;
`;
