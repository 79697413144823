// import api from '@/services/api';
import { BalanceTransferPayload } from '../interfaces/BalanceTransferPayload';
import { BalanceTransferResponse } from '../interfaces/BalanceTransferResponse';

export const balanceTransfer = async (payload: BalanceTransferPayload): Promise<BalanceTransferResponse> => {
  console.log('Rota desativada: não integramos mais transferencia com BMP.', payload);
  return {} as BalanceTransferResponse;
  // const { data } = await api.post(`/users/protested-titles/corporation/transferir-saldo`, payload);
  // return data;
};
