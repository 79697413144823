import IconController from '@/components/Ui/IconController';
import Text from '@/components/Ui/Text';
import DescriptionContainer from '@/modules/Deal/components/DescriptionContainer';
import { Titulos } from '@/modules/Entidades/ListarTitulos/interfaces/interface';
import { PaymentContainer } from '@/modules/Entidades/RelatoriosOfertasTitulo/paginate/styles';
import { formatarDinheiro } from '@/utils/Utilities';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../Button';
import { Container, ContainerButton, ItemDetails } from './styles';

interface IProps {
  posts: Titulos[];
}

const DataList: React.FC<IProps> = ({ posts }) => {
  const history = useHistory();

  return (
    <div className="list__container">
      <div className="list_data">
        {posts.map((item: Titulos) => {
          return (
            <Container className="Container" key={item.id}>
              <DescriptionContainer>
                <ItemDetails width="15%">
                  <Text margin="12px 0px 0px 0px" size="small" bold>
                    Número do título:
                  </Text>
                  <Text margin="12px 0px 0px 0px" size="small">
                    {item.numero_titulo}
                  </Text>
                  <Text margin="12px 0px 0px 0px" size="small" bold>
                    Nosso número:
                  </Text>
                  <Text margin="12px 0px 0px 0px" size="small">
                    {item.nosso_numero}
                  </Text>
                </ItemDetails>
                <ItemDetails width="20%">
                  <Text size="small" bold>
                    {item.nome_devedor}
                  </Text>
                  <Text margin="12px 0px 0px 0px" size="small">
                    E-mail: {item.email_devedor}
                  </Text>
                  <PaymentContainer>
                    <Text bold margin="12px 0px 0px 0px" size="small">
                      {item.is_deleted == true ? 'Título Desativado' : 'Título Ativo'}
                    </Text>
                    {item.is_deleted == true ? (
                      <IconController paddingLeft={'8px'} margin="12px 0px 0px 0px" icon="closeCircle" fontSize="1.25em" iconColor="red" />
                    ) : (
                      <IconController
                        paddingLeft={'8px'}
                        margin="12px 0px 0px 0px"
                        icon="checkCircle"
                        fontSize="1.25em"
                        iconColor="green"
                      />
                    )}
                  </PaymentContainer>
                </ItemDetails>
                <ItemDetails width="15%">
                  <Text size="small" bold>
                    Valor Título: {formatarDinheiro(Number(item.valor_titulo))}
                  </Text>
                  <Text margin="12px 0px 0px 0px" size="small">
                    Saldo Protesto: {formatarDinheiro(Number(item.valor_saldo_protesto))}
                  </Text>
                  <Text margin="12px 0px 0px 0px" size="small">
                    Custas: {item.valor_custas ? formatarDinheiro(Number(item.valor_custas)) : 'R$ 0,00'}
                  </Text>
                  <Text margin="12px 0px 0px 0px" size="small">
                    Custas Calculadas: {item.valor_custas_calculado ? formatarDinheiro(Number(item.valor_custas_calculado)) : 'R$ 0,00'}
                  </Text>
                </ItemDetails>
                <ItemDetails width="15%">
                  <Text size="small" bold>
                    Tipo do Título
                  </Text>
                  {item.status_codigo === '102' ? (
                    <Text margin="12px 0px 0px 0px" size="small">
                      Protestado
                    </Text>
                  ) : (
                    <span></span>
                  )}
                  {item.status_codigo === '144' ? (
                    <Text margin="12px 0px 0px 0px" size="small">
                      Custas/Emolumentos
                    </Text>
                  ) : (
                    <span></span>
                  )}
                  {item.status_codigo === '320' ? (
                    <Text margin="12px 0px 0px 0px" size="small">
                      Negociação
                    </Text>
                  ) : (
                    <span></span>
                  )}
                  {item.status_codigo === '998' ? (
                    <Text margin="12px 0px 0px 0px" size="small">
                      Título Desabilitado
                    </Text>
                  ) : (
                    <span></span>
                  )}
                </ItemDetails>

                <ContainerButton>
                  <Button full onClick={() => history.push(`/titulos/listar-titulos/${item.id}`)}>
                    Ver Título
                  </Button>
                </ContainerButton>
              </DescriptionContainer>
            </Container>
          );
        })}
      </div>
    </div>
  );
};

export default DataList;
