import { IUserCompanyProps } from '@/components/Interfaces/UserCompanyProps';
import Button from '@/components/Ui/Button';
import DefaultHeader from '@/components/Ui/DefaultHeader';
import { DefaultContainer } from '@/components/Ui/DefaultHeader/style';
import { FullScreenLoader } from '@/components/Ui/FullScreenLoader';
import Input from '@/components/Ui/Input';
import ModalConfirm from '@/components/Ui/Modal/ModalConfirm';
import ModalError from '@/components/Ui/Modal/ModalError';
import ModalWarning from '@/components/Ui/Modal/ModalWarning';
import Toggle from '@/components/Ui/Toggle';
import { ISignInProps } from '@/modules/_layouts/components/HeaderCompany/components/UserSetting';
import { formatarDinheiro } from '@/utils/Utilities';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import jwtDecode from 'jwt-decode';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ContainerButton } from '../components/EditContainer/styles';
import { CancelarOfertaTitulo } from './providers/cancelarOfertaTitulo';
import { getOfertaTituloById } from './providers/getTituloDetalhe';

interface Props {}

const EditarOfertaTitulo: React.FC<Props> = () => {
  const history = useHistory();
  const url = window.location.href.split('/');
  const ofertaTituloId = url[5];
  const [loadingMessage, setLoadingMessage] = useState('');
  const [showModalSucess, setShowModalSucess] = useState(false);
  const [showModalWarning, setShowModalWarning] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emolumentos, setEmolumentos] = useState('');

  const [status, setStatus] = useState(false);
  const handleChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStatus(e.target.checked);
  };
  const [statusEnvioSms, setStatusEnvioSms] = useState(false);
  const handleChangeStatusEnvioSms = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStatusEnvioSms(e.target.checked);
  };
  const [boleto, setBoleto] = useState(false);
  const handleChangeBoleto = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBoleto(e.target.checked);
  };
  const [cartao, setCartao] = useState(false);
  const handleChangeCartao = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCartao(e.target.checked);
  };
  const [notificacao, setNotificacao] = useState(false);
  const handleChangeNotificacao = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNotificacao(e.target.checked);
  };
  const [desconto, setDesconto] = useState<string | number>('');
  const handleChangeDesconto = (e: React.ChangeEvent<HTMLInputElement>) => {
    discountCtrl(e);
    setDesconto(e.target.value);
  };
  const [desconto_porcentagem, setDescontoPorcentagem] = useState('');
  const handleChangeDescontoPorcentagem = (e: React.ChangeEvent<HTMLInputElement>) => {
    discountCtrl(e);
    setDescontoPorcentagem(e.target.value);
  };
  const [juros_cartao, setJurosCartao] = useState('');
  const handleChangeJurosCartao = (e: React.ChangeEvent<HTMLInputElement>) => {
    setJurosCartao(e.target.value);
  };
  const [juros_boleto, setJurosBoleto] = useState<string | number>('');
  const handleChangeJurosBoleto = (e: React.ChangeEvent<HTMLInputElement>) => {
    setJurosBoleto(e.target.value);
  };
  const formRef = useRef<FormHandles>(null);
  const [id, setId] = useState(0);

  const accessToken = useSelector((state: ISignInProps) => state.SignIn.access_token);
  const [user, setUserDecode] = useState({} as IUserCompanyProps);
  const [isSuperUser, setIsSuperUser] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const discountCtrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'desconto') {
      setDescontoPorcentagem('00');
    } else if (e.target.id === 'desconto_porcentagem') {
      setDesconto('0.00');
    }
  };

  const getOfertaData = useCallback(async () => {
    try {
      setIsLoading(true);
      setLoadingMessage('Carregando Detalhes da Oferta...');
      const oferta = await getOfertaTituloById(ofertaTituloId);

      // if (oferta.titulo) {
      // const titulo = await getTituloById(oferta.titulo);

      //   if (titulo) {
      //     let valorCustas: number;
      //     if (!titulo.valor_custas) {
      //       valorCustas = 0;
      //     } else {
      //       valorCustas = Number(titulo.valor_custas);
      //     }
      //     setEmolumentos(valorCustas.toFixed(2).replace('.', ','));
      //   }
      // }

      if (!oferta.desconto) {
        oferta.desconto = '0.00';
      }

      if (!oferta.desconto_porcentagem) {
        oferta.desconto_porcentagem = '0.00';
      }

      // TODO setNotificacao

      formRef.current?.setData({
        apresentante: oferta.apresentante,
        data_fim: oferta.data_fim,
        data_inicio: oferta.data_inicio,
        id: oferta.id,
        id_titulo: oferta.id_titulo,
        // key: oferta.key,
        mensagem: oferta.mensagem,
        nosso_numero: oferta.nosso_numero,
        numero_titulo: oferta.numero_titulo,
        parcela_boleto: oferta.parcela_boleto,
        parcela_cartao: oferta.parcela_cartao,
        protestado_entidade_uuid: oferta.protestado_entidade_uuid,
        titulo: oferta.titulo
      });

      setDesconto(oferta.desconto);
      setDescontoPorcentagem(oferta.desconto_porcentagem);
      setJurosCartao(oferta.juros_cartao);
      setJurosBoleto(oferta.juros_boleto);

      setStatus(oferta.status);
      setStatusEnvioSms(oferta.status_envio_sms);
      setBoleto(oferta.boleto);
      setCartao(oferta.cartao);
      setId(oferta.id);
    } catch (error) {
      toast.error('Não foi possível carregar seu dados, tente mais tarde!');
    } finally {
      setIsLoading(false);
    }
  }, [emolumentos]);

  const handleSubmit = useCallback(async (fields: any) => {
    try {
      setIsLoading(true);
      setLoadingMessage('Atualizando Oferta...');
      setShowModalWarning(true);
    } catch (err) {
      setShowModalError(true);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleSendCancelOffer = async (id: any) => {
    try {
      setIsLoading(true);
      setShowModalWarning(false);
      setLoadingMessage('Desabilitando Oferta...');
      const payload = {
        id: id,
        is_active: false
      };

      await CancelarOfertaTitulo(payload);
      setShowModalSucess(true);
    } catch (error) {
      toast.error('Não foi possível Desabilitar a Oferta');
      setShowModalError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (accessToken && Object.keys(user).length === 0) {
      const userDecode = jwtDecode<IUserCompanyProps>(accessToken);
      if (userDecode) {
        setUserDecode(userDecode);
        const isAdmin = userDecode.is_superuser_protestado || userDecode.is_admin ? true : false;
        setIsSuperUser(userDecode.is_superuser_protestado);
        setIsAdmin(isAdmin);
      }
    }
    getOfertaData();
  }, [emolumentos]);

  return (
    <>
      {isLoading && <FullScreenLoader loadingMessage={loadingMessage} />}
      <DefaultContainer paddingTop paddingBottom>
        <DefaultHeader
          label={`Detalhes Oferta Titulo ${ofertaTituloId}`}
          pageMessage="Confira abaixo os detalhes da Oferta Título"
        ></DefaultHeader>
        <Form className="editForm" ref={formRef} onSubmit={handleSubmit}>
          <Input readOnly name="id" type="string" label="Id da Oferta" isBold full />
          <Input readOnly name="titulo" type="string" isBold label="Id do Título" full />
          <Input
            isView={isSuperUser}
            readOnly
            name="protestado_entidade_uuid"
            type="string"
            placeholder="Ex.: b0fc0a27-589b-4c5f-8392-fec521d84e52"
            label="Protestado Entidade UUID"
            isBold
            full
          />
          <Input readOnly name="data_inicio" placeholder="Ex.: 2022-05-01" label="Data Inicial" isBold full />
          <Input readOnly name="data_fim" placeholder="Ex.: 2022-05-31" label="Data Final" isBold full />
          <Input
            readOnly
            name="desconto"
            label="Desconto Valor"
            placeholder="Ex.: R$ 50"
            value={formatarDinheiro(Number(desconto))}
            isBold
            onChange={handleChangeDesconto}
          />
          <Input
            readOnly
            name="desconto_porcentagem"
            mask={desconto_porcentagem.length > 5 ? '999%' : '99%'}
            type="string"
            placeholder="Ex.: 20%"
            label="Desconto Porcentagem (%)"
            value={desconto_porcentagem}
            onChange={handleChangeDescontoPorcentagem}
            isBold
            full
          />

          <Toggle
            margin="20px 10px"
            fontWeight="500"
            onChange={handleChangeStatus}
            label="Status"
            value={status}
            name="status"
            paddingRight
            disabled={true}
          />

          {status === true ? (
            <>
              <ContainerButton>
                <Button type="submit" size="big" full color="error" loading={isLoading} disabled={false} data-cy="desabilitarOferta">
                  Desabilitar Oferta
                </Button>
              </ContainerButton>
            </>
          ) : (
            <ContainerButton>
              <Button type="submit" size="big" full color="cancelar" loading={isLoading} disabled={true}>
                Oferta Desabilitada
              </Button>
            </ContainerButton>
          )}
        </Form>
      </DefaultContainer>

      <ModalWarning
        isOpen={showModalWarning}
        buttonLabelConfirm="Confirmar"
        label="Você tem certeza que deseja desabilitar essa oferta? Após cancelar uma oferta ela não será mais mostrada aos devedores"
        handleButtonClick={() => handleSendCancelOffer(id)}
        handleClickCancelButton={() => setShowModalWarning(false)}
        onClose={() => setShowModalWarning(false)}
      />

      <ModalConfirm
        isOpen={showModalSucess}
        onClose={() => history.push('/ofertas/listar-ofertas-titulo')}
        handleButtonClick={() => history.push('/ofertas/listar-ofertas-titulo')}
        label={`Oferta Titulo ${ofertaTituloId} Desabilitada com Sucesso`}
        buttonLabel="Ok"
      />
      <ModalError
        isOpen={showModalError}
        onClose={() => setShowModalError(false)}
        handleButtonClick={() => setShowModalError(false)}
        label="Erro na Solicitação, verifique os dados informados"
        buttonLabel="Ok"
      />
    </>
  );
};

export default EditarOfertaTitulo;
