import { OfertaGlobal } from '@/modules/Entidades/ListarOfertasGlobais/interfaces/interface';

export interface GlobalOfferV1 {
  id: string;
  name: string;
  discount_value: string;
  is_active: boolean;
  start_date: string;
  end_date: string;
  value_from: string;
  value_to: string;
  due_from: number;
  due_to: number;
  uploaded_from: number;
  uploaded_to: number;
  created_at: string;
  updated_at: string;
  company: string;
}

export function convertGlobalOfferV1(globalOffer: GlobalOfferV1): OfertaGlobal {
  return {
    id: globalOffer.id,
    valor_de: globalOffer.value_from,
    valor_ate: globalOffer.value_to,
    desconto: '',
    desconto_porcentagem: globalOffer.discount_value,
    cartao: true, // integração com simpay permite cartão
    parcela_cartao: 0,
    juros_cartao: '',
    boleto: false, // integração com simpay não permite boleto atualmente
    parcela_boleto: 0,
    juros_boleto: '',
    status: globalOffer.is_active,
    data_inicio: globalOffer.start_date || null,
    data_fim: globalOffer.end_date || null,
    apresentante: null, // não utilizamos mais apresentante
    iof: '',
    taxa_servicos: '',
    status_envio_sms: false,
    mensagem: '',
    key: globalOffer.id,
    protestado_entidade_uuid: globalOffer.company
  };
}

export function convertGlobalOffersV1(globalOffers: GlobalOfferV1[]): OfertaGlobal[] {
  return globalOffers.map(convertGlobalOfferV1);
}
