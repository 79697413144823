import jwtDecode from 'jwt-decode';
import React, { StrictMode, useEffect } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Provider, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';

import theme from './assets/styles/theme';
import { IUserCompanyProps } from './components/Interfaces/UserCompanyProps';
import { ISignInProps } from './modules/_layouts/components/HeaderCompany/components/UserSetting';
import Routes from './routes';
import history from './services/history';
import store, { Persistor } from './store';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';

const siteKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_ID as string;

const App: React.FC = () => {
  const accessToken = useSelector((state: ISignInProps) => state.SignIn.access_token);

  useEffect(() => {
    if (accessToken) {
      const userDecode = jwtDecode<IUserCompanyProps>(accessToken);
      if (userDecode && userDecode?.corporation_white_label_data?.primary_color) {
        theme.colors.primary = userDecode?.corporation_white_label_data?.primary_color;
        theme.colors.primaryCompany = userDecode?.corporation_white_label_data?.primary_color;
        theme.colors.secondary = userDecode?.corporation_white_label_data?.secondary_color;

        theme.colors.headerFooterColor =
          userDecode?.corporation_white_label_data?.header_footer_color &&
          userDecode?.corporation_white_label_data?.header_footer_color !== ''
            ? userDecode?.corporation_white_label_data?.header_footer_color
            : theme.colors.headerFooterColor;

        theme.colors.headerFooterFontColor =
          userDecode?.corporation_white_label_data?.header_footer_fontcolor &&
          userDecode?.corporation_white_label_data?.header_footer_fontcolor !== ''
            ? userDecode?.corporation_white_label_data?.header_footer_fontcolor
            : theme.colors.headerFooterFontColor;
      }
    }
  }, [accessToken]);

  if (!siteKey) {
    return <div>Error: Google reCAPTCHA site key is not defined.</div>;
  }

  return (
    <StrictMode>
      <ToastContainer />
      <GoogleReCaptchaProvider
        reCaptchaKey={siteKey}
        language="pt-BR" // Opcional: ajuste o idioma conforme necessário
      >
        <Provider store={store}>
          <PersistGate persistor={Persistor}>
            <Routes history={history} />
          </PersistGate>
        </Provider>
      </GoogleReCaptchaProvider>
    </StrictMode>
  );
};

export default App;
