import api from '@/services/api';
import { convertGlobalOffersV1 } from '@/utils/utils_v1/GlobalOffer';

export const getListaOfertasGlobais = async (params: any) => {
  // TODO params quando os filtros estiverem 100%
  const { data } = await api.get(`/users/protested-titles/corporation/ofertas-globais`, {
    params: {
      ...(params.id ? { id: params.id } : {}),
      ...(params.valor_de ? { value_from: params.valor_de } : {}),
      ...(params.valor_ate ? { value_to: params.valor_ate } : {}),
      ...(params.status ? { is_active: true } : {})
    }
  });
  const convertedGlobalOffers = convertGlobalOffersV1(data.results);
  return convertedGlobalOffers;
};
