import React from 'react';
import ReactToggle from 'react-toggle';
import 'react-toggle/style.css';
import { ToggleArea, ToggleTextContainer } from './styles';

interface Props {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: boolean;
  label: string;
  name: string;
  paddingRight?: boolean;
  paddingLeft?: boolean;
  margin?: string;
  fontWeight?: string;
  disabled?: boolean;
  useDefaultChecked?: boolean; // deve ser usado quando o componente não for controlável
  isView?: boolean;
}

const Toggle: React.FC<Props> = ({
  onChange,
  value,
  label,
  name,
  paddingRight = false,
  paddingLeft = false,
  margin,
  fontWeight,
  disabled = false,
  useDefaultChecked = false,
  isView = true
}) => {
  return (
    <ToggleArea margin={margin} isView={isView}>
      <ToggleTextContainer paddingRight={paddingRight} paddingLeft={paddingLeft} fontWeight={fontWeight}>
        <span>{label}</span>
      </ToggleTextContainer>

      {useDefaultChecked ? (
        <ReactToggle id={name} disabled={disabled} name={name} defaultChecked={value} onChange={onChange} />
      ) : (
        <ReactToggle id={name} disabled={disabled} name={name} checked={value} onChange={onChange} />
      )}
    </ToggleArea>
  );
};

export default Toggle;
