import React from 'react';
import ReactTooltip from 'react-tooltip';
import IconController from '../IconController';
import { TooltipContainer } from './styles';

interface TooltipProps {
  tooltipMessage?: string;
  padding?: string;
  margin?: string;
  isView?: boolean;
  icon?: string;
  place?: 'top' | 'right' | 'bottom' | 'left';
  type?: 'dark' | 'success' | 'warning' | 'error' | 'info' | 'light';
  effect?: 'float' | 'solid';
  iconFontSize?: string;
  backgroundColor?: string;
  iconColor?: string;
}

const Tooltip: React.FC<TooltipProps> = ({
  tooltipMessage = '',
  padding,
  margin,
  isView = false,
  icon = 'help',
  place = 'top',
  type = 'dark',
  effect = 'float',
  iconFontSize,
  backgroundColor,
  iconColor
}) => {
  return (
    <TooltipContainer padding={padding} margin={margin} isView={isView}>
      <a data-for="main" data-tip={tooltipMessage} data-iscapture="true">
        <IconController icon={icon} margin="0px" fontSize={iconFontSize} iconColor={iconColor}></IconController>
      </a>
      <ReactTooltip
        id="main"
        className="customClass"
        place={place}
        type={type}
        effect={effect}
        backgroundColor={backgroundColor}
        textColor="#FFF"
      />
    </TooltipContainer>
  );
};

export default Tooltip;
