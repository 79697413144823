import React, { useEffect, useState, useRef, useCallback } from 'react';
import { toast } from 'react-toastify';
import { FullScreenLoader } from '@/components/Ui/FullScreenLoader';
import Title from '@/components/Ui/Title';
import Button from '@/components/Ui/Button';
import Input from '@/components/Ui/Input';
import EmptyFeedback from '@/components/Helpers/EmptyFeedback';
import FeedbackImage from '@/assets/images/magnifier.png';
import { ContainerTop, FeedbackArea, ResultsArea } from '../components/EditContainer/styles';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { IEmails } from './interfaces/interface';
//pagination
import DefaultPagination from '@/components/Ui/DefaultPagination';
import {
  DefaultFilterButton,
  DefaultFilterButtonContainer,
  DefaultFilterCheckboxArea,
  DefaultFilterContainer,
  DefaultFilterField,
  DefaultFilterInputs,
  DefaultFilterRow,
  DefaultTopContainer
} from '@/components/Ui/DefaultFilterContainer/styles';
import { DefaultContainerPages, DefaultListPageContainer } from '@/components/Ui/DefaultHeader/style';
import { useHistory } from 'react-router-dom';
import GoBack from '@/components/Ui/GoBack';
import Toggle from '@/components/Ui/Toggle';
import { getTemplatesEmail } from '../EnviarEmail/providers/getTemplatesEmail';
import DataList from './paginate/DataList';
import Header from './components/Header';

const EnviarEmail: React.FC = () => {
  const history = useHistory();
  const itemsPerPage = 10;
  const [data, setData] = useState<any[]>([]); // TODO TIPAR COM MENSAGERIA
  const [displayedData, setDisplayedData] = useState<any[]>([]); // TODO TIPAR COM MENSAGERIA
  const [isLoading, setIsLoading] = useState(false);
  const allPages = Math.ceil(data.length / itemsPerPage);
  const formRef = useRef<FormHandles>(null);

  const [is_active, setIsActive] = useState(true);
  const handleChangeIsActive = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsActive(e.target.checked);
  };

  const onPageChange = (page: number = 1) => {
    const startItem = (page - 1) * itemsPerPage;
    const endItem = page * itemsPerPage;
    setDisplayedData(data.slice(startItem, endItem));
  };

  const clearFilter = useCallback((fieldValue: string): void => {
    formRef.current?.clearField(fieldValue);
  }, []);

  const clearAllFilters = useCallback((): void => {
    formRef.current?.setFieldValue('assunto', '');
    formRef.current?.setFieldValue('data_inicial', '');
    formRef.current?.setFieldValue('data_final', '');
    setIsActive(true);
  }, []);

  const handleSubmit = async (fields: IEmails) => {
    try {
      setIsLoading(true);
      const payload = {
        id: fields.id,
        assunto: fields.assunto,
        data_inicial: fields.data_inicial,
        data_final: fields.data_final,
        status: is_active
      };
      const templates = await getTemplatesEmail(payload);
      if (templates?.results.length > 0) {
        setData(templates.results);
      } else {
        toast.warning('Nenhuma campanha encontrada para este filtro!');
        setData([]);
      }
    } catch (error) {
      toast.error('Não foi possível listar os templates de E-mail');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onPageChange();
  }, [data]);

  return (
    <>
      {isLoading && <FullScreenLoader loadingMessage="Buscando Templates..." />}
      <DefaultContainerPages paddingTop paddingBottom>
        <DefaultTopContainer>
          <GoBack />
          <ContainerTop>
            <Button color="secondary" onClick={() => history.push('/mensageria/listar-campanhas/criar-template-email')}>
              Criar Campanha
            </Button>
          </ContainerTop>
        </DefaultTopContainer>
        <Title label="Campanhas" isVisiblePixel />
        <DefaultListPageContainer>
          <DefaultFilterContainer>
            <Form className="editForm" ref={formRef} onSubmit={handleSubmit}>
              <DefaultFilterInputs>
                <DefaultFilterField>
                  <Input
                    width="100%"
                    name="id"
                    type="string"
                    placeholder="Ex.: 14"
                    label="ID da Campanha"
                    padding="8px 8px 8px 6px"
                    iconSize="1.2rem"
                    isClearable={true}
                    clearFunction={() => clearFilter('id')}
                    data-cy="campanhaId"
                  />
                </DefaultFilterField>
                <DefaultFilterField>
                  <Input
                    width="100%"
                    name="assunto"
                    type="string"
                    placeholder="Ex.: Negocie seu protesto"
                    label="Assunto do E-mail"
                    padding="8px 8px 8px 6px"
                    iconSize="1.2rem"
                    isClearable={true}
                    clearFunction={() => clearFilter('assunto')}
                  />
                </DefaultFilterField>
                <DefaultFilterField>
                  <Input
                    width="100%"
                    name="data_inicial"
                    type="date"
                    label="Data Inicial"
                    padding="8px 8px 8px 6px"
                    iconSize="1.2rem"
                    isClearable={true}
                    clearFunction={() => clearFilter('data_inicial')}
                  />
                </DefaultFilterField>
                <DefaultFilterField>
                  <Input
                    width="100%"
                    name="data_final"
                    type="date"
                    label="Data Final"
                    padding="8px 8px 8px 6px"
                    iconSize="1.2rem"
                    isClearable={true}
                    clearFunction={() => clearFilter('data_final')}
                  />
                </DefaultFilterField>
              </DefaultFilterInputs>
              <DefaultFilterInputs margin="20px 0 0 0">
                <DefaultFilterCheckboxArea>
                  <Toggle onChange={handleChangeIsActive} label="Ativos" value={is_active} name="is_active" paddingRight />
                </DefaultFilterCheckboxArea>
              </DefaultFilterInputs>
              <DefaultFilterRow padding="0px 10px 0px 10px">
                <DefaultFilterButtonContainer>
                  <DefaultFilterButton>
                    <Button type="button" onClick={clearAllFilters} size="big" color="warning" width="100%" disabled={!!isLoading}>
                      Limpar Filtros
                    </Button>
                  </DefaultFilterButton>
                </DefaultFilterButtonContainer>
                <DefaultFilterButtonContainer>
                  <DefaultFilterButton>
                    <Button
                      type="submit"
                      minWidth="180px"
                      size="big"
                      color="primary"
                      width="100%"
                      loading={isLoading}
                      disabled={!!isLoading}
                      data-cy="filtrarCampanhas"
                    >
                      Filtrar
                    </Button>
                  </DefaultFilterButton>
                </DefaultFilterButtonContainer>
              </DefaultFilterRow>
            </Form>
          </DefaultFilterContainer>
          {data?.length === 0 ? (
            <FeedbackArea>
              <EmptyFeedback
                imagePath={FeedbackImage}
                title="Clique em Filtrar para buscar Resultados"
                message="Utilize os filtros acima para encontrar as campanhas cadastradas"
              />
            </FeedbackArea>
          ) : (
            <ResultsArea>
              <Header />
              <DataList templates={displayedData} />
              <GoBack />
              <DefaultPagination allPagesNumber={allPages} itemsPerPage={10} itemsNumber={data.length} pageChange={onPageChange} />
            </ResultsArea>
          )}
        </DefaultListPageContainer>
      </DefaultContainerPages>
    </>
  );
};

export default EnviarEmail;
