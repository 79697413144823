import React from 'react';
import { Container, ContainerTitle } from '../GerenciarOfertas/components/EditContainer/styles';
import ScreenNavCard from '@/components/Ui/ScreenNavCard';
import { ScreenNavCardContainerDefault } from '@/components/Ui/ScreenNavCard/styles';
import Text from '@/components/Ui/Text';

const Relatorios: React.FC = () => {
  return (
    <>
      <Container className="Container" paddingTop paddingBottom>
        <ContainerTitle className="containerTitle">
          <Text color="primary" size="big" align="center" textJustify={false} bold>
            Relatórios
          </Text>
          <Text margin="20px" color="primary" size="medium" align="center" textJustify={false}>
            O que deseja fazer? Selecione uma opção abaixo:
          </Text>
        </ContainerTitle>
        <ScreenNavCardContainerDefault repeats={3}>
          <ScreenNavCard
            titleLabel="Títulos"
            description="Visualize relatórios completos dos Títulos"
            icon="relatorios"
            btnLabel="Acessar"
            path="/relatorios/titulos"
            isDashboard={false}
            isManagement={true}
            btnSize="custom2"
            btnColor="secondary"
          />
          <ScreenNavCard
            titleLabel="Renegociações"
            description="Visualize relatórios referentes às Renegociações realizadas/em andamento"
            icon="relatorios"
            btnLabel="Acessar"
            path="/relatorios/renegociacoes"
            isDashboard={false}
            isManagement={true}
            btnSize="custom2"
            btnColor="secondary"
          />
          <ScreenNavCard
            titleLabel="Ofertas Globais"
            description="Visualize relatórios referentes às Ofertas Globais"
            icon="relatorios"
            btnLabel="Acessar"
            path="/relatorios/ofertas-globais"
            isDashboard={false}
            isManagement={true}
            btnSize="custom2"
            btnColor="secondary"
          />
          <ScreenNavCard
            titleLabel="Ofertas Título"
            description="Visualize relatórios referentes às Ofertas de Títulos"
            icon="relatorios"
            btnLabel="Acessar"
            path="/relatorios/ofertas-titulo"
            isDashboard={false}
            isManagement={true}
            btnSize="custom2"
            btnColor="secondary"
          />
          {/* <ScreenNavCard
            titleLabel="Mensageria/Emails"
            description="Visualize relatórios referentes às Mensagens de Email"
            icon="relatorios"
            btnLabel="Acessar"
            path="/relatorios/mensageria-emails"
            isDashboard={false}
            isManagement={true}
            btnSize="custom2"
            btnColor="secondary"
          /> */}
        </ScreenNavCardContainerDefault>
      </Container>
    </>
  );
};

export default Relatorios;
