import React from 'react'

import styled from 'styled-components'

const Container = styled.div`
  padding-bottom: 10px;
`

const AuthMessage: React.FC = ({ children }) => {
  return <Container>{children}</Container>
}

export default AuthMessage
