import React, { ReactNode } from 'react'

import { shade, tint } from 'polished'
import styled, { css } from 'styled-components'

import theme from '@/assets/styles/theme'

import Loading from '../Loading'

interface Props {
  size?: 'small' | 'default' | 'big'
  color?: 'primary' | 'inverse' | 'cancelar'
  full?: boolean
  loading?: boolean
  inline?: boolean
  disabled?: boolean
  width?: string
  onClick?: () => ReactNode | void
}

const sizes = (type = 'default') => {
  switch (type) {
    case 'small':
      return css`
        border-radius: 4px;
        font-size: 14px;
        padding: 8px;
      `

    case 'default':
      return css`
        border-radius: 4px;
        font-size: 18px;
        padding: 12px 20px;
      `

    case 'big':
      return css`
        border-radius: 5px;
        font-size: 20px;
        padding: 12px 20px;
      `
  };
};

const colors = (type = 'primary') => {
  switch (type) {
    case 'primary':
      return css`
        background: ${theme.colors.secondary};
        color: ${theme.colors.white};
        &:hover {
          background: ${shade(0.1, theme.colors.secondary)};
        }
      `

    case 'inverse':
      return css`
        background: transparent;
        border: 1px solid ${theme.colors.secondary};
        color: ${theme.colors.secondary};
        &:hover {
          background: ${tint(0.8, theme.colors.gray[400])};
        }
      `

    case 'cancelar':
      return css`
        background: ${theme.colors.gray[250]};
        border: 1px solid ${theme.colors.gray[250]};
        color: ${theme.colors.gray[350]};
        &:hover {
          background: ${tint(0.8, theme.colors.gray[400])};
        }
      `
  };
};

const Button: React.FC<Props & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  loading,
  onClick,
  children,
  ...rest
}) => (
  <SbuttonEnd {...rest} onClick={onClick}>
    {loading ? <Loading /> : children}
  </SbuttonEnd>
)

// const Sbutton = styled.button<Props>`
//   align-items: center;
//   background: ${theme.colors.primary};
//   border: 0;
//   color: ${theme.colors.white};
//   cursor: pointer;
//   font-weight: bold;
//   letter-spacing: 0.4px;
//   display: ${props => (props.inline ? 'inline-block' : 'flex')};
//   justify-content: center;
//   transition: background-color 0.2s, color 0.2s;
//   width: ${props => (props.full ? '100%' : props.width)};

//   &[disabled] {
//     cursor: not-allowed;
//     opacity: 0.5;
//   }
//   ${props => (props.size ? sizes[props.size] : sizes.default)}
//   ${props => (props.color ? colors[props.color] : colors.primary)}
// `

const SbuttonEnd = styled.button<Props>`
  align-items: end;
  background: ${theme.colors.primary};
  border: 0;
  color: ${theme.colors.white};
  cursor: pointer;
  font-weight: bold;
  letter-spacing: 0.4px;
  display: ${({ inline }) => (inline ? 'inline-block' : 'flex')};
  justify-content: right;
  transition: background-color 0.2s, color 0.2s;
  width: ${({ full, width }) => (full ? '100%' : width)};

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
  ${({ size }) => (size ? sizes(size) : sizes('default'))}
  ${({ color }) => (color ? colors(color) : colors('primary'))}
`

export default Button
