import IconController from '@/components/Ui/IconController';
import React, { useMemo } from 'react';
import { ChipStatus as ChipStatusStyle } from './styles';

interface ChipStatusProps {
  statusArquivo: boolean;
}

const ChipStatus = ({ statusArquivo }: ChipStatusProps) => {
  const status = useMemo(() => {
    if (statusArquivo) {
      return 'Oferta Ativa';
    }
    return 'Oferta Desabilitada';
  }, [statusArquivo]);

  return (
    <>
      <ChipStatusStyle status={statusArquivo}>
        <IconController margin="3px 8px 0px 0px" icon={statusArquivo ? 'checkCircle' : 'closeCircle'} fontSize="20px" iconColor="white" />
        {status}
      </ChipStatusStyle>
    </>
  );
};

export default ChipStatus;
