import styled, { css } from 'styled-components';

import { AgreementStatusEnum } from '@/@types/enum/agreementStatus';
import flex from '@/assets/styles/flex';
import theme from '@/assets/styles/theme/';

interface DefaultListContainerProps {
  padding?: string;
  repeats?: number;
  useGrid?: boolean;
}

export const DefaultCardContainer = styled.div<DefaultListContainerProps>`
  width: 100%;
  padding: ${(props) => (props.padding ? props.padding : '30px 50px')};
  margin-bottom: 50px;
  border-radius: 10px;
  background-color: ${theme.colors.white};

  @media only screen and (max-width: 1366px) {
    margin-bottom: 30px;
  }

  @media only screen and (max-width: 768px) {
    padding: 20px;
    margin-bottom: 20px;
  }
`;

export const DefaultCardDescription = styled.div<DefaultListContainerProps>`
  ${(props) =>
    props.useGrid &&
    `
    display: grid;
    display: ${props.repeats ? `repeat(${props.repeats}, 1fr)` : 'repeat(4, 1fr)'};
    grid-template-columns: ${props.repeats ? `repeat(${props.repeats}, 1fr)` : 'repeat(4, 1fr)'};
    gap: 12px;
  `}

  ${(props) =>
    !props.useGrid &&
    `
    display: flex;
    justify-content: space-between;
  `}
`;

export const DefaultCardColumns = styled.div`
  ${flex.direction.column}
  ${flex.justifySpaceBetween.alignStart}
  margin: 10px 10px;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    margin: 0;
    height: 60px;
    margin-top: 30px;
  }
`;

export const DefaultContainerButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
`;
