import styled from 'styled-components';

interface DefaultTemplateModalProps {
  padding?: string;
}

export const LogoProtestadoContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 16px;
`;

export const LogoProtestado = styled.img`
  width: 300px;
  height: auto;
`;

export const ContainerButton = styled.div<DefaultTemplateModalProps>`
  display: flex;
  justify-content: center;
  padding: ${(props) => (props.padding ? props.padding : '0px')};
`;
