import React, { useCallback, useEffect, useRef } from 'react';
//import XLSX from 'xlsx'

interface UploadProps {
  onSelectFile: (file: UploadFile) => void;
  uploadFile: UploadFile | null;
}

export interface UploadFile {
  name: string;
  lastModified: string;
  lastModifiedDate: Date;
  size: number;
  type: string;
  webkitRelativePath: string;
}

const Upload = ({ onSelectFile, uploadFile }: UploadProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const changeHandler = useCallback((event: any) => {
    const arquivo = event.target?.files[0];
    onSelectFile(arquivo);
  }, []);

  useEffect(() => {
    if (uploadFile == null) {
      inputRef.current.value = '';
    }
  }, [uploadFile]);

  return (
    <input
      ref={inputRef}
      id="uploadInput"
      accept=".xlsx, .xls, .csv"
      title="Selecione um arquivo"
      type="file"
      name="file"
      onChange={changeHandler}
    />
  );
};

export default Upload;
