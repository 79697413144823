import theme from '@/assets/styles/theme';
import { Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Adress, Container, Copyright } from './styles';

const Footer = () => {
  const [version, setVersion] = useState('');

  useEffect(() => {
    const fetchVersion = async () => {
      // if (window.location.hostname === 'localhost') {
      //   setVersion('local');
      // } else {
      try {
        const response = await fetch('/version.txt');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const text = await response.text(); // Obtemos o texto do arquivo
        console.log(text);
        setVersion(text.trim());
      } catch (error) {
        console.error('Erro ao buscar versão:', error);
      }
      // }
    };

    fetchVersion();
  }, []);

  return (
    <Container color={theme.colors.headerFooterColor} fontColor={theme.colors.headerFooterFontColor}>
      <Copyright>© 2022 - Protestado - Todos os direitos reservados.</Copyright>
      <Text fontSize="xs" color={theme.colors.headerFooterFontColor}>
        Versão: {version}
      </Text>
      <Adress>Av. Engenheiro Luís Carlos Berrini, nº. 1748 - Conjunto 1710, Cidade Monções, São Paulo-SP</Adress>
    </Container>
  );
};

export default Footer;
