import Button from '@/components/Ui/Button';
import IconController from '@/components/Ui/IconController';
import Text from '@/components/Ui/Text';
import { formatarDinheiro } from '@/utils/Utilities';
import React, { useEffect, useState } from 'react';
import { AccountBalanceAreaContainer, AccountBalanceTextArea } from './style';
import theme from '@/assets/styles/theme';

interface Props {
  accountBalance: number;
  withdrawBalance: () => void;
}

const AccountBalanceArea: React.FC<Props> = ({ accountBalance, withdrawBalance }) => {
  const [hasAccountBalance, setHasAccountBalance] = useState(false);
  useEffect(() => {
    if (accountBalance > 0) {
      setHasAccountBalance(true);
    } else {
      setHasAccountBalance(false);
    }
  }, [accountBalance]);
  return (
    <>
      <AccountBalanceAreaContainer backgroundColor={hasAccountBalance ? theme.colors.secondary : '#d0d0d0'}>
        <IconController margin="0px 0px -10px 0px" icon={hasAccountBalance ? 'happyFace' : 'sadFace'} fontSize="75px" iconColor={theme.colors.primary} />
        <AccountBalanceTextArea>
          <Text color="primary" size="big">
            {hasAccountBalance ? 'Você possui saldo disponível' : 'Você não possui saldo disponível'}
          </Text>
          <Text color="primary" size="big" bold>
            para saque: {formatarDinheiro(accountBalance)}
          </Text>
        </AccountBalanceTextArea>
        <Button
          isManagement
          disabled={!hasAccountBalance}
          size="custom2"
          type="button"
          full
          color="secondary"
          onClick={() => withdrawBalance()}
        >
          Transferir
        </Button>
      </AccountBalanceAreaContainer>
    </>
  );
};

export default AccountBalanceArea;
