import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import { AreaInputMask, InputMaskLabel } from './styles';
import NumberFormat from 'react-number-format';
import Tooltip from '../Tooltip';

interface Props<Multiline = false> {
  label?: string;
  note?: string;
  name: string;
  multiline?: Multiline;
  full?: boolean;
  mask?: string | (string | RegExp)[];
  isBold?: boolean;
  height?: string;
  width?: string;
  pattern?: string;
  placeholder?: string;
  disabled?: boolean;
  customMargin?: string;
  customMarginLabel?: string;
  onBlur?(event: React.SyntheticEvent): Promise<void>;
  onChange?(event: React.SyntheticEvent): void;
  value?: string | number;
  prefix: string;
  isView?: boolean;
  tooltip?: boolean;
  tooltipMessage?: string;
  tooltipPlace?: 'top' | 'right' | 'bottom' | 'left';
  tooltipType?: 'dark' | 'success' | 'warning' | 'error' | 'info' | 'light';
  tooltipEffect?: 'float' | 'solid';
  iconFontSize?: string;
  tooltipBackgroundColor?: string;
  dataCy?: string;
}

type InputProps = JSX.IntrinsicElements['input'] & Props<false>;
type TextAreaProps = JSX.IntrinsicElements['textarea'] & Props<true>;

const InputMask: React.FC<InputProps | TextAreaProps> = ({
  label,
  note,
  name,
  multiline,
  mask,
  full,
  isBold,
  height,
  disabled = false,
  customMargin = undefined,
  customMarginLabel = undefined,
  onChange,
  onBlur,
  value,
  pattern,
  placeholder,
  width,
  prefix,
  isView = true,
  tooltip = false,
  tooltipMessage,
  tooltipPlace,
  tooltipType,
  tooltipEffect,
  iconFontSize,
  tooltipBackgroundColor,
  dataCy,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      path: 'value',
      ref: inputRef.current
    });
  }, [fieldName, registerField]);

  return (
    <AreaInputMask customMargin={customMargin} isView={isView}>
      <InputMaskLabel customMargin={customMarginLabel}>
        {label && (
          <label htmlFor={fieldName}>
            {label}
            <Tooltip
              isView={tooltip}
              tooltipMessage={tooltipMessage}
              place={tooltipPlace}
              type={tooltipType}
              effect={tooltipEffect}
              iconFontSize={iconFontSize}
              backgroundColor={tooltipBackgroundColor}
            />
          </label>
        )}
      </InputMaskLabel>
      <NumberFormat
        data-cy={dataCy}
        value={value}
        disabled={disabled}
        decimalScale={2}
        decimalSeparator=","
        thousandSeparator="."
        fixedDecimalScale={false}
        prefix={prefix}
        className="NumberFormat"
        id="uploadInput"
        name={name}
        inputMode="text"
        onChange={onChange}
        placeholder={placeholder}
      />
    </AreaInputMask>
  );
};

export default InputMask;
