import { IoIosClose } from 'react-icons/io';
import styled from 'styled-components';
import theme from '@/assets/styles/theme';

interface IContainerContent {
  padding?: string;
  height?: string;
  justifyContentType?: string;
  ignoreHeight?: boolean;
}

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-content: space-around;
  align-items: center;
  justify-content: center;
`;

export const ContainerContent = styled.div<IContainerContent>`
  background: ${theme.colors.white} 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  overflow-y: auto;
  box-shadow: 0px 0px 10px #00000033;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  max-height: auto;

  ${(props) =>
    !props.ignoreHeight &&
    `
    height: ${props.height ? props.height : '80%'};
  `}

  padding: ${(props) => (props.padding ? props.padding : '20px')};
`;

export const Content = styled.div<IContainerContent>`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.justifyContentType ? props.justifyContentType : 'space-around')};
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-shrink: 1;
  justify-content: flex-start;
  align-content: flex-start;
`;

export const ModalCloseIcon = styled(IoIosClose)`
  align-self: flex-end;
  min-height: 20px;
  min-width: 20px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  margin-left: auto;
  :hover {
    opacity: 0.4;
  }
`;

export const ImageSuccess = styled.img`
  max-width: 294px;
  height: auto;
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
`;
