import styled from 'styled-components'

import { Form as UnformWeb } from '@unform/web'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 490px;

  @media only screen and (max-width: 1366px) {
    padding: 80px 0px;
    height: 100vh;
    justify-content: center;
  }

  @media only screen and (max-width: 768px) {
    margin: 0 0;
    padding: 20% 0 100vh 0;
    height: 100%;
    width: 90%;
    width: 90%;
  }
`

export const ContainerButton = styled.div`
  margin: 30px 0 0 0;
  font-size: 22px;
`

export const Form = styled(UnformWeb)`
  width: 100%;
`
