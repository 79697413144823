import React from 'react';

import SvgWarning from '@/assets/images/modal/warning-modal.svg';
import Button from '@/components/Ui/Button';
import Text from '@/components/Ui/Text';

import ModalLayout from '../ModalLayout';
import { Image, ContainerButton, TextContainer } from './styles';

interface ModalInterface {
  isOpen: boolean;
  onClose: () => void;
  handleButtonClick: () => void;
  handleClickCancelButton: () => void;
  label: string;
  buttonLabelCalcel?: string;
  buttonLabelConfirm: string;
  textTitle?: string;
}

const ModalWarning: React.FC<ModalInterface> = ({
  isOpen = false,
  onClose,
  handleButtonClick,
  handleClickCancelButton,
  label,
  buttonLabelCalcel = 'Cancelar',
  buttonLabelConfirm,
  textTitle = 'Atenção'
}) => {
  return (
    <>
      {isOpen && (
        <ModalLayout padding="16px" height="450px" onClose={onClose}>
          <Image src={SvgWarning} alt="Atenção" />
          <TextContainer>
            <Text size="big" color="yellow" bold>
              {textTitle}
            </Text>
          </TextContainer>
          <TextContainer>
            <Text size="medium" color="primary" bold textJustify>
              {label}
            </Text>
          </TextContainer>
          <ContainerButton>
            <Button color="error" onClick={handleClickCancelButton} full={false}>
              {buttonLabelCalcel}
            </Button>
            <Button color="primary" onClick={handleButtonClick} full={false} data-cy="confirmarModal">
              {buttonLabelConfirm}
            </Button>
          </ContainerButton>
        </ModalLayout>
      )}
    </>
  );
};

export default ModalWarning;
