import React from 'react';
import { Usuarios } from '../../interfaces/interface';
import { FaUserCheck } from 'react-icons/fa';
import { FaUserTimes } from 'react-icons/fa';
import {
  Container,
  ContainerButton,
  CompanyDetails,
  PaymentsDescription,
  BtnItemDisabled,
  BtnItemActive,
  Section1,
  Section2
} from './styles';
import DescriptionContainer from '@/modules/Deal/components/DescriptionContainer';
import Text from '@/components/Ui/Text';
import Button from '../../Button';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ISignInProps } from '@/modules/_layouts/components/HeaderCompany/components/UserSetting';
import { checkIsSuperUser } from '@/utils/Utilities';

interface IProps {
  posts: Usuarios[];
}

const DataList: React.FC<IProps> = ({ posts }) => {
  const history = useHistory();
  const accessToken = useSelector((state: ISignInProps) => state.SignIn.access_token);
  const isSuperUser = checkIsSuperUser(accessToken);

  return (
    <div className="list__container">
      <div className="list_data">
        {posts.map((item: Usuarios, index: number) => {
          return (
            <Container>
              <DescriptionContainer>
                <Section1>
                  <CompanyDetails>
                    <Text size="medium" bold>
                      E-mail do Usuário:{item.email}
                    </Text>
                    <Text size="small">Id: {item.id}</Text>
                    <Text size="small">Data da Criação: {moment(item.created_at).format('DD/MM/YYYY')}</Text>
                  </CompanyDetails>
                </Section1>
                <Section2>
                  <CompanyDetails>
                    <Text size="small">
                      {item.is_active === true ? (
                        <BtnItemActive>
                          <h2>
                            <FaUserCheck /> Usuário Ativo
                          </h2>
                        </BtnItemActive>
                      ) : (
                        <BtnItemDisabled>
                          <h2>
                            <FaUserTimes /> Desabilitado
                          </h2>
                        </BtnItemDisabled>
                      )}
                    </Text>
                  </CompanyDetails>
                </Section2>
                <Section2>
                  <CompanyDetails>
                    <Text size="small">
                      {item.is_admin === true ? (
                        <BtnItemActive>
                          <h2>
                            <FaUserCheck /> Usuário Admin
                          </h2>
                        </BtnItemActive>
                      ) : (
                        <BtnItemDisabled>
                          <h2>
                            <FaUserTimes /> Não é Admin
                          </h2>
                        </BtnItemDisabled>
                      )}
                    </Text>
                    <Text size="small" isView={isSuperUser}>
                      {item.is_superuser_protestado === true ? (
                        <BtnItemActive>
                          <h2>
                            <FaUserCheck /> Super Usuário
                          </h2>
                        </BtnItemActive>
                      ) : (
                        <BtnItemDisabled>
                          <h2>
                            <FaUserTimes /> Não é Super Usuário
                          </h2>
                        </BtnItemDisabled>
                      )}
                    </Text>
                  </CompanyDetails>
                </Section2>
                <PaymentsDescription>
                  <ContainerButton>
                    <Button
                      full
                      onClick={() => history.push(`/usuarios/${item.id}`)}
                      data-cy={`selecionarUsuario${index}`}
                      id={`selecionarUsuario${index}`}
                    >
                      Editar Usuário
                    </Button>
                  </ContainerButton>
                </PaymentsDescription>
              </DescriptionContainer>
            </Container>
          );
        })}
      </div>
    </div>
  );
};

export default DataList;
