import FeedbackImage from '@/assets/images/magnifier.png';
import EmptyFeedback from '@/components/Helpers/EmptyFeedback';
import Button from '@/components/Ui/Button';
import { FullScreenLoader } from '@/components/Ui/FullScreenLoader';
import Input from '@/components/Ui/Input';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { ButtonRow, FeedbackArea, ResultsArea } from '../components/EditContainer/styles';
import { getTituloRelatorioRequestFiltrar } from './providers/getTituloRelatorio';
//pagination
import {
  ButtonEndArea,
  DefaultContainerIcon,
  DefaultContainerIconText,
  DefaultFilterButton,
  DefaultFilterButtonContainer,
  DefaultFilterCheckboxArea,
  DefaultFilterContainer,
  DefaultFilterField,
  DefaultFilterInputs,
  DefaultFilterRow,
  DefaultHeaderFiltro
} from '@/components/Ui/DefaultFilterContainer/styles';
import DefaultHeader from '@/components/Ui/DefaultHeader';
import { DefaultContainerPages, DefaultListPageContainer } from '@/components/Ui/DefaultHeader/style';
import DefaultPagination from '@/components/Ui/DefaultPagination';
import GoBack from '@/components/Ui/GoBack';
import ModalConfirm from '@/components/Ui/Modal/ModalConfirm';
import ModalWarning from '@/components/Ui/Modal/ModalWarning';
import Select from '@/components/Ui/Select';
import Text from '@/components/Ui/Text';
import Toggle from '@/components/Ui/Toggle';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { Titulos } from '../ListarTitulos/interfaces/interface';
import { handleListTitulo } from '../ListarTitulos/utils/handleListTitulo';
import DataList from './components/paginate/DataList';
import { FiltroRelatorioTitulo } from './interfaces/FiltroRelatorioTitulo';
import { enviaRelatorioEmail } from './providers/getTituloRelatorio';

interface RelatorioTituloFields {
  id: string;
  nome_devedor: string;
  nosso_numero: string;
  dias_de_negociacao: string;
  dias_pagamento_confirmado: string;
  id_arquivo: string;
  numero_titulo: string;
}

const RelatoriosTitulos: React.FC = () => {
  const [data, setData] = useState<Titulos[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [displayedData, setDisplayedData] = useState<Titulos[]>([]);
  const itemsPerPage = 10;
  const allPages = Math.ceil(data.length / itemsPerPage);
  const [showModalWarning, setShowModalWarning] = useState(false);
  const [showModalSucess, setShowModalSucess] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [email, setEmail] = useState('');
  const [clearSelect, setClearSelect] = useState(false);

  const [titulo_pago, setTitulopago] = useState(false);
  const handleChangePagos = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitulopago(e.target.checked);
  };
  const [is_deleted, setIsDeleted] = useState(false);
  const handleChangeDel = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsDeleted(e.target.checked);
  };
  const [oferta_renegociacoes, setOfertaRenegociacoes] = useState(false);
  const handleChangeOfertaRenegociacoes = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOfertaRenegociacoes(e.target.checked);
  };
  const [renegociacoes_ativas, setRenegociacoesAtivas] = useState(false);
  const handleChangeRenegociacoesAtivas = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRenegociacoesAtivas(e.target.checked);
  };
  const [renegociacoes_sem_evolucao, setRenegociacoesSemEvolucao] = useState(false);
  const handleChangeRenegociacoesSemEvolucao = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRenegociacoesSemEvolucao(e.target.checked);
  };

  const [renegociacoes_pagas, setRenegociacoesPagas] = useState(false);
  const handleChangeRenegociacoesPagas = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRenegociacoesPagas(e.target.checked);
  };
  const [tempoPagamento, setTempoPagamento] = useState('');
  const handleTempoPagamento = useCallback((tempoPlataforma: string) => {
    setTempoPagamento(tempoPlataforma);
  }, []);

  const handleButtonColor = useCallback(
    (value: string): 'primary' | 'inverse' => {
      if (value === tempoPagamento) {
        return 'primary';
      } else {
        return 'inverse';
      }
    },
    [tempoPagamento]
  );

  const aceiteOfertaOptions = [
    { value: '1', label: '1 Dia' },
    { value: '2', label: '2 Dias' },
    { value: '3', label: '3 Dias' },
    { value: '4', label: '4 Dias' },
    { value: '5', label: '5 Dias' },
    { value: '6', label: '6 Dias' },
    { value: '7', label: '7 Dias' },
    { value: '8', label: '8 Dias' },
    { value: '9', label: '9 Dias' },
    { value: '10', label: '10 Dias' },
    { value: '30', label: '30 Dias' }
  ];

  const onPageChange = (page = 1) => {
    const startItem = (page - 1) * itemsPerPage;
    const endItem = page * itemsPerPage;
    setDisplayedData(data.slice(startItem, endItem));
  };

  const formRef = useRef<FormHandles>(null);

  const clearFilter = useCallback((fieldValue: string) => {
    formRef.current?.clearField(fieldValue);
  }, []);

  const clearAllFilters = useCallback(() => {
    formRef.current?.reset();
    setClearSelect(true);
    setIsDeleted(false);
    setOfertaRenegociacoes(false);
    setRenegociacoesAtivas(false);
    setRenegociacoesSemEvolucao(false);
    setRenegociacoesPagas(false);
  }, []);

  useEffect(() => {
    onPageChange();
  }, [data]);

  const handleSendEmail = async (id: any) => {
    try {
      setIsLoading(true);
      setShowModalWarning(false);
      const payload = {
        email: 'email@email.com'
      };

      await enviaRelatorioEmail(payload);
      setShowModalSucess(true);
    } catch (error) {
      toast.error('Não foi possível Desabilitar a Oferta');
      setShowModalError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (fields: RelatorioTituloFields) => {
    try {
      setIsLoading(true);

      const params = {
        id: fields.id,
        nome_devedor: fields.nome_devedor,
        nosso_numero: fields.nosso_numero,
        dias_de_negociacao: fields.dias_de_negociacao,
        // dias_pagamento_confirmado: fields.dias_pagamento_confirmado,
        is_deleted: is_deleted,
        oferta_renegociacoes: oferta_renegociacoes,
        renegociacoes_ativas: renegociacoes_ativas,
        titulo_pago: titulo_pago,
        id_arquivo: fields.id_arquivo,
        numero_titulo: fields.numero_titulo,
        dias_pagamento_confirmado: tempoPagamento
        // renegociacoes_sem_evolucao: renegociacoes_sem_evolucao,
        // renegociacoes_pagas: renegociacoes_pagas,
      } as FiltroRelatorioTitulo;

      const TituloList = await getTituloRelatorioRequestFiltrar(params);

      if (TituloList?.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const titulo: any[] = handleListTitulo(TituloList);
        setData(titulo);
      } else {
        toast.warning('Não foi possível listar Títulos com o Filtro selecionado...');
        setData([]);
      }
    } catch (error) {
      toast.error('Não foi possível listar os Títulos!');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <FullScreenLoader loadingMessage="Carregando Títulos..." />}
      <DefaultContainerPages paddingTop paddingBottom>
        <DefaultHeader label="Relatório Geral de Títulos" marginTop="12px" />
        <DefaultListPageContainer>
          <DefaultFilterContainer>
            <DefaultHeaderFiltro>
              <Text bold color="secondary" size="medium2">
                Filtro
              </Text>
              <DefaultContainerIconText onClick={() => setIsOpen(!isOpen)}>
                {!isOpen ? (
                  <Text bold color="secondary">
                    Mostrar mais filtros
                  </Text>
                ) : (
                  <Text bold color="secondary">
                    Mostrar menos filtros
                  </Text>
                )}
                <DefaultContainerIcon>{!isOpen ? <IoIosArrowDown /> : <IoIosArrowUp />}</DefaultContainerIcon>
              </DefaultContainerIconText>
            </DefaultHeaderFiltro>

            <Form className="editForm" ref={formRef} onSubmit={handleSubmit}>
              <DefaultFilterInputs>
                <DefaultFilterField>
                  <Input
                    width="100%"
                    name="id"
                    type="string"
                    placeholder="Ex.: 3200"
                    label="ID do Título"
                    padding="8px 8px 8px 6px"
                    iconSize="1.2rem"
                    isClearable={true}
                    clearFunction={() => clearFilter('id')}
                  />
                </DefaultFilterField>
                <DefaultFilterField>
                  <Input
                    width="100%"
                    name="nome_devedor"
                    type="string"
                    placeholder="Ex.: Fernando Alves"
                    label="Nome do Devedor"
                    padding="8px 8px 8px 6px"
                    iconSize="1.2rem"
                    isClearable={true}
                    clearFunction={() => clearFilter('nome_devedor')}
                  />
                </DefaultFilterField>
                <DefaultFilterField>
                  <Input
                    width="100%"
                    name="id_arquivo"
                    type="string"
                    placeholder="Ex.: 870"
                    label="ID do Arquivo Upload"
                    padding="8px 8px 8px 6px"
                    iconSize="1.2rem"
                    isClearable={true}
                    clearFunction={() => clearFilter('id_arquivo')}
                  />
                </DefaultFilterField>
                <DefaultFilterField>
                  <Input
                    width="100%"
                    name="numero_titulo"
                    type="string"
                    placeholder="Ex.: 123456"
                    label="Número do Título"
                    padding="8px 8px 8px 6px"
                    iconSize="1.2rem"
                    isClearable={true}
                    clearFunction={() => clearFilter('numero_titulo')}
                  />
                </DefaultFilterField>
                <DefaultFilterField>
                  <Input
                    width="100%"
                    name="nosso_numero"
                    type="string"
                    placeholder="Ex.: 123456/2"
                    label="Nosso Número"
                    padding="8px 8px 8px 6px"
                    iconSize="1.2rem"
                    isClearable={true}
                    clearFunction={() => clearFilter('nosso_numero')}
                  />
                </DefaultFilterField>
              </DefaultFilterInputs>
              {isOpen && (
                <>
                  <DefaultFilterInputs margin="20px 0 0 0">
                    <DefaultFilterField>
                      <Select
                        clearSelect={clearSelect}
                        setClearSelect={setClearSelect}
                        isFull
                        name="dias_de_negociacao"
                        label="Aceite de Oferta dentro de:"
                        color="gray400"
                        customMargin="20px 0px 0px 0px"
                        options={aceiteOfertaOptions}
                      />
                    </DefaultFilterField>
                    <DefaultFilterCheckboxArea>
                      <Toggle onChange={handleChangePagos} label="Títulos Pagos" value={titulo_pago} name="titulo_pago" paddingRight />
                    </DefaultFilterCheckboxArea>
                    <DefaultFilterCheckboxArea>
                      <Toggle onChange={handleChangeDel} label="Títulos Desabilitados" value={is_deleted} name="is_deleted" paddingRight />
                    </DefaultFilterCheckboxArea>
                    <DefaultFilterCheckboxArea>
                      <Toggle
                        onChange={handleChangeOfertaRenegociacoes}
                        label="Títulos com Ofertas"
                        value={oferta_renegociacoes}
                        name="oferta_renegociacoes"
                        paddingRight
                      />
                    </DefaultFilterCheckboxArea>
                    <DefaultFilterCheckboxArea>
                      <Toggle
                        onChange={handleChangeRenegociacoesAtivas}
                        label="Renegociações ativas"
                        value={renegociacoes_ativas}
                        name="renegociacoes_ativas"
                        paddingRight
                      />
                    </DefaultFilterCheckboxArea>
                  </DefaultFilterInputs>
                  <DefaultFilterInputs margin="30px 0 0 0">
                    <Text size="medium" margin="20px 20px 20px 20px" color="gray500" weight="medium">
                      Tempo do Pagamento na plataforma:
                    </Text>
                    <Text size="default" margin="20px 20px 20px 20px" color="gray500">
                      Ao selecionar essa informação, serão selecionados apenas títulos que tiveram seu pagamento computado na plataforma
                      dentro do prazo (em dias) selecionado
                    </Text>
                    <ButtonRow>
                      <Button onClick={() => handleTempoPagamento('1')} type="button" size="small" color={handleButtonColor('1')}>
                        {'Até 1D'}
                      </Button>
                      <Button onClick={() => handleTempoPagamento('7')} type="button" size="small" color={handleButtonColor('7')}>
                        {'Até 7D'}
                      </Button>
                      <Button onClick={() => handleTempoPagamento('30')} type="button" size="small" color={handleButtonColor('30')}>
                        {'Até 30D'}
                      </Button>
                      <Button onClick={() => handleTempoPagamento('90')} type="button" size="small" color={handleButtonColor('90')}>
                        {'Até 90D'}
                      </Button>
                      <Button onClick={() => handleTempoPagamento('')} type="button" size="small" color={handleButtonColor('')}>
                        Todos
                      </Button>
                    </ButtonRow>
                  </DefaultFilterInputs>
                </>
              )}
              <DefaultFilterRow padding="0px 10px 0px 10px">
                <DefaultFilterButtonContainer>
                  <DefaultFilterButton>
                    <Button type="button" onClick={clearAllFilters} size="big" color="warning" width="100%" disabled={!!isLoading}>
                      Limpar Filtros
                    </Button>
                  </DefaultFilterButton>
                </DefaultFilterButtonContainer>
                <DefaultFilterButtonContainer>
                  {data && data.length > 0 && (
                    <DefaultFilterButton padding="0px 12px 0px 0px">
                      <Button
                        onClick={() => setShowModalWarning(true)}
                        type="button"
                        minWidth="180px"
                        size="big"
                        color="primary"
                        width="100%"
                        loading={isLoading}
                        disabled={!!isLoading}
                      >
                        Exportar
                      </Button>
                    </DefaultFilterButton>
                  )}
                  <DefaultFilterButton>
                    <Button
                      type="submit"
                      minWidth="180px"
                      size="big"
                      color="primary"
                      width="100%"
                      loading={isLoading}
                      disabled={!!isLoading}
                    >
                      Filtrar
                    </Button>
                  </DefaultFilterButton>
                </DefaultFilterButtonContainer>
              </DefaultFilterRow>
            </Form>
          </DefaultFilterContainer>
          {data?.length === 0 ? (
            <FeedbackArea>
              <EmptyFeedback
                imagePath={FeedbackImage}
                title="Clique em Filtrar para buscar Resultados"
                message="
            Utilize os filtros acima para encontrar os Títulos cadastrados"
              />
            </FeedbackArea>
          ) : (
            <ResultsArea>
              <DataList posts={displayedData} />
              <ButtonEndArea>
                <Button
                  onClick={() => setShowModalWarning(true)}
                  size="big"
                  color="primary"
                  width="16%"
                  loading={isLoading}
                  disabled={!!isLoading}
                  margin="0 15px 0 0"
                >
                  Exportar
                </Button>
              </ButtonEndArea>
              <GoBack />
              <DefaultPagination allPagesNumber={allPages} itemsPerPage={10} itemsNumber={data.length} pageChange={onPageChange} />
            </ResultsArea>
          )}
        </DefaultListPageContainer>
        <ModalWarning
          isOpen={showModalWarning}
          buttonLabelConfirm="Gerar Relatório"
          label="Clique em confirmar para receber seu relatório por e-mail"
          handleButtonClick={() => {
            handleSendEmail(email);
          }}
          handleClickCancelButton={() => setShowModalWarning(false)}
          onClose={() => setShowModalWarning(false)}
        />
        <ModalConfirm
          isOpen={showModalSucess}
          onClose={() => setShowModalSucess(false)}
          handleButtonClick={() => setShowModalSucess(false)}
          label={`E-mail enviado com Sucesso`}
          buttonLabel="Ok"
        />
      </DefaultContainerPages>
    </>
  );
};

export default RelatoriosTitulos;
