import styled from 'styled-components'

import theme from '@/assets/styles/theme'

export const LinkGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const LinkGroupItem = styled.div`
  display: flex;
  flex-direction: row;
  color: ${theme.colors.gray[500]};
  padding-top: 30px;
  flex-wrap: wrap;
  * {
    text-align: center;
    flex-grow: 1;
  }
`
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 490px;
  height: 100%;
  justify-content: center;
  padding: 40px 0px;

  @media only screen and (max-width: 1366px) {
    padding: 80px 0px;
  }

  @media only screen and (max-width: 768px) {
    margin: 0 0;
    width: 90%;
  }
`
