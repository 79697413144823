import styled from 'styled-components';

interface LoadingTextProps {
  isView?: string;
}

export const Container = styled.div`
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ReactLoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 16px;
`;

export const ReactLoadingTextContainer = styled.div<LoadingTextProps>`
  display: ${(props) => !props.isView && 'none !important'};
  color: white;
  font-weight: 450;
`;
