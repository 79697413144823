import React from 'react'

import { Container } from './styles'

interface IContainerProps {
  height?: boolean
}

const DealDescription: React.FC<IContainerProps> = ({ children, height }) => {
  return <Container height={height}>{children}</Container>
}

export default DealDescription
