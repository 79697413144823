import React from 'react'
import ReactLoading from 'react-loading'

interface Loading {
  type?:
    | 'spinningBubbles'
    | 'blank'
    | 'balls'
    | 'bars'
    | 'bubbles'
    | 'cubes'
    | 'cylon'
    | 'spin'
    | 'spokes'
    | undefined
}

const Loading: React.FC<Loading> = ({ type = 'spin' }) => {
  return <ReactLoading type={type} width="25px" height="25px" color="#FFF" />
}

export default Loading
