import styled, { css } from 'styled-components';
import discountImage from '@/assets/images/discount.png';
import flex from '@/assets/styles/flex';
import theme from '@/assets/styles/theme/';

export const Container = styled.div`
  ${flex.direction.column};
  ${flex.justify.spaceBetween}
  padding: 10px 40px;
  overflow-y: auto;
  margin-bottom: 50px;
  border-radius: 10px;
  background-color: ${theme.colors.white};
  @media only screen and (max-width: 1366px) {
    margin-bottom: 30px;
  }
  @media only screen and (max-width: 768px) {
    padding: 20px;
    margin-bottom: 20px;
  }
`;

export const ItemDetails = styled.div`
  ${flex.direction.column}
  ${flex.justifySpaceBetween.alignStart}
  margin: 10px 10px;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    margin: 0;
    height: 60px;
    margin-top: 30px;
  }
`;

export const ContainerButton = styled.div`
  /* margin: 10px 0 0 0; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const PaymentDiscount = styled.div`
  ${flex.direction.row};
  ${flex.justifyStart.alignCenter};
  flex: 1;
`;

export const DiscountImage = styled.div`
  ${flex.direction.row};
  ${flex.justifyCenter.alignCenter};

  position: relative;
  background-image: url(${discountImage});
  background-repeat: no-repeat;
  width: 84px;
  height: 87px;
`;

export const DiscountDetails = styled.div`
  ${flex.direction.column};
  ${flex.justifySpaceBetween.alignStart};

  height: 87px;
  margin: 0 0 0 15px;

  @media (max-width: 768px) {
    height: 64px;
  }
`;

export const ListContainer = styled.div``;
