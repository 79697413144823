import styled from 'styled-components';

interface DefaultHeaderProps {
  marginTop?: string;
  paddingTop?: boolean;
  paddingBottom?: boolean;
  ignoreWidth?: boolean;
}

export const DefaultContainer = styled.div<DefaultHeaderProps>`
  display: flex;
  flex-direction: column;
  width: ${(DefaultHeaderProps) => (DefaultHeaderProps.ignoreWidth ? '' : '520px')};
  padding-top: ${(DefaultHeaderProps) => (DefaultHeaderProps.paddingTop ? '24px' : '')};
  padding-bottom: ${(DefaultHeaderProps) => (DefaultHeaderProps.paddingBottom ? '24px' : '')};
  margin-top: ${(DefaultHeaderProps) => (DefaultHeaderProps.marginTop ? DefaultHeaderProps.marginTop : '11.801896733403582vh')};

  @media only screen and (max-width: 1440px) {
    width: ${(DefaultHeaderProps) => (DefaultHeaderProps.ignoreWidth ? '' : '520px')};
    margin-top: 10vh;
    margin-bottom: 30px;
  }

  @media only screen and (max-width: 1024px) {
    margin-top: 10vh;
    margin-bottom: 20px;
  }

  @media only screen and (max-width: 768px) {
    margin-top: 10vh;
    margin-bottom: 20px;
  }
`;

export const DefaultContainerPages = styled.div<DefaultHeaderProps>`
  display: flex;
  flex-direction: column;
  width: 80%;
  padding-top: ${(DefaultHeaderProps) => (DefaultHeaderProps.paddingTop ? '24px' : '')};
  padding-bottom: ${(DefaultHeaderProps) => (DefaultHeaderProps.paddingBottom ? '24px' : '')};
  margin-top: ${(DefaultHeaderProps) => (DefaultHeaderProps.marginTop ? DefaultHeaderProps.marginTop : '11.801896733403582vh')};

  @media only screen and (max-width: 1440px) {
    width: 85%;
    margin-top: 10vh;
    margin-bottom: 30px;
  }

  @media only screen and (max-width: 1024px) {
    width: 85%;
    margin-top: 10vh;
    margin-bottom: 20px;
  }

  @media only screen and (max-width: 768px) {
    width: 90%;
    margin-top: 10vh;
    margin-bottom: 20px;
  }
`;

export const MarginTopTitle = styled.div<DefaultHeaderProps>`
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '35px')};
`;

export const PageMessage = styled.div`
  margin-bottom: 10px;
`;

export const DefaultListPageContainer = styled.div<DefaultHeaderProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '')};
`;
