export enum LOCAL_STORAGE_KEYS {
  REDIRECT_PATH = '@protestado_redirectPath',
  SHOW_CNPJ_MODAL = '@protestado_showCnpjModal'
}

export function setItemLocalStorage(key: string, value: string): void {
  localStorage.setItem(key, value);
}

export function removeItemLocalStorage(key: string): void {
  localStorage.removeItem(key);
}

export function getItemLocalStorage(key: string): null | string {
  const item = localStorage.getItem(key);

  if (item) {
    return item;
  }

  return null;
}
