import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import queryString from 'query-string';

import Button from '@/components/Ui/Button';
import GoBack from '@/components/Ui/GoBack';
import ModalConfirm from '@/components/Ui/Modal/ModalConfirm';
import Text from '@/components/Ui/Text';
import Title from '@/components/Ui/Title';
import history from '@/services/history';

import AuthMessage from '../components/AuthMessage';
import AuthTitle from '../components/AuthTitle';
import { Container } from '../components/Container/styles';
import { sendEmailConfirmation } from './providers/sendEmailConfirmation';

const EmailConfirmation: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();

  const handleSentEmail = async () => {
    try {
      setIsLoading(true);

      const qString = queryString.parse(location.search);
      const cpf = qString.document as string;

      if (!document) {
        history.push('/');
      }

      const payload = {
        cpf
      };

      sendEmailConfirmation(payload);
      setShowModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Container>
        <GoBack label="Voltar para login" url="/login" />
        <AuthTitle>
          <Title label="Seu email ainda não foi confirmado!" isVisiblePixel />
        </AuthTitle>
        <AuthMessage>
          <Text size="medium2">
            Por favor confirme seu endereço de email. Para confirmar, basta ir até sua caixa de entrada e clicar no link que te enviamos.
          </Text>

          <br />
          <Text size="medium2">
            Caso não tenha recebido o email de confirmação, não se preocupe, basta clicar no botão Receber o email novamente.
          </Text>

          <br />
          <br />
          <Button size="big" full color="primary" type="button" onClick={handleSentEmail} loading={isLoading} disabled={!!isLoading}>
            Receber o email novamente
          </Button>
        </AuthMessage>
      </Container>

      <ModalConfirm
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        handleButtonClick={() => setShowModal(false)}
        label="Confirmação de email enviado com sucesso!"
        buttonLabel="Ok"
      />
    </>
  );
};
export default EmailConfirmation;
