/* eslint-disable @typescript-eslint/no-explicit-any */
import { all, takeLatest } from 'redux-saga/effects'

import { Types as ConfirmationTypes } from '../ducks/ConfirmEmail'
import { Types as ForgotTypes } from '../ducks/ForgotPassword'
import { Types as ForgotTypesCompany } from '../ducks/ForgotPasswordCompany'
import { Types as RecoverTypes } from '../ducks/RecoverPassword'
import { Types as AuthTypes } from '../ducks/SignIn'
import { Types as AuthTypesEmpresas } from '../ducks/SignInEmpresas'
import { Types as SignUpTypes } from '../ducks/SignUp'
import { emailConfirmationRequest } from './EmailConfirmation'
import { forgotRequest } from './ForgotPassword'
import { forgotCompanyRequest } from './ForgotPasswordCompany'
import { recoverPasswordrequest } from './RecoverPassword'
import { loginRequest, logout } from './SignIn'
import { loginEmpresasRequest } from './SignInEmpresas'
import { signUpRequest } from './SignUp'

export default function* rootSaga() {
  return yield all([
    takeLatest<string, any>(AuthTypes.LOGIN_REQUEST, loginRequest),
    takeLatest<string, any>(AuthTypesEmpresas.LOGIN_EMPRESAS_REQUEST, loginEmpresasRequest),
    takeLatest<string, any>(ForgotTypes.FORGOT_REQUEST, forgotRequest),
    takeLatest<string, any>(ForgotTypesCompany.FORGOT_COMPANY_REQUEST, forgotCompanyRequest),
    takeLatest<string, any>(RecoverTypes.RECOVER_PASSWORD_REQUEST, recoverPasswordrequest),
    takeLatest<string, any>(SignUpTypes.SIGNUP_REQUEST, signUpRequest),
    takeLatest<string, any>(ConfirmationTypes.EMAIL_CONFIRMATION_REQUEST, emailConfirmationRequest),
    takeLatest<string, any>(AuthTypes.LOGOUT_REQUEST, logout),
  ])
}
