import React from 'react';
import { Container, Content, ModalHeader, ContainerContent, ModalCloseIcon } from './styles';

interface ModalInterface {
  onClose?: () => void;
  showCloseButton?: boolean;
  padding?: string;
  height?: string;
  justifyContentType?: string;
  ignoreHeight?: boolean;
}

const ModalLayout: React.FC<ModalInterface> = ({
  onClose,
  children,
  showCloseButton,
  padding,
  height,
  justifyContentType,
  ignoreHeight
}) => {
  if (String(showCloseButton) !== 'false') showCloseButton = true;

  return (
    <>
      <Container>
        <ContainerContent padding={padding} height={height} ignoreHeight={ignoreHeight}>
          {showCloseButton && (
            <ModalHeader>
              <ModalCloseIcon onClick={onClose} />
            </ModalHeader>
          )}
          <Content justifyContentType={justifyContentType}>{children}</Content>
        </ContainerContent>
      </Container>
    </>
  );
};

export default ModalLayout;
