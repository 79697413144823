import theme from '@/assets/styles/theme';
import styled from 'styled-components';

interface containerProps {
  marginTop?: string;
  paddingTop?: boolean;
  paddingBottom?: boolean;
}

interface Props {
  isView?: boolean;
}

export const Button = styled.div`
  margin: 30px 0 0 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const Text = styled.div`
  align-self: center;
  margin: 0px 30px 0px 30px;
`;

export const Container = styled.div<containerProps>`
  width: 90%;
  margin-top: 40vh;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  padding-top: ${(containerProps) => (containerProps.paddingTop ? '24px' : '')};
  padding-bottom: ${(containerProps) => (containerProps.paddingBottom ? '24px' : '')};
  margin-top: ${(containerProps) => (containerProps.marginTop ? containerProps.marginTop : 'calc(11.801896733403582vh + 24px)')};
  border-radius: 12px;
  background-color: #ffffff70;

  @media only screen and (max-width: 1440px) {
    margin-top: calc(10vh + 24px);
    margin-bottom: 30px;
  }

  @media only screen and (max-width: 1024px) {
    margin-top: calc(10vh + 24px);
    margin-bottom: 20px;
  }

  @media only screen and (max-width: 768px) {
    margin-top: calc(10vh + 24px);
    margin-bottom: 20px;
  }

  .label {
    margin: 10px 10px;
    font-size: 20px;
    color: ${theme.colors.primary};
    font-weight: normal;
    font-weight: bold;
    -webkit-text-decoration-color: ${theme.colors.primary};
    text-decoration-color: ${theme.colors.primary};
  }
  .labelDescricao {
    margin: 10px 10px;
    font-size: 16px;
    color: ${theme.colors.primary};
    font-weight: normal;
    -webkit-text-decoration-color: ${theme.colors.primary};
    text-decoration-color: ${theme.colors.primary};
  }
  .labelDescricaoCenter {
    text-align: center;
    margin: 10px 10px;
    font-size: 16px;
    color: ${theme.colors.primary};
    font-weight: normal;
    -webkit-text-decoration-color: ${theme.colors.primary};
    text-decoration-color: ${theme.colors.primary};
  }
  .iconsUpload {
    display: flex;
  }
`;

export const PageTitle = styled.div`
  width: 100%;
  margin-top: 35px;

  @media only screen and (max-width: 1366px) {
    margin-top: 30px;
  }

  @media only screen and (max-width: 768px) {
    margin-top: 20px;
  }
`;

export const PageMessage = styled.div`
  margin-top: 20px;

  @media only screen and (max-width: 1366px) {
    margin-top: 10px;
  }
`;

export const ContainerButton = styled.div`
  margin: 30px 0 0 0;
`;

export const ContainerGeral = styled.div`
  margin: 0px 0 0 0;
  display: flex;
  justify-content: center;
  text-align: -webkit-center;
`;

export const ContainerLeft = styled.div`
  margin: 30px 0 0 0;
  width: 40%;
  display: grid;
  align-items: normal;
  justify-content: center;
  justify-items: center;
`;

export const ContainerRight = styled.div`
  margin: 30px 0 0 0;
  width: 40%;
  display: grid;
  align-items: normal;
  justify-content: center;
  justify-items: center;
`;
export const ContainerEntidades = styled.div<Props>`
  display: ${(props) => !props.isView && 'none !important'};
  margin: 30px 0 0 0;
  width: 100%;
  display: grid;
  align-items: normal;
  justify-content: center;
  justify-items: center;
`;
export const ContainerUsuarios = styled.div<Props>`
  display: ${(props) => !props.isView && 'none !important'};
  margin: 30px 0 0 0;
  width: 100%;
  display: grid;
  align-items: normal;
  justify-content: center;
  justify-items: center;
`;

export const IconArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 60px 0px 70px;
`;

export const ContainerTitulos = styled.div<Props>`
  display: ${(props) => !props.isView && 'none !important'};
  margin: 30px 0 0 0;
  width: 100%;
  display: grid;
  align-items: normal;
  justify-content: center;
  justify-items: center;
`;

export const ContainerOfertas = styled.div<Props>`
  display: ${(props) => !props.isView && 'none !important'};
  margin: 30px 0 0 0;
  width: 100%;
  display: grid;
  align-items: normal;
  justify-content: center;
  justify-items: center;
`;

export const ContainerTitle = styled.div`
  display: grid;
  align-self: center;
  text-align: center;
`;
